import React from 'react';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import PrimaryAction from '@administrate/piston/dist/atoms/PrimaryAction/PrimaryAction';
import { Glyphicon } from 'react-bootstrap';
import inject from '../inject';
import Navigation from '../../components/Navigation/Navigation';
import { STORES } from '../../constants';
import MobileNavigation from '../../components/MobileNavigation/MobileNavigation';

import alertStore from '../../stores/alertStore';
import { CartWidget } from '../../components/CartWidget/CartWidget';
import SearchBar from './SearchBar';
import CategoryDropdown from './CategoryDropdown';
import UserMenu from '../../components/Navigation/UserMenu';
import { usePortalConfiguration } from '../../hooks/portalConfiguration';

const { STORE_CART, STORE_NAVIGATION, STORE_CATALOGUE, STORE_STORE } = STORES;

const WeblinkNavigation = ({
  [STORE_CART]: { shoppingCartSeatCount, isLoading, cart },
  [STORE_NAVIGATION]: { toCart, toGiftVouchers },
  [STORE_STORE]: {
    cartlessCheckout,
    storeDetailsIsLoading,
    giftVouchersEnabled,
  },
  t,
  type = 'general',
  back,
  disabled,
}) => {
  const isDisabled = _get(cart, 'state') === 'pending';
  const { requireLoginOnCheckout } = usePortalConfiguration();

  if (alertStore.unauthorized) {
    return null;
  }

  return (
    <>
      {type === 'general' && (
        <MobileNavigation showSignInButton={requireLoginOnCheckout} />
      )}
      <Navigation
        className={`weblink defined-navbar-styles menubar--${type}`}
        back={back}
        brand={
          <div
            className="company-logo company-logo-desktop"
            aria-label={t('weblink:home')}
          />
        }
        left={type === 'general' ? <CategoryDropdown /> : undefined}
        right={
          type === 'general' ? (
            <>
              {!storeDetailsIsLoading && !cartlessCheckout && (
                <CartWidget
                  t={t}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  toCart={toCart}
                  cartSummaryList={cart.summaryList}
                  shoppingCartSeatCount={shoppingCartSeatCount}
                  reservationsValidUntil={cart.reservationsValidUntil}
                  hasExpiredItems={!!cart.expiredItems.length}
                />
              )}
              {giftVouchersEnabled && (
                <li>
                  <PrimaryAction
                    onClick={toGiftVouchers}
                    label={[
                      <Glyphicon glyph="gift" key="gift" />,
                      t('weblink:giftVouchers'),
                    ]}
                  />
                </li>
              )}
              {requireLoginOnCheckout && <UserMenu />}
            </>
          ) : (
            undefined
          )
        }
        search={
          type === 'general' ? (
            <div className="SearchInput">
              <SearchBar id="desktop-searchBar" />
            </div>
          ) : (
            undefined
          )
        }
        disabled={disabled}
      />
    </>
  );
};

WeblinkNavigation.defaultProps = {
  back: null,
  disabled: false,
};

WeblinkNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  back: PropTypes.shape({
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default withTranslation()(
  inject(
    STORE_CART,
    STORE_NAVIGATION,
    STORE_CATALOGUE,
    STORE_STORE,
  )(observer(WeblinkNavigation)),
);
