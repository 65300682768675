import { decorate, action, observable, runInAction } from 'mobx';

class AlertStore {
  constructor() {
    runInAction(() => {
      this.show = false;
      this.alertMessage = null;
      this.alertType = 'danger';
      this.unauthorized = false;
    });
  }

  setAlertMessage = action((alertMessage, alertType = 'danger') => {
    this.alertMessage = alertMessage;
    this.alertType = alertType;
    this.show = true;
  });

  closeAlert = action(() => {
    this.show = false;
  });

  setUnauthorized = action(() => {
    this.unauthorized = true;
  });
}

decorate(AlertStore, {
  show: observable,
  alertType: observable,
  alertMessage: observable,
  unauthorized: observable,
});

export default new AlertStore();
