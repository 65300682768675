import { useContext, useEffect, useRef } from 'react';
import {
  getApolloContext,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';

function getQueryOptions(variables, options) {
  return {
    fetchPolicy: 'no-cache',
    variables,
    ...(options || {}),
    notifyOnNetworkStatusChange: true,
  };
}

export function useWebLinkClient() {
  return useContext(getApolloContext()).client;
}

export function useWebLinkQuery(query, variables, options) {
  const queryOptions = getQueryOptions(variables, options);
  return useQuery(query, queryOptions);
}

export function useLazyWeblinkQuery(query, options) {
  const queryOptions = getQueryOptions({}, options);
  return useLazyQuery(query, queryOptions);
}

export function useWebLinkMutation(mutation) {
  return useMutation(mutation, {
    fetchPolicy: 'no-cache',
  });
}

export function useScript(url) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, [url]);
}

export function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
