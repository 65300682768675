import { t } from 'i18next';

import {
  WEBLINK_CARD_DECLINED_ERROR_CODES,
  WEBLINK_CARD_DETAILS_INCORRECT_ERROR_CODES,
  WEBLINK_CARD_TYPE_NOT_ACCEPTED_ERROR_CODES,
  WEBLINK_CARD_EXPIRED_ERROR_CODES,
  WEBLINK_AUTHENTICATION_FAILURE_ERROR_CODES,
  WEBLINK_ALTERNATIVE_CARD_REQUIRED_ERROR_CODES,
  WEBLINK_TRY_CARD_AGAIN_ERROR_CODES,
  WEBLINK_NOT_TEST_MODE_ERROR_CODES,
  WEBLINK_INSUFFICIENT_FUNDS_ERROR_CODES,
} from '../constants/paymentProviderErrorCodes';

export default code => {
  switch (true) {
    case WEBLINK_CARD_DECLINED_ERROR_CODES.includes(code):
      return t('weblink:cardDeclined');
    case WEBLINK_CARD_DETAILS_INCORRECT_ERROR_CODES.includes(code):
      return t('weblink:cardDetailsIncorrect');
    case WEBLINK_CARD_TYPE_NOT_ACCEPTED_ERROR_CODES.includes(code):
      return t('weblink:cardTypeNotAccepted');
    case WEBLINK_CARD_EXPIRED_ERROR_CODES.includes(code):
      return t('weblink:cardExpired');
    case WEBLINK_ALTERNATIVE_CARD_REQUIRED_ERROR_CODES.includes(code):
      return t('weblink:alternativeCardRequired');
    case WEBLINK_INSUFFICIENT_FUNDS_ERROR_CODES.includes(code):
      return t('weblink:insufficientFunds');
    case WEBLINK_TRY_CARD_AGAIN_ERROR_CODES.includes(code):
      return t('weblink:tryCardAgain');
    case WEBLINK_NOT_TEST_MODE_ERROR_CODES.includes(code):
      return t('weblink:notTestMode');
    case WEBLINK_AUTHENTICATION_FAILURE_ERROR_CODES.includes(code):
      return t('weblink:paymentAuthenticationFailed');
    default:
      return t('weblink:transactionFailed');
  }
};
