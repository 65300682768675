import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { fr, nl, enGB, enUS } from 'date-fns/locale';
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import { withTranslation } from 'react-i18next';
import { Button } from '@administrate/piston-ux';
import { formatDateLong } from '../../utils/formatDateTime';
import bundleConfig from '../../bundleConfig';

const DateRangePicker = ({
  label,
  onSubmit,
  onUpdate,
  t,
  fromDate,
  toDate,
}) => {
  const locales = { fr, nl, enGB, enUS };
  const { locale: configLocale } = bundleConfig;
  const [expanded, setExpanded] = useState(false);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const toggleExpanded = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  };
  const clearValues = () => {
    setStartDate(null);
    setEndDate(null);
    onUpdate({
      selection: {
        startDate: null,
        endDate: null,
      },
    });
    toggleExpanded();
    onSubmit();
  };

  const dropdownWrapper = useRef(null);
  const handleClickOutside = useCallback(
    event => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (
        dropdownWrapper &&
        dropdownWrapper.current &&
        !dropdownWrapper.current.contains(event.target) &&
        !dropdownWrapper.current.contains(path[0])
      ) {
        setExpanded(false);
      }
    },
    [dropdownWrapper],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  const dateText = getDateText(startDate, endDate);

  return (
    <div
      className="dropdown-container form-group DateRangePicker"
      ref={dropdownWrapper}
    >
      <div onClick={toggleExpanded} onKeyDown={toggleExpanded} role="none">
        <label className="control-label" htmlFor="datepicker">
          {label}
        </label>
        <div className="form-control">{dateText}</div>
      </div>
      {expanded && (
        <div className="calendar-popover" id="datepicker">
          <ReactDateRangePicker
            locale={locales[configLocale]}
            ranges={[
              {
                startDate: startDate || new Date(),
                endDate: endDate || new Date(),
                key: 'selection',
              },
            ]}
            onChange={date => {
              setStartDate(date.selection.startDate);
              setEndDate(date.selection.endDate);
              onUpdate(date);
            }}
            staticRanges={[]}
            inputRanges={[]}
            months={2}
            direction="horizontal"
          />
          <Button
            id="clearDates"
            type="default"
            onClick={clearValues}
            label={t('weblink:clearFilter')} // TODO: @gbannerman - Say "Clear dates" or something instead?
          />
        </div>
      )}
    </div>
  );
};

// TODO: @gbannerman - Use translation strings
function getDateText(startDate, endDate) {
  if (startDate && endDate && startDate.getTime() === endDate.getTime()) {
    return formatDateLong(DateTime.fromJSDate(startDate).toLocal());
  }

  const start = startDate
    ? formatDateLong(DateTime.fromJSDate(startDate).toLocal())
    : null;
  const end = endDate
    ? formatDateLong(DateTime.fromJSDate(endDate).toLocal())
    : null;

  if (startDate && endDate) {
    return `${start} - ${end}`;
  }
  if (start) {
    return `After ${start}`;
  }
  if (end) {
    return `Before ${end}`;
  }
  return 'All dates';
}

DateRangePicker.defaultProps = {
  label: null,
  fromDate: null,
  toDate: null,
};

DateRangePicker.propTypes = {
  label: PropTypes.string,
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(DateRangePicker);
