import qs from 'querystring';

export const getQueryParams = () => {
  // Need to be extra careful because with embedded navigation window is undefined for some reason.
  if (!window || !window.location || !window.location.search) {
    return {};
  }
  const search = window.location.search.substring(1); // remove ? from the search component
  return qs.parse(search);
};
