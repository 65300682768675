import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { getUserLocales } from 'get-user-locale';
import introspectionQueryResultData from '../fragmentTypes.json';

export const TIMEOUT_MILLISECONDS = 60000;

export const INVALID_TOKEN_MESSAGES = [
  'Invalid Token For Cart Auth',
  'Context creation failed: Token Expired or Invalid',
];

export const isCartAuthError = error =>
  error.graphQLErrors &&
  error.graphQLErrors[0] &&
  INVALID_TOKEN_MESSAGES.includes(error.graphQLErrors[0].message);

export const buildCache = () =>
  new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    }),
  });

const buildAcceptLanguageHeaderString = localeOverride => {
  if (localeOverride) {
    return localeOverride;
  }

  return (getUserLocales() || []).join(',');
};

export const getAuthorizationHeaders = (portalAuthDomain, token, locale) => ({
  'weblink-portal': portalAuthDomain,
  'weblink-client': 'weblink',
  authorization: `Bearer ${token}`,
  'Accept-Language': buildAcceptLanguageHeaderString(locale),
});
