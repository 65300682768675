import wrapPromise from './wrapPromise';

function toData(response) {
  return response.data;
}

/** **Must be called outside of the suspended component**. In the parent or outside of React's rendering.
 * Uses apollo client in a react suspense compatible way.
 * Returns a resource that resolves .data to the caller after reading is complete
 *
 * @param {ApolloClient} client
 * @param {*} query A gql tagged query
 * @returns A resource to call .read inside a child of a suspense component
 */
function createQueryResource(client, query) {
  // TODO techincally this does not have to be a hook (CHECK is it even valid as a hook?)
  return wrapPromise(client.query({ query }).then(toData));
}

export default createQueryResource;
