import React, { useEffect } from 'react';
import { usePortalConfiguration } from '../hooks/portalConfiguration';
import { useAuth0 } from '../auth/lib/react-auth0-wrapper';

const SIGN_IN_ROUTE = '/signin';

export const withAuthenticationRequired = WrappedComponent => props => {
  const { requireLoginOnCheckout } = usePortalConfiguration();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const mustLogin = requireLoginOnCheckout && !isAuthenticated;
  useEffect(() => {
    if (mustLogin) {
      const targetPath = window.location.pathname + window.location.search;
      const appState = { targetPath };
      const redirectParams = {
        operation: 'checkout',
        appState,
        password_reset_redirect_uri: new URL(
          `${SIGN_IN_ROUTE}?next=${encodeURIComponent(targetPath)}`,
          window.location.origin,
        ).toString(),
      };

      loginWithRedirect(redirectParams);
    }
  }, [mustLogin, loginWithRedirect]);

  if (mustLogin) {
    return <></>;
  }
  return <WrappedComponent {...props} />;
};
