import gql from 'graphql-tag';

export const getCountries = gql`
  query getCountries {
    countries {
      code
      name
      id
      provinces {
        code
        name
      }
    }
  }
`;

export default {
  getCountries,
};
