import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

import ApolloLinkTimeout from 'apollo-link-timeout';
import {
  buildCache,
  getAuthorizationHeaders,
  TIMEOUT_MILLISECONDS,
} from './apolloClientUtils';

import configStore from '../stores/configStore';

const cache = buildCache();

const createIdpTokenAuthLink = (portalAuthDomain, getTokenSilently) =>
  setContext(async (_, { headers }) => {
    const { locale } = configStore;
    const token = await getTokenSilently();
    return {
      headers: {
        ...headers,
        ...getAuthorizationHeaders(portalAuthDomain, token, locale),
      },
    };
  });

export const createAuthenticatedClient = ({
  uri,
  portalAuthDomain,
  getTokenSilently,
}) =>
  new ApolloClient({
    link: ApolloLink.from([
      createIdpTokenAuthLink(portalAuthDomain, getTokenSilently),
      new ApolloLinkTimeout(TIMEOUT_MILLISECONDS),
      new HttpLink({ uri, credentials: 'same-origin' }),
    ]),
    cache,
  });
