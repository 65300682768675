import gql from 'graphql-tag';

const getStoreDetails = gql`
  query getStoreDetails {
    store {
      instanceCode
      currency {
        code
        name
        symbol
      }
      region {
        id
        code
      }
      paymentMethods {
        id
        __typename
        paymentAttributeDefinitions {
          key
          label
          type
          options {
            label
            value
          }
        }
        ... on Stripe {
          publicKey
        }
        ... on Check {
          mailingAddress {
            lines
            postcode
            region
            town
            country {
              name
            }
          }
        }
        ... on Converge {
          isLive
        }
      }
      defaultErrorMessage
      name
      terms {
        title
        text
      }
      taxInclusive
      isTaxInclusiveOnly
      confirmEmails
      hidePrices
      cartlessCheckout
      requireLearnerDetails
      requireLoginOnCheckout
      hideCompanyFieldForBooker
      requireBookerBillingAddress
      giftVoucherConfiguration {
        id
        name
        currencyCode
        productDescription
      }
      priceLevels {
        id
        name
      }
      giftVoucherEmailTemplates {
        edges {
          node {
            label
            id
          }
        }
      }
      postalCountries {
        id
        name
        code
      }
      trainingRequestWorkflow {
        id
        enabled
      }
      overrideUrls {
        catalogue
        cart
      }
      multiRegion
      idpConfiguration {
        brand
        clientId
        domain
      }
      analyticsEnabled
    }
  }
`;

const getCustomFieldDefinitions = gql`
  query getPortalCustomFieldDefinitions(
    $type: SupportsWebLinkPortalCustomFieldEntities
  ) {
    customFieldDefinitions(type: $type) {
      key
      label
      type
    }
  }
`;

export default { getStoreDetails, getCustomFieldDefinitions };
