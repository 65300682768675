import React from 'react';
import PropTypes from 'prop-types';

const DetailsItem = ({ label, value }) =>
  value ? (
    <p className="col-sm-6">
      <span className="buyer-detail-label">{label}</span>
      <span className="buyer-detail-value">{value}</span>
    </p>
  ) : null;

DetailsItem.defaultProps = {
  value: null,
};

DetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default DetailsItem;
