import React from 'react';

/** We emit these same styles in the builder, but we can't
 * rely on those styles existing in the DOM for older installations
 * that may not have the initial pre loader bar
 */
const LOADING_STYLES = `
.placeholder {
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: 1em;
  background: #f3f9fc;
  display: inline-block;
  background-size: 1000px 100%;
}
@media only screen and (min-width: 600px) {
  .placeholder {
    margin: 12px 20%;
    max-width: 60%;
  }
}
@media only screen and (min-width: 1200px) {
  .placeholder {
    margin: 12px 15%;
    max-width: 70%;
  }
}

.placeholder.animated {
  /* shimmer is defined in piston-ux styles that we already import (and hopefully in preloader) */
  animation: shimmer 4s infinite linear;
  background-image: linear-gradient(
    to right,
    #f3f9fc 4%,
    #e7f3f9 25%,
    #f3f9fc 36%
  );
}
`;

const LoadingBar = () => {
  return (
    <>
      <style>{LOADING_STYLES}</style>
      <div className="animated placeholder" />
    </>
  );
};

export default LoadingBar;
