import gql from 'graphql-tag';

const getPointOfSaleOrderFields = gql`
  query getPointOfSaleOrderFields {
    pointOfSaleOrderFields {
      key
      label
      isRequired
      type
      options {
        label
        value
      }
      section
    }
  }
`;

export default getPointOfSaleOrderFields;
