import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Icon = ({ glyph, size, pro }) => (
  <span className={size ? `Icon ${size}` : 'Icon'}>
    {pro ? (
      <span className={`glyphicon-pro glyphicon-pro-${glyph}`} />
    ) : (
      <Glyphicon glyph={glyph} />
    )}
  </span>
);

Icon.defaultProps = {
  size: null,
  pro: false,
};

Icon.propTypes = {
  glyph: PropTypes.string.isRequired,
  size: PropTypes.string,
  pro: PropTypes.bool,
};

export default Icon;
