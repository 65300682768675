import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Card,
  PrimaryAction,
  TextInput,
  FieldLabel,
} from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER, STORES } from '../../constants';
import inject from '../inject';

const { STORE_DETAILS, STORE_STORE, STORE_CART } = STORES;
const maxGiftVoucherValue = 250;
const minGiftVoucherValue = 0.01;

const GiftVouchersCard = ({
  t,
  [STORE_DETAILS]: { addGiftVoucherToCartAction },
  [STORE_STORE]: { giftVoucherConfigurationId, formatCurrency },
  [STORE_CART]: { cart },
}) => {
  const [giftVoucherAmount, setGiftVoucherAmount] = useState('');
  const [amountAboveMax, setAmountAboveMax] = useState(false);
  const [amountBelowMin, setAmountBelowMin] = useState(false);

  const validateAmount = amount => {
    setGiftVoucherAmount(amount);
    const numericalAmount = parseFloat(amount);
    if (numericalAmount > maxGiftVoucherValue) {
      setAmountAboveMax(true);
      setAmountBelowMin(false);
      return;
    }
    if (numericalAmount < minGiftVoucherValue) {
      setAmountBelowMin(true);
      setAmountAboveMax(false);
      return;
    }
    if (amount.match(/^\d*$/) === null) {
      setGiftVoucherAmount('');
      return;
    }

    setAmountBelowMin(false);
    setAmountAboveMax(false);
  };

  const onSubmit = () => {
    addGiftVoucherToCartAction({
      giftVoucherId: giftVoucherConfigurationId,
      amount: giftVoucherAmount,
    });
  };

  return (
    <Card>
      <Card.Body type="GiftVoucher-detail">
        <img
          className="GiftVoucherImage"
          src={CATALOG_ITEM_IMAGE_PLACEHOLDER}
          alt="Alt"
        />
        <div className="card-content call-to-actions">
          <p>{t('weblink:createYourGiftVoucher')}</p>
          <FieldLabel label={t('weblink:amount')} />
          <TextInput
            name="amount"
            value={giftVoucherAmount}
            onChange={validateAmount}
          />
          {giftVoucherAmount !== '' && amountAboveMax && (
            <label htmlFor="amount" className="text-danger">
              {t('weblink:giftVoucherAboveMaxValue') +
                formatCurrency(maxGiftVoucherValue)}
            </label>
          )}
          {giftVoucherAmount !== '' && amountBelowMin && (
            <label htmlFor="amount" className="text-danger">
              {t('weblink:giftVoucherBelowMinValue') +
                formatCurrency(minGiftVoucherValue)}
            </label>
          )}
          <PrimaryAction
            onClick={onSubmit}
            label={t('weblink:addToCart')}
            disabled={
              amountAboveMax ||
              amountBelowMin ||
              giftVoucherAmount === '' ||
              !!cart.promotionalCode
            }
            disabledReason={
              giftVoucherAmount && !!cart.promotionalCode
                ? t('weblink:cannotAddGiftVoucherCartContainsPromoCode')
                : undefined
            }
          />
        </div>
      </Card.Body>
    </Card>
  );
};

GiftVouchersCard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_DETAILS, STORE_STORE, STORE_CART)(observer(GiftVouchersCard)),
);
