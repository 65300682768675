import React, { Suspense } from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ApolloClient } from 'apollo-client';
import { observer } from 'mobx-react-lite';

import Router from './Router';
import { Provider as ContextProvider } from './contexts';
import { configure as configureI18n } from './i18n';
import ModalOutlet from './components/ModalOutlet/ModalOutlet';
import portalStore from './stores/portalStore';
import { WeblinkApiConfigurationPropType } from './graphql/providers/WeblinkApiProvider';

const i18n = configureI18n();

const Weblink = ({
  stores,
  history,
  integration,
  apolloClient,
  weblinkApiConfiguration,
}) => (
  <ContextProvider
    stores={stores}
    integration={integration}
    i18n={i18n}
    apolloClient={apolloClient}
    weblinkApiConfiguration={weblinkApiConfiguration}
  >
    <Suspense fallback="Loading...">
      <ReactRouter history={history}>
        <Router integration={integration} embedded={!!portalStore.embedSite} />
      </ReactRouter>
    </Suspense>
    <ModalOutlet />
  </ContextProvider>
);

Weblink.defaultProps = {
  integration: false,
};

Weblink.propTypes = {
  integration: PropTypes.bool,
  stores: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  apolloClient: PropTypes.instanceOf(ApolloClient).isRequired,
  weblinkApiConfiguration: WeblinkApiConfigurationPropType.isRequired,
};

export default observer(Weblink);
