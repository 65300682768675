import React from 'react';
import PropTypes from 'prop-types';
import { Notice } from '@administrate/piston';
import { withTranslation } from 'react-i18next';

const NonDiverseEmailsNotice = ({ t }) => (
  <Notice
    title={`${t('weblink:nonDiverseEmailsTitle')}`}
    body={`${t('weblink:nonDiverseEmailsBody')}`}
    type="danger"
  />
);

NonDiverseEmailsNotice.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NonDiverseEmailsNotice);
