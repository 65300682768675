import React, { useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ActionMenu } from '@administrate/piston';

import NavDropdown from '../../components/NavDropdown';
import inject from '../inject';
import { STORES } from '../../constants';

const { STORE_CATALOGUE, STORE_NAVIGATION } = STORES;

const CategoryDropdown = ({
  [STORE_NAVIGATION]: { toCategory },
  [STORE_CATALOGUE]: {
    allCategories,
    allCategoriesIsLoading: loading,
    navbarOpen,
    changeNavbarOpen,
  },
  t,
}) => {
  const [topHeight, setTopHeight] = useState(0);

  const categoryDropdown = useRef(null);
  const dropdownWrapper = useRef(null);
  const menuDivider = useRef(null);

  const dropdownRows = loading
    ? []
    : allCategories
        .filter(({ node }) => !node.parent)
        .reduce((acc, { node }, index) => {
          let items = [];
          if (node.categories.edges.length) {
            items = node.categories.edges.map(({ node: innerNode }) => (
              <ActionMenu.MenuItem
                id={innerNode.id}
                onSelect={() => {
                  toCategory({
                    categoryId: node.id,
                    categoryName: node.name,
                    subcategoryId: innerNode.id,
                    subcategoryName: innerNode.name,
                  });
                  changeNavbarOpen(false);
                }}
                key={innerNode.id}
              >
                {innerNode.name}
              </ActionMenu.MenuItem>
            ));
          }
          acc.push(
            <ActionMenu.MenuItem
              id={node.id}
              onSelect={() => {
                toCategory({ categoryId: node.id, categoryName: node.name });
                changeNavbarOpen(false);
              }}
              key={node.id}
            >
              {node.name}
            </ActionMenu.MenuItem>,
          );

          if (items.length) {
            acc.push(
              <ul
                key={`${node.id}-items`}
                style={{ top: `${topHeight * index - 1}px` }}
                className="dropdown-menu navigation-dropdown-menu-sub-categories"
                id={node.name}
              >
                {items}
              </ul>,
            );
          }
          return acc;
        }, [])
        .concat([
          <div className="divider" ref={menuDivider} key="divider" />,
          <ActionMenu.MenuItem
            onSelect={() => {
              toCategory();
              changeNavbarOpen(false);
            }}
            key={t('weblink:allCourses')}
            id="all-courses"
          >
            {t('weblink:allCourses')}
          </ActionMenu.MenuItem>,
        ]);

  const handleClickOutside = useCallback(
    event => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (
        dropdownWrapper &&
        dropdownWrapper.current &&
        !dropdownWrapper.current.contains(event.target) &&
        path && !dropdownWrapper.current.contains(path[0])
      ) {
        changeNavbarOpen(false);
      }
    },
    [dropdownWrapper, changeNavbarOpen],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    if (categoryDropdown.current && menuDivider.current) {
      const height = categoryDropdown.current.offsetHeight;
      const divider = menuDivider.current.offsetHeight;
      const categories = dropdownRows.filter(
        el => !(el.type === 'ul' || el.props.className === 'divider'),
      ).length;
      const numberOfDividers =
        dropdownRows.filter(el => el.props.className === 'divider').length *
        divider;
      setTopHeight((height - numberOfDividers) / categories);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [dropdownRows, handleClickOutside]);

  return (
    <NavDropdown
      open={navbarOpen}
      onClick={() => changeNavbarOpen(!navbarOpen)}
      ref={dropdownWrapper}
      categoryDropdownRef={categoryDropdown}
      label={
        <span>
          {t('weblink:categories')}
          &nbsp;
          <span className="caret" />
        </span>
      }
    >
      {dropdownRows}
    </NavDropdown>
  );
};

CategoryDropdown.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_CATALOGUE, STORE_NAVIGATION)(observer(CategoryDropdown)),
);
