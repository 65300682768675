import React, { useEffect, useState } from 'react';
import {
  useTypedFormValues,
  FutureArticleHeader,
} from '@administrate/piston-ux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

export const CatalogueFilterBar = ({ loading, onChange }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  useEffect(() => {
    onChange({
      search,
    });
  }, [onChange, search]);

  return (
    <FutureArticleHeader
      loading={loading}
      search={{
        label: t('courses'),
        onChange: value => setSearch(value),
      }}
      filters={{
        values: useTypedFormValues({
          search: '',
        }),
      }}
      id="learning-search"
    />
  );
};

CatalogueFilterBar.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

CatalogueFilterBar.defaultProps = {
  loading: false,
  onChange: noop,
};
