import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@administrate/piston';
import {
  Col,
  Container,
  OpacityOverlay,
  Placeholder,
  Row,
} from '@administrate/piston-ux';

export const SummaryCardSkeleton = ({ t, showAmendCart }) => (
  <OpacityOverlay on>
    <Card>
      <div className="CardTitle SummaryCard__header">
        <h2>{t('weblink:summary')}</h2>
        {showAmendCart && (
          <p className="SummaryCard__header_amend">
            {t('weblink:amendMyCart')}
          </p>
        )}
      </div>
      <Card.Body>
        <Container fluid className="SummaryCard__content">
          <Row>
            <Col xs={12}>
              <Placeholder />
              <hr />
              <Placeholder />
              <Placeholder />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  </OpacityOverlay>
);

SummaryCardSkeleton.propTypes = {
  t: PropTypes.func.isRequired,
  showAmendCart: PropTypes.bool.isRequired,
};
