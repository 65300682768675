import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Breadcrumb, HorizontalSpacer, PageTitle } from '@administrate/piston';
import { useIntegration } from '../hooks/integration';
import LoadingBar from './LoadingBar/LoadingBar';

export const PageLoadingSkeleton = () => {
  const { active: integrationEnabled } = useIntegration();

  if (integrationEnabled) {
    return <LoadingBar />;
  }

  return (
    <>
      <Navbar
        inverse
        className="Navigation weblink defined-navbar-styles menubar--general"
      >
        <Navbar.Header>
          <Navbar.Brand></Navbar.Brand>
        </Navbar.Header>
      </Navbar>
      <article>
        <>
          <div className="course-overview">
            <PageTitle className="detail">
              <Breadcrumb>
                <Breadcrumb.Content>&nbsp;</Breadcrumb.Content>
              </Breadcrumb>
              <h1>&nbsp;</h1>
            </PageTitle>
            <HorizontalSpacer type="double" />
          </div>
        </>
      </article>
    </>
  );
};
