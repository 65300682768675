import React from 'react';
import PropTypes from 'prop-types';

import SummaryCard from '../SummaryCard/SummaryCard';

const CartSummaryCard = ({ toCatalogue }) => {
  return (
    <SummaryCard canCheckout canRemovePromotionCode toCatalogue={toCatalogue} />
  );
};

CartSummaryCard.propTypes = {
  toCatalogue: PropTypes.func,
};

CartSummaryCard.defaultProps = {
  toCatalogue: null,
};

export default CartSummaryCard;
