import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import inject from '../inject';
import { STORES } from '../../constants';
import SummaryCard, {
  Component as SummaryCardComponent,
} from '../../components/SummaryCard/SummaryCard';
import portalStore from '../../stores/portalStore';

const { STORE_CART, STORE_NAVIGATION, STORE_STORE } = STORES;

const SummaryCardContainer = ({
  [STORE_CART]: {
    cart,
    isLoading,
    disableCheckout,
    cartlessCheckoutIsUpdating,
    removePromotionCode,
    unapplyGiftVoucher,
    containsValidItems,
  },
  [STORE_STORE]: { cartlessCheckout, storeDetailsIsLoading },
  [STORE_NAVIGATION]: { toCheckout, toCart },
  canCheckout,
  navigateToCart,
  showCartContent,
  showAmendCart,
  retrievedCart,
  canRemovePromotionCode,
  toCatalogue,
}) => {
  return (
    <SummaryCard
      navigateToCatalogue={
        portalStore.embedSite
          ? () => {
              window.location = portalStore.embedSite;
            }
          : toCatalogue
      }
      navigateToCheckout={toCheckout}
      navigateToCart={navigateToCart || toCart}
      price={retrievedCart ? retrievedCart.price : cart.price}
      cartContent={retrievedCart ? retrievedCart.items : cart.items}
      canCheckout={canCheckout}
      disabled={isLoading || disableCheckout}
      promotionalCode={cart.promotionalCode}
      giftVoucherApplications={cart.giftVoucherApplications}
      showCartContent={showCartContent}
      showAmendCart={showAmendCart}
      cartlessCheckout={cartlessCheckout}
      storeDetailsIsLoading={storeDetailsIsLoading}
      cartlessCheckoutIsUpdating={cartlessCheckoutIsUpdating}
      removePromotionCode={removePromotionCode}
      unapplyGiftVoucher={unapplyGiftVoucher}
      primaryActionDisabled={!containsValidItems}
      canRemovePromotionCode={canRemovePromotionCode}
    />
  );
};

SummaryCardContainer.defaultProps = {
  canCheckout: SummaryCardComponent.defaultProps.canCheckout,
  showCartContent: SummaryCardComponent.defaultProps.showCartContent,
  showAmendCart: SummaryCardComponent.defaultProps.showAmendCart,
  toCatalogue: null,
  retrievedCart: null,
};

SummaryCardContainer.propTypes = {
  canCheckout: SummaryCardComponent.propTypes.canCheckout,
  showCartContent: SummaryCardComponent.propTypes.showCartContent,
  showAmendCart: SummaryCardComponent.propTypes.showAmendCart,
  retrievedCart: PropTypes.shape({}),
  canRemovePromotionCode: PropTypes.bool.isRequired,
  toCatalogue: PropTypes.func,
};

export default inject(
  STORE_CART,
  STORE_NAVIGATION,
  STORE_STORE,
)(observer(SummaryCardContainer));
