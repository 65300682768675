import { action, decorate, observable } from 'mobx';
import { get as _get } from 'lodash';

import queries from '../queries';
import BaseStore from './baseStore';

const {
  course: {
    getCourseBasicDetailsByCode,
    getCourseCodeById,
    getAvailableCourses,
  },
} = queries;

class CourseStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.initialize();
  }

  initialize = action('CourseStore initialize', () => {
    this.courseBasicDetails = {};
    this.availableCourses = [];
    this.isLoading = true;
    this.isError = false;
  });

  setLoadingState = action('setLoadingState', value => {
    this.isLoading = value;
  });

  setErrorState = action('setErrorState', value => {
    this.isError = value;
  });

  updateCourseBasicDetails = action('updateCourseBasicDetails', rawData => {
    this.courseBasicDetails = {
      id: _get(rawData, 'courses.edges[0].node.id'),
      code: _get(rawData, 'courses.edges[0].node.code'),
      name: _get(rawData, 'courses.edges[0].node.name'),
    };
  });

  updateAvailableCourses = action('updateAvailableCourses', rawData => {
    this.availableCourses = rawData.courses.edges.map(
      ({ node: { id, name } }) => ({
        id,
        name,
      }),
    );
  });

  queryCourseBasicDetails = action(
    'queryCourseBasicDetails',
    async ({ code }) => {
      this.setLoadingState(true);
      this.setErrorState(false);
      const rawResponse = await this.apolloClient.query({
        query: getCourseBasicDetailsByCode,
        variables: {
          code: decodeURI(code),
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      if (rawResponse.errors) {
        this.setErrorState(true);
        return;
      }

      this.updateCourseBasicDetails(rawResponse.data);
      this.setLoadingState(false);
    },
  );

  getAvailableCourses = action('getAvailableCourses', async () => {
    const rawResponse = await this.apolloClient.query({
      query: getAvailableCourses,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (rawResponse.errors) {
      this.setErrorState(true);
      return;
    }

    this.updateAvailableCourses(rawResponse.data);
  });

  getCourseDataById = async courseId => {
    const rawResponse = await this.apolloClient.query({
      query: getCourseCodeById,
      variables: {
        courseId,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (rawResponse.errors) {
      return null;
    }
    const node = _get(rawResponse, 'data.courses.edges[0].node', null);
    if (!node) {
      return null;
    }
    return { id: node.id, code: node.code, name: node.name };
  };
}

decorate(CourseStore, {
  courseBasicDetails: observable,
  availableCourses: observable,
  isLoading: observable,
  isError: observable,
});

export default CourseStore;
