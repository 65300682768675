import { withTranslation } from 'react-i18next';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';

import {
  Card,
  Form,
  FutureArticleHeader,
  Input,
  List,
  ListCell,
  ListDrawer,
  ListRow,
  Prompt,
  StaticInput,
  StatusLabel,
  useTypedFormValues,
  ListEmpty,
} from '@administrate/piston-ux';
import { FormArray, FormConsumer } from '@administrate/piston-ux/lib/Form';
import { hasErrors } from '@administrate/piston-ux/lib/Submit';
import { ColorOptions } from '@administrate/piston-ux/lib/types';

import PropTypes from 'prop-types';
import withCurrency from '../../../containers/withCurrency';
import { EventObjectiveSelector } from './EventObjectiveSelector';
import { getLearningObjectiveOptions } from './mappers';
import { EventDateRange } from '../../EventDateRange';
import { learningObjective } from '../../../proptypes/pathDetails';

const SelectDateWarning = withTranslation()(
  observer(({ errors, t }) => {
    if (hasErrors(errors)) {
      return <Prompt type="warning" message={t('weblink:selectDate')} />;
    }
    return null;
  }),
);

const objectiveColumn = '150px 3fr 2fr 3fr 2fr';

const CourseStatusLabel = observer(({ errors, t }) => (
  <StatusLabel
    color={hasErrors(errors) ? ColorOptions.LightGrey : ColorOptions.Green}
    text={t('weblink:course')}
  />
));

const statusLabelText = (values, t) => {
  if (values.objective.type === 'LearningPath') {
    return t('weblink:learningPath');
  }
  if (values.objective.type === 'Event') {
    return t('weblink:course');
  }
  if (values.objective.type === 'External') {
    return t('weblink:external');
  }
  return values.objective.type;
};

const PathObjectives = ({
  learningPathId,
  learningObjectives,
  onObjectiveSelection,
  requireObjectiveFulfillment,
  showRemainingPlacesFilter,
  showLocationFilter,
  minimumRemainingPlaces,
  location,
  locale,
  t,
  onEventSearchCompleted,
}) => {
  const values = useTypedFormValues({
    objectives: getLearningObjectiveOptions(learningObjectives, {
      location,
      startDate: null,
      availableSeats:
        minimumRemainingPlaces !== null
          ? minimumRemainingPlaces.toString()
          : null,
    }),
  });

  const firstCourseArrayIndex = values.objectives.findIndex(
    node => node.objective.type === 'Course',
  );

  const openDrawer = (arrayIndex, eventId) => {
    if (eventId) return false;
    return arrayIndex === firstCourseArrayIndex;
  };

  const selectEventObjective = (objectiveId, selectedEvent) => {
    const { objective } = values.objectives.find(
      ({ id }) => id === objectiveId,
    );
    objective.event.eventId = selectedEvent.id;
    objective.event.location = selectedEvent.location
      ? selectedEvent.location.name
      : '-';
    objective.event.start = selectedEvent.start;
    objective.event.end = selectedEvent.end;
    objective.event.timeZoneName = selectedEvent.timeZoneName;
    objective.event.deliveryMethod = selectedEvent.deliveryMethod;
    objective.event.remainingPlaces = selectedEvent.remainingPlaces;
    onObjectiveSelection(objectiveId, selectedEvent);
  };

  return (
    <Form values={values} onSubmit={undefined}>
      <Card>
        <FutureArticleHeader
          title={t('weblink:objectives')}
          dataTestId="learning-path-objective-selector"
        />
        {values.objectives.length === 0 ? (
          <ListEmpty text={t('weblink:noObjectives')} />
        ) : (
          <List hover={false} extraClass="objectives">
            <FormArray name="objectives">
              <FormConsumer>
                {({ values: formValue, arrayIndex }) => (
                  <Observer>
                    {() => (
                      <>
                        {formValue.objective &&
                          (formValue.objective.type === 'Course' ||
                            formValue.objective.type === 'Event') && (
                            <ListRow
                              gridColumns={objectiveColumn}
                              dataTestId={`objective-${formValue.objective.id}-row`}
                            >
                              <ListCell
                                label={t('weblink:objectiveType')}
                                dataTestId={`objective-${formValue.objective.id}-row-type`}
                              >
                                <FormConsumer>
                                  {({ errors }) => (
                                    <CourseStatusLabel errors={errors} t={t} />
                                  )}
                                </FormConsumer>
                              </ListCell>
                              <ListCell
                                label={t('weblink:objectiveName')}
                                extraClass="objectives--title"
                                dataTestId={`objective-${formValue.objective.id}-row-title`}
                              >
                                <StaticInput name="objective.name" label="" />
                                <div className="d-none">
                                  <Input
                                    type="hidden"
                                    name="objective.event.eventId"
                                    valid={v => !!v || 'Value is Required'}
                                    label={t('weblink:objectiveName')}
                                    uniqueId={formValue.id}
                                  />
                                </div>
                              </ListCell>
                              <ListCell
                                label={t('weblink:location')}
                                extraClass={
                                  formValue.objective.event.location
                                    ? 'hide-label pt-2'
                                    : 'd-none'
                                }
                              >
                                <StaticInput
                                  name="objective.event.location"
                                  label={t('weblink:location')}
                                />
                                <div className="d-none">
                                  <Input
                                    type="hidden"
                                    name="objective.event.location"
                                    valid={v => !!v || 'Value is Required'}
                                    label={t('weblink:objectiveLocation')}
                                    uniqueId={formValue.id}
                                  />
                                </div>
                              </ListCell>
                              <ListCell
                                label={t('weblink:dates')}
                                extraClass={
                                  formValue.objective.event.start ||
                                  formValue.objective.event.end
                                    ? 'hide-label'
                                    : 'd-none hide-label'
                                }
                              >
                                <EventDateRange
                                  event={formValue.objective.event}
                                />
                              </ListCell>

                              <ListCell>
                                <>
                                  <FormConsumer>
                                    {({ errors }) =>
                                      requireObjectiveFulfillment && (
                                        <SelectDateWarning errors={errors} />
                                      )
                                    }
                                  </FormConsumer>
                                  {formValue.objective.type === 'Event' &&
                                    formValue.objective.event
                                      .remainingPlaces === 0 && (
                                      <Prompt
                                        type="warning"
                                        message={t('weblink:noPlacesRemaining')}
                                      />
                                    )}
                                </>
                              </ListCell>

                              {formValue.objective.type === 'Course' && (
                                <ListDrawer
                                  title={t('weblink:selectDates')}
                                  open={openDrawer(
                                    arrayIndex,
                                    formValue.objective.event.eventId,
                                  )}
                                  dataTestId={`objective-${formValue.objective.id}-row-drawer`}
                                >
                                  <EventObjectiveSelector
                                    courseCode={formValue.objective.courseCode}
                                    learningPathId={learningPathId}
                                    onEventSelection={event =>
                                      selectEventObjective(
                                        formValue.objective.id,
                                        event,
                                      )
                                    }
                                    showRemainingPlacesFilter={
                                      showRemainingPlacesFilter
                                    }
                                    showLocationFilter={showLocationFilter}
                                    location={formValue.location}
                                    startDate={formValue.startDate}
                                    availableSeats={formValue.availableSeats}
                                    locale={locale}
                                    t={t}
                                    onEventSearchCompleted={
                                      onEventSearchCompleted
                                    }
                                  />
                                </ListDrawer>
                              )}
                            </ListRow>
                          )}
                        {formValue.objective &&
                          formValue.objective.type !== 'Course' &&
                          formValue.objective.type !== 'Event' && (
                            <ListRow gridColumns={objectiveColumn}>
                              <ListCell label={t('weblink:objectiveType')}>
                                <StatusLabel
                                  color={ColorOptions.Green}
                                  text={statusLabelText(formValue, t)}
                                />
                              </ListCell>
                              <ListCell label={t('weblink:objectiveName')}>
                                <StaticInput name="objective.name" label="" />
                              </ListCell>
                            </ListRow>
                          )}
                      </>
                    )}
                  </Observer>
                )}
              </FormConsumer>
            </FormArray>
          </List>
        )}
      </Card>
    </Form>
  );
};

PathObjectives.defaultProps = {
  requireObjectiveFulfillment: false,
  locale: null,
  showRemainingPlacesFilter: true,
  showLocationFilter: true,
  minimumRemainingPlaces: null,
  location: null,
  onEventSearchCompleted: () => {},
};

PathObjectives.propTypes = {
  learningPathId: PropTypes.string.isRequired,
  learningObjectives: MobxPropTypes.observableArrayOf(learningObjective)
    .isRequired,
  requireObjectiveFulfillment: PropTypes.bool,
  t: PropTypes.func.isRequired,
  locale: PropTypes.string,
  onObjectiveSelection: PropTypes.func.isRequired,
  showRemainingPlacesFilter: PropTypes.bool,
  showLocationFilter: PropTypes.bool,
  minimumRemainingPlaces: PropTypes.number,
  location: PropTypes.string,
  onEventSearchCompleted: PropTypes.func,
};

export default withTranslation()(withCurrency(observer(PathObjectives)));
