/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Container } from '@administrate/piston';
import {
  Form,
  Input,
  Submit,
  useTypedFormValues,
  Prompt,
} from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import inject from '../inject';
import { STORES } from '../../constants';

const { STORE_CART } = STORES;

const translateFailureMessage = (t, message) => {
  switch (message) {
    case 'The minimum order value requirements are not met':
    case 'Promotion Code is not eligible to be used':
      return t('weblink:promoCodeNotEligibleForOrder');

    case 'Promotion Code has expired':
      return t('weblink:promoCodeExpired');

    default:
      return t('weblink:promoCodeApplicationFail');
  }
};

const discountIsZero = value => {
  return !value || value === '0.00';
};

const PromotionCodeInput = observer(
  ({ [STORE_CART]: { cart }, isLoading, applyPromotionCode, t }) => {
    const cardRef = useRef(null);
    const [applicationResult, setApplicationResult] = useState(null);
    const values = useTypedFormValues({ promotionCode: '' });

    const onSubmit = useCallback(async () => {
      const result = await applyPromotionCode(values.promotionCode);
      setApplicationResult(result);
      values.promotionCode = '';
    }, [values, setApplicationResult, applyPromotionCode]);

    useEffect(
      () => () => {
        values.promotionCode = '';
        setApplicationResult(null);
      },
      [],
    );

    return (
      <Card ref={cardRef}>
        <h2 className="CardTitle">{t('weblink:haveAPromoCode')}</h2>
        <Card.Body>
          <Container fluid>
            <Container.Row>
              <Container.Col xs={12}>
                <Form values={values} onSubmit={onSubmit}>
                  <div className="d-flex promo-code-container">
                    <Input
                      id="promo-code"
                      name="promotionCode"
                      label={t('weblink:addYourPromoCode')}
                      helpMessage={
                        cart.containsGiftVoucher
                          ? t(
                              'weblink:cannotApplyPromoCodeWhenCartContainsGiftVoucher',
                            )
                          : t('weblink:onePromoCodeAllowed')
                      }
                      disabled={
                        !!cart.promotionalCode || cart.containsGiftVoucher
                      }
                    />
                    <div className="d-flex promo-code-submit-container">
                      <Submit
                        label={t('weblink:addCode')}
                        disabled={
                          isLoading ||
                          !values.promotionCode.length ||
                          cart.containsGiftVoucher
                        }
                      />
                    </div>
                  </div>
                </Form>
              </Container.Col>
            </Container.Row>
            <Container.Row>
              <Container.Col xs={12}>
                <div className="pt-4">
                  {applicationResult &&
                    (applicationResult.success ? (
                      <>
                        {discountIsZero(applicationResult.discountTotal) ? (
                          <>
                            <Prompt
                              type="warning"
                              message={t('weblink:promoAppliedZeroDiscount')}
                            />
                          </>
                        ) : (
                          <>
                            <Prompt
                              type="success"
                              message={t('weblink:promoCodeApplicationSuccess')}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Prompt
                          type="warning"
                          message={translateFailureMessage(
                            t,
                            applicationResult.message,
                          )}
                        />
                      </>
                    ))}
                </div>
              </Container.Col>
            </Container.Row>
          </Container>
        </Card.Body>
      </Card>
    );
  },
);

PromotionCodeInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  applyPromotionCode: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default inject(STORE_CART)(withTranslation()(PromotionCodeInput));
