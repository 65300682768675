import React from 'react';
import PropTypes from 'prop-types';

export const CartWidgetIconLabel = ({ isLoading, shoppingCartSeatCount }) => {
  const content = isLoading ? (
    <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
  ) : (
    <span className="cart-widget-icon-label-count">
      <span className="cart-widget-icon-label-count-bracket">(</span>
      {shoppingCartSeatCount}
      <span className="cart-widget-icon-label-count-bracket">)</span>
    </span>
  );

  return (
    <>
      <span className="glyphicon glyphicon-shopping-cart" /> {content}
    </>
  );
};

CartWidgetIconLabel.defaultProps = {
  isLoading: false,
  shoppingCartSeatCount: 0,
};

CartWidgetIconLabel.propTypes = {
  isLoading: PropTypes.bool,
  shoppingCartSeatCount: PropTypes.number,
};
