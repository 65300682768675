export const labelForContinueButton = (cart, requireLearnerDetails, t) => {
  const learnerDetailsRequired =
    requireLearnerDetails || cart.items.some(item => item.isGiftVoucher);

  if (cart.isFree && !learnerDetailsRequired) {
    return t('weblink:completeOrder');
  }
  if (!learnerDetailsRequired) {
    return t('weblink:continueToPaymentMethod');
  }
  return t('weblink:continueToLearnerDetails');
};
