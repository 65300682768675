import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon';

import { Button } from '@administrate/piston-ux';
import FieldLabel from '@administrate/piston/dist/atoms/FieldLabel/FieldLabel';
import TextInput from '@administrate/piston/dist/atoms/TextInput/TextInput';

import { formatDateLong } from '../../utils/formatDateTime';
import { useLazyWeblinkQuery } from '../../weblink-hooks';

const GiftVoucherBalance = ({ t }) => {
  const query = gql`
    query giftVoucherCode($voucherCode: String!) {
      giftVoucher(code: $voucherCode) {
        balance
        currencyCode
        expiresAt
      }
    }
  `;

  const defaultVoucherState = undefined;
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherBalance, setVoucherBalance] = useState(defaultVoucherState);
  const [voucherExpiryDate, setVoucherExpiryDate] = useState(defaultVoucherState);
  const [error, setError] = useState('');
  const [checkVoucherCode, { loading }] = useLazyWeblinkQuery(query, {
    onCompleted: data => {
      if (data.giftVoucher && data.giftVoucher.balance) {
        setVoucherBalance(
          `${data.giftVoucher.balance} ${data.giftVoucher.currencyCode}`,
        );
        setVoucherExpiryDate(
          data.giftVoucher.expiresAt ? formatDateLong(DateTime.fromISO(`${data.giftVoucher.expiresAt}`)) : "-",
        )
        setError('');
      } else {
        setError(t('weblink:giftVoucherInvalidCode'));
      }
    },
  });

  const handleClick = () => {
    if (voucherCode !== '') {
      checkVoucherCode({
        variables: {
          voucherCode,
        },
      });
    } else {
      setError(t('weblink:giftVoucherPleaseEnterCode'));
    }
  };

  const clearData = e => {
    e.preventDefault();
    setVoucherCode('');
    setVoucherBalance(defaultVoucherState);
  };

  const renderResult = () => {
    if (voucherBalance) {
      return (
        <div id="resultsSection">
          <div id="voucherCodeSection">
            <span id="voucherCode">
              {voucherCode} <span id="tick">✔</span>
            </span>
            <span
              role="button"
              id="checkAnotherLink"
              tabIndex="0"
              onClick={clearData}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  clearData(e);
                }
              }}
            >
              {t('weblink:giftVouchersCheckAnother')}
            </span>
          </div>
          <div>
            <Row>
              <Col lg={4}>
                <div id="balanceDisplay">
                  <FieldLabel label={t('weblink:giftVouchersBalance')} />
                  <p>{voucherBalance}</p>
                </div>
              </Col>
              <Col lg={4}>
                <div id="expiryDateDisplay">
                  <FieldLabel label={t('weblink:giftVoucherExpiryDate')} />
                  <p>{voucherExpiryDate}</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderInputs = () => {
    if (!voucherBalance) {
      return (
        <div id="inputSection">
          <div id="textInputSection">
            <TextInput
              name="Gift Voucher code"
              type="text"
              disabled={loading}
              onChange={e => setVoucherCode(e)}
            />
          </div>
          <div id="buttonInputSection">
            <Button
              type="primary"
              label={t('weblink:giftVouchersCheckBalanceButton')}
              ariaLabel="Check Voucher Code Button"
              onClick={handleClick}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="GiftVoucherBalance">
      <div id="labelSection">
        <div id="voucherCodeLabel">
          <FieldLabel label={t('weblink:giftVoucherCode')} />
        </div>
        <div id="errorLabel">{error && <FieldLabel label={error} />}</div>
      </div>
      {renderInputs()}
      {renderResult()}
    </div>
  );
};

GiftVoucherBalance.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(GiftVoucherBalance));
