import { action, decorate, observable, reaction } from 'mobx';
import { get as _get } from 'lodash';

import BaseStore from './baseStore';

class NavStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.initialize();
  }

  initialize = action(() => {
    this.searchOpen = false;
    this.menuOpen = false;
    this.removedMenu = true;
    this.menu = {
      'main-menu': [
        {
          key: 'categories',
          subSections: true,
          back: false,
          onclick: () => this.changeMenu(this.menu.categories),
        },
        {
          key: 'cart',
          subSections: false,
          back: false,
          onclick: () => this.rootStore.navigationStore.toCart(),
        },
      ],
      categories: [
        {
          key: 'back',
          subSections: false,
          back: true,
          onclick: () => this.changeMenu(this.menu['main-menu']),
        },
      ],
    };
    this.currentMenu = this.menu['main-menu'];
    reaction(
      () => this.rootStore.catalogueStore.allCategoriesResponse,
      allCategoriesResponse =>
        this.processCategoriesResponse(allCategoriesResponse),
    );
  });

  changeMenu = action(value => {
    this.currentMenu = value;
  });

  configureCategory = action(
    ({ category, previousName, previousKey, filterProp }) => {
      const subCategories = _get(category, 'categories.edges', []);
      if (subCategories.length) {
        const categoryKey = `${category.name}`.replace(' ', '-').toLowerCase();
        this.menu[categoryKey] = [
          {
            key: `back-to-${previousName.toLowerCase()}`,
            subSections: false,
            back: true,
            onclick: () => this.changeMenu(this.menu[previousKey]),
          },
        ];

        subCategories.forEach(({ node: subCategory }) => {
          if (!filterProp || !subCategory[filterProp]) {
            const subSections = !!_get(subCategory, 'categories.edges', [])
              .length;
            const subCategoryKey = `${subCategory.name}`
              .replace(' ', '-')
              .toLowerCase();

            this.menu[categoryKey].push({
              key: subCategoryKey,
              name: () => subCategory.name,
              subSections,
              back: false,
              onclick: subSections
                ? () => this.changeMenu(this.menu[subCategoryKey])
                : () => {
                    this.closeMenuAndSearch();
                    return this.rootStore.navigationStore.toCategory({
                      categoryId: subCategory.id,
                      categoryName: subCategory.name,
                    });
                  },
            });

            this.configureCategory({
              category: subCategory,
              previousName: category.name,
              previousKey: categoryKey,
            });
          }
        });
      }
    },
  );

  processCategoriesResponse = action(categoriesResponse => {
    this.configureCategory({
      category: {
        name: 'Categories',
        categories: _get(categoriesResponse, 'categories', []),
      },
      previousName: 'Navigation',
      previousKey: 'main-menu',
      filterProp: 'parent',
    });

    this.menu.categories.push({
      key: 'all-courses',
      subSections: false,
      back: false,
      onclick: () => {
        this.closeMenuAndSearch();
        return this.rootStore.navigationStore.toCatalogue();
      },
    });
  });

  // Timeout is in sync with the css transition duration that sets the opacity of the menu to 0.
  resetMenu = () =>
    setTimeout(
      action(() => {
        this.currentMenu = this.menu['main-menu'];
      }),
      250,
    );

  // Timeout is enough for the menu animations to play.
  removeMenu = () =>
    setTimeout(
      action(() => {
        this.removedMenu = true;
      }),
      250,
    );

  // Timeout is enough for the menu animations to play.
  openMenu = () =>
    setTimeout(
      action(() => {
        this.menuOpen = true;
      }),
      50,
    );

  enableMenu = action(() => {
    this.removedMenu = false;
  });

  closeMenu = action(() => {
    this.menuOpen = false;
  });

  toggleSearch = action(() => {
    this.closeMenu();
    this.removeMenu();
    this.searchOpen = !this.searchOpen;
    this.resetMenu();
  });

  toggleMenu = action(() => {
    this.searchOpen = false;
    if (this.menuOpen) {
      this.closeMenu();
      this.removeMenu();
      this.resetMenu();
    } else {
      this.enableMenu();
      this.openMenu();
    }
  });

  closeMenuAndSearch = action(() => {
    this.searchOpen = false;
    this.closeMenu();
    this.removeMenu();
    this.resetMenu();
  });
}

decorate(NavStore, {
  searchOpen: observable,
  menuOpen: observable,
  currentMenu: observable,
  menu: observable,
  removedMenu: observable,
});

export default NavStore;
