import React, { useContext, forwardRef } from 'react';
import { STORE } from '../stores/contexts/store';

const withIntegration = WrappedComponent =>
  forwardRef((props, ref) => {
    const stores = useContext(STORE);
    const { integration } = stores.STORE_ROOT;

    return <WrappedComponent integration={integration} ref={ref} {...props} />;
  });

export default withIntegration;
