import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import {
  Card,
  StandardAction,
  PrimaryAction,
  SuppressedAction,
} from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import { noop as _noop } from 'lodash';

import CourseRows from '../CourseRow/CourseRow';
import withCurrency from '../../containers/withCurrency';
import { STORES } from '../../constants';
import inject from '../../containers/inject';

const { STORE_STORE } = STORES;

// TODO: Extract checkout and cart specific code into CheckoutSummaryCard and CartSummaryCard.
const SummaryCard = ({
  navigateToCatalogue,
  navigateToCheckout,
  navigateToCart,
  readOnly,
  price,
  cartContent,
  canCheckout,
  showCartContent,
  showAmendCart,
  disabled,
  formatCurrencyForCalculation,
  promotionalCode,
  giftVoucherApplications,
  cartlessCheckout,
  storeDetailsIsLoading,
  removePromotionCode,
  unapplyGiftVoucher,
  primaryActionDisabled,
  canRemovePromotionCode,
  t,
  [STORE_STORE]: { isTaxInclusiveOnly },
}) => (
  <Card type="SummaryCard">
    <div className="CardTitle SummaryCard__header">
      <h2>
        {storeDetailsIsLoading
          ? t('weblink:checkoutFormLoading')
          : t('weblink:summary')}
      </h2>
      {!storeDetailsIsLoading && !cartlessCheckout && showAmendCart && (
        <div className="SummaryCard__header_amend">
          <span
            role="link"
            tabIndex={0}
            className="link-button link-button__text-color"
            onClick={navigateToCart}
            onKeyPress={navigateToCart}
          >
            {t('weblink:amendMyCart')}
          </span>
        </div>
      )}
    </div>
    <Card.Body>
      {showCartContent && <CourseRows cartContent={cartContent} />}

      {!storeDetailsIsLoading && !!price && (
        <div className="SummaryCard__totals">
          <div className="SummaryCard_total">
            <span className="SummaryCard_total_label">{`${t(
              'weblink:subTotal',
            )}:`}</span>
            <span className="SummaryCard_total_value">
              {formatCurrencyForCalculation(price.subTotal)}
            </span>
          </div>
          {promotionalCode && (
            <div className="SummaryCard_total">
              <span className="SummaryCard_total_label">
                {`${t('weblink:discountTotal')}:`}
              </span>
              <br />
              <div className="SummaryCard_promotional_code">
                <span>{`${t('weblink:code')}: ${promotionalCode}`}</span>
                {canRemovePromotionCode && (
                  <SuppressedAction
                    onClick={() => removePromotionCode(promotionalCode)}
                    className="removeFromCart"
                    label={
                      <span
                        className="ml-1 glyphicon glyphicon-remove-circle"
                        aria-label={t('weblink:removePromoCode')}
                      />
                    }
                  />
                )}
              </div>
              <span className="SummaryCard_total_value">
                -{formatCurrencyForCalculation(price.discountTotal)}
              </span>
            </div>
          )}
          {price.taxes
            .filter(
              tax =>
                !isTaxInclusiveOnly ||
                (isTaxInclusiveOnly && parseFloat(tax.totalAmount) > 0),
            )
            .map(tax => (
              <div className="SummaryCard_total" key={tax.type.id}>
                <span className="SummaryCard_total_label">{`${tax.type.name}:`}</span>
                <span className="SummaryCard_total_value">
                  {formatCurrencyForCalculation(tax.totalAmount)}
                </span>
              </div>
            ))}
          <div className="SummaryCard_total">
            <span className="SummaryCard_total_label">{`${t(
              'weblink:grandTotal',
            )}:`}</span>
            <span className="SummaryCard_total_value font-weight-bold">
              {formatCurrencyForCalculation(price.grandTotal)}
            </span>
          </div>
          {giftVoucherApplications && giftVoucherApplications.length > 0 && (
            <>
              <span className="SummaryCard_total_label">
                {`${t('weblink:giftVouchers')}:`}
              </span>
              <br />
              {giftVoucherApplications.map(giftVoucherApplication => (
                <div
                  className="SummaryCard_gift_voucher"
                  key={`summary-card-gift-voucher-${giftVoucherApplication.giftVoucher.code}`}
                >
                  <div className="SummaryCard_gift_voucher_left">
                    {`${t('weblink:code')}: ${
                      giftVoucherApplication.giftVoucher.code
                    }`}
                    {!readOnly && (
                      <SuppressedAction
                        onClick={() =>
                          unapplyGiftVoucher(
                            giftVoucherApplication.giftVoucher.code,
                          )
                        }
                        className="removeFromCart"
                        label={
                          <span
                            className="ml-1 glyphicon glyphicon-remove-circle"
                            aria-label={`${t('weblink:delete')} from Cart`}
                          />
                        }
                      />
                    )}
                  </div>
                  <div className="SummaryCard_gift_voucher_right">
                    -
                    {formatCurrencyForCalculation(
                      giftVoucherApplication.amount,
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
          {price.payableTotal && price.payableTotal !== price.grandTotal && (
            <div className="SummaryCard_total">
              <span className="SummaryCard_total_label">{`${t(
                'weblink:payableTotal',
              )}:`}</span>
              <span className="SummaryCard_total_value">
                {formatCurrencyForCalculation(price.payableTotal)}
              </span>
            </div>
          )}
        </div>
      )}
    </Card.Body>
    {canCheckout && (
      <Card.Footer
        left={
          navigateToCatalogue && (
            <StandardAction
              label={t('weblink:continueShopping')}
              disabled={disabled}
              onClick={navigateToCatalogue}
            />
          )
        }
        right={
          <PrimaryAction
            label={t('weblink:proceedToCheckout')}
            disabled={disabled || primaryActionDisabled}
            onClick={navigateToCheckout}
          />
        }
      />
    )}
  </Card>
);

SummaryCard.defaultProps = {
  disabled: false,
  readOnly: false,
  canCheckout: false,
  showCartContent: false,
  showAmendCart: false,
  promotionalCode: null,
  primaryActionDisabled: false,
  navigateToCatalogue: null,
  navigateToCheckout: _noop,
  navigateToCart: _noop,
  removePromotionCode: _noop,
  unapplyGiftVoucher: _noop,
};

const taxPropType = PropTypes.shape({
  totalAmount: PropTypes.string,
  type: PropTypes.shape({
    name: PropTypes.string,
  }),
});

SummaryCard.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  canCheckout: PropTypes.bool,
  showCartContent: PropTypes.bool,
  showAmendCart: PropTypes.bool,
  navigateToCart: PropTypes.func,
  navigateToCatalogue: PropTypes.func,
  navigateToCheckout: PropTypes.func,
  promotionalCode: PropTypes.string,
  giftVoucherApplications: MobxPropTypes.observableArray.isRequired,
  price: PropTypes.shape({
    discountTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grandTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    payableTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    taxes: MobxPropTypes.arrayOrObservableArrayOf(taxPropType),
  }).isRequired,
  cartContent: MobxPropTypes.arrayOrObservableArray.isRequired,
  formatCurrencyForCalculation: PropTypes.func.isRequired,
  cartlessCheckout: PropTypes.bool.isRequired,
  storeDetailsIsLoading: PropTypes.bool.isRequired,
  removePromotionCode: PropTypes.func,
  unapplyGiftVoucher: PropTypes.func,
  primaryActionDisabled: PropTypes.bool,
  canRemovePromotionCode: PropTypes.bool.isRequired,
};

export { SummaryCard as Component };

export default withTranslation()(
  inject(STORE_STORE)(withCurrency(SummaryCard)),
);
