import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';

import { PropTypes as MobxPropTypes } from 'mobx-react';
import LearnersForm from './LearnersForm';
import GiftVoucherRecipientsForm from './GiftVoucherRecipientsForm';

import withCurrency from '../../containers/withCurrency';
import { formatDate, formatTime } from '../../utils/formatDateTime';

function renderItemBlock(item, t, formatCurrency) {
  const locationName = item.location?.name;
  if (item.giftVoucherId) {
    return (
      <div className="itemblock">
        <div className="itemblock-course-details">
          <h2>
            <strong>{`${t('weblink:giftVoucher')}: `}</strong>
            {formatCurrency(item.unitAmount, '£')}
          </h2>
        </div>
      </div>
    );
  }
  if (item.pathId) {
    return (
      <div className="itemblock">
        <div className="itemblock-course-details">
          <h2>
            <strong>{`${t('weblink:path')}: `}</strong>
            {item.course}
          </h2>
        </div>
      </div>
    );
  }
  return (
    <div className="itemblock">
      <div className="itemblock-course-details">
        <h2>
          <strong>{`${t('weblink:course')}: `}</strong>
          {item.course}
        </h2>
        {locationName && (
          <span className="itemblock-coursedetails--location">
            {`${locationName}, `}
          </span>
        )}
        <span className="itemblock-coursedetails--location">
          {`${formatDate(item.startDateTime)}, ${formatTime(
            item.startDateTime,
          )}`}
        </span>
      </div>
    </div>
  );
}

const OrderItem = ({
  item,
  updateItemLearnerProperty,
  updateItemLearnerEmail,
  updateItemRecipientProperty,
  updateItemRecipientEmail,
  toggleUseBookerDetails,
  setBookerAsRecipient,
  t,
  isLoading,
  confirmEmails,
  buyerIsRecipient,
  clearRecipientDetails,
  attributeDefinitions = [],
  requireLearnerDetails = true,
  formatCurrency,
}) => (
  <div className="checkout_setlearners_courses">
    <div className="mb-4">{renderItemBlock(item, t, formatCurrency)}</div>
    {item.isGiftVoucher ? (
      <GiftVoucherRecipientsForm
        item={item}
        recipient={item.recipientDetails}
        updateItemRecipientProperty={updateItemRecipientProperty}
        updateItemRecipientEmail={updateItemRecipientEmail}
        isLoading={isLoading}
        confirmEmails={confirmEmails}
        learners={item.learners}
        setBookerAsRecipient={setBookerAsRecipient}
        clearRecipientDetails={clearRecipientDetails}
        buyerIsRecipient={buyerIsRecipient}
      />
    ) : (
      <LearnersForm
        item={item}
        learners={item.learners}
        updateItemLearnerProperty={updateItemLearnerProperty}
        updateItemLearnerEmail={updateItemLearnerEmail}
        toggleUseBookerDetails={toggleUseBookerDetails}
        isLoading={isLoading}
        confirmEmails={confirmEmails}
        attributeDefinitions={attributeDefinitions}
        requireLearnerDetails={requireLearnerDetails}
      />
    )}
  </div>
);

OrderItem.propTypes = {
  toggleUseBookerDetails: PropTypes.func.isRequired,
  setBookerAsRecipient: PropTypes.func.isRequired,
  item: MobxPropTypes.objectOrObservableObject.isRequired,
  updateItemLearnerProperty: PropTypes.func.isRequired,
  updateItemLearnerEmail: PropTypes.func.isRequired,
  updateItemRecipientProperty: PropTypes.func.isRequired,
  updateItemRecipientEmail: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmEmails: PropTypes.bool.isRequired,
  attributeDefinitions: MobxPropTypes.arrayOrObservableArray,
  requireLearnerDetails: PropTypes.bool,
  buyerIsRecipient: PropTypes.func.isRequired,
  clearRecipientDetails: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
};

OrderItem.defaultProps = {
  attributeDefinitions: [],
  requireLearnerDetails: true,
};

export default withTranslation()(withCurrency(observer(OrderItem)));
