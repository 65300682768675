import React, { useEffect, useRef, useCallback } from 'react';
import { Notice } from '@administrate/piston';
import PropTypes from 'prop-types';

const ESC_KEY_CODE = 27;

const OverPageNotice = props => {
  const { show, onDismiss, body } = props;

  const timeout = useRef(null);
  const onDismissWrapper = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    onDismiss();
  }, [onDismiss]);

  const escFunction = useCallback(
    event => {
      if (event.keyCode === ESC_KEY_CODE) {
        onDismissWrapper();
      }
    },
    [onDismissWrapper],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (show) {
      timeout.current = setTimeout(() => {
        onDismissWrapper();
      }, 6000);
    }
  }, [show, onDismissWrapper]);

  return (
    <div
      style={{ pointerEvents: 'none' }}
      className={`over-page-alert-box modal in d-block ${
        show ? 'over-page-alert-box__open' : ''
      } ${body && !show ? 'over-page-alert-box__closing' : ''}`}
    >
      <div
        style={{ pointerEvents: 'auto' }}
        className="modal-dialog"
        role="button"
        tabIndex={0}
        onClick={onDismissWrapper}
        onKeyDown={escFunction}
      >
        <Notice
          canClose
          {...props}
          onDismiss={onDismissWrapper}
          icon="glyphicon glyphicon-remove"
        />
      </div>
    </div>
  );
};

OverPageNotice.propTypes = {
  show: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  ...Notice.propTypes,
};

OverPageNotice.defaultProps = {
  show: false,
  ...Notice.defaultProps,
};

export default OverPageNotice;
