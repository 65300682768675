import { observable, action, decorate, computed } from 'mobx';

// TODO(Peter): add run function to immediatly run (requires refactoring the wrap function)
class Loader {
  constructor({ isLoggingEnabled = false } = {}) {
    this._requests = 0;
    this._isLoggingEnabled = isLoggingEnabled;
  }

  get isLoading() {
    return this._requests !== 0;
  }

  start = action('start loader', () => {
    this._requests += 1;
  });

  stop = action('stop loader', () => {
    if (this._requests > 0) {
      this._requests -= 1;
    } else if (this._isLoggingEnabled) {
      /* eslint-disable no-console */
      console.warn('Loader.stop() was called more times than it was started');
    }
  });

  wrap = fn => (...args) => {
    this.start();
    try {
      const result = fn(...args);
      if (result instanceof Promise) {
        return result
          .then(res => {
            this.stop();
            return res;
          })
          .catch(err => {
            this.stop();
            throw err;
          });
      }
      this.stop();
      return result;
    } catch (ex) {
      this.stop();
      throw ex;
    }
  };
}

decorate(Loader, {
  _requests: observable,
  isLoading: computed,
});

export default Loader;
