import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { StickyContainer, Sticky } from 'react-sticky';
import { Col, Row } from 'react-bootstrap';
import { get as _get } from 'lodash';
import { LoadingBar } from '@administrate/piston';
import PropTypes from 'prop-types';

import inject from '../inject';
import Breadcrumb from '../Breadcrumb';
import PageTitle from '../../components/PageTitle';
import { STORES } from '../../constants';
import PathDetails from '../../components/Details/PathDetails';
import DetailsCard from '../../components/Details/DetailsCard';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import getImageUrls from './getImageUrls';
import DetailsCardSkeleton from '../../components/Details/DetailsCardSkeleton';
import { PageViewEvent, ProductEvent } from '../../analytics';

const { STORE_ANALYTICS, STORE_DETAILS, STORE_STORE } = STORES;

const PathDetailsContainer = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_DETAILS]: {
    pathDetails,
    isLoading,
    isError,
    navigateBackToCatalogue,
    openAddToCart,
    queryPathDetails,
    setPathObjective,
  },
  [STORE_STORE]: { formatCurrency, hidePrices, storeDetails, currency },
  id,
  categoryId,
  subcategoryId,
  showBreadcrumb,
  integration,
  embedded,
}) => {
  useEffect(() => {
    if (id !== null && pathDetails.id !== id) {
      queryPathDetails({ id });
    }
  }, [id, pathDetails.id, queryPathDetails]);

  useEffect(() => {
    if (storeDetails && !isLoading && pathDetails?.name) {
      setBrandedPageTitle(
        [pathDetails.name],
        storeDetails,
        !(integration || embedded),
      );
      captureEvent(new PageViewEvent());
    }
  }, [
    storeDetails,
    isLoading,
    pathDetails?.name,
    integration,
    embedded,
    captureEvent,
  ]);

  useEffect(() => {
    if (!isLoading && !isError && pathDetails.id) {
      captureEvent(
        ProductEvent.fromLearningPathView({
          path: pathDetails,
          currencyCode: currency.code,
        }),
      );
    }
  }, [isLoading, isError, pathDetails.id, captureEvent, currency.code]);

  return (
    <StickyContainer
      className={
        isLoading ? 'details_path withpanel loading' : 'details_path withpanel'
      }
    >
      <div className="details_loading-bar">
        <Sticky>
          {({ style }) => (
            <div style={{ ...style, zIndex: 2 }}>
              <LoadingBar isLoading={isLoading} />
            </div>
          )}
        </Sticky>
      </div>
      <PageTitle className="detail">
        <Row>
          <Col sm={7} md={8}>
            <div className={`course-overview course-${pathDetails.id}`}>
              {!isLoading && showBreadcrumb && (
                <Breadcrumb
                  courseName={pathDetails.name}
                  categoryId={categoryId}
                  subcategoryId={subcategoryId}
                />
              )}
              <h1>{!isLoading && <span>{pathDetails.name}</span>}</h1>
            </div>
          </Col>
          <Col sm={5} md={4}>
            <div className="course-information-panel show-mobile">
              {isLoading ? (
                <DetailsCardSkeleton />
              ) : (
                <DetailsCard
                  normalPrice={Number(_get(pathDetails.price, 'amount', 0))}
                  formatCurrency={formatCurrency}
                  isPath
                  imageUrls={getImageUrls(pathDetails)}
                  displayPrices={!hidePrices}
                />
              )}
            </div>
            <div className="course-information-panel show-desktop">
              <Sticky topOffset={26}>
                {({ style, isSticky }) => (
                  <div
                    style={{
                      ...style,
                      marginTop: isSticky ? '50px' : null,
                    }}
                  >
                    {isLoading ? (
                      <DetailsCardSkeleton />
                    ) : (
                      <DetailsCard
                        normalPrice={Number(
                          _get(pathDetails.price, 'amount', 0),
                        )}
                        formatCurrency={formatCurrency}
                        isPath
                        imageUrls={getImageUrls(pathDetails)}
                        displayPrices={!hidePrices}
                      />
                    )}
                  </div>
                )}
              </Sticky>
            </div>
          </Col>
        </Row>
      </PageTitle>
      <PathDetails
        pathDetails={pathDetails}
        isLoading={isLoading}
        isError={isError}
        redirect={navigateBackToCatalogue}
        openAddToCart={openAddToCart}
        setPathObjective={setPathObjective}
        requireObjectiveFulfillment={pathDetails.requireObjectiveFulfillment}
      />
    </StickyContainer>
  );
};

PathDetailsContainer.propTypes = {
  id: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  subcategoryId: PropTypes.string,
  showBreadcrumb: PropTypes.bool,
  embedded: PropTypes.bool,
};

PathDetailsContainer.defaultProps = {
  categoryId: null,
  subcategoryId: null,
  showBreadcrumb: true,
  embedded: false,
};

export default inject(
  STORE_ANALYTICS,
  STORE_DETAILS,
  STORE_STORE,
)(observer(PathDetailsContainer));
