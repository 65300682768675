import { intersectionBy as _intersectionBy } from 'lodash';

export const getAvailablePriceLevels = (priceLevels, storePriceLevels) => {
  const priceLevelsIntersection = _intersectionBy(
    priceLevels,
    storePriceLevels,
    'id',
  );
  return priceLevelsIntersection;
};

export const prePopulatePriceLevel = priceLevels => {
  if (!priceLevels) {
    return null;
  }
  const normalPriceLevel = priceLevels.find(({ name }) => name === 'Normal');
  if (normalPriceLevel) {
    return normalPriceLevel;
  }
  const firstPriceLevel = priceLevels[0];
  return firstPriceLevel;
};
