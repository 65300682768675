class NavigationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    if (this.rootStore.integration) {
      // Integration mode
      this.toCheckout = async () => {
        this.rootStore.checkoutStore.changeStep(0);
        await this.prepareItemsForCheckout();
        this.rootStore.callbackStore.navigateToCheckoutCallback();
      };
      this.toCatalogue = this.rootStore.callbackStore.navigateToCatalogueCallback;
      this.toHome = this.rootStore.callbackStore.navigateToHomeCallback;
      this.toCart = this.rootStore.callbackStore.navigateToCartCallback;
      this.to404 = this.rootStore.callbackStore.navigateToAuthErrorCallback;
      this.toCourseDetail = this.rootStore.callbackStore.navigateToCourseDetailCallback;
      this.toGiftVouchers = this.rootStore.callbackStore.navigateToGiftVouchersCallback;
      this.toPathDetail = this.rootStore.callbackStore.navigateToPathDetailCallback;
      this.toCategory = this.rootStore.callbackStore.navigateToCategoryCallback;
      this.toRequestTraining = this.rootStore.callbackStore.navigateToRequestTrainingCallback;
    } else {
      // Hosted site
      this.toCheckout = async () => {
        await this.prepareItemsForCheckout();
        this.rootStore.routerStore.navigateToCheckout();
      };
      this.toCatalogue = this.rootStore.routerStore.navigateToCatalogue;
      this.toHome = this.rootStore.routerStore.navigateToHome;
      this.toCart = this.rootStore.routerStore.navigateToCart;
      this.to404 = this.rootStore.routerStore.navigateTo404;
      this.toCourseDetail = this.rootStore.routerStore.navigateToCourseDetail;
      this.toGiftVouchers = this.rootStore.routerStore.navigateToGiftVouchers;
      this.toPathDetail = this.rootStore.routerStore.navigateToPathDetail;
      this.toCategory = this.rootStore.routerStore.navigateToCategory;
      this.toRequestTraining = this.rootStore.routerStore.navigateToRequestTraining;
    }

    if (this.rootStore.embededNavigation) {
      // Embed mode
      this.toHome = this.rootStore.integrationNavigationStore.goToCatalogue;
      this.toCart = this.rootStore.integrationNavigationStore.goToCart;
      this.toCourseDetail = this.rootStore.integrationNavigationStore.goToCourse;
      this.toGiftVouchers = this.rootStore.callbackStore.navigateToGiftVouchersCallback;
      this.toCataloguePage = this.rootStore.integrationNavigationStore.goToPage;
      this.toPathDetail = this.rootStore.integrationNavigationStore.goToPath;
      this.toCatalogue = ({ queryObject = null } = {}) =>
        this.rootStore.integrationNavigationStore.goToCatalogue({
          courseNameSearchText: queryObject ? queryObject.name : null,
        });
      this.toCategory = this.rootStore.integrationNavigationStore.goToCatalogue;
      this.toRequestTraining = this.rootStore.integrationNavigationStore.goToRequestTraining;
    }
  }

  prepareItemsForCheckout = async () => {
    await this.rootStore.cartStore.prepareItemsForCheckout();
  };
}

export default NavigationStore;
