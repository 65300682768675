import React, { useEffect } from 'react';
import { Container, Card, PrimaryAction } from '@administrate/piston';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { LoadingScreen } from '@administrate/piston-ux';
import inject from '../inject';
import withIntegration from '../withIntegration';
import SummaryCard from '../../components/SummaryCard/SummaryCard';
import { STORES } from '../../constants';
import BuyerDetails from '../../components/Checkout/ReviewOrder/BuyerDetails';
import LearnerDetails from '../../components/Checkout/ReviewOrder/LearnerDetails';
import GiftVoucherDetails from '../../components/Checkout/ReviewOrder/GiftVoucherDetails';
import portalStore from '../../stores/portalStore';
import RequestComplete from './RequestComplete';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import { PageViewEvent } from '../../analytics';

const {
  STORE_ANALYTICS,
  STORE_REVIEW_ORDER,
  STORE_CHECKOUT,
  STORE_STORE,
  STORE_CART,
} = STORES;

const CheckoutReviewOrder = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_REVIEW_ORDER]: {
    cartContent,
    price,
    customer,
    initialize: reviewStoreInitialize,
    promotionalCode,
    giftVoucherApplications,
  },
  [STORE_STORE]: { cartlessCheckout, storeDetailsIsLoading, storeDetails },
  [STORE_CHECKOUT]: {
    initialize: checkoutStoreInitialize,
    progress,
    isLoading: isCheckoutLoading,
  },
  [STORE_CART]: { isLoading: isCartLoading },
  t,
  integration,
}) => {
  const isLoading = isCheckoutLoading || isCartLoading;

  useEffect(
    () => () => {
      reviewStoreInitialize();
      checkoutStoreInitialize();
    },
    [reviewStoreInitialize, checkoutStoreInitialize],
  );

  useEffect(() => {
    if (storeDetails) {
      setBrandedPageTitle(
        ['Checkout', t('weblink:orderComplete')],
        storeDetails,
        !integration,
      );
    }
    captureEvent(new PageViewEvent());
  }, [storeDetails, integration, captureEvent, t]);

  const redirectSite = portalStore.embedSite;
  const hasLearners =
    cartContent &&
    cartContent.some(
      content =>
        content.learners &&
        content.learners.some(
          learner => learner && learner.firstName && learner.lastName,
        ),
    );
  const hasGiftVouchers =
    cartContent && cartContent.some(item => item.isGiftVoucher);
  const isCheckoutStepReview = progress.currentStep.item.name === 'review';

  return (
    <Container>
      <Container.Row>
        {isLoading && <LoadingScreen />}
        {cartContent.length > 0 && [
          <Container.Col lg={6} lgOffset={1} md={7} key="completeCart">
            {isCheckoutStepReview ? (
              <RequestComplete />
            ) : (
              <Card type="reviewOrder">
                <h1 className="CardTitle">{t('weblink:orderComplete')}</h1>
                <Card.Body>
                  <Container fluid>
                    <p>{t('weblink:successfulPaymentEmail')}</p>
                    {customer && customer.firstName && customer.lastName && (
                      <Container.Row>
                        <Container.Col xs={12}>
                          <h2 className="mt-4">
                            {t('weblink:bookerInformation')}
                          </h2>
                          <BuyerDetails customer={customer} />
                        </Container.Col>
                      </Container.Row>
                    )}

                    {hasLearners && (
                      <Container.Row>
                        <Container.Col xs={12}>
                          <h2>{t('weblink:learnerDetails')}</h2>
                          {cartContent.map(item => (
                            <div className="learner-details" key={item.id}>
                              <div className="learner-details-hr-container">
                                <h3 className="learner-details__event">
                                  <strong>{`${t('weblink:course')}: `}</strong>
                                  {item.course}
                                </h3>
                                <hr className="learner-details-hr" />
                              </div>
                              {item.learners.map(learner => (
                                <LearnerDetails
                                  learner={learner}
                                  key={learner.id}
                                />
                              ))}
                            </div>
                          ))}
                        </Container.Col>
                      </Container.Row>
                    )}

                    {hasGiftVouchers && (
                      <h2 className="mt-4">
                        {t('weblink:giftVoucherDetails')}
                      </h2>
                    )}
                    {hasGiftVouchers &&
                      cartContent
                        .filter(i => i.isGiftVoucher)
                        .map(gvCartItem => (
                          <Container.Row className="mb-4" key={gvCartItem.id}>
                            <Container.Col xs={12}>
                              <GiftVoucherDetails giftVoucher={gvCartItem} />
                            </Container.Col>
                          </Container.Row>
                        ))}
                    {redirectSite && (
                      <Container.Row>
                        <Container.Col xs={12}>
                          <PrimaryAction
                            onClick={() => {
                              window.location = redirectSite;
                            }}
                            label={t('weblink:continueShopping')}
                          />
                        </Container.Col>
                      </Container.Row>
                    )}
                  </Container>
                </Card.Body>
              </Card>
            )}
          </Container.Col>,
          <Container.Col lgOffset={0} lg={4} md={5} key="summaryCart">
            <SummaryCard
              readOnly
              price={price}
              cartContent={cartContent}
              showCartContent
              integration={integration}
              promotionalCode={promotionalCode}
              cartlessCheckout={cartlessCheckout}
              storeDetailsIsLoading={storeDetailsIsLoading}
              giftVoucherApplications={giftVoucherApplications}
              canRemovePromotionCode={false}
            />
          </Container.Col>,
        ]}
      </Container.Row>
    </Container>
  );
};

CheckoutReviewOrder.propTypes = {
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
};

export default withTranslation()(
  inject(
    STORE_ANALYTICS,
    STORE_REVIEW_ORDER,
    STORE_CHECKOUT,
    STORE_STORE,
    STORE_CART,
  )(withIntegration(observer(CheckoutReviewOrder))),
);
