import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const WidgetHooksContext = React.createContext();
export const useWidgetHooks = () => useContext(WidgetHooksContext);

export const WidgetHooksProvider = ({ hooks, children }) => (
  <WidgetHooksContext.Provider value={hooks}>
    {children}
  </WidgetHooksContext.Provider>
);

WidgetHooksProvider.propTypes = {
  hooks: PropTypes.shape({
    onPageChange: PropTypes.func,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
