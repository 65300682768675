import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import { Prompt } from '@administrate/piston-ux';
import LegacyModal from '../ModalOutlet/LegacyModal';

export const CartExpiryCountdown = ({
  t,
  integration,
  reservationsValidUntil,
  onComplete,
  handleCartExpiry,
  redirect,
  includeModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  // eslint-disable-next-line react/prop-types
  const getReservationsRemainingTime = ({ formatted, completed }) => {
    let remainingTime = '';

    if (completed) {
      remainingTime = t('weblink:expiredReservations');
    } else {
      const validHours = formatted.hours !== '00';
      remainingTime = validHours
        ? t('weblink:reservationsValidityHours', {
            hours: formatted.hours,
            minutes: formatted.minutes,
            seconds: formatted.seconds,
          })
        : t('weblink:reservationsValidityMinutes', {
            minutes: formatted.minutes,
            seconds: formatted.seconds,
          });
    }
    if (handleCartExpiry) {
      handleCartExpiry(completed);
      if (includeModal) {
        setShowModal(completed);
      }
    }

    return <Prompt type="warning" message={remainingTime} />;
  };

  const handleCloseModal = () => {
    setIsRedirecting(true);
    redirect();
  };

  return (
    <>
      <Countdown
        key={reservationsValidUntil}
        renderer={getReservationsRemainingTime}
        date={reservationsValidUntil}
        onComplete={onComplete}
      />
      {includeModal && (
        <LegacyModal
          title={t('weblink:expiredReservations')}
          isLoading={isRedirecting}
          disabled={isRedirecting}
          show={showModal}
          onSubmit={handleCloseModal}
          submitLabel={t(
            integration ? 'weblink:returnHome' : 'weblink:goToCart',
          )}
        >
          <p>{t('weblink:expiredReservationsGoToCart')}</p>
        </LegacyModal>
      )}
    </>
  );
};

CartExpiryCountdown.propTypes = {
  integration: PropTypes.bool,
  t: PropTypes.func.isRequired,
  reservationsValidUntil: PropTypes.string,
  onComplete: PropTypes.func,
  handleCartExpiry: PropTypes.func,
  redirect: PropTypes.func,
  includeModal: PropTypes.bool,
};

CartExpiryCountdown.defaultProps = {
  integration: false,
  reservationsValidUntil: null,
  onComplete: undefined,
  handleCartExpiry: undefined,
  redirect: undefined,
  includeModal: true,
};
