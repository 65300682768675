import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore,
} from 'mobx-react-router';
import qs from 'querystring';
import { reaction } from 'mobx';
import { slugify } from '../utils/slugs';
import { PAGING } from '../constants';

class RouterStore extends BaseRouterStore {
  constructor(rootStore, history) {
    super();
    this.rootStore = rootStore;
    this.history = syncHistoryWithStore(history, this);
    reaction(
      () => this.location,
      () => {
        rootStore.navStore.closeMenuAndSearch();
      },
    );
  }

  slugify = string => (this.rootStore.integration ? '' : slugify(string));

  navigateToCatalogue = ({ queryObject = null } = {}) => {
    this.push(
      `/catalogue${queryObject ? `?${qs.stringify(queryObject)}` : ''}`,
    );
  };

  navigateToHome = () => {
    this.push('/');
  };

  navigateToCart = () => {
    this.push('/cart');
  };

  navigateToCheckout = () => {
    this.push('/checkout');
  };

  navigateTo404 = () => {
    this.push('/404');
  };

  navigateToCourseDetail = ({
    categoryId,
    subcategoryId,
    courseCode,
    courseName,
  }) => {
    let path = '';
    if (categoryId) {
      path = path.concat(`/catalogue/${categoryId}`);
    }
    if (subcategoryId) {
      path = path.concat(`/subcategory/${subcategoryId}`);
    }
    this.push(`${path}/courses/${courseCode}${this.slugify(courseName)}`);
  };

  navigateToPathDetail = ({ categoryId, subcategoryId, pathId }) => {
    let path = '';
    if (categoryId) {
      path = path.concat(`/catalogue/${categoryId}`);
    }
    if (subcategoryId) {
      path = path.concat(`/subcategory/${subcategoryId}`);
    }
    this.push(`${path}/paths/${pathId}`);
  };

  navigateToGiftVouchers = () => {
    this.push('/gift-vouchers');
  };

  navigateToCategory = ({
    categoryId,
    subcategoryId,
    categoryName,
    subcategoryName,
    queryObject = null,
    pageNumber = 1,
  } = {}) => {
    if (subcategoryId) {
      this.push(
        `/catalogue/${categoryId}/subcategory/${subcategoryId}${
          queryObject ? `?${qs.stringify(queryObject)}` : ''
        }${this.slugify(subcategoryName)}`,
      );
    } else if (categoryId) {
      this.push(
        `/catalogue/${categoryId}${
          pageNumber && PAGING.TYPE === 'full' ? `/page/${pageNumber}` : ''
        }${
          queryObject && PAGING.TYPE !== 'full'
            ? `?${qs.stringify(queryObject)}`
            : ''
        }${PAGING.TYPE !== 'full' ? `${this.slugify(categoryName)}` : ''}`,
      );
    } else {
      this.push(`/catalogue/page/${pageNumber}`);
    }
  };

  navigateToRequestTraining = (interestId, interestName) =>
    this.push(`/trainingRequest/${interestId}${this.slugify(interestName)}`);
}

export default RouterStore;
