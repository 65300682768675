import course from './course';
import category from './category';
import cart from './cart';
import event from './event';
import fragments from './fragments';
import store from './store';
import location from './location';
import learningPath from './learningPath';
import country from './country';

export default {
  course,
  category,
  cart,
  event,
  fragments,
  store,
  location,
  learningPath,
  country,
};
