import { decorate, action, observable, runInAction } from 'mobx';

class ConfigStore {
  constructor() {
    runInAction(() => {
      this.locale = window?.navigator?.language ?? null;
    });
  }

  setLocale = action(locale => {
    if (this.locale !== locale) {
      this.locale = locale;
    }
  });
}

decorate(ConfigStore, {
  locale: observable,
});

export default new ConfigStore();
