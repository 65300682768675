import gql from 'graphql-tag';
import fragments from './fragments';

const getPathForSearchBar = gql`
  query getPathForSearchBar($name: String) {
    learningPaths(
      filters: { field: name, operation: like, value: $name }
      order: { field: name, direction: asc }
      first: 5
    ) {
      edges {
        node {
          ...LearningPath
        }
      }
    }
  }
  ${fragments.learningPath}
`;

const getPathById = gql`
  query getPathById($id: String!) {
    learningPaths(filters: { field: id, operation: eq, value: $id }) {
      edges {
        node {
          __typename
          ...LearningPath
          ...LearningPathAnalyticsDetails
        }
      }
    }
  }
  ${fragments.learningPath}
  ${fragments.learningPathAnalyticsDetails}
`;

export const getPathEventSelectionOptions = gql`
  query getEventSelectionOptions(
    $learningPathId: ID!
    $filters: [EventSelectionOptionFieldFilter!]
    $orderBy: [EventSelectionOptionFieldOrder!]
    $first: Int!
    $offset: Int!
  ) {
    learningPathEventSelectionOptions(
      learningPathId: $learningPathId
      filters: $filters
      orderBy: $orderBy
      first: $first
      offset: $offset
    ) {
      pageInfo {
        totalRecords
      }
      edges {
        node {
          eventOptionId
          event {
            id
            name
            location {
              id
              name
            }
            prices {
              amount
              financialUnit {
                name
                symbol
                code
              }
              region {
                id
                code
                name
              }
            }
            start
            end
            remainingPlaces
            timeZoneName
            registrationOpen
            registrationOpensAt
          }
        }
      }
    }
  }
`;

export default {
  getPathById,
  getPathForSearchBar,
  getPathEventSelectionOptions,
};
