import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import {
  TextInput,
  CheckboxInput,
  SelectListInput,
} from '@administrate/piston';

import {
  CustomFieldInput,
  Form,
  useTypedFormValues,
} from '@administrate/piston-ux';

import ValidatedLabelledInput from './ValidatedLabelledInput';
import CountryProvinceSelector from './Checkout/CountryProvinceSelector';
import { resetForm } from '../utils/forms';
import { parseRawValueIntoBoolean } from '../utils/customFields';

const CustomInput = ({ definition, learner, id, t }) => {
  const getValueFromStore = key =>
    learner.customFieldValues ? learner.customFieldValues[key] : '';

  const getMatchingOptionFromValue = (options, value) => {
    const foundOptions = options.filter(option => option.value === value);
    return foundOptions.length > 0 ? foundOptions[0] : null;
  };

  const createMultichoiceValue = value => {
    if (!value) {
      return [];
    }

    // Our values are in the store in the form "Foo|Bar|Baz"
    return value
      .split('|')
      .map(v => getMatchingOptionFromValue(definition.options, v));
  };

  const commonProps = {
    key: definition.key,
    label: definition.label,
    value: getValueFromStore(definition.key),
    onChange: newValue =>
      learner.setCustomFieldValue(
        definition.key,
        newValue === '' ? null : newValue,
      ),
    isValid: learner.isValidCustomField(definition),
    required: !learner.isValidCustomField(definition),
    id,
  };

  const values = useTypedFormValues({
    [id]: getValueFromStore(definition.key),
  });

  useEffect(
    () => resetForm(values, { [id]: getValueFromStore(definition.key) }),
    [id, getValueFromStore, values],
  );

  switch (definition.type) {
    case 'string':
      return <ValidatedLabelledInput {...commonProps} />;
    case 'url':
      return (
        <ValidatedLabelledInput
          {...commonProps}
          errorMessage="Value must be a valid URL"
        />
      );
    case 'email':
      return (
        <ValidatedLabelledInput
          {...commonProps}
          errorMessage="Value must be a valid email"
        />
      );
    case 'text':
      return (
        <ValidatedLabelledInput {...commonProps}>
          <TextInput
            multiline
            id={id}
            key={definition.key}
            value={getValueFromStore(definition.key) || ''}
            ariaLabel={definition.label}
            onChange={newValue =>
              learner.setCustomFieldValue(
                definition.key,
                newValue === '' ? null : newValue,
              )
            }
            isValid={learner.isValidCustomField(definition)}
          />
        </ValidatedLabelledInput>
      );
    // TODO: Add case 'html':
    case 'number':
      return (
        <ValidatedLabelledInput
          {...commonProps}
          errorMessage="Value must be a number"
        />
      );
    case 'integer':
      return (
        <ValidatedLabelledInput
          {...commonProps}
          errorMessage="Value must be an integer"
        />
      );
    case 'checkbox':
      return (
        <ValidatedLabelledInput {...commonProps}>
          <CheckboxInput
            id={id}
            key={definition.key}
            ariaLabel={definition.label}
            value={parseRawValueIntoBoolean(getValueFromStore(definition.key))}
            onChange={newValue =>
              learner.setCustomFieldValue(
                definition.key,
                newValue ? 'true' : 'false',
              )
            }
          />
        </ValidatedLabelledInput>
      );
    case 'choice':
    case 'country':
    case 'language':
      return (
        <ValidatedLabelledInput {...commonProps}>
          <SelectListInput
            key={definition.key}
            value={getMatchingOptionFromValue(
              definition.options,
              getValueFromStore(definition.key),
            )}
            onChange={newValue => {
              if (!newValue) {
                learner.setCustomFieldValue(definition.key, null);
                return;
              }
              learner.setCustomFieldValue(definition.key, newValue.value);
            }}
            options={definition.options ? definition.options : []}
          />
        </ValidatedLabelledInput>
      );
    case 'province':
      return (
        <CountryProvinceSelector
          id={id}
          definition={definition}
          learner={learner}
          value={commonProps.value}
          onChange={newValue =>
            learner.setCustomFieldValue(
              definition.key,
              newValue ? newValue.value : null,
            )
          }
        />
      );
    case 'multichoice':
      return (
        <ValidatedLabelledInput {...commonProps}>
          <SelectListInput
            id={id}
            multiple
            key={definition.key}
            value={createMultichoiceValue(getValueFromStore(definition.key))}
            onChange={newValues => {
              if (newValues.length === 0) {
                learner.setCustomFieldValue(definition.key, null);
                return;
              }

              const updatedValues = newValues.map(v => v.value).join('|');
              learner.setCustomFieldValue(definition.key, updatedValues);
            }}
            options={definition.options ? definition.options : []}
          />
        </ValidatedLabelledInput>
      );
    case 'date':
    case 'time':
    case 'datetime':
      return (
        <Form values={values}>
          <CustomFieldInput
            name={id}
            label={commonProps.label}
            type={definition.type}
            valid={() => commonProps.isValid}
            additionalInputProps={{ useBrowserLocale: true }}
            onChange={value =>
              learner.setCustomFieldValue(definition.key, value || null)
            }
          />
          {/*
          TODO: Remove when the valid message positioning from piston-ux is fixed
          for now it's enough to have screenreader feedback without displaying the
          error message
          */}
          {!commonProps.isValid && (
            <span className="sr-only">{t('weblink:required')}</span>
          )}
        </Form>
      );
    case 'document':
      // Document type not yet supported as we don't have the necessary document picker yet
      return null;
    default:
      return null;
  }
};

CustomInput.propTypes = {
  definition: PropTypes.shape({}).isRequired,
  learner: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CustomInput));
