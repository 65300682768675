import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import EventModal from '../../containers/EventModal/EventModal';
import PathModal from '../../containers/PathModal/PathModal';
import inject from '../../containers/inject';
import { STORES } from '../../constants/index';
import Alert from '../../containers/Alert';

const { STORE_MODALOUTLET } = STORES;

const ModalOutlet = ({ [STORE_MODALOUTLET]: { setOutletId, outletId } }) => {
  const id = useMemo(() => Math.floor(Math.random() * 10000), []);
  const [active, setActive] = useState(false);

  if (!outletId) {
    setOutletId(id);
    setActive(true);
  }

  useEffect(
    () => () => {
      if (active) {
        setOutletId(null);
      }
    },
    [active, setOutletId],
  );

  // avoid multiple outlets to be mounted
  if (outletId && outletId !== id) {
    if (active) {
      setActive(false);
    }
    return null;
  }

  return (
    <div className="weblink">
      <Alert />
      <PathModal />
      <EventModal />
    </div>
  );
};

export default inject(STORE_MODALOUTLET)(observer(ModalOutlet));
