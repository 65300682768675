import { action, decorate, observable, reaction } from 'mobx';
import { get as _get } from 'lodash';
import { DateTime } from 'luxon';

import queries from '../queries';
import BaseStore from './baseStore';
import alertStore from './alertStore';
import { formatDate, formatTime } from '../utils/formatDateTime';
import {
  getAvailablePriceLevels,
  prePopulatePriceLevel,
} from '../utils/priceLevel';
import { extractNodes } from '../utils/graphqlMappers';

const {
  course: { getCourseById },
  learningPath: { getPathById },
} = queries;

const eventPickerTitles = {
  addToCart: 'weblink:addToCart',
  cartlessCheckout: 'weblink:register',
  buyNow: 'weblink:buyNow',
};

const pathPickerTitles = {
  addToCart: 'weblink:addToCart',
  pathCartlessCheckout: 'weblink:register',
  buyNow: 'weblink:buyNow',
};

class DetailsStore extends BaseStore {
  updateCourseDetails = action('updateCourseDetails', rawData => {
    const outcomes = _get(rawData, 'courses.edges[0].node.outcomes');
    const normalPrice = _get(
      rawData,
      'courses.edges[0].node.priceRange.normalPrice.amount',
    );
    const events = _get(rawData, 'courses.edges[0].node.options.edges', []).map(
      event => {
        const startDate = DateTime.fromISO(_get(event, 'node.start'));
        const endDate = DateTime.fromISO(_get(event, 'node.end'));
        const deliveryMethod = _get(event, 'node.deliveryMethod');
        return {
          id: _get(event, 'node.id'),
          courseId: _get(event, 'node.course.id'),
          location: _get(event, 'node.location.name', 'Undecided'),
          price: {
            amount: Number(_get(event, 'node.price.amount', 0)),
            currency: this.rootStore.storeStore.currency.code,
          },
          startDate: formatDate(startDate),
          duration:
            deliveryMethod === 'lms'
              ? _get(event, 'node.accessDuration')
              : Math.ceil(endDate.diff(startDate, 'days').toObject().days),
          startTime: formatTime(startDate),
          endTime: formatTime(endDate),
          endDate: formatDate(endDate),
          endDateValid: endDate.isValid,
          remainingPlaces: _get(event, 'node.remainingPlaces'),
          registrationOpen: event.node.registrationOpen,
          registrationOpensAt: event.node.registrationOpensAt,
        };
      },
    );

    this.courseDetails = {
      id: _get(rawData, 'courses.edges[0].node.id'),
      code: _get(rawData, 'courses.edges[0].node.code'),
      name: _get(rawData, 'courses.edges[0].node.name'),
      description: _get(rawData, 'courses.edges[0].node.description'),
      teaserDescription: _get(
        rawData,
        'courses.edges[0].node.teaserDescription',
      ),
      prerequisites: _get(rawData, 'courses.edges[0].node.prerequisites'),
      topicsCovered: _get(rawData, 'courses.edges[0].node.topicsCovered'),
      faqs: _get(rawData, 'courses.edges[0].node.faqs'),
      outcomes:
        outcomes && outcomes.match(/<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>|\n/)
          ? outcomes
              .split(/<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>|\n/)
              .filter(item => item !== '')
          : [],
      normalPrice: normalPrice ? Number(normalPrice) : null,
      imageUrl: _get(rawData, 'courses.edges[0].node.imageUrl'),
      imageUrls: _get(rawData, 'courses.edges[0].node.imageUrls', []),
      events,
      bookableEvents: events.filter(event => event.registrationOpen),
      isFeatured: rawData?.courses?.edges[0]?.node?.isFeatured ?? false,
      categories: rawData?.courses?.edges[0]?.node?.categories ?? [],
      learningTags: extractNodes(
        rawData?.courses?.edges[0]?.node?.learningTags,
      ),
      accountAssociations: extractNodes(
        rawData?.courses?.edges[0]?.node?.accountAssociations,
      ),
    };
  });

  updatePathDetails = action(
    'updatePathDetails',
    ({ learningPaths: { edges } }) => {
      if (!edges.length) {
        this.setErrorState(true);
        return;
      }
      const [
        {
          node: {
            id,
            code,
            name,
            description,
            imageUrl,
            imageUrls,
            price,
            remainingPlaces,
            requireObjectiveFulfillment,
            learningObjectives,
            isFeatured,
            categories,
            learningTags,
            accountAssociations,
          },
        },
      ] = edges;
      this.pathDetails = {
        id,
        code,
        name,
        description,
        imageUrl,
        imageUrls,
        price,
        requireObjectiveFulfillment,
        objectives: learningObjectives.edges,
        isFeatured,
        categories,
        learningTags: extractNodes(learningTags),
        accountAssociations: extractNodes(accountAssociations),
      };
      this.isPathFullyBooked = remainingPlaces === 0;
      this.resetSelectedPathObjectives();
      this._checkPathObjectiveFulfillment();
    },
  );

  setPathObjective = action('setPathObjective', (objectiveId, event) => {
    this.selectedPathObjectives[objectiveId] = event.id;

    // Resetting the quantity so that we cannot overbook a Path based on the
    // remainingPlaces for the Event Objective
    this.rootStore.pathPickerStore.resetQuantity();
    this.rootStore.pathPickerStore.setMaxCartQuantity(event.remainingPlaces);
    this._checkPathObjectiveFulfillment();
  });

  initialize = action('DetailsStore initialize', () => {
    this.courseDetails = {};
    this.courseBasicDetails = {};
    this.pathDetails = {};
    this.selectedPathObjectives = {};
    this.isLoading = true;
    this.filterbarCriteria = {};
    this.isError = false;
    this.isRegisterLoading = false;
    this.isPathFullyBooked = false;
    this.mustFulfillPathObjectives = false;
  });

  setLoadingState = action('setLoadingState', value => {
    this.isLoading = value;
  });

  setRegisterLoadingState = action('setRegisterLoadingState', value => {
    this.isRegisterLoading = value;
  });

  setErrorState = action('setErrorState', value => {
    this.isError = value;
  });

  navigateBackToCatalogue = action('navigateBackToCatalogue', () => {
    this.rootStore.navigationStore.toCatalogue();
  });

  resetSelectedPathObjectives = action('resetSelectedPathObjectives', () => {
    this.selectedPathObjectives = {};
  });

  _checkPathObjectiveFulfillment = () => {
    if (!this.pathDetails.requireObjectiveFulfillment) {
      this.mustFulfillPathObjectives = false;
      return;
    }
    const allCourseObjectiveIds = this.pathDetails.objectives
      .filter(({ node }) => node.__typename === 'CourseObjective')
      .map(({ node }) => node.id);
    this.mustFulfillPathObjectives = allCourseObjectiveIds.some(
      objectiveId => !this.selectedPathObjectives[objectiveId],
    );
  };

  constructor(rootStore) {
    super(rootStore);
    this.initialize();
    reaction(
      () => this.courseDetails,
      ({ id }) => id && this.rootStore.eventPickerStore.setCourseId(id),
    );
    reaction(
      () => this.pathDetails,
      ({ id }) => id && this.rootStore.pathPickerStore.setPathId(id),
    );
  }

  queryCourseDetails = action('queryCourseDetails', async ({ code }) => {
    this.setErrorState(false);
    this.setLoadingState(true);
    const rawResponse = await this.apolloClient.query({
      query: getCourseById,
      variables: {
        code: decodeURI(code),
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (rawResponse.errors) {
      this.setErrorState(true);
      return;
    }

    this.updateCourseDetails(rawResponse.data);
    this.setLoadingState(false);
  });

  queryPathDetails = action('queryPathDetails', async ({ id }) => {
    this.setErrorState(false);
    this.setLoadingState(true);
    const rawResponse = await this.apolloClient.query({
      query: getPathById,
      variables: {
        id,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    if (rawResponse.errors) {
      this.setErrorState(true);
      return;
    }

    this.updatePathDetails(rawResponse.data);
    this.setLoadingState(false);
  });

  openAddToCart = action('openAddToCart', ({ prePopulated } = {}) => {
    this.rootStore.eventPickerStore.open({
      prePopulated,
      title: eventPickerTitles.addToCart,
      action: this.addToCartAction,
    });
  });

  openCourseCartlessCheckout = action(
    'openCourseCartlessCheckout',
    ({ prePopulated } = {}) => {
      if (prePopulated) {
        this.rootStore.eventPickerStore.setCourseId(prePopulated.courseId);
      }
      this.rootStore.eventPickerStore.open({
        prePopulated,
        title: eventPickerTitles.cartlessCheckout,
        action: item =>
          this.cartlessCheckoutAction('addToCart', item, 'eventPickerStore'),
      });
    },
  );

  openAddPathToCart = action('openAddPathToCart', () => {
    this.rootStore.pathPickerStore.open({
      title: pathPickerTitles.addToCart,
      action: this.addPathToCartAction,
    });
  });

  openPathCartlessCheckout = action('openPathCartlessCheckout', () => {
    this.rootStore.pathPickerStore.open({
      title: pathPickerTitles.pathCartlessCheckout,
      action: item =>
        this.cartlessCheckoutAction('addToCart', item, 'pathPickerStore'),
    });
  });

  openBuyPathNow = action('openBuyPathNow', () => {
    this.rootStore.pathPickerStore.open({
      action: this.buyNowAction,
      title: pathPickerTitles.buyNow,
    });
  });

  openBuyNow = action('openBuyNow', ({ prePopulated } = {}) => {
    this.rootStore.eventPickerStore.open({
      prePopulated,
      title: eventPickerTitles.buyNow,
      action: this.buyNowAction,
    });
  });

  buyNowAction = action('buyNowAction', item => {
    const selectedPathObjectives = this._mapSelectedObjectivesToObjectiveArray();
    this.rootStore.cartStore.createBuyNowCartAndGoToCheckout(
      item,
      selectedPathObjectives,
    );
    this.rootStore.eventPickerStore.close();
    this.rootStore.pathPickerStore.close();
  });

  addEventListItemToCart = action('addEventListItemToCart', selectedEvent => {
    const { priceLevels } = selectedEvent;
    const availablePriceLevels = getAvailablePriceLevels(
      priceLevels,
      this.rootStore.storeStore.priceLevels,
    );
    if (availablePriceLevels.length > 1) {
      const defaultPriceLevel = prePopulatePriceLevel(availablePriceLevels);
      this.rootStore.eventPickerStore.setCourseId(selectedEvent.courseId);
      this.openAddToCart({
        prePopulated: { ...selectedEvent, priceLevel: defaultPriceLevel },
      });
    } else {
      this.addToCartAction(selectedEvent);
    }
  });

  addToCartAction = action('addToCartAction', selectedEvent => {
    this.rootStore.eventPickerStore.close();
    alertStore.setAlertMessage('weblink:addedToCart', 'success');
    this.rootStore.navigationStore.toCart();
    this.rootStore.cartStore.addToCart(selectedEvent);
  });

  addPathToCartAction = action('addPathToCartAction', selectedPath => {
    this.rootStore.pathPickerStore.close();
    alertStore.setAlertMessage('weblink:addedToCart', 'success');
    this.rootStore.navigationStore.toCart();
    const selectedPathObjectives = this._mapSelectedObjectivesToObjectiveArray();
    this.rootStore.cartStore.addToCart(selectedPath, selectedPathObjectives);
    this.resetSelectedPathObjectives();
  });

  addGiftVoucherToCartAction = action(
    'addGiftVoucherToCartAction',
    selectedGiftVoucher => {
      alertStore.setAlertMessage('weblink:addedToCart', 'success');
      this.rootStore.navigationStore.toCart();
      this.rootStore.cartStore.addGiftVoucherToCart(selectedGiftVoucher);
    },
  );

  _mapSelectedObjectivesToObjectiveArray = () =>
    Object.entries(
      this.selectedPathObjectives,
    ).map(([objectiveId, eventId]) => ({ objectiveId, eventId }));

  cartlessCheckoutAction = action(
    'cartlessCheckoutAction',
    async (addToCartAction, selectedItem, store) => {
      if (this.rootStore[store].show) this.rootStore[store].close();
      this.setRegisterLoadingState(true);
      await this.rootStore.cartStore.clearCart();
      const selectedPathObjectives = this._mapSelectedObjectivesToObjectiveArray();
      await this.rootStore.cartStore[addToCartAction](
        selectedItem,
        selectedPathObjectives,
      );
      this.setRegisterLoadingState(false);
      this.rootStore.navigationStore.toCheckout();
    },
  );
}

decorate(DetailsStore, {
  courseDetails: observable,
  pathDetails: observable,
  eventPickerItem: observable,
  filterbarCriteria: observable,
  isLoading: observable,
  isError: observable,
  isRegisterLoading: observable,
  mustFulfillPathObjectives: observable,
});

export default DetailsStore;
