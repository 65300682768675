import React from 'react';
import { observer } from 'mobx-react-lite';
import { SingleChoice } from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import inject from '../../inject';
import { STORES } from '../../../constants';
import {
  RedirectPaymentButton,
  RedirectPaymentInfo,
} from './Redirect/RedirectPaymentMethod';

const { STORE_CHECKOUT } = STORES;

const PaymentButton = ({
  [STORE_CHECKOUT]: { checkoutWithMultiSafePay },
  paymentMethod,
}) => (
  <RedirectPaymentButton
    onClick={() => checkoutWithMultiSafePay(paymentMethod)}
  />
);

PaymentButton.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
};

export const MultiSafePayPaymentButton = inject(STORE_CHECKOUT)(
  observer(PaymentButton),
);

const PaymentInfo = ({ t, changePaymentMethod, paymentMethod }) => (
  <RedirectPaymentInfo paymentProviderName="MultiSafepay">
    <h5 className="mt-4">{t('weblink:choosePaymentMethod')}</h5>
    <SingleChoice
      value={paymentMethod}
      onChange={value => changePaymentMethod(value)}
    >
      <SingleChoice.Option optionKey="CREDITCARD">
        {t('weblink:creditCard')}
      </SingleChoice.Option>
      <SingleChoice.Option optionKey="QR">
        {t('weblink:bancontact')}
      </SingleChoice.Option>
    </SingleChoice>
  </RedirectPaymentInfo>
);

PaymentInfo.propTypes = {
  t: PropTypes.func.isRequired,
  changePaymentMethod: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string.isRequired,
};

export default withTranslation()(observer(PaymentInfo));
