import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { CartEvent, PageViewEvent } from '../../analytics';
import Cart from '../../components/Cart/Cart';
import { STORES } from '../../constants';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import inject from '../inject';
import withIntegration from '../withIntegration';

const { STORE_CART, STORE_STORE, STORE_NAVIGATION, STORE_ANALYTICS } = STORES;

const CartContainer = ({
  hideEditItemButton,
  showPlacesRemaining,
  [STORE_STORE]: { defaultErrorMessage, storeDetails },
  [STORE_CART]: {
    cart,
    reloadCart,
    isLoading,
    isApplyPromotionCodeLoading,
    applyPromotionCode,
    isError,
    error,
    reservationsValidUntil,
    handleCartExpiry,
    containsValidItems,
    rebookCartItem,
    cartItemToRebook,
    removeCartItemToRebookFromCart,
  },
  [STORE_NAVIGATION]: { toCatalogue },
  [STORE_ANALYTICS]: { captureEvent },
  t,
  integration,
}) => {
  useEffect(() => {
    if (!storeDetails) return;

    setBrandedPageTitle([t('weblink:myCart')], storeDetails, !integration);
    captureEvent(new PageViewEvent());
  }, [storeDetails, integration, t, captureEvent]);

  useEffect(() => {
    if (isLoading) return;

    captureEvent(CartEvent.fromCartView({ cart }));
  }, [cart, isLoading, captureEvent]);

  return (
    <Cart
      t={t}
      integration={integration}
      hideEditItemButton={hideEditItemButton}
      showPlacesRemaining={showPlacesRemaining}
      defaultErrorMessage={defaultErrorMessage}
      cart={cart}
      reloadCart={reloadCart}
      isLoading={isLoading}
      isApplyPromotionCodeLoading={isApplyPromotionCodeLoading}
      applyPromotionCode={applyPromotionCode}
      isError={isError}
      error={error}
      reservationsValidUntil={reservationsValidUntil}
      handleCartExpiry={handleCartExpiry}
      containsValidItems={containsValidItems}
      rebookCartItem={rebookCartItem}
      cartItemToRebook={cartItemToRebook}
      removeCartItemToRebookFromCart={removeCartItemToRebookFromCart}
      toCatalogue={toCatalogue}
    />
  );
};

CartContainer.propTypes = {
  hideEditItemButton: PropTypes.bool,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
};

CartContainer.defaultProps = {
  hideEditItemButton: false,
  showPlacesRemaining: true,
};

export default withTranslation()(
  inject(
    STORE_STORE,
    STORE_CART,
    STORE_NAVIGATION,
    STORE_ANALYTICS,
  )(withIntegration(observer(CartContainer))),
);
