import React from 'react';
import { SelectListInput, Button } from '@administrate/piston';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Glyphicon, FormGroup } from 'react-bootstrap';
import { toJS } from 'mobx';

import inject from '../inject';
import { STORES } from '../../constants';

const { STORE_NAVIGATION, STORE_CATALOGUE } = STORES;

const SearchBar = ({
  [STORE_CATALOGUE]: {
    searchBarLoading,
    searchBarOptions,
    searchBarText,
    setSearchBarText,
  },
  [STORE_NAVIGATION]: { toCatalogue, toCourseDetail, toPathDetail },
  id,
  t,
}) => (
  <>
    <FormGroup role="search">
      <SelectListInput
        async
        id={id}
        ariaLabel="Search Courses"
        options={
          !searchBarLoading
            ? toJS(searchBarOptions).map(({ node }) => node.name)
            : []
        }
        onInputChange={setSearchBarText}
        isLoading={searchBarLoading}
        useCache={false}
        filterOptions={() => true}
        labelKey="name"
        placeholder={`${t('weblink:search')}...`}
        onChange={change => {
          if (change) {
            if (typeof change === 'string') {
              const { node: selectedNode, __typename: type } = toJS(
                searchBarOptions,
              ).find(({ node }) => node.name === change);
              if (type === 'CourseEdge') {
                toCourseDetail({
                  courseCode: selectedNode.code,
                  courseName: selectedNode.name,
                });
              } else {
                toPathDetail({
                  pathId: selectedNode.id,
                  pathName: selectedNode.name,
                });
              }
              setSearchBarText();
            } else {
              toCatalogue({ queryObject: { name: change.name } });
            }
          }
        }}
        allowNew
        newSelectionPrefix={`${t('weblink:searchFor')}: `}
        value={searchBarText}
      />
    </FormGroup>
    <Button
      onClick={() => {
        if (searchBarText) {
          toCatalogue({ queryObject: { name: searchBarText } });
        } else {
          toCatalogue();
        }
      }}
      className="searchButton"
    >
      <span className="sr-only">Search</span>
      <Glyphicon glyph="search" />
    </Button>
  </>
);

SearchBar.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
};

SearchBar.defaultProps = {
  id: "weblink-SearchBar",
}

export default withTranslation()(
  inject(STORE_NAVIGATION, STORE_CATALOGUE)(observer(SearchBar)),
);
