import { action, decorate, observable, reaction, runInAction } from 'mobx';
import { get as _get, noop as _noop } from 'lodash';

import queries from '../queries';
import BaseStore from './baseStore';
import { MAX_SEATS } from '../constants';
import {
  getAvailablePriceLevels,
  prePopulatePriceLevel,
} from '../utils/priceLevel';

const {
  learningPath: { getPathById },
} = queries;

class PathPickerStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.show = false;
      this.title = null;
      this.action = _noop;
      this.pathId = null;
      this.pathTitle = '';
      this.pathPrice = null;
      this.imageUrl = null;
      this.isLoading = true;
      this.selectedPath = {
        quantity: 1,
      };
      this.priceLevels = [];
      this.maxCartQuantity = null;
    });
  }

  initialize = async () => {
    reaction(
      () => this.pathId,
      () => this.queryPath(),
    );
  };

  updateSelectablePath = action(rawData => {
    this.pathTitle = _get(rawData, 'learningPaths.edges[0].node.name');
    this.pathPrice = _get(rawData, 'learningPaths.edges[0].node.price');
    this.imageUrl = _get(rawData, 'learningPaths.edges[0].node.imageUrl');
    this.priceLevels = _get(rawData, 'learningPaths.edges[0].node.priceLevels');
  });

  setIsLoading = action(value => {
    this.isLoading = value;
  });

  setPathId = action(pathId => {
    if (this.pathId !== pathId) {
      this.pathId = pathId;
    }
  });

  prePopulate = action(() => {
    const priceLevels = getAvailablePriceLevels(
      this.priceLevels,
      this.rootStore.storeStore.priceLevels,
    );
    this.selectedPath = {
      quantity: 1,
      title: this.pathTitle,
      pathId: this.pathId,
      price: this.pathPrice,
      imageUrl: this.imageUrl,
      priceLevels,
      priceLevel: prePopulatePriceLevel(priceLevels),
    };
  });

  open = action(({ title, action: pathPickerAction }) => {
    this.show = true;
    this.title = title;
    this.action = pathPickerAction;
  });

  close = action(() => {
    this.show = false;
  });

  queryPath = action(async () => {
    this.setIsLoading(true);
    this.pathTitle = '';
    const id = this.pathId;
    const rawResponse = await this.apolloClient.query({
      query: getPathById,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });

    this.updateSelectablePath(rawResponse.data);
    this.prePopulate();
    this.setIsLoading(false);
  });

  changeQuantity = action(value => {
    const quantity =
      Math.min(
        ...[
          ...(this.maxCartQuantity !== null ? [this.maxCartQuantity] : []),
          parseInt(value, 10),
          this.rootStore.maxQuantity || MAX_SEATS,
        ],
      ) || null;

    this.selectedPath = {
      ...this.selectedPath,
      quantity,
    };
  });

  changePriceLevel = action(priceLevel => {
    this.selectedPath = {
      ...this.selectedPath,
      priceLevel,
    };
  });

  resetQuantity = action(() => {
    this.selectedPath = {
      ...this.selectedPath,
      quantity: 1,
    };
  });

  setMaxCartQuantity = action(quantity => {
    this.maxCartQuantity = quantity;
  });
}

decorate(PathPickerStore, {
  isLoading: observable,
  pathId: observable,
  pathTitle: observable,
  pathPrice: observable,
  selectedPath: observable,
  show: observable,
});

export default PathPickerStore;
