import React from 'react';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  List,
  ListHeader,
  ListRow,
  ListCell,
  Placeholder,
} from '@administrate/piston-ux';
import { EventDateRange } from './EventDateRange';
import Modal from './Modal';
import { useWebLinkQuery } from '../weblink-hooks';
import { getSessionsForEvent } from '../queries/sessions';
import { extractNodes } from '../utils/graphqlMappers';

const GRID_COLUMNS = '1fr 1fr 1fr';

const EventSessionsModal = ({ t, eventId, placeholderRows, onDoneClicked }) => {
  const { data, loading } = useWebLinkQuery(getSessionsForEvent, { eventId });

  return (
    <Modal
      onDone={onDoneClicked}
      title={t('weblink:eventSessions')}
      show
      loading={loading}
      size="large"
      primaryActionText={t('weblink:ok').toUpperCase()}
      showCancelButton={false}
      id="eventSessions"
    >
      <List loading={loading}>
        <ListHeader
          gridColumns={GRID_COLUMNS}
          headings={[
            {
              title: t('weblink:name'),
            },

            {
              title: t('weblink:dates'),
            },
            {
              title: t('weblink:location'),
            },
          ]}
        />
        {loading || !data ? (
          <EventSessionModalLoading t={t} placeholderRows={placeholderRows} />
        ) : (
          extractNodes(data.sessions).map(session => (
            <ListRow
              key={session.id}
              gridColumns={GRID_COLUMNS}
              dataLabel={session.name}
            >
              <ListCell label={t('name')}>{session.name}</ListCell>
              <ListCell label={t('dates')}>
                <EventDateRange event={session} />
              </ListCell>
              <ListCell label={t('location')}>
                {session.location ? session.location.name : null}
              </ListCell>
            </ListRow>
          ))
        )}
      </List>
    </Modal>
  );
};

EventSessionsModal.propTypes = {
  t: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  onDoneClicked: PropTypes.func.isRequired,
  placeholderRows: PropTypes.number,
};

EventSessionsModal.defaultProps = {
  placeholderRows: 3,
};

function EventSessionModalLoading({ t, placeholderRows }) {
  return [...Array(placeholderRows)].map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <ListRow key={i} gridColumns={GRID_COLUMNS} extra={<Placeholder />}>
      <ListCell loading label={t('name')} />
      <ListCell loading label={t('dates')} />
      <ListCell loading label={t('location')} />
    </ListRow>
  ));
}

EventSessionModalLoading.propTypes = {
  t: PropTypes.func.isRequired,
  placeholderRows: PropTypes.number,
};

EventSessionModalLoading.defaultProps = {
  placeholderRows: 3,
};

export default withTranslation()(observer(EventSessionsModal));
