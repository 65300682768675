import { observable, decorate, runInAction, action, computed } from 'mobx';
import { get as _get } from 'lodash';

import queries from '../queries';
import BaseStore from './baseStore';
import { INTEGRATON_CATEGORY_PAGES } from '../constants';

const { CATEGORIES, SUBCATEGORIES, CATALOGUE } = INTEGRATON_CATEGORY_PAGES;

class IntegrationCategoryStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.page = CATEGORIES;
      this.categoryId = null;
      this.subcategoryId = null;
      this.categoriesResponse = null;
      this.categoriesResponseIsLoading = false;
    });
  }

  initialize = async () => {
    await this.fetchCategories();
  };

  goToCategories = action(() => {
    this.categoryId = null;
    this.subcategoryId = null;
    this.page = CATEGORIES;
  });

  goToSubCategories = action(({ categoryId }) => {
    this.categoryId = categoryId;
    this.subcategoryId = null;
    this.page = SUBCATEGORIES;
  });

  goToCatalogue = action(({ categoryId = null, subcategoryId = null }) => {
    this.categoryId = categoryId;
    this.subcategoryId = subcategoryId;
    this.page = CATALOGUE;
  });

  get categories() {
    return _get(this.categoriesResponse, 'categories.edges', []);
  }

  get category() {
    return _get(
      this.categories.find(({ node }) => node.id === this.categoryId),
      'node',
      {},
    );
  }

  setCategoryId = action(({ categoryId = null, subcategoryId = null }) => {
    if (
      categoryId !== this.categoryId ||
      subcategoryId !== this.subcategoryId
    ) {
      this.categoriesResponse = null;
      this.categoryId = categoryId;
      this.subcategoryId = subcategoryId;
    }
  });

  get categoryFilterId() {
    let categoryFilterId = null;
    if (this.categoryId && !this.subcategoryId) {
      categoryFilterId = this.categoryId;
    }
    if (this.subcategoryId) {
      categoryFilterId = this.subcategoryId;
    }
    return categoryFilterId;
  }

  fetchCategories = action(async () => {
    this.categoriesResponseIsLoading = true;
    const { data: categoriesResponse } = await this.apolloClient.query({
      query: queries.category.getCategories,
      variables: {
        categoryFieldOrder: {
          field: 'name',
          direction: 'asc',
        },
        ...(this.categoryFilterId
          ? {
              categoryFieldFilter: {
                field: 'id',
                value: this.categoryFilterId,
                operation: 'eq',
              },
            }
          : null),
      },
    });
    runInAction(() => {
      this.categoriesResponseIsLoading = false;
      this.categoriesResponse = categoriesResponse;
    });
  });
}

decorate(IntegrationCategoryStore, {
  page: observable,
  categoryId: observable,
  subcategoryId: observable,
  categoriesResponse: observable,
  categoriesResponseIsLoading: observable,
  categories: computed,
  category: computed,
});

export default IntegrationCategoryStore;
