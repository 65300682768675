import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import ApolloLinkTimeout from 'apollo-link-timeout';

import alertStore from './stores/alertStore';
import portalStore from './stores/portalStore';
import configStore from './stores/configStore';
import { portalTokenStore } from './stores/localStorage';
import { rootStore } from './stores';
import queries from './queries';
import {
  buildCache,
  getAuthorizationHeaders,
  INVALID_TOKEN_MESSAGES,
  TIMEOUT_MILLISECONDS,
} from './graphql/apolloClientUtils';
import { getQueryParams } from './utils/url';

let tokenFetcher = null;

const fetchToken = ({ operation, forward, portalAuthURL, portalAuthDomain }) =>
  new Observable(observer => {
    if (!tokenFetcher) {
      // Create tokenFetcher
      tokenFetcher = makePostRequest(
        portalAuthURL,
        {
          domain: portalAuthDomain,
        },
        {
          'Content-Type': 'application/json',
        },
      );
      // Save portalToken if no portalToken is present
      // Always save createCart portalToken (this one will be used to auth the cart aswell)
      tokenFetcher.then(({ portal_token: token }) => {
        if (
          !portalTokenStore.getPortalToken(portalAuthDomain) ||
          operation.operationName === 'createCart'
        ) {
          portalTokenStore.setPortalToken(portalAuthDomain, token);
        }
        tokenFetcher = null;
      });
    }

    tokenFetcher
      .then(({ portal_token: token }) => {
        operation.setContext({
          headers: {
            'weblink-portal': portalAuthDomain,
            authorization: `Bearer ${token}`,
          },
        });
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        forward(operation).subscribe(subscriber);
      })
      .catch(err => {
        if (!rootStore.integration && err.status === 404) {
          return rootStore.navigationStore.to404();
        }
        return observer.error(err);
      });
  });

const setAuthorizationHeaders = (
  operation,
  portalAuthDomain,
  token,
  locale,
) => {
  operation.setContext({
    headers: getAuthorizationHeaders(portalAuthDomain, token, locale),
  });
  return operation;
};

const createAuthMiddleware = ({ portalAuthURL, portalAuthDomain }) =>
  new ApolloLink((operation, forward) => {
    portalStore.setPortalAuthDomain(portalAuthDomain);
    if (window.location.search) {
      const {
        cartID,
        portalToken: portalTokenFromSearch,
        locale,
      } = getQueryParams();

      if (portalTokenFromSearch) {
        portalTokenStore.setPortalToken(
          portalAuthDomain,
          portalTokenFromSearch,
        );
      }
      if (cartID) {
        window.localStorage.setItem(
          `weblink:${portalAuthDomain}:cartId`,
          cartID,
        );
      }
      if (locale) {
        configStore.setLocale(locale);
      }
    }

    const token = portalTokenStore.getPortalToken(portalAuthDomain);

    if (token) {
      // Always save createCart portalToken (this one will be used to auth the cart aswell)
      if (
        operation.operationName === 'createCart' &&
        portalTokenStore.getPortalToken(portalAuthDomain) !== token
      ) {
        portalTokenStore.setPortalToken(portalAuthDomain, token);
      }
      setAuthorizationHeaders(
        operation,
        portalAuthDomain,
        token,
        configStore.locale,
      );
      return forward(operation);
    }

    return fetchToken({
      operation,
      forward,
      portalAuthURL,
      portalAuthDomain,
    });
  });

const cache = buildCache();

const createClient = ({ SFAPIURL: uri, portalAuthURL, portalAuthDomain }) =>
  new ApolloClient({
    link: ApolloLink.from([
      createAuthMiddleware({ portalAuthURL, portalAuthDomain }),
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            if (
              message ===
              'Context creation failed: Portal restricted to authenticated users'
            ) {
              alertStore.setUnauthorized();
              rootStore.navigationStore.to404();
            }
            // Cart auth error - reset cart
            if (INVALID_TOKEN_MESSAGES.includes(message)) {
              portalTokenStore.deletePortalToken(portalAuthDomain);
              window.localStorage.removeItem(
                `weblink:${portalAuthDomain}:cartId`,
              );
              window.localStorage.removeItem(
                `weblink:${portalAuthDomain}:buyNowCartId`,
              );
              rootStore.cartStore.resetCart();
              rootStore.checkoutStore.initialize();
            }
            if (
              message.includes('Portal not found') ||
              message.includes('Portal disabled')
            ) {
              rootStore.navigationStore.to404();
            }
            // eslint-disable-next-line no-console
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          });
          return null;
        }
        if (networkError) {
          if (
            networkError.statusCode === 401 &&
            (networkError.result.error === 'Token Expired or Invalid' ||
              networkError.result.error === 'Invalid Token')
          ) {
            portalTokenStore.deletePortalToken(portalAuthDomain);
            // Reset cart if present
            window.localStorage.removeItem(
              `weblink:${portalAuthDomain}:cartId`,
            );
            window.localStorage.removeItem(
              `weblink:${portalAuthDomain}:buyNowCartId`,
            );
            rootStore.cartStore.resetCart();
            rootStore.checkoutStore.initialize();

            // Skip cart related request retries, but createCart (if we dont have a token with createCart it should retry with fetchToken)
            if (
              Object.keys(queries.cart).includes(operation.operationName) &&
              operation.operationName !== 'createCart'
            ) {
              return null;
            }
            // Retry call with new token
            return fetchToken({
              operation,
              forward,
              portalAuthURL,
              portalAuthDomain,
            });
          }
          if (networkError.statusCode === 404) {
            rootStore.navigationStore.to404();
          }
          alertStore.setAlertMessage('weblink:networkErrorEncountered');
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`);
        }
        return null;
      }),
      new ApolloLinkTimeout(TIMEOUT_MILLISECONDS),
      new HttpLink({
        uri,
        credentials: 'same-origin',
      }),
    ]),
    cache,
  });

class PortalAuthError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.name = 'PortalAuthError';
    this.statusCode = statusCode;
  }
}

async function makePostRequest(url, body, headers) {
  const result = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    throw new PortalAuthError(result.statusText, result.status);
  }

  return result.json();
}

export default createClient;
