import React from 'react';
import { Card } from '@administrate/piston';
import { Placeholder } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import DetailsCardImages from './DetailsCardImages';

const DetailsCardSkeleton = () => (
  <Card>
    <Card.Body type="course-detail">
      <DetailsCardImages />
      <div className="card-content course-price">
        <div className="CardCourse--values">
          <Placeholder width="40%" />
        </div>
      </div>
      <div className="card-content call-to-actions">
        <Placeholder />
        <Placeholder />
        <div className="trainingRequest">
          <Placeholder width="40%" />
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default withTranslation()(DetailsCardSkeleton);
