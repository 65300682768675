import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Card, Container } from '@administrate/piston';
import { withTranslation } from 'react-i18next';

const RequestComplete = ({ t }) => (
  <Card>
    <Card.Body>
      <Container fluid>
        <div className="pt-4">
          <Container.Row>
            <Container.Col xs={12}>
              <div className="text-center mb-4">
                <span
                  className="text-center glyphicon glyphicon-exclamation-sign"
                  style={{
                    color: '#cccccc',
                    height: 100,
                    width: 100,
                    fontSize: 100,
                  }}
                />
              </div>
              <h2 className="text-center">{t('weblink:awaitingApproval')}</h2>
              <p className="text-center">
                {t('weblink:awaitingApprovalContent')}
              </p>
            </Container.Col>
          </Container.Row>
        </div>
      </Container>
    </Card.Body>
  </Card>
);

RequestComplete.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(RequestComplete));
