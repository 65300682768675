import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { code as iso4217Code } from 'currency-codes';

import { SecondaryAction } from '@administrate/piston';
import PropTypes from 'prop-types';
import CardPaymentForm from './CardPaymentForm';

import inject from '../../inject';
import { STORES } from '../../../constants';
import { useScript } from '../../../weblink-hooks';

const { STORE_CHECKOUT, STORE_CART } = STORES;

const PROD_CONVERGE_JS_URL =
  'https://api.convergepay.com/hosted-payments/Checkout.js';
const TESTING_CONVERGE_JS_URL =
  'https://api.demo.convergepay.com/hosted-payments/Checkout.js';

const PROD_CONVERGE_3DS_URL =
  'https://libs.fraud.elavongateway.com/sdk-web-js/1.0.5/3ds2-web-sdk.min.js';
const TESTING_CONVERGE_3DS_URL =
  'https://uat.libs.fraud.eu.elavonaws.com/sdk-web-js/1.0.5/3ds2-web-sdk.min.js';

const PROD_CONVERGE_EFS_URL = 'https://gw.fraud.elavongateway.com/3ds2';
const TESTING_CONVERGE_EFS_URL = 'https://uat.gw.fraud.eu.elavonaws.com/3ds2';

const parseErrorResponse = (response, setIsLoading, setError, t) => {
  const { error } = response;
  const code = Number(error.errorCode);

  // eslint-disable-next-line no-console
  console.error('3DS Error:', error);
  switch (code) {
    case 203:
      setError(t('weblink:threeDsCheckYourDetails'));
      break;
    case 305:
      setError(t('weblink:threeDsTryAnotherCard'));
      break;
    case 402:
    case 403:
    case 404:
    case 405:
      setError(t('weblink:threeDSecureServerUnavailable'));
      break;
    default:
      setError(t('weblink:threeDsCheckYourDetails'));
  }
  setIsLoading(false);
};

const onThreeDSecure = async ({
  response,
  isLive,
  values,
  cart,
  setIsLoading,
  checkoutWithConverge,
  setError,
  resetErrorAndLoading,
  t,
}) => {
  if (!response.ssl_3ds2_token) {
    // Required to handle transitionary period where customers don't have 3DS2 enabled.
    if (
      response.ssl_3ds2_error &&
      response.ssl_3ds2_error === 'Terminal not setup for 3ds2'
    ) {
      checkoutWithConverge(values);
      return;
    }

    // eslint-disable-next-line no-console
    console.error('3DS Response:', response);
    setError(t('weblink:threeDsFailedToInitialize'));
    return;
  }

  const sdk = new window.Elavon3DSWebSDK({
    baseUrl: isLive ? PROD_CONVERGE_EFS_URL : TESTING_CONVERGE_EFS_URL,
    token: response.ssl_3ds2_token,
  });

  const cartPrice = cart.price;
  const currencyCode = cart.currency.code;
  const [mm, yy] = values.expiration.split('/');

  const threeDsRequest = {
    messageId: `Cart:${cart.id}`,
    purchaseAmount: (parseFloat(cartPrice.grandTotal) * 100).toString(),
    purchaseCurrency: iso4217Code(currencyCode).number,
    purchaseExponent: '2',
    acctNumber: values.cardNumber,
    cardExpiryDate: `${yy}${mm}`,
    messageCategory: '01',
    transType: '01',
    threeDSRequestorAuthenticationInd: '01',
    challengeWindowSize: '03',
    displayMode: 'lightbox',
    // TODO: These don't seem to work for 3DS1 fallback atm...
    clientStartProtocolVersion: '1.0.2',
    clientEndProtocolVersion: '2.1.0',
  };

  // Works around https://bugs.chromium.org/p/chromium/issues/detail?id=1095999 for now
  const { height, width, colorDepth } = window.screen;
  window.screen = {
    colorDepth: colorDepth === 30 ? 24 : colorDepth,
    height,
    width,
  };

  sdk.web3dsFlow(threeDsRequest).then(
    res => {
      if (!res.authenticated || res.transStatus === 'N') {
        // eslint-disable-next-line no-console
        console.error('3DS Error:', res);
        setError('weblink:threeDsFailedToAuthenticate');
        setIsLoading(false);
        return;
      }

      resetErrorAndLoading();
      checkoutWithConverge(values);
    },
    res => {
      parseErrorResponse(res, setIsLoading, setError, t);
    },
  );
};

const PaymentButton = ({
  [STORE_CHECKOUT]: {
    getConvergeToken,
    checkoutWithConverge,
    isLoading: checkoutIsLoading,
    isCompletePaymentLoading,
    canCompleteOrder,
  },
  [STORE_CART]: {
    isApplyPromotionCodeLoading,
    containsValidItems,
    cart,
    changeIsError,
    setCheckoutError,
  },
  values,
  isLive,
  t,
}) => {
  const [isLoading, setIsLoading] = useState(checkoutIsLoading);

  const resetErrorAndLoading = useCallback(() => {
    changeIsError(false);
    setCheckoutError('');
    setIsLoading(false);
  }, [setIsLoading]);

  const setError = error => {
    setCheckoutError(error);
    changeIsError(true);
  };

  return (
    <SecondaryAction
      label={
        <span>
          {t('weblink:completePayment')}
          {isLoading && (
            <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
          )}
        </span>
      }
      disabled={
        isApplyPromotionCodeLoading ||
        isLoading ||
        isCompletePaymentLoading ||
        !canCompleteOrder ||
        !containsValidItems
      }
      onClick={async () => {
        resetErrorAndLoading();
        setIsLoading(true);

        const threeDsCallback = {
          onThreeDSecure2: async response =>
            onThreeDSecure({
              response,
              isLive,
              values,
              cart,
              setIsLoading,
              checkoutWithConverge,
              resetErrorAndLoading,
              setError,
              t,
            }),
        };

        try {
          const token = await getConvergeToken();
          window.ConvergeEmbeddedPayment.getEFSToken(
            { ssl_txn_auth_token: token },
            threeDsCallback,
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to get token from Converge API', err);
          setIsLoading(false);
          setError(t('weblink:threeDsTokenGenerationFailed'));
        }
      }}
    />
  );
};

PaymentButton.propTypes = {
  values: PropTypes.shape({}).isRequired,
  isLive: PropTypes.bool.isRequired,
};

export const ConvergePaymentButton = withTranslation()(
  inject(STORE_CHECKOUT, STORE_CART)(observer(PaymentButton)),
);

const PaymentInfo = ({ values, isLive }) => {
  useScript(isLive ? PROD_CONVERGE_JS_URL : TESTING_CONVERGE_JS_URL);
  useScript(isLive ? PROD_CONVERGE_3DS_URL : TESTING_CONVERGE_3DS_URL);

  return <CardPaymentForm values={values} />;
};

PaymentInfo.propTypes = {
  values: PropTypes.shape({}).isRequired,
  isLive: PropTypes.bool.isRequired,
};

export default observer(PaymentInfo);
