import React from 'react';
import PropTypes from 'prop-types';

import SummaryCard from '../SummaryCard/SummaryCard';
import useQueryParams from '../../hooks/useQueryParams';

const CheckoutSummaryCard = ({
  isOrderRetrievalCart,
  canRemovePromotionCode,
}) => {
  const params = useQueryParams();
  const cartUrl = params.get('cartUrl');
  const navigateToCart = cartUrl
    ? () => {
        window.location = cartUrl;
      }
    : null;

  return (
    <SummaryCard
      navigateToCart={navigateToCart}
      showAmendCart={!isOrderRetrievalCart}
      showCartContent
      canRemovePromotionCode={canRemovePromotionCode}
    />
  );
};

CheckoutSummaryCard.propTypes = {
  isOrderRetrievalCart: PropTypes.bool.isRequired,
  canRemovePromotionCode: PropTypes.bool.isRequired,
};

export default CheckoutSummaryCard;
