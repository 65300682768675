import React from 'react';
import { StandardAction, PaginationBar } from '@administrate/piston';
import PropTypes from 'prop-types';

const Pager = ({
  currentPage,
  totalPages,
  onSelectPage,
  disabled,
  label,
  onClick,
  pagerType,
  hasMoreProducts,
}) => {
  let pager;
  if (pagerType === 'full') {
    pager =
      totalPages > 1 ? (
        <PaginationBar
          currentPage={currentPage}
          totalPages={totalPages}
          onSelectPage={onSelectPage}
        />
      ) : (
        ''
      );
  } else {
    pager = hasMoreProducts ? (
      <StandardAction disabled={disabled} label={label} onClick={onClick} />
    ) : (
      ''
    );
  }
  return pager;
};
Pager.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  onSelectPage: null,
  disabled: null,
  label: null,
  onClick: null,
  hasMoreProducts: false,
};

Pager.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onSelectPage: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  pagerType: PropTypes.string.isRequired,
  hasMoreProducts: PropTypes.bool,
};
export default Pager;
