import { STORES } from '../constants';

import RouterStore from './routerStore';
import CatalogueStore from './catalogueStore';
import CheckoutStore from './checkoutStore';
import DetailsStore from './detailsStore';
import CartStore from './cartStore';
import CourseStore from './courseStore';
import EventPickerStore from './eventPickerStore';
import ReviewOrderStore from './reviewOrderStore';
import EventListStore from './eventListStore';
import StoreStore from './storeStore';
import NavStore from './navStore';
import PathPickerStore from './pathPickerStore';
import CallbackStore from './callbackStore';
import NavigationStore from './navigationStore';
import ModalOutletStore from './modalOutletStore';
import IntegrationNavigationStore from './integrationNavigationStore';
import IntegrationCategoryStore from './integrationCategoryStore';
import CatalogueFiltersStore from './catalogueFiltersStore';
import AnalyticsStore from './analyticsStore';
import UserStore from './userStore';
import { getQueryParams } from '../utils/url';
import portalStore from './portalStore';

const {
  STORE_ROOT,
  STORE_ROUTER,
  STORE_CATALOGUE,
  STORE_CHECKOUT,
  STORE_DETAILS,
  STORE_CART,
  STORE_COURSE,
  STORE_EVENT_PICKER,
  STORE_REVIEW_ORDER,
  STORE_STORE,
  STORE_NAV,
  STORE_PATH_PICKER,
  STORE_CALLBACK,
  STORE_NAVIGATION,
  STORE_INTEGRATION_NAVIGATION,
  STORE_INTEGRATION_CATEGORY,
  STORE_EVENTLIST,
  STORE_MODALOUTLET,
  STORE_CATALOGUE_FILTERS,
  STORE_ANALYTICS,
  STORE_USER,
} = STORES;

// eslint-disable-next-line import/no-mutable-exports
export let rootStore = null;

class RootStore {
  constructor(
    integration,
    { embededNavigation, history, callbacks, maxQuantity, analyticsHandler },
  ) {
    this.integration = integration;
    this.embededNavigation = embededNavigation;
    this.maxQuantity = maxQuantity;
    this.apolloClient = null;

    this.storeStore = new StoreStore(this);
    this.cartStore = new CartStore(this);
    this.courseStore = new CourseStore(this);
    this.catalogueStore = new CatalogueStore(this);
    this.navStore = new NavStore(this);
    this.routerStore = new RouterStore(this, history);
    this.reviewOrderStore = new ReviewOrderStore(this);
    this.eventPickerStore = new EventPickerStore(this);
    this.checkoutStore = new CheckoutStore(this);
    this.detailsStore = new DetailsStore(this);
    this.pathPickerStore = new PathPickerStore(this);
    this.callbackStore = new CallbackStore(this, callbacks);
    this.eventListStore = new EventListStore(this);
    if (integration) {
      this.integrationNavigationStore = new IntegrationNavigationStore(this);
      this.integrationCategoryStore = new IntegrationCategoryStore(this);
    }
    this.navigationStore = new NavigationStore(this);
    this.modalOutletStore = new ModalOutletStore();
    this.catalogueFiltersStore = new CatalogueFiltersStore(this);
    this.analyticsStore = new AnalyticsStore(this, analyticsHandler);
    this.userStore = new UserStore(this);
    this.initializationPromise = null;
  }

  async initialize(storeData, apolloClient, user) {
    // When bundled, we set up potentially many component trees with the same client,
    // we want this to only be called once, or if the client is replaced
    if (apolloClient === this.apolloClient) {
      return this.initializationPromise;
    }
    this.storeStore.updateStoreDetails(storeData);
    this.apolloClient = apolloClient;
    const isAuthenticated = !!user;
    this.initializationPromise = Promise.all(
      [
        this.cartStore.initialize(isAuthenticated),
        this.userStore.initialize(user),
      ].concat(
        [
          this.catalogueStore,
          this.eventPickerStore,
          this.checkoutStore,
          this.pathPickerStore,
          this.eventListStore,
          this.integrationCategoryStore,
          this.catalogueFiltersStore,
          this.analyticsStore,
        ]
          .filter(store => store !== undefined)
          .map(store => store.initialize()),
      ),
    );
    return this.initializationPromise;
  }
}

export default function createStores(
  integration,
  {
    embededNavigation = false,
    history,
    callbacks,
    maxQuantity,
    analyticsHandler,
  },
) {
  rootStore = new RootStore(integration, {
    embededNavigation,
    history,
    callbacks,
    maxQuantity,
    analyticsHandler,
  });

  return {
    [STORE_ROOT]: rootStore,
    [STORE_ROUTER]: rootStore.routerStore,
    [STORE_CATALOGUE]: rootStore.catalogueStore,
    [STORE_CHECKOUT]: rootStore.checkoutStore,
    [STORE_DETAILS]: rootStore.detailsStore,
    [STORE_CART]: rootStore.cartStore,
    [STORE_COURSE]: rootStore.courseStore,
    [STORE_EVENT_PICKER]: rootStore.eventPickerStore,
    [STORE_REVIEW_ORDER]: rootStore.reviewOrderStore,
    [STORE_STORE]: rootStore.storeStore,
    [STORE_NAV]: rootStore.navStore,
    [STORE_PATH_PICKER]: rootStore.pathPickerStore,
    [STORE_CALLBACK]: rootStore.callbackStore,
    [STORE_NAVIGATION]: rootStore.navigationStore,
    [STORE_EVENTLIST]: rootStore.eventListStore,
    [STORE_MODALOUTLET]: rootStore.modalOutletStore,
    [STORE_CATALOGUE_FILTERS]: rootStore.catalogueFiltersStore,
    [STORE_ANALYTICS]: rootStore.analyticsStore,
    [STORE_USER]: rootStore.userStore,
    ...(integration
      ? {
          [STORE_INTEGRATION_NAVIGATION]: rootStore.integrationNavigationStore,
          [STORE_INTEGRATION_CATEGORY]: rootStore.integrationCategoryStore,
        }
      : {}),
  };
}

class NoRootStore extends Error {
  constructor() {
    super('Root store not created, cannot initialize.');
  }
}

function processQueryParams() {
  if (!window.location.search) return;

  const { user, originalSite } = getQueryParams();

  if (user) {
    rootStore.userStore.setCustomUserId(user);
  }
  if (originalSite) {
    portalStore.setEmbedSite(originalSite);
  }
}

export async function initializeStores(storeData, apolloClient, user) {
  if (!rootStore) {
    throw new NoRootStore();
  }
  await rootStore.initialize(storeData, apolloClient, user);
  processQueryParams();
}
