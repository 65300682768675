import {
  action,
  decorate,
  observable,
  toJS,
  computed,
  reaction,
  runInAction,
} from 'mobx';
import {
  get as _get,
  noop as _noop,
  assign as _assign,
  find as _find,
} from 'lodash';
import fp from 'lodash/fp';
import Event from './Event';

import queries from '../queries';
import { MAX_SEATS } from '../constants';
import BaseStore from './baseStore';
import {
  getAvailablePriceLevels,
  prePopulatePriceLevel,
} from '../utils/priceLevel';

const { course: courseQueries } = queries;

class EventPickerStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.show = false;
      this.title = null;
      this.action = _noop;
      this.courseId = null;
      this.prePopulated = null;
      this.courseName = '';
      this.isLoading = true;
      this.imageUrl = null;
      this.selectableEvents = [];
      this.selectedEvent = {
        quantity: 1,
      };
      this.filterCriteria = {};
    });
  }

  initialize = async () => {
    reaction(
      () => this.courseId,
      () => this.queryEvents(),
    );
  };

  close = action(() => {
    this.show = false;
    this.title = null;
    this.action = _noop;
  });

  open = action(({ title, action: modalAction, prePopulated }) => {
    this.setPrePopulated(prePopulated);
    this.prePopulate();
    this.title = title;
    this.action = modalAction;
    this.show = true;
  });

  updateSelectableEvents = action(rawData => {
    this.selectableEvents = _get(
      rawData,
      'courses.edges[0].node.options.edges',
      [],
    )
      .filter(({ node: eventNode }) => eventNode.registrationOpen)
      .map(Event.fromAPI);
    this.courseName = _get(rawData, 'courses.edges[0].node.name');
    this.imageUrl = _get(rawData, 'courses.edges[0].node.imageUrl');
  });

  setIsLoading = action(value => {
    this.isLoading = value;
  });

  setCourseId = action(courseId => {
    if (this.courseId !== courseId) {
      this.courseId = courseId;
    } else {
      this.prePopulate();
    }
  });

  setPrePopulated = action(event => {
    this.prePopulated = event;
  });

  resetSelected = action(() => {
    this.selectedEvent = {
      quantity: 1,
    };
    this.filterCriteria = {};
  });

  prePopulate = action(() => {
    if (this.prePopulated) {
      const locationNameOrObject = this.prePopulated.location;
      const locationName = locationNameOrObject?.name ?? locationNameOrObject;

      this.filterCriteria = {
        location: locationName,
        id: this.prePopulated.id,
      };
      this.selectedEvent = _assign(
        {
          quantity: this.prePopulated.quantity || 1,
          course: this.courseName,
          courseId: this.courseId,
          range: Event.fromAPI(
            this.prePopulated,
            this.rootStore.storeStore.currency.code,
          ).range,
          priceLevel: this.prePopulated.priceLevel,
        },
        _find(this.selectableEvents, {
          location: locationName,
          id: this.prePopulated.eventId || this.prePopulated.id,
        }),
      );
    } else if (this.selectableEvents.length === 1) {
      this.filterCriteria = {
        location: this.selectableEvents[0].location,
        id: this.selectableEvents[0].id,
      };
      this.selectedEvent = _assign(
        {
          quantity: 1,
          course: this.courseName,
          courseId: this.courseId,
        },
        this.selectableEvents[0],
      );
      this.selectedEvent.priceLevel = prePopulatePriceLevel(
        this.eventPriceLevels,
      );
    } else {
      this.resetSelected();
    }
  });

  queryEvents = action(async () => {
    this.setIsLoading(true);
    this.courseName = '';
    this.resetSelected();

    const rawResponse = await this.apolloClient.query({
      query: courseQueries.getCourseByFilter,
      variables: {
        filters: [{ field: 'id', value: this.courseId, operation: 'eq' }],
      },
      fetchPolicy: 'no-cache',
    });

    this.updateSelectableEvents(rawResponse.data);
    this.prePopulate();
    this.setIsLoading(false);
  });

  get eventLocations() {
    return fp.flow(
      fp.uniqBy('location'),
      fp.map(event => event.location),
      fp.filter(location => location !== 'Undecided'),
    )(this.selectableEvents);
  }

  get eventDates() {
    return fp.filter({ location: this.filterCriteria.location })(
      this.selectableEvents,
    );
  }

  changeQuantity = action(value => {
    const quantity =
      Math.min(
        ...[
          ...(this.selectedEvent.remainingPlaces !== null
            ? [this.selectedEvent.remainingPlaces]
            : []),
          parseInt(value, 10),
          this.rootStore.maxQuantity || MAX_SEATS,
        ],
      ) || null;
    this.selectedEvent = {
      ...this.selectedEvent,
      quantity,
    };
  });

  filterEvents = action(filter => {
    switch (filter.key) {
      case 'location':
        this.filterCriteria = {
          location: filter.value,
        };
        this.selectedEvent = {
          quantity: this.selectedEvent.quantity,
        };
        break;

      case 'id':
        if (filter.value) {
          const id = filter.value;
          this.filterCriteria = {
            ...this.filterCriteria,
            id,
          };
        } else {
          this.filterCriteria = {
            ...this.filterCriteria,
            id: null,
          };
        }
        this.selectedEvent = _assign(
          { quantity: 1, course: this.courseName, courseId: this.courseId },
          _find(this.selectableEvents, toJS(this.filterCriteria)),
        );
        this.selectedEvent.priceLevel = prePopulatePriceLevel(
          this.eventPriceLevels,
        );
        break;

      case 'priceLevel':
        this.selectedEvent = {
          ...this.selectedEvent,
          priceLevel: filter.value,
        };
        break;

      default:
        break;
    }
  });

  get eventPriceLevels() {
    const priceLevels = _get(this.selectedEvent, 'priceLevels', []);
    return getAvailablePriceLevels(
      priceLevels,
      this.rootStore.storeStore.priceLevels,
    );
  }
}

decorate(EventPickerStore, {
  courseId: observable,
  courseName: observable,
  eventDates: computed,
  eventLocations: computed,
  filterCriteria: observable,
  imageUrl: observable,
  isLoading: observable,
  prePopulated: observable,
  selectableEvents: observable,
  selectedEvent: observable,
  show: observable,
  title: observable,
  action: observable,
  eventPriceLevels: computed,
});

export default EventPickerStore;
