import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { Form, Input, useTypedFormValues } from '@administrate/piston-ux';

const CartItemQuantity = ({ row, disabled, onChange, t }) => {
  const values = useTypedFormValues({ quantity: Number(row.quantity) });

  useEffect(() => {
    // This is used to update the piston-ux form with the correct value when the
    // `row` is updated externally via re-creating the Cart etc. which is done
    // in the stores. We check for equality due to the fact that our `onChange`
    // also updates this value
    if (row.quantity !== values.quantity) {
      values.quantity = Number(row.quantity);
    }
  }, [row.quantity]);

  return (
    <Form values={values}>
      <div className="mt-md-2">
        <span className="content-quantity_label">{t('weblink:quantity')}:</span>
        <label className="sr-only" htmlFor="cart_quantity">
          {t('weblink:quantity')}
          input
        </label>

        <Input
          type="number"
          name="quantity"
          min={1}
          max={row.maxBookableQuantity}
          disabled={disabled}
          onChange={_.debounce(val => {
            const value = val > row.maxBookableQuantity ? row.maxBookableQuantity : val;
            values.quantity = value;
            onChange(value);
          }, 500)}
        />
      </div>
    </Form>
  );
};

CartItemQuantity.defaultProps = {
  disabled: false,
  onChange: undefined,
};

CartItemQuantity.propTypes = {
  row: MobxPropTypes.objectOrObservableObject.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CartItemQuantity));
