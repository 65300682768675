import { decorate, runInAction, observable, computed, action } from 'mobx';

import {
  isEmail,
  isURL,
  isNumber,
  isInteger,
} from '@administrate/piston-ux/lib/utils/validators';

/* eslint-disable-next-line */
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class GiftVoucherRecipient {
  constructor({
    email,
    confirmedEmail,
    name,
    giftVoucherHasLinkedItem,
    emailConfigurationId = '',
    postalAddressLineOne = '',
    postalAddressLineTwo = '',
    postalAddressTown = '',
    postalAddressPostcode = '',
    postalAddressCountryId = '',
    postalAddressCountryName = '',
    message = '',
  }) {
    runInAction(() => {
      this.email = email;
      this.confirmedEmail = confirmedEmail;
      this.name = name;
      this.giftVoucherHasLinkedItem = giftVoucherHasLinkedItem;
      this.postalAddressLineOne = postalAddressLineOne;
      this.postalAddressLineTwo = postalAddressLineTwo;
      this.postalAddressTown = postalAddressTown;
      this.postalAddressPostcode = postalAddressPostcode;
      this.postalAddressCountryId = postalAddressCountryId;
      this.postalAddressCountryName = postalAddressCountryName;
      this.message = message;
      this.emailConfigurationId = emailConfigurationId;
    });
  }

  updateProperty = action(({ property, value }) => {
    this[property] = value;
  });

  updateEmail = action(({ email, confirmEmail }) => {
    if (!confirmEmail) {
      // If Portal confirmEmails setting is off
      // Populate field with email value so that validation for confirmEmail always return true
      this.confirmedEmail = email;
    }
    this.email = email;
  });

  get isEmailValid() {
    return emailRegex.test(this.email);
  }

  get isEmailConfirmed() {
    return this.confirmedEmail && this.confirmedEmail === this.email;
  }

  get isNameValid() {
    return !!this.name;
  }

  get isPostalAddressLineOneValid() {
    return this.giftVoucherHasLinkedItem ? !!this.postalAddressLineOne : true;
  }

  get isPostalAddressTownValid() {
    return this.giftVoucherHasLinkedItem ? !!this.postalAddressTown : true;
  }

  get isPostalAddressPostcodeValid() {
    return this.giftVoucherHasLinkedItem ? !!this.postalAddressPostcode : true;
  }

  get isPostalAddressCountryIdValid() {
    return this.giftVoucherHasLinkedItem ? !!this.postalAddressCountryId : true;
  }

  get isEmailConfigurationIdValid() {
    return this.emailConfigurationId === null
      ? true
      : !!this.emailConfigurationId;
  }

  isValid() {
    const emailValid =
      this.giftVoucherHasLinkedItem ||
      (this.isEmailValid && this.isEmailConfirmed);
    return (
      emailValid &&
      this.isNameValid &&
      this.isPostalAddressLineOneValid &&
      this.isPostalAddressTownValid &&
      this.isPostalAddressPostcodeValid &&
      this.isPostalAddressCountryIdValid &&
      this.isEmailConfigurationIdValid
    );
  }

  get isEmpty() {
    return (
      !this.name &&
      !this.email &&
      !this.confirmedEmail &&
      !this.isEmailConfigurationIdValid
    );
  }

  static getValidatorForType(type) {
    switch (type) {
      case 'url':
        return v => !v || isURL(v);
      case 'email':
        return v => !v || isEmail(v);
      case 'number':
        return v => !v || isNumber(v);
      case 'integer':
        return v => !v || isInteger(v);
      default:
        return null;
    }
  }
}

decorate(GiftVoucherRecipient, {
  email: observable,
  confirmedEmail: observable,
  name: observable,
  giftVoucherHasLinkedItem: observable,
  postalAddressLineOne: observable,
  postalAddressLineTwo: observable,
  postalAddressTown: observable,
  postalAddressPostcode: observable,
  postalAddressCountryId: observable,
  postalAddressCountryName: observable,
  message: observable,
  emailConfigurationId: observable,
  isEmailValid: computed,
  isEmailConfirmed: computed,
  isNameValid: computed,
  isEmpty: computed,
  isPostalAddressLineOneValid: computed,
  isPostalAddressTownValid: computed,
  isPostalAddressPostcodeValid: computed,
  isPostalAddressCountryIdValid: computed,
  isEmailConfigurationIdValid: computed,
});

export default GiftVoucherRecipient;
