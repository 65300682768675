import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from '@administrate/piston';
import { isInteger } from '@administrate/piston-ux/lib/utils/validators';
import { Form, Input, DateTimeInput } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { validateCardExpiry } from '../../../utils/pistonUXValidation';
import inject from '../../inject';
import { STORES } from '../../../constants';

const { STORE_CHECKOUT } = STORES;

const useCardValidation = () => {
  const [validCardNumber, setValidCardNumber] = useState(false);
  const [validExpiration, setValidExpiration] = useState(false);
  const [validCvv2, setValidCvv2] = useState(false);

  const setCardNumberIsValid = cardNumber =>
    setValidCardNumber(
      cardNumber && isInteger(cardNumber) && cardNumber.length >= 15,
    );

  const setExpirationIsValid = expiration =>
    setValidExpiration(validateCardExpiry(expiration));

  const setCvv2isValid = cvv2 =>
    setValidCvv2(cvv2 && isInteger(cvv2) && [3, 4].includes(cvv2.length));

  return [
    [validCardNumber, setCardNumberIsValid],
    [validExpiration, setExpirationIsValid],
    [validCvv2, setCvv2isValid],
  ];
};

const CardPaymentForm = ({
  [STORE_CHECKOUT]: { isLoading, changeCanCompleteOrder },
  values,
  t,
}) => {
  const [
    [validCardNumber, setCardNumberIsValid],
    [validExpiration, setExpirationIsValid],
    [validCvv2, setCvv2isValid],
  ] = useCardValidation();

  const [validAddressLine1, setValidAddressLine1] = useState(!!values.address1);
  const [validZip, setValidZip] = useState(!!values.zip);

  useEffect(() => {
    changeCanCompleteOrder(
      validCardNumber &&
        validExpiration &&
        validCvv2 &&
        validAddressLine1 &&
        validZip,
    );
  }, [
    validCardNumber,
    validExpiration,
    validCvv2,
    validAddressLine1,
    validZip,
    changeCanCompleteOrder,
  ]);

  return (
    <Container.Row>
      <Container.Col xs={12}>
        <p>{t('weblink:encryptionNotice')}</p>
      </Container.Col>
      <Container.Col xs={12}>
        <h5 className="mt-4">{t('weblink:creditCard')}</h5>
      </Container.Col>
      <Container.Col xs={12}>
        <Form values={values} disabled={isLoading}>
          <Input
            label="Card Number"
            isRequired
            name="cardNumber"
            valid={() => validCardNumber}
            maxLength={19}
            onChange={value => setCardNumberIsValid(value)}
          />
          <DateTimeInput
            label="Expiration (MM/YY)"
            type="monthYear"
            name="expiration"
            valid={() => validExpiration}
            onChange={value => setExpirationIsValid(value)}
          />
          <Input
            label="Card Security Code"
            isRequired
            name="cvv2"
            valid={() => validCvv2}
            maxLength={4}
            onChange={value => setCvv2isValid(value)}
          />
          <Input
            label="Address Line 1"
            isRequired
            name="address1"
            valid={() => validAddressLine1}
            onChange={value => setValidAddressLine1(!!value)}
          />
          <Input
            label="ZIP or Postal Code"
            isRequired
            name="zip"
            valid={() => validZip}
            onChange={value => setValidZip(!!value)}
          />
        </Form>
      </Container.Col>
    </Container.Row>
  );
};

CardPaymentForm.propTypes = {
  values: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_CHECKOUT)(observer(CardPaymentForm)),
);
