import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import inject from '../../inject';
import { STORES } from '../../../constants';
import { CheckoutPaymentButton } from '../../../components/Checkout/Payment/PaymentButton';

const { STORE_CHECKOUT } = STORES;

const PaymentButton = ({ [STORE_CHECKOUT]: { checkoutWithCheck } }) => (
  <CheckoutPaymentButton onClick={() => checkoutWithCheck()} />
);

export const CheckPaymentButton = withTranslation()(
  inject(STORE_CHECKOUT)(observer(PaymentButton)),
);

const PaymentInfo = ({ checkMailingAddress, t }) => {
  const splitLines = checkMailingAddress.lines.map(line => (
    <span key={line}>
      {line}
      <br />
    </span>
  ));

  return (
    <Container.Row>
      <Container.Col xs={12}>
        <p>{t('weblink:checkMailingInstructions')}</p>
        <p>
          <strong>{t('weblink:mailingAddress')}</strong>
        </p>
        <p>
          <span>
            {splitLines}
            {checkMailingAddress.town}
            <br />
            {checkMailingAddress.region} {checkMailingAddress.postcode}
            <br />
            {checkMailingAddress.countryName}
          </span>
        </p>
      </Container.Col>
    </Container.Row>
  );
};

PaymentInfo.propTypes = {
  t: PropTypes.func.isRequired,
  checkMailingAddress: PropTypes.shape({}).isRequired,
};

export default withTranslation()(PaymentInfo);
