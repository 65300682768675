import gql from 'graphql-tag';
import fragments from './fragments';
import { PAGING } from '../constants';

const getCourseCodeById = gql`
  query getCourseCodeById($courseId: String!) {
    courses(filters: [{ field: id, operation: eq, value: $courseId }]) {
      edges {
        node {
          id
          code
          name
        }
      }
    }
  }
`;

const getCourseById = gql`
  query getCourseById($code: String!) {
    courses(filters: [{ field: code, operation: eq, value: $code }]) {
      edges {
        node {
          ...Course
          ...CourseAnalyticsDetails
          description
          teaserDescription
          prerequisites
          topicsCovered
          faqs
          outcomes
          deliveryMethod
          options {
            edges {
              node {
                ... on Event {
                  ...Event
                }
              }
            }
          }
        }
      }
    }
  }
  ${fragments.courseAnalyticsDetails}
  ${fragments.course}
  ${fragments.event}
`;

const getCourseByFilter = gql`
  query getCourseByFilter(
    $filters: [CourseFieldFilter]!
    $optionsFirst: Int
    $optionsOffset: Int
  ) {
    courses(filters: $filters) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalRecords
      }
      edges {
        node {
          ...Course
          options(first: $optionsFirst, offset: $optionsOffset) {
            pageInfo {
              totalRecords
              hasNextPage
            }
            edges {
              node {
                ... on Event {
                  ...Event
                }
              }
            }
          }
        }
      }
    }
  }
  ${fragments.course}
  ${fragments.event}
`;

const getCourseForSearchBar = gql`
  query getCourseForSearchBar($name: String) {
    courses(
      filters: { field: name, operation: like, value: $name }
      order: { field: name, direction: asc }
      first: 5
    ) {
      edges {
        node {
          ...Course
        }
      }
    }
  }
  ${fragments.course}
`;

const getCourseForListingPage = gql`
  query getCourseById($offset: Int, $filters: [CourseFieldFilter]!) {
    courses(
      first: ${PAGING.COURSE_LISTING}
      offset: $offset
      filters: $filters
    ) {
      pageInfo {
        totalRecords
      }
      edges {
        node {
          ...Course
          description
          teaserDescription
          category
        }
      }
    }
  }
  ${fragments.course}
`;

const getCatalogueForListingPage = gql`
  query getCatalogueForListingPage(
    $offset: Int
    $filters: [CatalogueFieldFilter]!
    $customFieldFilters: [CustomFieldFilterInput!]
    $type: CatalogueType
    $search: String
    $pageSize: Int
  ) {
    catalogue(
      first: $pageSize
      offset: $offset
      filters: $filters
      customFieldFilters: $customFieldFilters
      type: $type
      search: $search
    ) {
      pageInfo {
        totalRecords
      }
      edges {
        node {
          __typename
          ... on Course {
            ...Course
            description
            teaserDescription
            category
            isFeatured
            customFieldValues {
              definitionKey
              value
            }
          }
          ... on LearningPath {
            id
            name
            description
            category
            price {
              amount
            }
            imageUrl
            imageUrls
            isFeatured
            customFieldValues {
              definitionKey
              value
            }
          }
        }
      }
    }
  }
  ${fragments.course}
`;

const getCourseBasicDetailsByCode = gql`
  query getCourseBasicDetailsByCode($code: String!) {
    courses(filters: [{ field: code, operation: eq, value: $code }]) {
      edges {
        node {
          id
          code
          name
        }
      }
    }
  }
`;

const getAvailableCourses = gql`
  query getAvailableCourses {
    courses {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default {
  getCourseById,
  getCourseCodeById,
  getCourseByFilter,
  getCourseForSearchBar,
  getCourseForListingPage,
  getCatalogueForListingPage,
  getCourseBasicDetailsByCode,
  getAvailableCourses,
};
