function extractErrors(obj) {
  if (!obj) {
    return [];
  }
  const nestedErrors = Object.values(obj)
    .filter(value => value && value.errors)
    .flatMap(value => value.errors);
  return nestedErrors.concat(
    Object.values(obj)
      .filter(value => typeof value === 'object')
      .flatMap(value => extractErrors(value)),
  );
}
export function getAllMutationErrors(mutationResponse) {
  const { data, errors } = mutationResponse;
  if (!data) {
    return [];
  }
  return (errors || []).concat(extractErrors(data));
}

export function checkForMutationError(mutationResponse, errorMessage) {
  const errors = getAllMutationErrors(mutationResponse);
  return errors.some(
    error => error.message && error.message.includes(errorMessage),
  );
}
