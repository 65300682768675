import React from 'react';
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types';

const addStyleToDefaultAttrs = () => {
  const defaults = sanitizeHtml.defaults.allowedAttributes;
  Object.keys(defaults).forEach(key => {
    if (!defaults[key].includes('style')) {
      defaults[key].push('style');
    }
  });
  return defaults;
};

const getAttributes = tags => {
  const attrList = addStyleToDefaultAttrs();
  tags.forEach(tag => {
    const existing = attrList[tag];
    if (!existing) {
      attrList[tag] = ['style'];
    }
  });

  return attrList;
};

const HTMLContainer = ({ content, className, clearTags }) => {
  const allowedTags = clearTags
    ? []
    : [
        'a',
        'b',
        'br',
        'del',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hr',
        'i',
        'li',
        'ol',
        'p',
        'span',
        'strong',
        'u',
        'ul',
      ];

  const sanitizedContent = sanitizeHtml(content, {
    allowedTags,
    allowedAttributes: getAttributes(allowedTags),
  });

  return content ? (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  ) : null;
};

HTMLContainer.defaultProps = {
  className: '',
  content: '',
  clearTags: false,
};

HTMLContainer.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  clearTags: PropTypes.bool,
};

export default HTMLContainer;
