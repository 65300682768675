import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import portalStore from '../../stores/portalStore';

const BrandLogoRedirect = ({ brand, redirectSite, disabled }) => {
  if (disabled) {
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a style={{ pointerEvents: 'none' }}>{brand}</a>;
  }

  return redirectSite ? (
    <a href={redirectSite}>{brand}</a>
  ) : (
    <Link to="/">{brand}</Link>
  );
};

const Navigation = ({
  className,
  brand,
  left,
  right,
  search,
  t,
  back,
  disabled,
}) => {
  const redirectSite = portalStore.embedSite;
  return (
    <Navbar inverse collapseOnSelect className={`Navigation ${className}`}>
      <Navbar.Header>
        {back && <Back back={back} />}
        <Navbar.Brand>
          <BrandLogoRedirect
            brand={brand}
            redirectSite={redirectSite}
            disabled={disabled}
          />
          <a href="#skip-main-navigation" className="sr-only">
            {t('weblink:skipMainNavigation')}
          </a>
        </Navbar.Brand>
        {(left || right || search) && <Navbar.Toggle />}
      </Navbar.Header>
      {(left || right || search) && (
        <Navbar.Collapse>
          <div className="bar">
            <div className="actions">
              <div className="actions-body">
                {left && <Nav className="categories">{left}</Nav>}
                {search && (
                  <div className="searchbar-wrapper">
                    <Navbar.Form className="actions-search-on-desktop">
                      {search}
                    </Navbar.Form>
                  </div>
                )}
                {right && <Nav pullRight>{right}</Nav>}
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      )}
      <span className="d-none" id="skip-main-navigation" />
    </Navbar>
  );
};

const Back = ({ back }) => (
  <button
    className="navbar-back"
    onClick={back.onClick}
    disabled={back.disabled}
    type="button"
  >
    <span className="icon glyphicon glyphicon-chevron-left" />
    <span className="navbar-back--label">{back.label}</span>
  </button>
);

Back.defaultProps = {
  back: null,
};

Back.propTypes = {
  back: PropTypes.shape({
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
};

BrandLogoRedirect.defaultProps = {
  brand: null,
  redirectSite: null,
  disabled: false,
};

BrandLogoRedirect.propTypes = {
  brand: PropTypes.element,
  redirectSite: PropTypes.string,
  disabled: PropTypes.bool,
};

Navigation.defaultProps = {
  className: '',
  brand: null,
  left: null,
  right: null,
  search: null,
  back: null,
  disabled: false,
};

Navigation.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  brand: PropTypes.element,
  left: PropTypes.element,
  right: PropTypes.element,
  search: PropTypes.element,
  back: PropTypes.shape({
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default withTranslation()(Navigation);
