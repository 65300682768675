import gql from 'graphql-tag';

const getLocations = gql`
  query getLocations {
    locations(order: { field: name, direction: asc }) {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`;

export default { getLocations };
