import gql from 'graphql-tag';

const learningTags = gql`
  fragment LearningTagDetails on LearningTagConnection {
    edges {
      node {
        id
        name
      }
    }
  }
`;

const accountAssociations = gql`
  fragment AccountAssociationDetails on AccountAssociationConnection {
    edges {
      node {
        id
        account {
          id
          name
        }
        associationType {
          id
          name
        }
      }
    }
  }
`;

const productOptionAnalyticsDetails = gql`
  fragment ProductOptionAnalyticsDetails on ProductOption {
    ... on Event {
      course {
        learningTags {
          ...LearningTagDetails
        }
        accountAssociations {
          ...AccountAssociationDetails
        }
      }
    }
    ... on LearningPath {
      learningTags {
        ...LearningTagDetails
      }
      accountAssociations {
        ...AccountAssociationDetails
      }
    }
  }

  ${learningTags}
  ${accountAssociations}
`;

const courseAnalyticsDetails = gql`
  fragment CourseAnalyticsDetails on Course {
    isFeatured
    categories {
      id
      name
    }
    learningTags {
      ...LearningTagDetails
    }
    accountAssociations {
      ...AccountAssociationDetails
    }
  }

  ${learningTags}
  ${accountAssociations}
`;

const learningPathAnalyticsDetails = gql`
  fragment LearningPathAnalyticsDetails on LearningPath {
    isFeatured
    categories {
      id
      name
    }
    learningTags {
      ...LearningTagDetails
    }
    accountAssociations {
      ...AccountAssociationDetails
    }
  }

  ${learningTags}
  ${accountAssociations}
`;

const event = gql`
  fragment Event on Event {
    id
    name
    end
    start
    remainingPlaces
    location {
      id
      name
    }
    venue {
      name
    }
    price {
      amount
    }
    priceLevels {
      id
      name
      amount
      symbol
    }
    tax {
      id
      effectiveRate
    }
    deliveryMethod
    accessDuration
    course {
      code
      imageUrl
      id
      isFeatured
    }
    timeZoneName
    locale {
      id
      name
    }
    classroomStart
    classroomEnd
    lmsStart
    lmsEnd
    maxPlaces
    registrationOpen
    registrationOpensAt
    sessionCount
  }
`;

const course = gql`
  fragment Course on Course {
    id
    name
    code
    imageUrl
    imageUrls
    priceRange {
      normalPrice {
        amount
      }
      minimumPrice {
        amount
      }
    }
  }
`;

const learningPath = gql`
  fragment LearningPath on LearningPath {
    id
    code
    name
    description
    requireObjectiveFulfillment
    isFeatured
    category: name
    price {
      amount
    }
    priceLevels {
      id
      name
      amount
      symbol
    }
    imageUrl
    imageUrls
    remainingPlaces
    learningObjectives {
      edges {
        node {
          __typename
          id
          ... on EventObjective {
            event {
              id
              name
              start
              end
              remainingPlaces
              location {
                id
                name
                region {
                  id
                  name
                  code
                }
              }
            }
          }
          ... on CourseObjective {
            course {
              id
              code
              name
            }
          }
          ... on ExternalObjective {
            id
            name
          }
          ... on LearningPathObjective {
            id
            learningPath {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const learnerDetails = gql`
  fragment LearnerDetails on LearnerDetails {
    firstName
    lastName
    email
    attributes {
      definitionKey
      value
    }
  }
`;

const giftVoucherConfiguration = gql`
  fragment GiftVoucherConfiguration on GiftVoucherConfiguration {
    currencyCode
    linkedItem {
      ... on StockItem {
        id
        name
        description
        price {
          amount
          financialUnit {
            code
            symbol
          }
        }
      }
    }
  }
`;

const error = gql`
  fragment Error on FieldError {
    label
    value
    message
  }
`;

const productOption = gql`
  fragment ProductOption on ProductOption {
    id
    code
    name
    price {
      amount
    }
    categories {
      id
      name
    }
  }
  ${learnerDetails}
`;

const pointOfSaleDefinition = gql`
  fragment PointOfSaleDefinition on PointOfSaleLearnerFieldDefinition {
    label
    key
    type
    isRequired
    section
    options {
      label
      value
    }
  }
`;

const cart = gql`
  fragment Cart on Cart {
    id
    registration {
      registerables {
        edges {
          node {
            type
            id
          }
        }
      }
    }
    buyerDetails {
      firstName
      lastName
      email
      companyName
      customFieldValues {
        definitionKey
        value
      }
      billingAddress {
        streetAddress1
        streetAddress2
        streetAddress3
        town
        province {
          name
          code
        }
        postcode
        country {
          id
          name
          code
        }
      }
    }
    reservationsValidUntil
    promotionalCode
    giftVoucherApplications {
      amount
      giftVoucher {
        code
        currencyCode
      }
    }
    state
    requiresReviewBeforePurchase
    price {
      subTotal
      grandTotal
      discountTotal
      payableTotal
      taxes {
        totalAmount
        type {
          id
          effectiveRate
          name
        }
      }
    }
    items {
      id
      quantity
      unitAmount
      subTotalAmount
      totalTaxAmount
      totalAmount
      isReservable
      isExpired
      reserved
      tax {
        id
        effectiveRate
      }
      pointOfSaleDefinitions {
        ...PointOfSaleDefinition
      }
      ... on PathLineItem {
        productOption {
          ...ProductOption
          ...LearningPath
          ...ProductOptionAnalyticsDetails
        }
        learnerDetails {
          ...LearnerDetails
        }
        objectives {
          event {
            id
            start
            location {
              name
            }
            course {
              name
            }
            remainingPlaces
          }
          learningPathObjective {
            id
          }
        }
      }
      ... on EventLineItem {
        productOption {
          ...ProductOption
          ...Event
          ...ProductOptionAnalyticsDetails
        }
        learnerDetails {
          ...LearnerDetails
        }
      }
      ... on GiftVoucherLineItem {
        productOption {
          ...ProductOption
          ...GiftVoucherConfiguration
        }
        giftVoucherRecipientDetails {
          name
          email
          postalAddressLineOne
          postalAddressLineTwo
          postalAddressTown
          postalAddressPostcode
          postalAddressCountry
        }
      }
      childItems {
        id
        quantity
        unitAmount
        subTotalAmount
        totalAmount
        tax {
          id
          effectiveRate
        }
        ... on StockItemLineItem {
          productOption {
            ... on StockItem {
              id
              name
              description
            }
          }
        }
      }
      priceLevel {
        id
        name
      }
    }
    region {
      id
      code
      name
    }
    currency {
      code
      name
      symbol
    }
  }
  ${event}
  ${productOption}
  ${learningPath}
  ${giftVoucherConfiguration}
  ${pointOfSaleDefinition}
  ${productOptionAnalyticsDetails}
`;

const fragments = {
  learnerDetails,
  learningPath,
  event,
  course,
  cart,
  error,
  pointOfSaleDefinition,
  courseAnalyticsDetails,
  learningPathAnalyticsDetails,
};

export default fragments;
