import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { SecondaryAction, Container } from '@administrate/piston';
import { CardElement, useStripe } from '@stripe/react-stripe-js';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import inject from '../../inject';
import { STORES } from '../../../constants';

const { STORE_CHECKOUT, STORE_CART } = STORES;

const PaymentButton = ({
  [STORE_CHECKOUT]: {
    checkoutWithStripe,
    isLoading,
    isCompletePaymentLoading,
    setIsCompletePaymentLoading,
    canCompleteOrder,
  },
  [STORE_CART]: { isApplyPromotionCodeLoading, containsValidItems },
  cardElement,
  t,
}) => {
  const stripe = useStripe();
  return (
    <SecondaryAction
      label={
        <span>
          {t('weblink:completePayment')}
          {isLoading && (
            <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
          )}
        </span>
      }
      disabled={
        isApplyPromotionCodeLoading ||
        isLoading ||
        isCompletePaymentLoading ||
        !canCompleteOrder ||
        !containsValidItems
      }
      onClick={() => {
        setIsCompletePaymentLoading(true);
        checkoutWithStripe(stripe, cardElement);
        setIsCompletePaymentLoading(false);
      }}
    />
  );
};

PaymentButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export const StripePaymentButton = withTranslation()(
  inject(STORE_CHECKOUT, STORE_CART)(observer(PaymentButton)),
);

const PaymentInfo = ({
  [STORE_CHECKOUT]: {
    isLoading,
    changeCanCompleteOrder,
    customerBillingAddress,
  },
  setCardElement,
  t,
}) => {
  useEffect(() => {
    changeCanCompleteOrder(false);
  }, []);

  return (
    <Container.Row>
      <Container.Col xs={12}>
        <p>{t('weblink:encryptionNotice')}</p>
      </Container.Col>
      <Container.Col xs={12}>
        <p className="mt-4">
          <strong>{t('weblink:creditCard')}</strong>
        </p>
      </Container.Col>
      <Container.Col xs={12}>
        <CardElement
          onReady={el => setCardElement(el)}
          onChange={({ complete }) => changeCanCompleteOrder(complete)}
          options={{
            disabled: isLoading,
            hidePostalCode: !!(
              customerBillingAddress && customerBillingAddress.postcode
            ),
            style: {
              base: {
                fontSize: '18px',
              },
            },
          }}
        />
      </Container.Col>
    </Container.Row>
  );
};

PaymentInfo.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(inject(STORE_CHECKOUT)(observer(PaymentInfo)));
