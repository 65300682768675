import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import {
  Container,
  StatusLabel,
  LabelledInput,
  FieldLabel,
  TextInput,
  SelectListInput,
  CheckboxInput,
} from '@administrate/piston';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'react-bootstrap';

import ValidatedLabelledInput from '../ValidatedLabelledInput';
import formatSelectList from '../../utils/formatSelectList';

import inject from '../../containers/inject';
import { STORES } from '../../constants';
import { useWebLinkQuery } from '../../weblink-hooks';
import { getCountries } from '../../queries/country';

const { STORE_STORE } = STORES;

const style = {
  flexContainer: {
    alignItems: 'center',
    height: '30px',
  },
  horizontalRule: {
    top: '50%',
  },
  container: {
    height: '30px',
  },
  label: {
    marginRight: '10px',
  },
};

const GiftVoucherRecipientsForm = ({
  item,
  recipient,
  updateItemRecipientProperty,
  updateItemRecipientEmail,
  t,
  isLoading,
  confirmEmails,
  setBookerAsRecipient,
  clearRecipientDetails,
  buyerIsRecipient,
  [STORE_STORE]: { giftVoucherEmailTemplates, postalCountries },
}) => {
  if (giftVoucherEmailTemplates.length === 1) {
    updateItemRecipientProperty({
      id: item.id,
      property: 'emailConfigurationId',
      value: giftVoucherEmailTemplates[0].id,
    });
  } else if (giftVoucherEmailTemplates.length < 1) {
    updateItemRecipientProperty({
      id: item.id,
      property: 'emailConfigurationId',
      value: null,
    });
  }

  const postalCountryOption = data => {
    updateItemRecipientProperty({
      id: item.id,
      property: 'postalAddressCountryId',
      value: data ? data.value : null,
    });
  };

  const [countries, setCountries] = useState([]);

  useWebLinkQuery(
    getCountries,
    {},
    {
      fetchPolicy: 'cache-first',
      onCompleted: ({ countries: fetchedCountries }) => {
        setCountries(fetchedCountries);
      },
    },
  );

  const countryOptions =
    postalCountries && postalCountries.length > 0 ? postalCountries : countries;

  const formattedCountryOptions = formatSelectList({
    list: countryOptions,
    label: 'name',
    value: 'id',
  });

  if (formattedCountryOptions.length === 1) {
    postalCountryOption(formattedCountryOptions[0]);
  }

  const [useBuyerInfoChecked, setUseBuyerInfoChecked] = useState(
    buyerIsRecipient(item),
  );

  useEffect(() => {
    setUseBuyerInfoChecked(buyerIsRecipient(item));
  }, [item, setUseBuyerInfoChecked, buyerIsRecipient]);

  return (
    <Container.Row className="row learner-details" key={`${item.id}_recipient`}>
      <Container.Col sm={12}>
        <Container.Row>
          <Container.Col sm={12}>
            <div
              className="checkout_setlearners_courses_learner-title learner-title"
              style={style.container}
            >
              <div
                className="checkout_setlearners_courses_learner-title_hr"
                style={style.horizontalRule}
              />
              <div
                className="checkout_setlearners_courses_learner-title_group"
                style={style.flexContainer}
              >
                <div
                  className="checkout_setlearners_courses_learner-title_label"
                  style={style.label}
                >
                  <h3 className="sr-only">{`${t('weblink:recipient')}`}</h3>
                  <StatusLabel
                    color={recipient.isValid() ? 'green' : 'lightgrey'}
                  >
                    {`${t('weblink:recipient')}`}
                  </StatusLabel>
                </div>
                <div className="checkout_setlearners_courses_learner-title_apply-user">
                  <CheckboxInput
                    className="d-none d-sm-inline-block"
                    title={t('weblink:useBookerInfo')}
                    ariaLabel={t('weblink:useBookerInfo')}
                    onChange={checked => {
                      setUseBuyerInfoChecked(checked);
                      if (checked) {
                        setBookerAsRecipient(item.id);
                      } else {
                        clearRecipientDetails(item);
                      }
                    }}
                    disabled={isLoading}
                    value={useBuyerInfoChecked}
                  />
                </div>
              </div>
            </div>
          </Container.Col>
        </Container.Row>
        {!recipient.giftVoucherHasLinkedItem && (
          <Container.Row key={`${item.id}_emailAddress`}>
            <Container.Col sm={12}>
              <ValidatedLabelledInput
                id={`${item.id}_emailAddress`}
                label={t('weblink:emailAddress')}
                onChange={email => {
                  updateItemRecipientEmail({
                    id: item.id,
                    email,
                    confirmEmails,
                  });
                }}
                value={recipient.email}
                disabled={isLoading || useBuyerInfoChecked}
                isValid={recipient.isEmailValid}
                required={!useBuyerInfoChecked}
              />
            </Container.Col>
          </Container.Row>
        )}
        {!recipient.giftVoucherHasLinkedItem && confirmEmails && (
          <Container.Row key={`${item.id}_confirmedEmail`}>
            <Container.Col sm={12}>
              <ValidatedLabelledInput
                id={`${item.id}_confirmedEmail`}
                label={t('weblink:confirmEmail')}
                onChange={value =>
                  updateItemRecipientProperty({
                    id: item.id,
                    property: 'confirmedEmail',
                    value,
                  })
                }
                value={recipient.confirmedEmail}
                disabled={isLoading || useBuyerInfoChecked}
                isValid={recipient.isEmailConfirmed}
                errorMessage={t('weblink:emailConfirmationIsRequired')}
                required={!useBuyerInfoChecked}
              />
            </Container.Col>
          </Container.Row>
        )}
        <Container.Row key={`${item.id}_name`}>
          <Container.Col sm={6}>
            <ValidatedLabelledInput
              id={`${item.id}_name`}
              label={t('weblink:name')}
              onChange={value =>
                updateItemRecipientProperty({
                  id: item.id,
                  property: 'name',
                  value,
                })
              }
              value={recipient.name}
              disabled={isLoading || useBuyerInfoChecked}
              isValid={recipient.isNameValid}
              required={!useBuyerInfoChecked}
            />
          </Container.Col>
        </Container.Row>
        {giftVoucherEmailTemplates.length > 1 && (
          <Container.Row key={`${item.id}_template`}>
            <Container.Col sm={12}>
              <ValidatedLabelledInput
                id={`${item.id}_template`}
                label={t('weblink:templateLanguage')}
                isValid={recipient.isEmailConfigurationIdValid}
                onChange={() => {}}
                disabled={isLoading}
              >
                <SelectListInput
                  id={`${item.id}_templateLanguageList`}
                  ariaLabel={t('weblink:templateLanguage')}
                  options={formatSelectList({
                    list: giftVoucherEmailTemplates,
                    label: 'label',
                    value: 'id',
                  })}
                  value={
                    recipient.emailConfigurationId
                      ? {
                          value: recipient.emailConfigurationId,
                          label: giftVoucherEmailTemplates.filter(
                            template =>
                              template.id === recipient.emailConfigurationId,
                          )[0].label,
                        }
                      : null
                  }
                  onChange={data =>
                    updateItemRecipientProperty({
                      id: item.id,
                      property: 'emailConfigurationId',
                      value: data ? data.value : null,
                    })
                  }
                />
              </ValidatedLabelledInput>
            </Container.Col>
          </Container.Row>
        )}
        <Container.Row key={`${item.id}_giftVoucherMessage`}>
          <Container.Col sm={12}>
            <LabelledInput label={t('weblink:giftVoucherMessage')}>
              <TextInput
                multiline
                ariaLabel={t('weblink:giftVoucherMessage')}
                id={`${item.id}_giftVoucherMessage`}
                value={recipient.message}
                onChange={value =>
                  updateItemRecipientProperty({
                    id: item.id,
                    property: 'message',
                    value,
                  })
                }
              />
            </LabelledInput>
          </Container.Col>
        </Container.Row>
        {item.giftVoucherConfigLinkedItem && (
          <Container.Row key={`${item.id}_giftVoucherHasLinkedItem`}>
            <Container.Col sm={12}>
              <Checkbox
                id={`${item.id}_giftVoucherHasLinkedItem`}
                checked={recipient.giftVoucherHasLinkedItem}
                onChange={() => {
                  updateItemRecipientProperty({
                    id: item.id,
                    property: 'giftVoucherHasLinkedItem',
                    value: !recipient.giftVoucherHasLinkedItem,
                  });
                }}
              >
                {t('weblink:postageFeeMessage', {
                  cost: item.giftVoucherConfigLinkedItem.cost,
                })}
              </Checkbox>
            </Container.Col>
          </Container.Row>
        )}
        {recipient.giftVoucherHasLinkedItem && [
          <Container.Row key={`${item.id}_addressLine1`}>
            <Container.Col sm={12}>
              <ValidatedLabelledInput
                id={`${item.id}_addressLine1`}
                label={t('weblink:addressLine1')}
                value={recipient.postalAddressLineOne}
                onChange={value =>
                  updateItemRecipientProperty({
                    id: item.id,
                    property: 'postalAddressLineOne',
                    value,
                  })
                }
                required
                isValid={recipient.isPostalAddressLineOneValid}
              />
            </Container.Col>
          </Container.Row>,
          <Container.Row key={`${item.id}_addressLine2`}>
            <Container.Col sm={12}>
              <LabelledInput label={t('weblink:addressLine2')}>
                <TextInput
                  ariaLabel={t('weblink:addressLine2')}
                  id={`${item.id}_addressLine2`}
                  value={recipient.postalAddressLineTwo}
                  onChange={value =>
                    updateItemRecipientProperty({
                      id: item.id,
                      property: 'postalAddressLineTwo',
                      value,
                    })
                  }
                />
              </LabelledInput>
            </Container.Col>
          </Container.Row>,
          <Container.Row key={`${item.id}_city_postcode}`}>
            <Container.Col sm={6}>
              <ValidatedLabelledInput
                id={`${item.id}_city}`}
                label={t('weblink:city')}
                value={recipient.postalAddressTown}
                onChange={value =>
                  updateItemRecipientProperty({
                    id: item.id,
                    property: 'postalAddressTown',
                    value,
                  })
                }
                required
                isValid={recipient.isPostalAddressTownValid}
              />
            </Container.Col>
            <Container.Col sm={6}>
              <ValidatedLabelledInput
                id={`${item.id}_postcode`}
                label={t('weblink:postcode')}
                value={recipient.postalAddressPostcode}
                onChange={value =>
                  updateItemRecipientProperty({
                    id: item.id,
                    property: 'postalAddressPostcode',
                    value,
                  })
                }
                required
                isValid={recipient.isPostalAddressPostcodeValid}
              />
            </Container.Col>
          </Container.Row>,
          <Container.Row key={`${item.id}_country`}>
            <Container.Col sm={12}>
              {formattedCountryOptions.length === 1 ? (
                <div>
                  <FieldLabel label={t('weblink:country')} />
                  <p>{formattedCountryOptions[0].label}</p>
                </div>
              ) : (
                <ValidatedLabelledInput
                  id={`${item.id}_country`}
                  label={t('weblink:country')}
                  isValid={recipient.isPostalAddressCountryIdValid}
                  onChange={() => {}}
                >
                  <SelectListInput
                    ariaLabel={t('weblink:country')}
                    id={`${item.id}_countrySelectList`}
                    options={formattedCountryOptions}
                    onChange={data => postalCountryOption(data)}
                  />
                </ValidatedLabelledInput>
              )}
            </Container.Col>
          </Container.Row>,
        ]}
      </Container.Col>
    </Container.Row>
  );
};

GiftVoucherRecipientsForm.propTypes = {
  item: MobxPropTypes.objectOrObservableObject.isRequired,
  recipient: MobxPropTypes.objectOrObservableObject.isRequired,
  updateItemRecipientProperty: PropTypes.func.isRequired,
  updateItemRecipientEmail: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmEmails: PropTypes.bool.isRequired,
  buyerIsRecipient: PropTypes.func.isRequired,
  setBookerAsRecipient: PropTypes.func.isRequired,
  clearRecipientDetails: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_STORE)(observer(GiftVoucherRecipientsForm)),
);
