import { Card, List, ListHeader } from '@administrate/piston-ux';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import inject from '../../../containers/inject';
import { STORES } from '../../../constants';
import CartRow, { CART_ROW_GRID_COLUMNS } from './CartRow';

const { STORE_CART } = STORES;

const CartItemsList = ({
  items,
  hideEditItemButton,
  showPlacesRemaining,
  [STORE_CART]: { isLoading },
  t,
}) => {
  return (
    <div className="mt-4">
      <Card>
        <h2 className="sr-only">Cart Contents</h2>
        <List extraClass="cart-table" loading={isLoading}>
          <ListHeader
            headings={[
              {
                title: t('weblink:item'),
              },
              {
                title: t('weblink:price'),
              },
              {
                title: t('weblink:quantity'),
              },
              {
                title: t('weblink:total'),
              },
              {
                title: '',
              },
              {
                title: '',
              },
            ]}
            gridColumns={CART_ROW_GRID_COLUMNS}
          />
          {items.map(item => (
            <CartRow
              key={item.id}
              row={item}
              hideEditButton={hideEditItemButton}
              showPlacesRemaining={showPlacesRemaining}
            />
          ))}
        </List>
      </Card>
    </div>
  );
};

CartItemsList.propTypes = {
  items: MobxPropTypes.arrayOrObservableArray.isRequired,
  hideEditItemButton: PropTypes.bool,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CartItemsList.defaultProps = {
  hideEditItemButton: false,
  showPlacesRemaining: true,
};

export default withTranslation()(inject(STORE_CART)(observer(CartItemsList)));
