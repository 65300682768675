import i18next from 'i18next';
import moment from 'moment';

import isEmail from './isEmail';

export const validateRequiredField = (value, label) => {
  if (!value) {
    return i18next.t('weblink:requiredValidationMessage', { label });
  }
  return true;
};

export const validateMaximumLength = (value, length, label) => {
  if (value.length > length) {
    return i18next.t('weblink:maximumLengthValidationMessage', {
      label,
      length,
    });
  }
  return true;
};

export const validateRequiredFieldWithMaximumLength = (
  value,
  length,
  label,
) => {
  if (!value) {
    return i18next.t('weblink:requiredValidationMessage', { label });
  }
  if (value.length > length) {
    return i18next.t('weblink:maximumLengthValidationMessage', {
      label,
      length,
    });
  }
  return true;
};

export const validateRequiredEmailWithMaximumLength = (
  value,
  length,
  label,
) => {
  if (!value) {
    return i18next.t('weblink:requiredValidationMessage', { label });
  }
  if (!isEmail(value)) {
    return i18next.t('weblink:invalidEmail');
  }
  if (value.length > length) {
    return i18next.t('weblink:maximumLengthValidationMessage', {
      label,
      length,
    });
  }
  return true;
};

export const validateCardExpiry = expiry => {
  if (!expiry || !expiry.includes('/')) {
    return false;
  }
  const [month, year] = expiry.split('/');
  if (!month || !year) {
    return false;
  }

  if (month < 0 || month > 12) {
    return false;
  }

  const now = moment();
  const expiryAsMoment = moment(`${year}${month}`, 'YYMM').endOf('month');

  return expiryAsMoment.isValid() && expiryAsMoment.isAfter(now);
};
