import React, { createContext, Suspense } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { Settings } from 'luxon';
import moment from 'moment';
import { ApolloClient } from 'apollo-client';

import { TranslationProvider as PistonUxTranslationProvider } from '@administrate/piston-ux';
import { configure as configureI18n, PISTONUX_NAMESPACE } from './i18n';
import { parseTimezone } from './utils/timezones';
import configStore from './stores/configStore';
import AuthProvider from './auth/AuthProvider';
import { StoreProvider } from './stores/contexts/store';
import { PortalConfigurationProvider } from './hooks/portalConfiguration';
import { WidgetHooksProvider } from './hooks/WidgetHooksProvider';
import {
  WeblinkApiConfigurationPropType,
  WeblinkApiProvider,
} from './graphql/providers/WeblinkApiProvider';
import { IntegrationProvider } from './hooks/integration';
import createQueryResource from './utils/createQueryResource';
import queries from './queries';
import { PageLoadingSkeleton } from './components/PageLoadingSkeleton';

export const TIMEZONE = createContext();

export const Provider = ({
  stores,
  children,
  i18n,
  timezone,
  apolloClient,
  weblinkApiConfiguration,
  integration,
  hooks,
}) => {
  const storeDetailsResource = createQueryResource(
    apolloClient,
    queries.store.getStoreDetails,
  );
  const TimeZoneProvider = TIMEZONE.Provider;
  const currentTimeZone = parseTimezone(timezone);
  const i18nInstance = configureI18n(i18n);

  const { locale } = configStore;

  Settings.defaultZoneName = currentTimeZone;
  Settings.defaultLocale = locale;

  const userLocale =
    locale ||
    (navigator.languages ? navigator.languages[0] : navigator.language);
  const hasUserLocale = moment
    .locales()
    .includes(userLocale.toLocaleLowerCase());
  moment.locale(hasUserLocale ? userLocale : 'en');

  return (
    <IntegrationProvider active={integration}>
      <Suspense fallback={<PageLoadingSkeleton />}>
        <I18nextProvider i18n={i18nInstance}>
          <PistonUxTranslationProvider
            t={i18nInstance.getFixedT(null, PISTONUX_NAMESPACE)}
          >
            <PortalConfigurationProvider
              storeDetailsResource={storeDetailsResource}
            >
              <AuthProvider weblinkApiConfiguration={weblinkApiConfiguration}>
                <WeblinkApiProvider
                  guestApolloClient={apolloClient}
                  weblinkApiConfiguration={weblinkApiConfiguration}
                >
                  <TimeZoneProvider value={currentTimeZone}>
                    <StoreProvider stores={stores}>
                      <WidgetHooksProvider hooks={hooks}>
                        {children}
                      </WidgetHooksProvider>
                    </StoreProvider>
                  </TimeZoneProvider>
                </WeblinkApiProvider>
              </AuthProvider>
            </PortalConfigurationProvider>
          </PistonUxTranslationProvider>
        </I18nextProvider>
      </Suspense>
    </IntegrationProvider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  integration: PropTypes.bool,
  stores: PropTypes.shape({}).isRequired,
  i18n: PropTypes.shape({}),
  timezone: PropTypes.string,
  hooks: PropTypes.shape({}),
  apolloClient: PropTypes.instanceOf(ApolloClient).isRequired,
  weblinkApiConfiguration: WeblinkApiConfigurationPropType.isRequired,
};

Provider.defaultProps = {
  i18n: null,
  integration: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  hooks: {},
};
