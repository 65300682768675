import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { populateSentryContext } from '../utils/sentry';

const PortalConfigurationContext = React.createContext();
export const usePortalConfiguration = () =>
  useContext(PortalConfigurationContext);

export const PortalConfigurationProvider = ({
  storeDetailsResource,
  children,
}) => {
  const storeDetails = storeDetailsResource.read();
  const { store } = storeDetails;
  populateSentryContext(store);

  return (
    <PortalConfigurationContext.Provider value={store}>
      {children}
    </PortalConfigurationContext.Provider>
  );
};

PortalConfigurationProvider.propTypes = {
  storeDetailsResource: PropTypes.shape({ read: PropTypes.func }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
