import portalStore from './portalStore';

class PortalTokenStore {
  getPortalToken(domain) {
    return window.localStorage.getItem(this._getPortalTokenName(domain));
  }

  setPortalToken(domain, value) {
    return window.localStorage.setItem(this._getPortalTokenName(domain), value);
  }

  deletePortalToken(domain) {
    return window.localStorage.removeItem(this._getPortalTokenName(domain));
  }

  // eslint-disable-next-line class-methods-use-this
  _getPortalTokenName(domain) {
    return `weblink:${domain}:portalToken`;
  }
}

class CartRetrievalStore {
  getRetrievedCartId() {
    return window.localStorage.getItem(this._getRetrievedCartIdKey());
  }

  setRetrievedCartId(value) {
    return window.localStorage.setItem(this._getRetrievedCartIdKey(), value);
  }

  removeRetrievedCartId() {
    return window.localStorage.removeItem(this._getRetrievedCartIdKey());
  }

  // eslint-disable-next-line class-methods-use-this
  _getRetrievedCartIdKey() {
    return `weblink:${portalStore.portalAuthDomain}:retrievedCartId`;
  }
}

export const portalTokenStore = new PortalTokenStore();
export const cartRetrievalStore = new CartRetrievalStore();
