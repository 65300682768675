import React from 'react';
import PropTypes from 'prop-types';

const BillingAddressItem = ({ label, billingAddress }) => (
  <p className="col-sm-6">
    <span className="buyer-detail-label">{label}</span>
    <span className="buyer-detail-value">{billingAddress.streetAddress1}</span>
    <span className="buyer-detail-value">{billingAddress.streetAddress2}</span>
    <span className="buyer-detail-value">{billingAddress.streetAddress3}</span>
    <span className="buyer-detail-value">{billingAddress.town}</span>
    <span className="buyer-detail-value">{billingAddress.postcode}</span>
    <span className="buyer-detail-value">
      {billingAddress.province ? billingAddress.province.name : null}
    </span>
    <span className="buyer-detail-value">
      {billingAddress.country ? billingAddress.country.name : null}
    </span>
  </p>
);

BillingAddressItem.propTypes = {
  label: PropTypes.string,
  billingAddress: PropTypes.shape({
    streetAddress1: PropTypes.string,
    streetAddress2: PropTypes.string,
    streetAddress3: PropTypes.string,
    town: PropTypes.string,
    postcode: PropTypes.string,
    province: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  }),
};

BillingAddressItem.defaultProps = {
  label: '',
  billingAddress: {},
};

export default BillingAddressItem;
