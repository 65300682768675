import gql from 'graphql-tag';
import fragments from './fragments';

export const createStripePaymentIntent = gql`
  mutation createPaymentIntent($input: CreateStripePaymentIntentInput!) {
    stripe {
      createPaymentIntent(input: $input) {
        paymentIntent {
          id
          clientSecret
          requiresAction
        }
        cart {
          ...Cart
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

export const placeStripeOrder = gql`
  mutation placeStripeOrder($input: PlaceStripeOrderInput!) {
    stripe {
      placeOrder(input: $input) {
        cart {
          ...Cart
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;
