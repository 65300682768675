export default {
  STORE_ROOT: 'STORE_ROOT',
  STORE_ROUTER: 'STORE_ROUTER',
  STORE_CATALOGUE: 'STORE_CATALOGUE',
  STORE_DETAILS: 'STORE_DETAILS',
  STORE_COURSE: 'STORE_COURSE',
  STORE_CART: 'STORE_CART',
  STORE_EVENT_PICKER: 'STORE_EVENT_PICKER',
  STORE_REVIEW_ORDER: 'STORE_REVIEW_ORDER',
  STORE_CHECKOUT: 'STORE_CHECKOUT',
  STORE_STORE: 'STORE_STORE',
  STORE_NAV: 'STORE_NAV',
  STORE_PATH_PICKER: 'STORE_PATH_PICKER',
  STORE_CALLBACK: 'STORE_CALLBACK',
  STORE_NAVIGATION: 'STORE_NAVIGATION',
  STORE_INTEGRATION_NAVIGATION: 'STORE_INTEGRATION_NAVIGATION',
  STORE_INTEGRATION_CATEGORY: 'STORE_INTEGRATION_CATEGORY',
  STORE_EVENTLIST: 'STORE_EVENTLIST',
  STORE_MODALOUTLET: 'STORE_MODALOUTLET',
  STORE_CATALOGUE_FILTERS: 'STORE_CATALOGUE_FILTERS',
  STORE_ANALYTICS: 'STORE_ANALYTICS',
  STORE_USER: 'STORE_USER',
};
