import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Container, StatusLabel, CheckboxInput } from '@administrate/piston';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { Button } from '@administrate/piston-ux';

import ValidatedLabelledInput from '../ValidatedLabelledInput';
import LearnerAttributeSection from './LearnerAttributeSection';

// TODO remove this styling once the design is updated to have a button instead of the checkbox for copying over customer data!
const style = {
  flexContainer: {
    alignItems: 'center',
    height: '30px',
  },
  horizontalRule: {
    top: '50%',
  },
  container: {
    height: '30px',
  },
  label: {
    marginRight: '10px',
  },
};

const LearnersForm = ({
  item,
  learners,
  updateItemLearnerProperty,
  updateItemLearnerEmail,
  t,
  isLoading,
  confirmEmails,
  toggleUseBookerDetails,
  attributeDefinitions = [],
  requireLearnerDetails = true,
}) => {
  const contactAttributeDefintions = React.useMemo(
    () =>
      attributeDefinitions.filter(
        definition => definition.section === 'Contact',
      ),
    [attributeDefinitions],
  );
  const eventAttributeDefinitions = React.useMemo(
    () =>
      attributeDefinitions.filter(definition => definition.section === 'Event'),
    [attributeDefinitions],
  );
  const pathAttributeDefinitions = React.useMemo(
    () =>
      attributeDefinitions.filter(definition => definition.section === 'Path'),
    [attributeDefinitions],
  );

  const showMoreKey = learner => `${item.id}-${learner.id}`;

  const initialShowMoreLearnerInformation = {};
  learners.forEach(learner => {
    initialShowMoreLearnerInformation[showMoreKey(learner)] = null;
  });
  const [showMoreLearnerInformation, setShowMoreLearnerInformation] = useState(
    initialShowMoreLearnerInformation,
  );

  const shouldShowMoreLearnerInformation = learner => {
    // If the user has explicitly chosen to show/not show the information, do what they want
    if (
      showMoreLearnerInformation[showMoreKey(learner)] !== null &&
      showMoreLearnerInformation[showMoreKey(learner)] !== undefined
    ) {
      return showMoreLearnerInformation[showMoreKey(learner)];
    }

    // Open when the user starts filling in details for a new learner
    return !learner.isEmpty;
  };

  const getDefinitions = () => {
    if (item.isGiftVoucher) {
      return [];
    }
    if (item.isPath) {
      return pathAttributeDefinitions;
    }
    return eventAttributeDefinitions;
  };

  return learners.map((learner, index) => (
    <Container.Row className="row learner-details" key={learner.id}>
      <Container.Col sm={12}>
        <Container.Row>
          <Container.Col sm={12}>
            <div
              className="checkout_setlearners_courses_learner-title learner-title"
              style={style.container}
            >
              <div
                className="checkout_setlearners_courses_learner-title_hr"
                style={style.horizontalRule}
              />
              <div
                className="checkout_setlearners_courses_learner-title_group"
                style={style.flexContainer}
              >
                <div
                  className="checkout_setlearners_courses_learner-title_label"
                  style={style.label}
                >
                  <h3 className="sr-only">{`${t('weblink:learner')} ${index +
                    1}`}</h3>
                  <StatusLabel
                    color={
                      learner.isValid(
                        attributeDefinitions,
                        requireLearnerDetails,
                      )
                        ? 'green'
                        : 'lightgrey'
                    }
                  >
                    {`${t('weblink:learner')} ${index + 1}`}
                  </StatusLabel>
                </div>
                {index === 0 && (
                  <div className="checkout_setlearners_courses_learner-title_apply-user">
                    <CheckboxInput
                      className="d-none d-sm-inline-block"
                      title={t('weblink:useBookerInfo')}
                      onChange={() => toggleUseBookerDetails(item.id)}
                      disabled={isLoading}
                      value={item.usingBookerDetails}
                    />
                  </div>
                )}
              </div>
            </div>
          </Container.Col>
        </Container.Row>
        <Container.Row>
          <Container.Col sm={12}>
            <ValidatedLabelledInput
              label={t('weblink:emailAddress')}
              onChange={email => {
                updateItemLearnerEmail({
                  id: item.id,
                  learnerId: learner.id,
                  email,
                  confirmEmails,
                });
              }}
              value={learner.email}
              disabled={isLoading || (index === 0 && item.usingBookerDetails)}
              isValid={learner.isEmailValid}
              required={requireLearnerDetails || !learner.isUnnamed}
              id={`${learner.id}_emailAddress`}
            />
          </Container.Col>
        </Container.Row>
        {confirmEmails && (
          <Container.Row>
            <Container.Col sm={12}>
              <ValidatedLabelledInput
                label={t('weblink:confirmEmail')}
                onChange={value =>
                  updateItemLearnerProperty({
                    id: item.id,
                    learnerId: learner.id,
                    property: 'confirmedEmail',
                    value,
                  })
                }
                value={learner.confirmedEmail}
                disabled={isLoading || (index === 0 && item.usingBookerDetails)}
                isValid={learner.isEmailConfirmed}
                errorMessage={t('weblink:emailConfirmationIsRequired')}
                required
                id={`${learner.id}_confirmedEmail`}
              />
            </Container.Col>
          </Container.Row>
        )}
        <Container.Row>
          <Container.Col sm={6}>
            <ValidatedLabelledInput
              label={t('weblink:firstName')}
              onChange={value =>
                updateItemLearnerProperty({
                  id: item.id,
                  learnerId: learner.id,
                  property: 'firstName',
                  value,
                })
              }
              value={learner.firstName}
              disabled={isLoading || (index === 0 && item.usingBookerDetails)}
              isValid={learner.isFirstNameValid}
              required={requireLearnerDetails || !learner.isUnnamed}
              id={`${learner.id}_firstName`}
            />
          </Container.Col>
          <Container.Col sm={6}>
            <ValidatedLabelledInput
              label={t('weblink:lastName')}
              onChange={value =>
                updateItemLearnerProperty({
                  id: item.id,
                  learnerId: learner.id,
                  property: 'lastName',
                  value,
                })
              }
              value={learner.lastName}
              disabled={isLoading || (index === 0 && item.usingBookerDetails)}
              isValid={learner.isLastNameValid}
              required={requireLearnerDetails || !learner.isUnnamed}
              id={`${learner.id}_lastName`}
            />
          </Container.Col>
        </Container.Row>
        {attributeDefinitions.length > 0 && (
          <>
            <Container.Row>
              <Container.Col sm={6}>
                <Button
                  type="suppressed"
                  label={
                    shouldShowMoreLearnerInformation(learner)
                      ? t('weblink:hideLearnerInformation')
                      : t('weblink:showMoreLearnerInformation')
                  }
                  onClick={() =>
                    setShowMoreLearnerInformation({
                      ...showMoreLearnerInformation,
                      [showMoreKey(learner)]: !shouldShowMoreLearnerInformation(
                        learner,
                      ),
                    })
                  }
                />
              </Container.Col>
            </Container.Row>
            <Container.Row>
              {shouldShowMoreLearnerInformation(learner) && (
                <div className="checkout_learner_attribute-panel">
                  {(!learner.isUnnamed || requireLearnerDetails) && (
                    <LearnerAttributeSection
                      item={item}
                      type="Contact"
                      learner={learner}
                      definitions={contactAttributeDefintions}
                    />
                  )}
                  <LearnerAttributeSection
                    item={item}
                    type={item.isPath ? 'Path' : 'Event'}
                    learner={learner}
                    definitions={getDefinitions(item)}
                  />
                </div>
              )}
            </Container.Row>
          </>
        )}
      </Container.Col>
    </Container.Row>
  ));
};

LearnersForm.propTypes = {
  toggleUseBookerDetails: PropTypes.func.isRequired,
  item: MobxPropTypes.objectOrObservableObject.isRequired,
  learners: PropTypes.shape({}).isRequired,
  updateItemLearnerProperty: PropTypes.func.isRequired,
  updateItemLearnerEmail: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmEmails: PropTypes.bool.isRequired,
  attributeDefinitions: MobxPropTypes.arrayOrObservableArray,
  requireLearnerDetails: PropTypes.bool,
};

LearnersForm.defaultProps = {
  requireLearnerDetails: true,
};

export default withTranslation()(observer(LearnersForm));
