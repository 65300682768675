import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Container, Row, Col } from '@administrate/piston-ux';
import { Trans, withTranslation } from 'react-i18next';
import portalStore from '../stores/portalStore';

const ReturnToCatalogue = ({ t, toCatalogue, customMessage }) => (
  <Container>
    <Row>
      <Col xs={12}>
        <div className="mt-5">
          <h2 className={customMessage ? 'CardTitle' : ''}>
            {t(customMessage || 'weblink:cartEmpty')}
          </h2>
          <p>
            <Trans
              i18nKey="weblink:goBackToCatalogue"
              defaults="Go back to the <0>catalog</0> and pick a course."
            >
              {toCatalogue ? (
                <button
                  type="button"
                  className="link-button"
                  style={{ textDecoration: 'underline' }}
                  onClick={() => {
                    const redirectSite = portalStore.embedSite;
                    if (redirectSite) {
                      window.location = redirectSite;
                    } else {
                      toCatalogue();
                    }
                  }}
                />
              ) : (
                <span />
              )}
            </Trans>
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);

ReturnToCatalogue.propTypes = {
  t: PropTypes.func.isRequired,
  toCatalogue: PropTypes.func.isRequired,
  customMessage: PropTypes.string,
};

ReturnToCatalogue.defaultProps = {
  customMessage: null,
};

export default withTranslation()(observer(ReturnToCatalogue));
