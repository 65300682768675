import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Row, Col } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import inject from '../../../inject';
import { STORES } from '../../../../constants';

const { STORE_CHECKOUT, STORE_CART } = STORES;

const PaymentButton = ({
  [STORE_CHECKOUT]: { isLoading },
  [STORE_CART]: { isApplyPromotionCodeLoading, containsValidItems },
  onClick,
  t,
}) => (
  <Button
    label={
      <span>
        {t('weblink:proceedToPaymentProvider')}
        {isLoading && (
          <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
        )}
      </span>
    }
    type="primary"
    disabled={isApplyPromotionCodeLoading || isLoading || !containsValidItems}
    onClick={onClick}
  />
);

PaymentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export const RedirectPaymentButton = withTranslation()(
  inject(STORE_CHECKOUT, STORE_CART)(observer(PaymentButton)),
);

const PaymentInfo = ({ t, paymentProviderName, children }) => (
  <Row>
    {!!children && <Col xs={12}>{children}</Col>}
    <Col xs={12}>
      <p>{t('weblink:encryptionNotice')}</p>
      <p>
        {t('weblink:redirectToPaymentProviderNotice', {
          paymentProviderName,
        })}
      </p>
    </Col>
  </Row>
);

PaymentInfo.propTypes = {
  paymentProviderName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  t: PropTypes.func.isRequired,
};

PaymentInfo.defaultProps = {
  children: undefined,
};

export const RedirectPaymentInfo = withTranslation()(observer(PaymentInfo));
