import React from 'react';
import { observer } from 'mobx-react-lite';
import { PrimaryAction, StandardAction } from '@administrate/piston';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Prompt } from '@administrate/piston-ux';
import { STORES } from '../../constants';
import inject from '../inject';
import TrainingRequestLink from '../../components/TrainingRequest/TrainingRequestLink';

const { STORE_DETAILS, STORE_STORE } = STORES;

const DetailsCardActions = ({
  isPath,
  t,
  hideTrainingRequestLink,
  [STORE_DETAILS]: {
    courseDetails,
    pathDetails,
    isLoading,
    openAddPathToCart,
    openAddToCart,
    openCourseCartlessCheckout,
    openPathCartlessCheckout,
    openBuyNow,
    openBuyPathNow,
    isPathFullyBooked,
    mustFulfillPathObjectives,
  },
  [STORE_STORE]: { cartlessCheckout, storeDetailsIsLoading },
}) => {
  const cannotAddToCart =
    isLoading ||
    (isPath && (isPathFullyBooked || mustFulfillPathObjectives)) ||
    (!isPath &&
      courseDetails.bookableEvents &&
      courseDetails.bookableEvents.length === 0);
  return (
    <>
      {!isLoading && (
        <>
          {isPathFullyBooked && (
            <Prompt type="warning" message={t('weblink:noPlacesRemaining')} />
          )}
          {mustFulfillPathObjectives && (
            <Prompt
              type="warning"
              message={t('weblink:cannotBookUntilObjectivesDatesSelected')}
            />
          )}
        </>
      )}
      {!storeDetailsIsLoading && !isPath && (
        <>
          {!cartlessCheckout ? (
            <>
              <PrimaryAction
                onClick={openAddToCart}
                label={t('weblink:addToCart')}
                disabled={cannotAddToCart}
              />
              <StandardAction
                onClick={openBuyNow}
                label={t('weblink:buyNow')}
                disabled={cannotAddToCart}
              />
            </>
          ) : (
            <PrimaryAction
              onClick={openCourseCartlessCheckout}
              label={t('weblink:register')}
              disabled={cannotAddToCart}
            />
          )}
          {courseDetails &&
            courseDetails.id &&
            courseDetails.name &&
            !hideTrainingRequestLink && (
              <TrainingRequestLink
                interestId={courseDetails.id}
                interestName={courseDetails.name}
              />
            )}
        </>
      )}
      {!storeDetailsIsLoading && isPath && (
        <>
          {!cartlessCheckout ? (
            <>
              <PrimaryAction
                onClick={openAddPathToCart}
                label={t('weblink:addToCart')}
                disabled={cannotAddToCart}
              />
              <StandardAction
                onClick={openBuyPathNow}
                label={t('weblink:buyNow')}
                disabled={cannotAddToCart}
              />
            </>
          ) : (
            <PrimaryAction
              onClick={openPathCartlessCheckout}
              label={t('weblink:register')}
              disabled={cannotAddToCart}
            />
          )}
          {pathDetails &&
            pathDetails.id &&
            pathDetails.name &&
            !hideTrainingRequestLink && (
              <TrainingRequestLink
                interestId={pathDetails.id}
                interestName={pathDetails.name}
              />
            )}
        </>
      )}
    </>
  );
};

DetailsCardActions.propTypes = {
  isPath: PropTypes.bool,
  t: PropTypes.func.isRequired,
  pathId: PropTypes.string,
  hideTrainingRequestLink: PropTypes.bool,
};

DetailsCardActions.defaultProps = {
  isPath: false,
  pathId: null,
  hideTrainingRequestLink: false,
};

export default withTranslation()(
  inject(STORE_DETAILS, STORE_STORE)(observer(DetailsCardActions)),
);
