import { sha256 } from 'js-sha256';
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-85231646-6';

export const initAnalytics = (instance, userId) => {
  const gaOptions = {};

  if (userId) {
    const preHashedId = `${instance}_${userId}`;
    gaOptions.userId = sha256(preHashedId);
  }

  ReactGA.initialize(TRACKING_ID, { gaOptions });
  ReactGA.set({ dimension1: instance });
};

export const sendEvent = (args, trackerNames) => {
  ReactGA.event(args, trackerNames);
};

export const sendPageView = (path, trackerNames, title) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path, trackerNames, title);
};
