import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { Steps, Step, Container, Row } from '@administrate/piston-ux';

const CheckoutProgress = ({ currentStep, path, t, isLoading }) => (
  <div className="header pb-3">
    <Container>
      <Row>
        {!isLoading && (
          <div className="col-md-8 col-md-offset-2 px-4">
            <Steps>
              {path.map((step, index) => {
                const completed = index < currentStep;
                if (currentStep + 1 === path.length) {
                  return (
                    <Step
                      title={t(step.label)}
                      complete
                      glyph="check"
                      key={step.label}
                    />
                  );
                }
                return (
                  <Step
                    key={step.label}
                    title={
                      isLoading && path.length === 1
                        ? t('weblink:checkoutLoading')
                        : t(step.label)
                    }
                    active={index === currentStep}
                    complete={completed}
                    glyph={completed ? 'check' : undefined}
                  />
                );
              })}
            </Steps>
          </div>
        )}
      </Row>
    </Container>
  </div>
);

CheckoutProgress.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default withTranslation()(observer(CheckoutProgress));
