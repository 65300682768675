import React from 'react';
import { Query } from 'react-apollo';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import { Breadcrumb } from '@administrate/piston';
import { withTranslation } from 'react-i18next';

import inject from './inject';
import queries from '../queries';
import { STORES } from '../constants';

const { STORE_NAVIGATION } = STORES;

const BreadcrumbContainer = ({
  [STORE_NAVIGATION]: { toCategory, toHome },
  categoryId,
  courseName,
  filtered,
  subcategoryId,
  t,
}) => (
  <Breadcrumb>
    <Breadcrumb.Content>
      <button
        type="button"
        className="link-button link-button__brand-primary"
        onClick={toHome}
      >
        {t('weblink:home')}
      </button>
      {categoryId && (
        <Query
          query={queries.category.getCategoryById}
          variables={{ categoryId, subcategoryId: subcategoryId || '' }}
        >
          {({ data, loading }) => {
            if (loading) {
              return null;
            }

            const categoryName = _get(data, 'category.edges[0].node.name');
            const subcategoryName = _get(
              data,
              'subcategory.edges[0].node.name',
            );

            if (subcategoryId) {
              return (
                <>
                  <button
                    type="button"
                    className="link-button link-button__brand-primary"
                    onClick={() => toCategory({ categoryId, categoryName })}
                  >
                    {categoryName}
                  </button>
                  {courseName ? (
                    <button
                      type="button"
                      className="link-button link-button__brand-primary"
                      onClick={() =>
                        toCategory({
                          categoryId,
                          subcategoryId,
                          categoryName,
                          subcategoryName,
                        })
                      }
                    >
                      {subcategoryName}
                    </button>
                  ) : (
                    <span>{subcategoryName}</span>
                  )}
                </>
              );
            }

            return courseName ? (
              <button
                type="button"
                className="link-button link-button__brand-primary"
                onClick={() => toCategory({ categoryId, categoryName })}
              >
                {categoryName}
              </button>
            ) : (
              <span>{categoryName}</span>
            );
          }}
        </Query>
      )}
      {filtered && <span>{t('weblink:results')}</span>}
      {courseName && <span>{courseName}</span>}
    </Breadcrumb.Content>
  </Breadcrumb>
);

BreadcrumbContainer.defaultProps = {
  categoryId: null,
  subcategoryId: null,
  courseName: null,
  filtered: false,
};

BreadcrumbContainer.propTypes = {
  t: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  subcategoryId: PropTypes.string,
  courseName: PropTypes.string,
  filtered: PropTypes.bool,
};

export default withTranslation()(inject(STORE_NAVIGATION)(BreadcrumbContainer));
