import { decorate, runInAction, observable, computed, action } from 'mobx';

import {
  isEmail,
  isURL,
  isNumber,
  isInteger,
} from '@administrate/piston-ux/lib/utils/validators';
import { isProvinceValueValid } from '../components/Checkout/CountryProvinceSelector';

/* eslint-disable-next-line */
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Learner {
  constructor({
    id,
    email,
    confirmedEmail,
    firstName,
    lastName,
    customFieldValues,
  }) {
    runInAction(() => {
      this.id = id;
      this.email = email;
      this.confirmedEmail = confirmedEmail;
      this.firstName = firstName;
      this.lastName = lastName;
      this.customFieldValues = customFieldValues || {};
    });
  }

  static getEmptyLearner(id) {
    return new Learner({
      id,
      email: '',
      confirmedEmail: '',
      firstName: '',
      lastName: '',
      customFieldValues: {},
    });
  }

  updateProperty = action(({ property, value }) => {
    this[property] = value;
  });

  updateEmail = action(({ email, confirmEmail }) => {
    if (!confirmEmail) {
      // If Portal confirmEmails setting is off
      // Populate field with email value so that validation for confirmEmail always return true
      this.confirmedEmail = email;
    }
    this.email = email;
  });

  _allowEmptyValueIfUnnamed(value) {
    return !!value || this.isUnnamed;
  }

  get isEmailValid() {
    return this.isUnnamed ? !this.email : emailRegex.test(this.email);
  }

  get isEmailConfirmed() {
    return this.confirmedEmail && this.confirmedEmail === this.email;
  }

  get isFirstNameValid() {
    return this._allowEmptyValueIfUnnamed(this.firstName);
  }

  get isLastNameValid() {
    return this._allowEmptyValueIfUnnamed(this.lastName);
  }

  customFieldValuesValid(customFieldDefinitions) {
    return customFieldDefinitions.every(definition =>
      this.isValidCustomField(definition),
    );
  }

  isValid(customFieldDefinitions, requireLearnerDetails) {
    if (!this.customFieldValuesValid(customFieldDefinitions)) {
      return false;
    }
    return this.isNamed || (!requireLearnerDetails && this.isUnnamed);
  }

  get isNamed() {
    return this.firstName && this.lastName && this.email && this.isEmailValid;
  }

  get isUnnamed() {
    return !this.firstName && !this.lastName && !this.email;
  }

  get isEmpty() {
    return this.isUnnamed && !this.confirmedEmail;
  }

  getCustomFieldValue(definitionKey) {
    if (definitionKey in this.customFieldValues) {
      return this.customFieldValues[definitionKey];
    }

    return null;
  }

  setCustomFieldValue(definitionKey, newValue) {
    // Need to overwrite the whole object here, as this.customFieldValues has no observable keys to start with,
    // and so the form does not know to re-render after the observable is extended
    this.customFieldValues = {
      ...this.customFieldValues,
      ...{
        [definitionKey]: newValue,
      },
    };
  }

  static getValidatorForType(type) {
    switch (type) {
      case 'url':
        return v => !v || isURL(v);
      case 'email':
        return v => !v || isEmail(v);
      case 'number':
        return v => !v || isNumber(v);
      case 'integer':
        return v => !v || isInteger(v);
      case 'province':
        return v => isProvinceValueValid(v);
      default:
        return null;
    }
  }

  isValidCustomField(definition) {
    const value = this.customFieldValues
      ? this.customFieldValues[definition.key]
      : '';

    const requiredButEmpty = this.fieldIsRequired(definition) && !value;

    const typeValidator = Learner.getValidatorForType(definition.type);
    if (typeValidator) {
      return !requiredButEmpty && typeValidator(value);
    }

    return !requiredButEmpty;
  }

  fieldIsRequired(definition) {
    if (this.isUnnamed && definition.section === 'Contact') {
      return false;
    }
    return definition.isRequired;
  }
}

decorate(Learner, {
  id: observable,
  email: observable,
  confirmedEmail: observable,
  firstName: observable,
  lastName: observable,
  customFieldValues: observable,
  isEmailValid: computed,
  isEmailConfirmed: computed,
  isFirstNameValid: computed,
  isLastNameValid: computed,
  isEmpty: computed,
  isUnnamed: computed,
});

export default Learner;
