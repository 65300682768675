import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withCurrency from '../../../containers/withCurrency';

const GiftVoucherDetails = ({ giftVoucher: gv, t, formatCurrency }) => {
  return (
    <div className="GiftVoucherDetails">
      <h3>
        {t('weblink:voucher')}: {formatCurrency(gv.unitAmount)}
        {gv.recipientDetails.giftVoucherHasLinkedItem && (
          <span>
            {' '}
            ({t('weblink:giftVoucherPlusPostage')}{' '}
            {gv.giftVoucherConfigLinkedItem.cost})
          </span>
        )}
      </h3>
      <div className="row">
        {!gv.recipientDetails.giftVoucherHasLinkedItem && (
          <div className="col-sm-6">
            <span className="buyer-detail-label">
              {t('weblink:giftVoucherEmailTo')}
            </span>
            <span className="buyer-detail-value">
              {gv.recipientDetails.email}
            </span>
          </div>
        )}
        {gv.recipientDetails.giftVoucherHasLinkedItem && (
          <div className="col-sm-6">
            <span className="buyer-detail-label">
              {t('weblink:giftVoucherRecipientAddress')}
            </span>
            <span className="buyer-detail-value">
              {gv.recipientDetails.postalAddressLineOne}
            </span>
            {gv.recipientDetails.postalAddressLineTwo !== '' && (
              <span className="buyer-detail-value">
                {gv.recipientDetails.postalAddressLineTwo}
              </span>
            )}
            <span className="buyer-detail-value">
              {gv.recipientDetails.postalAddressTown}
            </span>
            <span className="buyer-detail-value">
              {gv.recipientDetails.postalAddressPostcode}
            </span>
            <span className="buyer-detail-value">
              {gv.recipientDetails.postalAddressCountryName}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

GiftVoucherDetails.propTypes = {
  giftVoucher: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
};

export default withTranslation()(withCurrency(GiftVoucherDetails));
