import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import withCurrency from '../../../containers/withCurrency';

const CartItemPrice = ({ amount, formatCurrencyForCalculation }) => (
  <div className="mt-2">{formatCurrencyForCalculation(amount)}</div>
);

CartItemPrice.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  formatCurrencyForCalculation: PropTypes.func.isRequired,
};

export default withCurrency(observer(CartItemPrice));
