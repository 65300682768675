import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePortalConfiguration } from '../hooks/portalConfiguration';
import { initAnalytics, sendPageView } from '../utils/analytics';
import { useAuth0 } from '../auth/lib/react-auth0-wrapper';
import getDomainId from '../utils/getDomainId';

export const AnalyticsProvider = ({ children }) => {
  const { analyticsEnabled, instanceCode } = usePortalConfiguration();
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (instanceCode && !initialized && analyticsEnabled) {
      if (isAuthenticated && user && user.sub) {
        const encodedUserId = user.sub.split(':')[2];
        initAnalytics(instanceCode, getDomainId(encodedUserId));
      } else {
        initAnalytics(instanceCode);
      }
      setInitialized(true);
    }
  }, [initialized, analyticsEnabled, instanceCode, isAuthenticated, user]);

  useEffect(() => {
    if (initialized && analyticsEnabled) {
      const currentPath = location.pathname + location.search;
      sendPageView(currentPath);
    }
  }, [location, initialized, analyticsEnabled]);

  return children;
};
