/** **Must be called outside of the suspended component**. In the parent or outside of React's rendering.
 * Wraps a promise in a resource that can be .read() so React can suspense it */
function wrapPromise(promise) {
  let status = 'pending';
  let response = null;

  const suspender = promise
    .then(res => {
      status = 'success';
      response = res;
    })
    .catch(err => {
      status = 'error';
      response = err;
    });

  const read = () => {
    switch (status) {
      case 'pending':
        throw suspender;
      case 'error':
        throw response;
      default:
        return response;
    }
  };

  return { read };
}

export default wrapPromise;
