/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from 'react';
import {
  PaginationBar,
  PrimaryAction,
  Container,
  Filters,
  SelectListInput,
  LabelledInput,
} from '@administrate/piston';
import {
  Button,
  Select,
  useTypedFormValues,
  Form,
} from '@administrate/piston-ux';
import { DateTime } from 'luxon';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { get as _get } from 'lodash';
import { EventDateRange } from '../../components/EventDateRange';
import EventTable from '../../components/EventTable/EventTable';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import withCurrency from '../withCurrency';
import inject from '../inject';
import bundleConfig from '../../bundleConfig';
import { STORES } from '../../constants';
import EventSessionsModal from '../../components/EventSessionsModal';
import { formatDateWithTime } from '../../utils/formatDateTime';

const {
  STORE_EVENTLIST,
  STORE_CATALOGUE,
  STORE_DETAILS,
  STORE_NAVIGATION,
  STORE_STORE,
} = STORES;

const getLocationIdFromName = (allLocations, location) => {
  if (!allLocations || !location) {
    return null;
  }
  const loc = allLocations.find(l =>
    l.name.toLowerCase().includes(location.toLowerCase()),
  );
  return loc && loc.id;
};

const EventList = ({
  formatCurrency,
  t,
  showTimezone,
  showDateFilter,
  showLocationFilter,
  showCourseFilter,
  showCategoryFilter,
  showTitleColumn,
  showLocationColumn,
  showVenueColumn,
  showStartDateColumn,
  showEndDateColumn,
  showDateAndTimeColumn,
  showDurationColumn,
  showTimeColumn,
  showPlacesRemainingColumn,
  showPriceColumn,
  showAddToCartColumn,
  showClassroomStartDateColumn,
  showClassroomDurationColumn,
  showClassroomTimeColumn,
  showLmsStartDateColumn,
  showLmsDurationColumn,
  showLmsTimeColumn,
  showSessionsColumn,
  alwaysHideSessionsColumn,
  showLocale,
  courseLinksOnTitle,
  code,
  fromDate,
  toDate,
  location,
  category,
  daysOfWeek,
  locations,
  minimumPlacesRemaining,
  fromTimeOfDay,
  toTimeOfDay,
  eventListOrder: defaultOrder,
  [STORE_STORE]: { taxInclusive, cartlessCheckout, isTaxInclusiveOnly },
  [STORE_EVENTLIST]: {
    eventGridData,
    eventGridPage,
    eventGridTotalPages,
    eventGridIsLoading,
    setEventGridPage,
    resetEventGridPage,

    updateFilterBarCriteria,
    filterbarCriteria,
    updateOrder,
    order: orderList,

    courseCodeIsLoading,
    courseCodeOptions,
    selectedCourse,
    setCourseCodeText,
    setSelectedCourse,
    fetchCourses,

    locations: allLocations,
  },
  [STORE_CATALOGUE]: { allCategories, allCategoriesIsLoading },
  [STORE_DETAILS]: { addEventListItemToCart, openCourseCartlessCheckout },
  [STORE_NAVIGATION]: { toCourseDetail },
}) => {
  let initialLocations = [];
  if (location) {
    const initialLocId = getLocationIdFromName(allLocations, location);
    initialLocations = initialLocId ? [initialLocId] : [];
  } else if (locations) {
    initialLocations = locations;
  }

  const [selectedEvent, setSelectedEvent] = useState(null);

  const formValues = useTypedFormValues({
    locations: initialLocations,
    dateRange: {
      start: filterbarCriteria.fromDate ?? null,
      end: filterbarCriteria.toDate ?? null,
    },
  });

  useEffect(() => {
    let filters = {};
    if (fromDate) {
      filters = { ...filters, fromDate };
    }
    if (toDate) {
      filters = { ...filters, toDate };
    }
    if (location) {
      const locId = getLocationIdFromName(allLocations, location);
      if (locId) {
        filters = { ...filters, locations: [locId] };
      }
    }
    if (code) {
      filters = { ...filters, code };
    }
    if (category) {
      filters = { ...filters, category };
    }
    if (daysOfWeek) {
      filters = { ...filters, daysOfWeek };
    }
    if (locations) {
      filters = { ...filters, locations };
    }
    if (minimumPlacesRemaining) {
      filters = { ...filters, minimumPlacesRemaining };
    }
    if (fromTimeOfDay) {
      filters = { ...filters, fromTimeOfDay };
    }
    if (toTimeOfDay) {
      filters = { ...filters, toTimeOfDay };
    }
    updateFilterBarCriteria(filters);
    updateOrder(defaultOrder);
    resetEventGridPage();
    fetchCourses({ code });

    return () => {
      updateFilterBarCriteria({
        fromDate: null,
        toDate: null,
        location: null,
        code: null,
        category: null,
        daysOfWeek: null,
        locations: null,
        minimumPlacesRemaining: null,
        fromTimeOfDay: null,
        toTimeOfDay: null,
      });
      updateOrder(defaultOrder);
      setSelectedCourse(null);
    };
  }, [
    fromDate,
    toDate,
    location,
    code,
    category,
    daysOfWeek,
    locations,
    minimumPlacesRemaining,
    fromTimeOfDay,
    toTimeOfDay,
    fetchCourses,
    resetEventGridPage,
    updateFilterBarCriteria,
    updateOrder,
    defaultOrder,
  ]);
  // The API has been updated to handle multiple order fields but this can
  // currently only be set on widget creation using the eventListOrder field.
  // The UI Component cannot handle multiple order fields yet.
  // Just use the first one for displaying which column is currently ordered.
  const [order] = orderList;

  const showSessions =
    !alwaysHideSessionsColumn &&
    (showSessionsColumn || eventGridData.some(event => event.sessionCount > 1));

  const columns = useMemo(
    () => [
      {
        label: t('weblink:eventTitle'),
        noshow: !showTitleColumn,
        className: 'eventList-title',
        content: row =>
          bundleConfig.hashRouting && courseLinksOnTitle ? (
            <button
              type="button"
              className="link-button"
              onClick={() =>
                toCourseDetail({
                  courseCode: row.courseCode,
                })
              }
            >
              {row.eventName}
            </button>
          ) : (
            row.eventName
          ),
        sortable: true,
        active: order.field === 'name',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'name',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:location'),
        noshow: !showLocationColumn,
        content: row => row.location,
        sortable: true,
        active: order.field === 'locationName',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'locationName',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:venue'),
        noshow: !showVenueColumn,
        content: row => row.venue,
      },
      {
        label: t('weblink:language'),
        noshow: !showLocale,
        content: row => (row.locale ? row.locale : 'English'),
        sortable: true,
        active: order.field === 'locale',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'localeId',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:startDate'),
        noshow: !showStartDateColumn,
        content: row => {
          if (row.deliveryMethod === 'lms') {
            return t('weblink:uponEnrolment');
          }
          return row.startDate;
        },
        sortable: true,
        active: order.field === 'start',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'start',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:endDate'),
        noshow: !showEndDateColumn,
        content: row => row.endDate,
        sortable: true,
        active: order.field === 'end',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'end',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:dateAndTime'),
        noshow: !showDateAndTimeColumn,
        content: row => {
          if (row.deliveryMethod === 'lms') {
            return t('weblink:uponEnrolment');
          }

          return <EventDateRange event={row.eventFromAPI} />;
        },
        sortable: true,
        active: order.field === 'start',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'start',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:duration'),
        noshow: !showDurationColumn,
        content: row => (row.duration ? `${row.duration} day(s)` : '-'),
      },
      {
        label: t('weblink:sessions'),
        noshow: !showSessions,
        content: row => {
          if (row.deliveryMethod === 'lms' && row.sessionCount === 0) {
            return t('weblink:selfPaced');
          }

          return (
            <>
              {row.sessionCount}{' '}
              {row.sessionCount > 1 && (
                <Button
                  label={`(${t('weblink:viewDates')})`}
                  onClick={() => setSelectedEvent(row)}
                  type="suppressed"
                />
              )}
            </>
          );
        },
      },
      {
        label: t('weblink:time'),
        noshow: !showTimeColumn,
        content: row => {
          if (row.deliveryMethod === 'lms') {
            return '-';
          }
          if (row.range && row.endDateValid) {
            const timeRange = `${row.startTime} - ${row.endTime}`;
            return showTimezone ? `${timeRange} ${row.timeZone}` : timeRange;
          }
          return '-';
        },
      },
      {
        label: t('weblink:classroomStartDate'),
        noshow: !showClassroomStartDateColumn,
        content: row => {
          if (row.deliveryMethod === 'lms') {
            return t('weblink:uponEnrolment');
          }
          return row.classroomStartDate;
        },
        sortable: true,
        active: order.field === 'classroomStart',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'classroomStart',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:classroomDuration'),
        noshow: !showClassroomDurationColumn,
        content: row =>
          row.classroomDuration ? `${row.classroomDuration} days` : '-',
      },
      {
        label: t('weblink:classroomTime'),
        noshow: !showClassroomTimeColumn,
        content: row => {
          if (row.deliveryMethod === 'lms') {
            return '-';
          }
          return row.classroomRange && row.classroomEndDateValid
            ? `${row.classroomStartTime} - ${row.classroomEndTime} ${row.classroomTimeZone}`
            : '-';
        },
      },
      {
        label: t('weblink:lmsStartDate'),
        noshow: !showLmsStartDateColumn,
        content: row => (row.lmsStartDate ? row.lmsStartDate : '-'),
        sortable: true,
        active: order.field === 'lmsStart',
        direction: order.direction,
        disabled: eventGridIsLoading,
        onSort: () => {
          updateOrder([
            {
              field: 'lmsStart',
              direction: order.direction === 'asc' ? 'desc' : 'asc',
            },
          ]);
          resetEventGridPage();
        },
      },
      {
        label: t('weblink:lmsDuration'),
        noshow: !showLmsDurationColumn,
        content: row => (row.lmsDuration ? `${row.lmsDuration} days` : '-'),
      },
      {
        label: t('weblink:lmsTime'),
        noshow: !showLmsTimeColumn,
        content: row =>
          row.lmsStartTime &&
          row.lmsEndTime &&
          row.lmsTimeZone &&
          row.lmsEndDateValid
            ? `${row.lmsStartTime} - ${row.lmsEndTime} ${row.lmsTimeZone}`
            : '-',
      },
      {
        label: t('weblink:placesRemaining'),
        noshow: !showPlacesRemainingColumn,
        content: row => {
          if (row.remainingPlaces === null) {
            return t('weblink:unlimited');
          }

          return row.remainingPlaces < 0 ? 0 : row.remainingPlaces;
        },
      },
      {
        label: `${t('weblink:price')}${!isTaxInclusiveOnly ? '*' : ''}`,
        noshow: !showPriceColumn,
        content: row => formatCurrency(row.price.amount),
      },
      {
        noshow: !showAddToCartColumn || cartlessCheckout,
        columntype: 'grid-detail-addToCart',
        className: 'eventList-addToCart',
        content: row => {
          const disabled =
            eventGridIsLoading ||
            (row.remainingPlaces !== null && row.remainingPlaces <= 0);

          if (!row.registrationOpen) {
            return (
              <>
                <small>{t('weblink:registrationOpensOn')}:</small>
                <p>{formatDateWithTime(row.registrationOpensAt)}</p>
              </>
            );
          }

          return (
            <PrimaryAction
              onClick={() => addEventListItemToCart({ ...row, quantity: 1 })}
              label={
                row.remainingPlaces !== null && row.remainingPlaces <= 0
                  ? t('weblink:soldOut')
                  : t('weblink:addToCart')
              }
              disabled={disabled}
            />
          );
        },
      },
      {
        noshow: !cartlessCheckout,
        content: row => (
          <Button
            onClick={() => openCourseCartlessCheckout({ prePopulated: row })}
            label={
              row.remainingPlaces !== null && row.remainingPlaces <= 0
                ? t('weblink:soldOut')
                : t('weblink:register')
            }
            disabled={
              eventGridIsLoading ||
              (row.remainingPlaces !== null && row.remainingPlaces <= 0)
            }
            type="suppressed"
          />
        ),
      },
    ],
    [
      showTitleColumn,
      order.field,
      order.direction,
      eventGridIsLoading,
      updateOrder,
      resetEventGridPage,
      showLocationColumn,
      showVenueColumn,
      showStartDateColumn,
      showEndDateColumn,
      showDateAndTimeColumn,
      showDurationColumn,
      showTimeColumn,
      showClassroomStartDateColumn,
      showClassroomDurationColumn,
      showClassroomTimeColumn,
      showLmsStartDateColumn,
      showLmsDurationColumn,
      showLmsTimeColumn,
      showPlacesRemainingColumn,
      showPriceColumn,
      showLocale,
      formatCurrency,
      showAddToCartColumn,
      addEventListItemToCart,
      toCourseDetail,
      courseLinksOnTitle,
      t,
      cartlessCheckout,
      openCourseCartlessCheckout,
      showTimezone,
      showSessions,
    ],
  );

  const showTaxInfo = {
    taxInclusive:
      taxInclusive &&
      (eventGridIsLoading || !!eventGridData.length) &&
      showPriceColumn &&
      !isTaxInclusiveOnly,
    taxExclusive:
      !taxInclusive &&
      (eventGridIsLoading || !!eventGridData.length) &&
      showPriceColumn &&
      !isTaxInclusiveOnly,
  };

  const selectedStartDate = fromDate
    ? DateTime.fromISO(fromDate).toJSDate()
    : null;
  const selectedEndDate = toDate ? DateTime.fromISO(toDate).toJSDate() : null;

  return (
    <div className="course-events-list mb-4">
      {(showDateFilter ||
        showLocationFilter ||
        showCourseFilter ||
        showCategoryFilter) && (
        <Row>
          <Container.Col xs={12}>
            <Filters cols={1} type="inline" onSubmit={resetEventGridPage}>
              {showCourseFilter && (
                <LabelledInput
                  label={t('weblink:course')}
                  controlId="SelectListInput_courseCode"
                >
                  <SelectListInput
                    async
                    ariaLabel={t('weblink:course')}
                    options={
                      !courseCodeIsLoading
                        ? courseCodeOptions.map(({ node }) => node.name)
                        : []
                    }
                    onSearch={setCourseCodeText}
                    isLoading={courseCodeIsLoading}
                    useCache={false}
                    filterOptions={() => true}
                    labelKey="name"
                    id="courseCode"
                    placeholder={t('weblink:searchFor')}
                    onChange={change => {
                      if (change) {
                        const selectedNode = courseCodeOptions.find(
                          ({ node }) => node.name === change,
                        );
                        updateFilterBarCriteria({
                          code: selectedNode.node.code,
                          category: null,
                        });
                        setSelectedCourse(selectedNode);
                      } else {
                        updateFilterBarCriteria({ code: null });
                        setSelectedCourse(null);
                      }
                    }}
                    value={_get(selectedCourse, 'node.name', null)}
                  />
                </LabelledInput>
              )}
              {showCategoryFilter && (
                <LabelledInput
                  label={t('weblink:category')}
                  controlId="SelectListInput_categoryId"
                >
                  <SelectListInput
                    options={allCategories.map(({ node }) => ({
                      text: node.name,
                      value: node.id,
                    }))}
                    id="categoryId"
                    placeholder={t('weblink:searchFor')}
                    ariaLabel={t('weblink:category')}
                    disabled={allCategoriesIsLoading || selectedCourse !== null}
                    onChange={(selected = { value: null }) => {
                      updateFilterBarCriteria({ category: selected.value });
                    }}
                    value={_get(
                      allCategories.find(
                        ({ node }) => node.id === filterbarCriteria.category,
                      ),
                      'node.name',
                    )}
                  />
                </LabelledInput>
              )}
              {showLocationFilter && (
                <Form values={formValues}>
                  <Select
                    options={allLocations.map(l => ({
                      label: l.name,
                      value: l.id,
                    }))}
                    label={t('weblink:location')}
                    name="locations"
                    onChange={selected => {
                      updateFilterBarCriteria({ locations: selected });
                    }}
                    disabled={courseCodeIsLoading}
                    placeholder={
                      courseCodeIsLoading
                        ? `${t('weblink:loading')}...`
                        : `${t('weblink:select')}...`
                    }
                    multiple
                  />
                </Form>
              )}
              {showDateFilter && (
                <DateRangePicker
                  label={t('weblink:date')}
                  onSubmit={resetEventGridPage}
                  onUpdate={date => {
                    updateFilterBarCriteria({
                      fromDate: date.selection.startDate,
                      toDate: date.selection.endDate,
                    });
                  }}
                  fromDate={selectedStartDate}
                  toDate={selectedEndDate}
                />
              )}
            </Filters>
          </Container.Col>
        </Row>
      )}
      <EventTable
        columns={columns}
        data={eventGridData}
        className="eventList"
        showTaxInclusivePriceInfo={showTaxInfo.taxInclusive}
        showTaxExclusivePriceInfo={showTaxInfo.taxExclusive}
        isLoading={eventGridIsLoading}
      />
      <PaginationBar
        currentPage={eventGridPage + 1}
        totalPages={eventGridTotalPages}
        onSelectPage={page => setEventGridPage(page - 1)}
      />
      {!!selectedEvent && (
        <EventSessionsModal
          eventId={selectedEvent.id}
          placeholderRows={selectedEvent.sessionCount}
          onDoneClicked={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};

EventList.defaultProps = {
  code: null,
  category: null,
  location: null,
  fromDate: null,
  toDate: null,
  daysOfWeek: null,
  locations: null,
  minimumPlacesRemaining: null,
  fromTimeOfDay: null,
  toTimeOfDay: null,
  eventListOrder: [
    {
      field: 'locationName',
      direction: 'asc',
    },
  ],
  showTimezone: true,
  showDateFilter: true,
  showLocationFilter: true,
  showCourseFilter: true,
  showCategoryFilter: true,
  showTitleColumn: true,
  showLocationColumn: true,
  showVenueColumn: false,
  showStartDateColumn: true,
  showEndDateColumn: false,
  showDateAndTimeColumn: false,
  showDurationColumn: true,
  showTimeColumn: true,
  showPlacesRemainingColumn: true,
  showPriceColumn: true,
  showAddToCartColumn: true,
  showClassroomStartDateColumn: false,
  showClassroomDurationColumn: false,
  showClassroomTimeColumn: false,
  showLmsStartDateColumn: false,
  showLmsDurationColumn: false,
  showLmsTimeColumn: false,
  showSessionsColumn: true,
  alwaysHideSessionsColumn: false,
  showLocale: false,
  courseLinksOnTitle: false,
};

export const orderPropType = PropTypes.shape({
  field: PropTypes.oneOf([
    'title',
    'name',
    'locationName',
    'start',
    'classroomStart',
    'lmsStart',
    'localeId',
  ]),
  direction: PropTypes.oneOf(['asc', 'desc']),
});

EventList.propTypes = {
  // preFilters
  code: PropTypes.string,
  category: PropTypes.string,
  location: PropTypes.string,
  fromDate: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.string]),
  toDate: PropTypes.oneOf([PropTypes.instanceOf(Date), PropTypes.string]),
  daysOfWeek: PropTypes.arrayOf(PropTypes.string),
  locations: PropTypes.arrayOf(PropTypes.string),
  minimumPlacesRemaining: PropTypes.number,
  fromTimeOfDay: PropTypes.string,
  toTimeOfDay: PropTypes.string,
  eventListOrder: PropTypes.oneOfType([
    orderPropType,
    PropTypes.arrayOf(orderPropType),
  ]),
  showTimezone: PropTypes.bool,
  // filter show
  showDateFilter: PropTypes.bool,
  showLocationFilter: PropTypes.bool,
  showCourseFilter: PropTypes.bool,
  showCategoryFilter: PropTypes.bool,
  // column show
  showTitleColumn: PropTypes.bool,
  showLocationColumn: PropTypes.bool,
  showVenueColumn: PropTypes.bool,
  showStartDateColumn: PropTypes.bool,
  showEndDateColumn: PropTypes.bool,
  showDateAndTimeColumn: PropTypes.bool,
  showDurationColumn: PropTypes.bool,
  showTimeColumn: PropTypes.bool,
  showPlacesRemainingColumn: PropTypes.bool,
  showPriceColumn: PropTypes.bool,
  showAddToCartColumn: PropTypes.bool,
  showClassroomStartDateColumn: PropTypes.bool,
  showClassroomDurationColumn: PropTypes.bool,
  showClassroomTimeColumn: PropTypes.bool,
  showLmsStartDateColumn: PropTypes.bool,
  showLmsDurationColumn: PropTypes.bool,
  showLmsTimeColumn: PropTypes.bool,
  showSessionsColumn: PropTypes.bool,
  alwaysHideSessionsColumn: PropTypes.bool,
  showLocale: PropTypes.bool,
  formatCurrency: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  courseLinksOnTitle: PropTypes.bool,
};

export default withTranslation()(
  withCurrency(
    inject(
      STORE_EVENTLIST,
      STORE_CATALOGUE,
      STORE_DETAILS,
      STORE_NAVIGATION,
      STORE_STORE,
    )(observer(EventList)),
  ),
);
