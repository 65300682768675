import React, { useEffect } from 'react';
import { Notice, HorizontalSpacer, Container } from '@administrate/piston';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import _ from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import inject from '../inject';
import { STORES, PAGING } from '../../constants';
import PageTitle from '../../components/PageTitle';
import CatalogueView from '../../components/Catalogue/Catalogue';
import Breadcrumb from '../Breadcrumb';
import withIntegration from '../withIntegration';
import Pager from '../../components/Pager/Pager';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import { CatalogueFilterBar } from '../../components/Catalogue/CatalogueFilterBar';
import CategoryDropdown from '../WeblinkNavigation/CategoryDropdown';
import { PageViewEvent } from '../../analytics';

const {
  STORE_NAVIGATION,
  STORE_CATALOGUE,
  STORE_STORE,
  STORE_ANALYTICS,
} = STORES;

const Catalogue = ({
  categoryId,
  subcategoryId,
  type,
  courseNameSearchText,
  integration,
  t,
  widgetPagerType,
  pageNumber,
  widgetCataloguePage,
  showSearchBar,
  pageSize,
  locations,
  placesRemaining,
  fromDate,
  toDate,
  categories,
  learningTags,
  customFieldFilters,
  cardLinkBuilder,
  cardOptions = {
    showDescription: false,
    showCategory: true,
    showFeaturedLabel: true,
    showPrice: true,
  },
  [STORE_NAVIGATION]: {
    toCourseDetail,
    toPathDetail,
    toCategory,
    toCataloguePage,
  },
  [STORE_CATALOGUE]: {
    categoriesResponse,
    categoriesResponseIsLoading,
    products,
    getCatalogueIsLoading,
    setCourseNameSearchText,
    cataloguePage,
    showMore,
    setPage,
    hasMoreProducts,
    numberOfPages,
    cataloguePagerType,
    setCatalogueType,
    setSearchBarText,
    setPageSize,
    setLocations,
    setPlacesRemaining,
    setFromDate,
    setToDate,
    setCategories,
    setLearningTags,
    setCustomFieldFilters,
    courseCustomFieldDefinitions,
    pathCustomFieldDefinitions,
    isSetPager,
  },
  [STORE_STORE]: { hidePrices, storeDetails },
  [STORE_ANALYTICS]: { captureEvent },
}) => {
  const isFullPager =
    cataloguePagerType === 'full' || widgetPagerType === 'full';
  const notIntegrationAndFullPager = !integration && isFullPager;
  const integrationAndFullPager = integration && isFullPager;
  const isSetPagerOrCataloguePage = cataloguePage || isSetPager;

  useEffect(() => {
    setCatalogueType({ catalogueType: type });
  }, [type, setCatalogueType]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    setLocations(locations);
  }, [locations]);

  useEffect(() => {
    setPlacesRemaining(placesRemaining);
  }, [placesRemaining]);

  useEffect(() => {
    setFromDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setToDate(toDate);
  }, [toDate]);

  useEffect(() => {
    if (categories.length > 0) {
      setCategories(categories);
    } else if (subcategoryId) {
      setCategories([subcategoryId]);
    } else if (categoryId) {
      setCategories([categoryId]);
    } else {
      setCategories([]);
    }
  }, [categories, categoryId, subcategoryId]);

  useEffect(() => {
    setLearningTags(learningTags);
  }, [learningTags]);

  useEffect(() => {
    setCustomFieldFilters(customFieldFilters);
  }, [customFieldFilters]);

  useEffect(() => {
    setCourseNameSearchText({ courseNameSearchText });
  }, [courseNameSearchText, setCourseNameSearchText]);

  useEffect(() => {
    if (notIntegrationAndFullPager && pageNumber) {
      setPage(Number(pageNumber - 1));
    }
  }, [pageNumber, notIntegrationAndFullPager, setPage]);

  useEffect(() => {
    if (integrationAndFullPager && widgetCataloguePage) {
      setPage(Number(widgetCataloguePage - 1));
    }
  }, [widgetCataloguePage, integrationAndFullPager, setPage]);

  useEffect(() => {
    if (isSetPagerOrCataloguePage) {
      if (notIntegrationAndFullPager) {
        toCategory({
          pageNumber: cataloguePage + 1,
          categoryId,
        });
      } else if (integrationAndFullPager) {
        toCataloguePage({
          pageNumber: cataloguePage + 1,
          categoryId,
          subcategoryId,
        });
      }
    }
  }, [
    cataloguePage,
    isSetPager,
    notIntegrationAndFullPager,
    integrationAndFullPager,
    isSetPagerOrCataloguePage,
    toCategory,
    toCataloguePage,
  ]);

  let title = null;
  let shortDescription = null;

  if (!categoryId) {
    title = courseNameSearchText
      ? `${t('weblink:resultsFor')}: ${courseNameSearchText}`
      : `${t('weblink:allCourses')}`;
  } else if (!categoriesResponseIsLoading && categoriesResponse) {
    ({ name: title, shortDescription } = _.first(
      _.map('node', categoriesResponse.categories.edges),
    ));
    if (courseNameSearchText) {
      title = title.concat(
        ' ',
        `${t('weblink:resultsFor')}: ${courseNameSearchText}`,
      );
    }
  }

  useEffect(() => {
    if (!storeDetails) return;

    setBrandedPageTitle(['Catalog', title], storeDetails, !integration);
    captureEvent(new PageViewEvent());
  }, [integration, storeDetails, title, captureEvent]);

  return (
    <>
      {!integration && (
        <>
          <div className="course-overview">
            <PageTitle
              className="detail"
              pageTitle={title}
              shortDescription={shortDescription}
            >
              <Breadcrumb
                categoryId={categoryId}
                subcategoryId={subcategoryId}
                filtered={!!courseNameSearchText}
              />
            </PageTitle>
          </div>
          <HorizontalSpacer type="double" />
        </>
      )}
      <article>
        <Container
          className={`catalogue ${integration ? 'catalogue-integration' : ''}`}
        >
          {showSearchBar && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ flex: '1' }}>
                <CatalogueFilterBar loading={categoriesResponseIsLoading} />
              </span>
              <span style={{ marginLeft: '1em', padding: '10px 0' }}>
                <CategoryDropdown />
              </span>
            </div>
          )}
          {getCatalogueIsLoading && !products.length ? (
            <CatalogueView.Loading />
          ) : (
            <CatalogueView
              onSelect={({ name, code, __typename, id }) => {
                setSearchBarText();
                if (__typename === 'Course') {
                  toCourseDetail({
                    categoryId,
                    subcategoryId,
                    courseCode: code,
                    courseName: name,
                  });
                } else if (__typename === 'LearningPath') {
                  toPathDetail({
                    categoryId,
                    subcategoryId,
                    pathId: id,
                    pathName: name,
                  });
                }
              }}
              catalogueItems={toJS(products)}
              setSearchBarText={setSearchBarText}
              categoryId={categoryId}
              subcategoryId={subcategoryId}
              displayPrices={!hidePrices && cardOptions.showPrice}
              courseCustomFieldDefinitions={courseCustomFieldDefinitions}
              pathCustomFieldDefinitions={pathCustomFieldDefinitions}
              showDescription={cardOptions.showDescription}
              showCategory={cardOptions.showCategory}
              showFeaturedLabel={cardOptions.showFeaturedLabel}
              cardLinkBuilder={cardLinkBuilder}
            />
          )}
          {!getCatalogueIsLoading && !products.length ? (
            <Notice
              body={t('weblink:noCoursesAvailable')}
              icon="glyphicon glyphicon-info-sign"
            />
          ) : null}
          <div className="load-section-show-more">
            <Pager
              integration={integration}
              currentPage={cataloguePage + 1}
              totalPages={numberOfPages}
              onSelectPage={page => setPage(page - 1)}
              disabled={getCatalogueIsLoading}
              label={t('weblink:showMore')}
              onClick={showMore}
              pagerType={integration ? widgetPagerType : cataloguePagerType}
              hasMoreProducts={hasMoreProducts}
            />
          </div>
        </Container>
      </article>
    </>
  );
};

Catalogue.defaultProps = {
  integration: false,
  categoryId: null,
  type: null,
  courseNameSearchText: null,
  subcategoryId: null,
  widgetPagerType: PAGING.TYPE,
  pageNumber: null,
  widgetCataloguePage: null,
  showSearchBar: false,
  pageSize: PAGING.COURSE_LISTING,
  locations: [],
  placesRemaining: [],
  fromDate: null,
  toDate: null,
  categories: [],
  learningTags: [],
  customFieldFilters: [],
  cardOptions: {
    showDescription: false,
    showCategory: true,
    showFeaturedLabel: true,
    showPrice: true,
  },
  cardLinkBuilder: null,
};

Catalogue.propTypes = {
  t: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  type: PropTypes.string,
  courseNameSearchText: PropTypes.string,
  subcategoryId: PropTypes.string,
  integration: PropTypes.bool,
  widgetPagerType: PropTypes.string,
  pageNumber: PropTypes.string,
  widgetCataloguePage: PropTypes.string,
  showSearchBar: PropTypes.bool,
  pageSize: PropTypes.number,
  locations: PropTypes.arrayOf(PropTypes.string),
  placesRemaining: PropTypes.arrayOf(
    PropTypes.shape({
      operation: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  learningTags: PropTypes.arrayOf(PropTypes.string),
  customFieldFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      operation: PropTypes.string.isRequired,
      value: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  cardOptions: PropTypes.shape({
    showDescription: PropTypes.bool.isRequired,
    showCategory: PropTypes.bool.isRequired,
    showFeaturedLabel: PropTypes.bool.isRequired,
    showPrice: PropTypes.bool.isRequired,
  }),
  cardLinkBuilder: PropTypes.func,
};

export default withTranslation()(
  withIntegration(
    inject(
      STORE_NAVIGATION,
      STORE_CATALOGUE,
      STORE_STORE,
      STORE_ANALYTICS,
    )(observer(Catalogue)),
  ),
);
