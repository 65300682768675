import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingBar } from '@administrate/piston';
import { Container, SafeHTMLDisplay } from '@administrate/piston-ux';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FieldLabel from '@administrate/piston/dist/atoms/FieldLabel/FieldLabel';

import { STORES } from '../../constants';
import inject from '../inject';
import PageTitle from '../../components/PageTitle';
import GiftVouchersCard from './GiftVouchersCard';
import GiftVoucherBalance from './GiftVoucherBalance';

const { STORE_DETAILS, STORE_STORE } = STORES;

const GiftVouchers = ({
  t,
  [STORE_STORE]: { giftVoucherConfigDescription },
}) => {
  return (
    <div className="GiftVouchers withpanel">
      <Helmet
        meta={[
          {
            name: 'Description',
            content: 'Gift Vouchers',
          },
        ]}
      />
      <StickyContainer>
        <div className="GiftVouchers_loading-bar">
          <Sticky>
            {({ style }) => (
              <div style={{ ...style, zIndex: 2 }}>
                <LoadingBar isLoading={false} />
              </div>
            )}
          </Sticky>
        </div>
        <PageTitle>
          <Row>
            <Col sm={7} md={8}>
              <div>
                <h1>{t('weblink:giftVouchers')}</h1>
              </div>
            </Col>
            <Col sm={5} md={4}>
              <div className="GiftVouchersCard-information-panel show-desktop">
                <Sticky topOffset={26}>
                  {({ style, isSticky }) => (
                    <div
                      style={{
                        ...style,
                        marginTop: isSticky ? '50px' : null,
                      }}
                    >
                      <GiftVouchersCard />
                    </div>
                  )}
                </Sticky>
              </div>
            </Col>
          </Row>
        </PageTitle>
        <Container>
          <div className="description">
            <SafeHTMLDisplay html={giftVoucherConfigDescription} />
          </div>
          <div className="existing-voucher">
            <Row>
              <Col sm={12} md={5}>
                <FieldLabel label={t('weblink:giftVouchersCheckBalance')} />
                <GiftVoucherBalance />
              </Col>
            </Row>
          </div>
        </Container>
      </StickyContainer>
    </div>
  );
};

export default withTranslation()(
  inject(STORE_DETAILS, STORE_STORE)(observer(GiftVouchers)),
);

GiftVouchers.propTypes = {
  t: PropTypes.func.isRequired,
};
