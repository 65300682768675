import {
  action,
  decorate,
  observable,
  runInAction,
  computed,
  reaction,
} from 'mobx';
import moment from 'moment';
import { get as _get } from 'lodash';
import fp from 'lodash/fp';

import Event from './Event';
import queries from '../queries';
import BaseStore from './baseStore';
import { momentToLocalIsoWithoutOffset } from '../utils/formatDateTime';
import { SearchEvent } from '../analytics';

const {
  event: { getEventByFilter },
  location: { getLocations },
} = queries;

const baseFilter = {
  criteriaFormatter: value => value,
  isValid: () => true,
};

const dateFilter = {
  criteriaFormatter: value => moment(value).utc(true),
  isValid: value => value.isValid(),
};

const eventListFilters = {
  code: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'courseCode',
      operation: 'eq',
      value: decodeURI(value),
    }),
  },
  location: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'locationName',
      operation: 'like',
      value: `%${value}%`,
    }),
  },
  fromDate: {
    ...dateFilter,
    criteriaFormatter: value =>
      value !== null
        ? dateFilter.criteriaFormatter(value).startOf('day')
        : null,
    gqlFilterBuilder: value => ({
      field: 'localStart',
      operation: 'ge',
      value: momentToLocalIsoWithoutOffset(value),
    }),
  },
  toDate: {
    ...dateFilter,
    criteriaFormatter: value =>
      value !== null ? dateFilter.criteriaFormatter(value).endOf('day') : null,
    gqlFilterBuilder: value => ({
      field: 'localStart',
      operation: 'le',
      value: momentToLocalIsoWithoutOffset(value),
    }),
  },
  category: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'category',
      operation: 'eq',
      value,
    }),
  },
  daysOfWeek: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'dayOfWeek',
      operation: 'in',
      values: value,
    }),
  },
  locations: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'locationId',
      operation: 'in',
      values: value,
    }),
    isValid: value => value && value.length > 0,
  },
  minimumPlacesRemaining: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'remainingPlaces',
      operation: 'ge',
      value: value.toString(),
    }),
  },
  fromTimeOfDay: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'sessionStartTime',
      operation: 'ge',
      value,
    }),
  },
  toTimeOfDay: {
    ...baseFilter,
    gqlFilterBuilder: value => ({
      field: 'sessionEndTime',
      operation: 'le',
      value,
    }),
  },
};

class DetailsStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.filterbarCriteria = {};
      this.order = [{ field: 'locationName', direction: 'asc' }];
      this.eventGridData = [];
      this.eventGridPagination = 10;
      this.eventGridPage = 0;
      this.eventGridTotalPages = 1;
      this.eventGridIsLoading = false;

      this.courseCodeIsLoading = false;
      this.courseCodeOptions = [];
      this.courseCodeText = null;
      this.selectedCourse = null;

      this.allLocationsIsLoading = false;
      this.allLocations = [];

      reaction(
        () => this.courseCodeText,
        () => this.fetchCourses(),
        {
          delay: 1000,
        },
      );
    });
  }

  initialize = async () => {
    await this.fetchAllLocations();
  };

  updateEventGridData = action(() => {
    this.eventGridIsLoading = true;
    const filters = Object.entries(this.filterbarCriteria).reduce(
      (prev, curr) => {
        const [filterName, filterValue] = curr;
        const { isValid, gqlFilterBuilder } = eventListFilters[filterName];
        if (!filterValue || !isValid(filterValue)) {
          return prev;
        }

        return [...prev, gqlFilterBuilder(filterValue)];
      },
      [],
    );
    this.apolloClient
      .query({
        query: getEventByFilter,
        variables: {
          filters,
          first: this.eventGridPagination,
          offset: this.eventGridPage * this.eventGridPagination,
          orderBy: this.order,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      .then(
        action(
          ({
            data: {
              events: {
                edges: events,
                pageInfo: { totalRecords },
              },
            },
          }) => {
            this.eventGridData = events.map(Event.fromAPI);
            this.eventGridTotalPages = Math.ceil(
              totalRecords / this.eventGridPagination,
            );
            this.eventGridIsLoading = false;
            this.rootStore.analyticsStore.captureEvent(
              SearchEvent.fromEventSearch({
                filters,
                resultCount: totalRecords,
              }),
            );
          },
        ),
      );
  });

  updateOrder = action(order => {
    if (Array.isArray(order)) {
      this.order = order;
    } else {
      this.order = [order];
    }

    // If both title and name are specified, remove title
    if (
      this.order.find(e => e.field === 'title') &&
      this.order.find(e => e.field === 'name')
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'WebLink: Order by title is deprecated. Name will be used instead.',
      );
      const titleIndex = this.order.findIndex(e => e.field === 'title');
      this.order.splice(titleIndex, 1);
    } else if (this.order.find(e => e.field === 'title')) {
      // Change any use of title without name to name
      // eslint-disable-next-line no-console
      console.warn('WebLink: Order by title is deprecated. Use name instead.');
      const titleIndex = this.order.findIndex(e => e.field === 'title');
      this.order[titleIndex].field = 'name';
    }
  });

  updateFilterBarCriteria = action(value => {
    this.filterbarCriteria = Object.entries(value).reduce(
      (prev, curr) => {
        const [filterName, filterValue] = curr;
        return {
          ...prev,
          [filterName]: eventListFilters[filterName].criteriaFormatter(
            filterValue,
          ),
        };
      },
      { ...this.filterbarCriteria },
    );
  });

  resetEventGridPage = () => {
    this.setEventGridPage(0);
  };

  resetEventGridFilters = action(() => {
    this.filterbarCriteria = {};
    this.resetEventGridPage();
  });

  setEventGridPage = action(page => {
    this.eventGridPage = page;
    this.updateEventGridData();
  });

  async getCourseByFilter(variables) {
    return this.apolloClient.query({
      query: queries.course.getCourseByFilter,
      fetchPolicy: 'no-cache',
      variables,
    });
  }

  getFetchCourseVariables(code, page) {
    const filters = [];
    if (this.courseCodeText) {
      filters.push({
        field: 'name',
        value: `%${this.courseCodeText}%`,
        operation: 'like',
      });
    }
    if (code) {
      filters.push({
        field: 'code',
        value: decodeURI(code),
        operation: 'eq',
      });
    }

    return {
      filters,
      optionsFirst: 100,
      optionsOffset: page === 0 ? page : page * 100,
    };
  }

  fetchCourses = action(async ({ code } = {}) => {
    this.courseCodeIsLoading = true;

    const {
      data: {
        courses: { edges },
      },
    } = await this.getCourseByFilter(this.getFetchCourseVariables(code, 0));

    this.courseCodeOptions = edges;

    if (!code) {
      this.courseCodeIsLoading = false;
      return;
    }

    if (!edges.length) {
      this.courseCodeIsLoading = false;
      return;
    }

    const edge = edges[0];
    const { pageInfo } = edge.node.options;

    if (!pageInfo.hasNextPage) {
      this.setSelectedCourse(edge);
      this.courseCodeIsLoading = false;
      return;
    }

    const allProductOptions = await this.paginateThroughProductOptions(
      code,
      pageInfo,
    );

    edge.node.options.edges = allProductOptions;
    this.setSelectedCourse(edge);
    this.courseCodeIsLoading = false;
  });

  async paginateThroughProductOptions(courseCode, pageInfo) {
    const pageCount = Math.ceil(pageInfo.totalRecords / 100);

    const promises = Array.from({ length: pageCount }).map((_, pageIndex) =>
      this.getCourseByFilter(
        this.getFetchCourseVariables(courseCode, pageIndex),
      ),
    );
    const values = await Promise.all(promises);

    return values.flatMap(
      queryData => queryData.data.courses.edges[0].node.options.edges,
    );
  }

  setCourseCodeText = action(courseCodeText => {
    if (courseCodeText !== this.courseCodeText) {
      this.courseCodeText = courseCodeText;
    }
  });

  setSelectedCourse = action(selectedCourse => {
    this.selectedCourse = selectedCourse;
  });

  fetchAllLocations = action(async () => {
    this.allLocationsIsLoading = true;
    const {
      data: { locations },
    } = await this.apolloClient.query({
      query: getLocations,
    });
    runInAction(() => {
      this.allLocations = locations.edges.map(({ node: { name, id } }) => ({
        name,
        id,
      }));
      this.allLocationsIsLoading = false;
    });
  });

  get courseLocations() {
    const selectableEvents = _get(
      this.selectedCourse,
      'node.options.edges',
      [],
    );
    return selectableEvents.length !== 0
      ? fp.flow(
          fp.map(({ node }) => node),
          fp.uniqBy('location.name'),
          fp.map(event => ({
            name: event.location.name,
            id: event.location.id,
          })),
          fp.filter(location => location !== 'Undecided'),
          fp.sortBy('name'),
        )(selectableEvents)
      : [];
  }

  get locations() {
    return this.selectedCourse ? this.courseLocations : this.allLocations;
  }
}

decorate(DetailsStore, {
  filterbarCriteria: observable,
  order: observable,
  eventGridData: observable,
  eventGridPage: observable,
  eventGridPagination: observable,
  eventGridIsLoading: observable,

  courseCodeIsLoading: observable,
  courseCodeOptions: observable,
  courseCodeText: observable,
  selectedCourse: observable,

  courseLocations: computed,
  allLocationsIsLoading: observable,
  allLocations: observable,
  locations: computed,
});

export default DetailsStore;
