import { decorate, observable, action, computed, runInAction } from 'mobx';

import { backOff } from 'exponential-backoff';
import BaseStore from './baseStore';
import queries from '../queries';
import portalStore from './portalStore';
import configStore from './configStore';
import {
  createStripePaymentIntent,
  placeStripeOrder,
} from '../queries/payments';
import parsePaymentError from '../utils/parsePaymentError';
import { CartEvent } from '../analytics';

class PaymentError extends Error {
  constructor(code) {
    super(code);
    this.displayMessage = parsePaymentError(code);
  }
}

export class ProgressList {
  constructor({ steps, skipper, checkoutStore }) {
    runInAction(() => {
      this.checkoutStore = checkoutStore;
      this.defaultSteps = steps;

      this.freeSteps = steps.filter(
        (item, index) => !skipper.free.includes(index),
      );
      this.customerSteps = steps.filter(
        (item, index) => !skipper.customer.includes(index),
      );
      this.freeCustomerSteps = steps.filter(
        (item, index) =>
          !skipper.customer.includes(index) && !skipper.free.includes(index),
      );
      this.orderRetrievalSteps = steps.filter(
        (item, index) => !skipper.retrieval.includes(index),
      );
      this.orderReviewSteps = steps
        .filter((item, index) => !skipper.review.includes(index))
        .concat({
          label: 'weblink:checkoutReview',
          name: 'review',
        });
      this.noLearnerDetailsSteps = steps.filter(
        (item, index) => !skipper.noLearnerDetails.includes(index),
      );
      this.freeNoLearnerDetailsSteps = steps.filter(
        (item, index) =>
          ![...skipper.noLearnerDetails, ...skipper.free].includes(index),
      );

      this.currentIndex = 0;
    });
  }

  get currentArray() {
    if (this.checkoutStore.showOrderRetrievalPath) {
      return this.orderRetrievalSteps;
    }
    if (this.checkoutStore.showOrderReviewPath) {
      return this.orderReviewSteps;
    }
    if (this.checkoutStore.showFreeCheckoutPath) {
      if (this.checkoutStore.hasGiftVoucher) {
        return this.freeSteps;
      }
      return this.checkoutStore.requireLearnerDetails
        ? this.freeSteps
        : this.freeNoLearnerDetailsSteps;
    }
    if (
      !this.checkoutStore.requireLearnerDetails &&
      this.checkoutStore.items.length &&
      !this.checkoutStore.hasGiftVoucher
    ) {
      return this.noLearnerDetailsSteps;
    }
    return this.defaultSteps;
  }

  get currentStep() {
    return {
      item: this.currentArray[this.currentIndex],
      index: this.currentIndex,
    };
  }

  setStep = action('setStep', index => {
    if (index >= 0 && this.currentArray.length > index) {
      this.currentIndex = index;
      this.checkoutStore.currentStepName = this.currentStep.item.name;
    }
  });

  next = action('next', () => {
    this.setStep(this.currentIndex + 1);
  });

  previous = action('previous', () => {
    this.setStep(this.currentIndex - 1);
  });

  setCompleted = action('setCompleted', () => {
    const completedStepIndex = this.currentArray.findIndex(
      ({ name }) => name === 'completed',
    );
    this.setStep(completedStepIndex);
  });
}

decorate(ProgressList, {
  currentIndex: observable,
  currentStep: computed,
  currentArray: computed,
});

class CheckoutStore extends BaseStore {
  get isLoading() {
    return this.loader.isLoading;
  }

  initialize = action('checkoutStore initialize', async () => {
    this.currentItemIndex = 0;
    this.customerFilledIn = false;
    this.showIncompleteNotice = false;
    this.isCompletePaymentLoading = false;

    const steps = [
      {
        label: 'weblink:checkoutBooker',
        name: 'buyer_info',
      },
      {
        label: 'weblink:checkoutLearners',
        name: 'learner_info',
      },
      {
        label: 'weblink:checkoutPayment',
        name: 'payment',
      },
      {
        label: 'weblink:checkoutComplete',
        name: 'completed',
      },
    ];

    const skipper = {
      free: [2],
      customer: [0, 1],
      retrieval: [0, 1],
      review: [2, 3],
      noLearnerDetails: [1],
    };

    this.progress = new ProgressList({
      steps,
      skipper,
      checkoutStore: this,
    });
    this.currentStepName = this.progress.currentStep.item.name;
  });

  changeStep = action('changeStep', step => {
    this.progress.setStep(step);
  });

  changeStepForward = action('changeStepForward', () => {
    this.progress.next();
  });

  changeStepBackward = action('changeStepBackward', () => {
    this.progress.previous();
  });

  resetProgress = action('resetProgress', () => {
    this.progress.setStep(0);
  });

  setIsCompletePaymentLoading = action('setIsCompletePaymentLoading', value => {
    this.isCompletePaymentLoading = value;
  });

  get hasGiftVoucher() {
    return this.rootStore.cartStore.cart.items.some(item => item.isGiftVoucher);
  }

  get items() {
    return this.rootStore.cartStore.cart.items;
  }

  get itemsRequiringLearnerDetails() {
    return this.requireLearnerDetails
      ? this.items
      : this.items.filter(item => item.isGiftVoucher);
  }

  get requireLearnerDetails() {
    return this.rootStore.storeStore.requireLearnerDetails;
  }

  get showFreeCheckoutPath() {
    if (this.currentStepName === 'payment') {
      return false;
    }
    if (this.rootStore.reviewOrderStore.isEmpty) {
      return this.rootStore.cartStore.cart.isFree;
    }
    return this.rootStore.reviewOrderStore.isFree;
  }

  get customerBillingAddress() {
    return this.rootStore.cartStore.cart.buyerDetails.billingAddress;
  }

  get emailsByProduct() {
    const emailsByProduct = [];
    this.itemsRequiringLearnerDetails.forEach(
      ({ courseId, eventId, pathId, learners }) => {
        const productId = eventId || courseId || pathId;
        const existing = emailsByProduct[productId] || [];
        emailsByProduct[productId] = [
          ...existing,
          ...(learners || [])
            .map(({ email }) => email)
            .filter(email => !!email),
        ];
      },
    );

    return emailsByProduct;
  }

  get hasDuplicateEmails() {
    return Object.values(this.emailsByProduct).some(
      emails => new Set(emails).size !== emails.length,
    );
  }

  get showOrderRetrievalPath() {
    return this.rootStore.cartStore.useRetrievedCart;
  }

  get showOrderReviewPath() {
    return (
      this.rootStore.cartStore.cart.requiresReviewBeforePurchase ||
      this.rootStore.reviewOrderStore.requiresReviewBeforePurchase
    );
  }

  get checkoutProgressPath() {
    return this.progress.currentArray;
  }

  changeIsLoading = action('changeIsLoading', value => {
    if (value) {
      this.loader.start();
    } else {
      this.loader.stop();
    }
  });

  clearNotice = action('clearNotice', () => {
    this.showIncompleteNotice = false;
  });

  changeCanCompleteOrder = action('changeCanCompleteOrder', value => {
    if (this.canCompleteOrder !== value) {
      this.canCompleteOrder = value;
    }
  });

  populateReviewStore = action(
    'populateReviewStore',
    (cart = this.rootStore.cartStore.cart) => {
      this.rootStore.reviewOrderStore.setPromotionalCode(cart.promotionalCode);
      this.rootStore.reviewOrderStore.setGiftVoucherApplications(
        cart.giftVoucherApplications,
      );
      this.rootStore.reviewOrderStore.setPrice(cart.price);
      this.rootStore.reviewOrderStore.setCartContent(cart.items);
      this.rootStore.reviewOrderStore.setCustomer(cart.buyerDetails);
      this.rootStore.reviewOrderStore.setRequiresReviewBeforePurchase(
        cart.requiresReviewBeforePurchase,
      );
    },
  );

  updateCartBuyerAndChangeStep = action(
    'updateCartBuyerAndChangeStep',
    async () => {
      this.rootStore.cartStore.changeIsError(false);
      this.clearNotice();
      this.changeIsLoading(true);

      const {
        firstName,
        lastName,
        email,
        company,
        billingAddress,
        pointOfSaleOrderFields,
      } = this.rootStore.cartStore.cart.buyerDetails;

      const includeBillingAddress = this.rootStore.storeStore
        .requireBookerBillingAddress;
      await this.rootStore.cartStore.updateCartBuyerInformation({
        firstName,
        lastName,
        email,
        company,
        billingAddress: includeBillingAddress ? billingAddress : undefined,
        pointOfSaleOrderFields,
      });

      if (this.rootStore.cartStore.isError) {
        this.rootStore.cartStore.changeIsError(true);
        this.changeIsLoading(false);
        return;
      }
      if (
        !this.rootStore.cartStore.cart.items ||
        !this.rootStore.cartStore.cart.items.length
      ) {
        this.rootStore.navigationStore.toCatalogue();
        this.changeIsLoading(false);
        return;
      }

      this.rootStore.analyticsStore.captureEvent(
        CartEvent.fromBookerDetailsSubmitted({
          cart: this.rootStore.cartStore.cart,
        }),
      );

      if (
        !this.requireLearnerDetails &&
        !this.hasGiftVoucher &&
        this.rootStore.cartStore.cart.isFree
      ) {
        await this.completeOrderAndChangeStep();
      }
      this.changeStepForward();
      this.changeIsLoading(false);
    },
  );

  updateCustomer = action('updateCustomer', (property, value) => {
    this.rootStore.cartStore.cart.buyerDetails[property] = value;
  });

  updateCustomerEmail = action('updateCustomerEmail', (value, confirmEmail) => {
    if (!confirmEmail) {
      // If Portal confirmEmails setting is off
      // Populate field with email value so that validation for confirmEmail always return true
      this.rootStore.cartStore.cart.buyerDetails.confirmedEmail = value;
    }
    this.rootStore.cartStore.cart.buyerDetails.email = value;
  });

  updateCurrentItemIndex = action('updateCurrentItemIndex', value => {
    this.currentItemIndex = value;
  });

  checkDataCompletion = action('checkDataCompletion', () => {
    const dataComplete =
      this.rootStore.cartStore.cart.buyerDetails.email &&
      this.rootStore.cartStore.cart.buyerDetails.firstName &&
      this.rootStore.cartStore.cart.buyerDetails.lastName;

    if (!dataComplete) this.showIncompleteNotice = true;

    return dataComplete;
  });

  get showNotice() {
    return this.rootStore.cartStore.isError || this.isFinished;
  }

  finishCheckout = action('finishCheckout', () => {
    this.rootStore.cartStore.resetCart();
    this.initialize();
  });

  addLearnersAndChangeStep = action('addLearnersAndChangeStep', async () => {
    this.rootStore.cartStore.changeIsError(false);
    this.changeIsLoading(true);

    if (this.requireLearnerDetails) {
      // We need to have the Learner Details checkout step when we are buying Gift Vouchers
      // but if this flag is set to false we should not be updating any Learners here.
      // Ideally the Gift Voucher and Learner Details would be seperate steps.
      await this.rootStore.cartStore.addLearners();
    }
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromLearnerDetailsSubmitted({
        cart: this.rootStore.cartStore.cart,
      }),
    );
    if (!this.rootStore.cartStore.isError) {
      await this.rootStore.cartStore.prepareCartForCheckout();
      if (!this.rootStore.cartStore.isError) {
        if (
          this.showFreeCheckoutPath &&
          this.rootStore.cartStore.cart.state === 'created'
        ) {
          this.completeOrderAndChangeStep();
        } else {
          this.changeStepForward();
          if (this.rootStore.cartStore.cart.state === 'orderReview') {
            this.finishCheckout();
          }
        }
      }
    }
    this.changeIsLoading(false);
  });

  _validatePaymentProviderConfigured = providerTypeName => {
    const paymentProvider = this.rootStore.storeStore.storeDetails.paymentMethods.find(
      paymentMethod => paymentMethod.__typename === providerTypeName,
    );

    if (!paymentProvider) {
      throw new Error(
        `Attempting to checkout with ${providerTypeName} but not configured for this Portal`,
      );
    }

    return paymentProvider;
  };

  checkoutWithStripe = action(
    'checkoutWithStripe',
    async (stripe, cardElement) => {
      try {
        this.rootStore.cartStore.changeIsError(false);
        this.rootStore.cartStore.setCheckoutError(null);

        const paymentMethodData = {
          type: 'card',
          card: cardElement,
        };
        if (
          this.customerBillingAddress &&
          this.customerBillingAddress.postcode
        ) {
          const {
            streetAddress1,
            streetAddress2,
            town,
            postcode,
            country,
          } = this.customerBillingAddress;
          paymentMethodData.billing_details = {
            address: {
              line1: streetAddress1,
              line2: streetAddress2,
              city: town,
              postal_code: postcode,
              country: country ? country.code : null,
            },
          };
        }

        const {
          paymentMethod,
          error: paymentMethodError,
        } = await stripe.createPaymentMethod(paymentMethodData);

        if (paymentMethodError) {
          // eslint-disable-next-line no-console
          console.error(
            'Failed to create Stripe payment method',
            paymentMethodError,
          );
          throw new PaymentError(
            paymentMethodError.decline_code || paymentMethodError.code,
          );
        }

        this.changeIsLoading(true);

        const { data } = await this.apolloClient.mutate({
          mutation: createStripePaymentIntent,
          variables: {
            input: {
              cartId: await this.rootStore.cartStore.cartId,
              stripeDetails: {
                paymentMethod: paymentMethod.id,
              },
            },
          },
        });

        const {
          cart,
          paymentIntent,
          errors: createPaymentIntentErrors,
        } = data.stripe.createPaymentIntent;

        if (createPaymentIntentErrors.length) {
          throw new PaymentError(createPaymentIntentErrors[0].value);
        }

        this.rootStore.cartStore.updateCartWithAPIResponse(cart);

        if (paymentIntent.requiresAction) {
          await this._handleStripeRequiresAction(
            stripe,
            paymentIntent.clientSecret,
          );
        } else {
          await this._completeStripeOrder(paymentIntent.id);
        }

        this.changeIsLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.rootStore.cartStore.setCheckoutError(error.displayMessage);
        this.rootStore.cartStore.changeIsError(true);
      }

      if (this.rootStore.cartStore.isError) this.changeCanCompleteOrder(false);
      this.changeIsLoading(false);
    },
  );

  _handleStripeRequiresAction = async (stripe, clientSecret) => {
    const { error, paymentIntent } = await stripe.handleCardAction(
      clientSecret,
    );

    if (error) {
      throw new PaymentError(error.decline_code || error.code);
    }

    await this._completeStripeOrder(paymentIntent.id);
  };

  _completeStripeOrder = async paymentIntentId => {
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromPaymentDetailsSubmitted({
        cart: this.rootStore.cartStore.cart,
        paymentType: 'card',
      }),
    );
    const {
      data: {
        stripe: { placeOrder: placeOrderResponse },
      },
    } = await this.apolloClient.mutate({
      mutation: placeStripeOrder,
      variables: {
        input: {
          cartId: await this.rootStore.cartStore.cartId,
          stripeDetails: {
            paymentIntentId,
          },
        },
      },
    });

    if (placeOrderResponse.errors.length) {
      throw new PaymentError(placeOrderResponse.errors[0].value);
    }
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromCheckoutComplete({
        cart: this.rootStore.cartStore.cart,
        paymentType: 'card',
      }),
    );
    this.finishCheckout();
  };

  getConvergeToken = action('getConvergeToken', async () => {
    const convergePaymentMethod = this._validatePaymentProviderConfigured(
      'Converge',
    );
    const { grandTotal } = await this.rootStore.cartStore.cart.price;

    const {
      data: {
        createPaymentSession: { token },
      },
    } = await this.apolloClient.mutate({
      mutation: queries.cart.createPaymentSession,
      variables: {
        input: {
          paymentProviderId: convergePaymentMethod.id,
          amount: parseFloat(grandTotal),
        },
      },
    });

    return token;
  });

  checkoutWithConverge = action('checkoutWithConverge', async values => {
    try {
      this.rootStore.cartStore.changeIsError(false);
      this.rootStore.cartStore.setCheckoutError(null);
      this.changeIsLoading(true);

      const { cardNumber, expiration, cvv2, address1, zip } = values;
      if (!cardNumber || !expiration || !cvv2 || !address1 || !zip) {
        this.rootStore.cartStore.setError('Some card details are missing');
        this.rootStore.cartStore.changeIsError(true);
        return;
      }

      const token = await this.getConvergeToken();

      const onApproval = async response => {
        await this.completeOrderAndChangeStep({
          paymentDetails: {
            paymentProvider: 'converge',
            convergeDetails: { pendingTransactionId: response.ssl_txn_id },
          },
        });
        this.changeIsLoading(false);
      };

      const onError = response => {
        console.error('Error', JSON.stringify(response)); // eslint-disable-line no-console

        this.rootStore.cartStore.changeIsError(true);
        this.changeIsLoading(false);
      };
      const onDeclined = response => {
        console.error('Declined', JSON.stringify(response)); // eslint-disable-line no-console

        this.rootStore.cartStore.setCheckoutError(
          'weblink:transactionDeclined',
        );
        this.rootStore.cartStore.changeIsError(true);
        this.changeIsLoading(false);
      };

      const [month, year] = expiration.split('/');

      window.ConvergeEmbeddedPayment.pay(
        {
          ssl_txn_auth_token: token,
          ssl_card_number: cardNumber,
          ssl_exp_date: `${month}${year}`,
          ssl_cvv2cvc2: cvv2,
          ssl_avs_address: address1,
          ssl_avs_zip: zip,
        },
        { onError, onDeclined, onApproval },
      );
    } catch (ex) {
      this.rootStore.cartStore.changeIsError(true);
    }
  });

  checkoutWithMultiSafePay = action(
    'checkoutWithMultiSafePay',
    async paymentMethod =>
      this._checkoutWithRedirect({
        mutation: queries.cart.startMultisafepayCheckout,
        mutationErrorPath: 'multisafepay.startCheckout.errors',
        redirectUrlExtractor: data => data.multisafepay.startCheckout.url,
        additionalInputFields: { paymentMethod },
      }),
  );

  checkoutWithWorldpay = action('checkoutWithWorldpay', async () =>
    this._checkoutWithRedirect({
      mutation: queries.cart.startWorldpayCheckout,
      mutationErrorPath: 'worldpay.startCheckout.errors',
      redirectUrlExtractor: data => data.worldpay.startCheckout.url,
    }),
  );

  _checkoutWithRedirect = async ({
    mutation,
    mutationErrorPath,
    redirectUrlExtractor,
    additionalInputFields = {},
  }) => {
    this.changeIsLoading(true);
    const { embedSite, postCheckoutRedirectUrl } = portalStore;
    const { locale } = configStore;

    try {
      const { data } = await this.mutate({
        mutation,
        variables: {
          input: {
            cartId: await this.rootStore.cartStore.cartId,
            ...(embedSite ? { originalSite: embedSite } : {}),
            ...(postCheckoutRedirectUrl ? { postCheckoutRedirectUrl } : {}),
            locale,
            ...additionalInputFields,
          },
        },
        checkForMutationErrors: mutationErrorPath,
      });
      const url = redirectUrlExtractor(data);
      if (url) {
        const cancelUrl = new URL(window.location.href);
        cancelUrl.pathname = '/payments/redirect/cancel';
        window.history.replaceState({}, '', cancelUrl);
        window.location = url;
      }
    } catch (error) {
      this.rootStore.cartStore.changeIsError(true);
      this.rootStore.cartStore.setError(error);
      this.changeIsLoading(false);
    }
  };

  waitForRedirectCheckoutComplete = action(
    'waitForRedirectCheckoutComplete',
    async () => {
      this.rootStore.analyticsStore.captureEvent(
        CartEvent.fromPaymentDetailsSubmitted({
          cart: this.rootStore.cartStore.cart,
          paymentType: 'card',
        }),
      );
      this.rootStore.cartStore.changeIsLoading(true);

      const verifyCartIsNoLongerPending = async () => {
        const cart = await this.rootStore.cartStore.getOriginalCart();
        if (cart.state === 'pending') {
          throw new Error(
            'Cart with successful payment has not been progressed to Won',
          );
        }
        return cart;
      };

      const updatedCart = await backOff(verifyCartIsNoLongerPending, {
        numOfAttempts: 1000,
      });
      this.rootStore.cartStore.setCart(updatedCart);
      this.rootStore.analyticsStore.captureEvent(
        CartEvent.fromCheckoutComplete({
          cart: this.rootStore.cartStore.cart,
          paymentType: 'card',
        }),
      );
      this.rootStore.cartStore.changeIsLoading(false);
      await this.rootStore.cartStore.resetCart();
    },
  );

  checkoutWithCheck = action('checkoutWithCheck', async () => {
    try {
      this.changeIsLoading(true);
      await this.completeOrderAndChangeStep({
        paymentDetails: { paymentProvider: 'check' },
      });
    } catch (ex) {
      this.rootStore.cartStore.changeIsError(true);
    }
    this.changeIsLoading(false);
  });

  checkoutWithInvoice = action(
    'checkoutWithInvoice',
    async (invoiceDetails, paymentAttributes) => {
      try {
        this.changeIsLoading(true);
        await this.completeOrderAndChangeStep({
          paymentDetails: {
            paymentProvider: 'invoice',
            invoiceDetails,
            paymentAttributes,
          },
        });
      } catch (ex) {
        this.rootStore.cartStore.changeIsError(true);
      }
      this.changeIsLoading(false);
    },
  );

  completeOrderAndChangeStep = action(
    'completeOrderAndChangeStep',
    async ({ paymentDetails } = {}) => {
      const paymentType = paymentDetails?.paymentProvider;
      this.rootStore.analyticsStore.captureEvent(
        CartEvent.fromPaymentDetailsSubmitted({
          cart: this.rootStore.cartStore.cart,
          paymentType,
        }),
      );
      this.rootStore.cartStore.changeIsError(false);
      this.changeIsLoading(true);
      try {
        const {
          data: {
            cart: { placeOrder: placeOrderResp },
          },
        } = await this.apolloClient.mutate({
          mutation: queries.cart.placeOrder,
          variables: {
            input: {
              ...paymentDetails,
              cartId: await this.rootStore.cartStore.cartId,
            },
          },
        });
        if (placeOrderResp.errors.length) {
          this.rootStore.cartStore.changeIsError(true);
          this.rootStore.cartStore.setError(placeOrderResp.errors);
          return;
        }

        this.rootStore.analyticsStore.captureEvent(
          CartEvent.fromCheckoutComplete({
            cart: this.rootStore.cartStore.cart,
            paymentType,
          }),
        );
        this.finishCheckout();
      } catch (error) {
        this.rootStore.cartStore.changeIsError(true);
        this.rootStore.cartStore.setError(error);
      } finally {
        this.changeIsLoading(false);
      }
    },
  );

  get isFinished() {
    return !!this.rootStore.reviewOrderStore.cartContent.length;
  }
}

decorate(CheckoutStore, {
  customerFilledIn: observable,
  customerBillingAddress: computed,
  currentItemIndex: observable,
  currentStepName: observable,
  showIncompleteNotice: observable,
  canCompleteOrder: observable,
  progress: observable,
  isCompletePaymentLoading: observable,
  isLoading: computed,
  showNotice: computed,
  isFinished: computed,
  checkoutProgressPath: computed,
  showFreeCheckoutPath: computed,
  hasGiftVoucher: computed,
  items: computed,
  itemsRequiringLearnerDetails: computed,
  requireLearnerDetails: computed,
});

export default CheckoutStore;
