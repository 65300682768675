import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from 'react-bootstrap';
import { Button, List, ListCell, ListRow } from '@administrate/piston-ux';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../constants';
import { CartExpiryCountdown } from '../Cart/CartExpiryCountdown';
import { summaryListProp } from './summaryListProp';

export const CompactCartWidget = React.forwardRef(
  (
    {
      cartSummaryList,
      reservationsValidUntil,
      onGoToCartClick,
      hasExpiredItems,
      t,
      ...props
    },
    ref,
  ) => {
    const isEmpty = cartSummaryList.length === 0;
    return (
      <Popover
        bsClass="weblink popover"
        id="compactCartWidget"
        ref={ref}
        {...props}
      >
        <div className="weblink">
          <h2 className="compact-cart-widget-title">
            {isEmpty ? t('weblink:cartEmpty') : t('weblink:myCart')}
          </h2>
          {reservationsValidUntil && (
            <div align="center">
              <CartExpiryCountdown
                t={t}
                reservationsValidUntil={reservationsValidUntil}
                includeModal={false}
              />
            </div>
          )}
          <List extraClass="compact-cart-widget-list">
            {cartSummaryList.map(
              ({ itemId, title, quantity, imageUrl, isGiftVoucher }) => (
                <React.Fragment key={`compactCartWidget-${itemId}`}>
                  {/* We have added a voucher-item class to the list below to be used externally. */}
                  <ListRow
                    gridColumns="2fr 4fr 1fr"
                    extraClass={
                      isGiftVoucher
                        ? 'compact-cart-widget-list-row voucher-item'
                        : 'compact-cart-widget-list-row'
                    }
                  >
                    <ListCell extraClass="compact-cart-widget-list-image">
                      <img
                        src={imageUrl || CATALOG_ITEM_IMAGE_PLACEHOLDER}
                        alt={t('weblink:imagePlaceholder', {
                          elementName: isGiftVoucher
                            ? t('weblink:giftVoucher')
                            : title,
                        })}
                      />
                    </ListCell>
                    <ListCell>
                      {isGiftVoucher ? t('weblink:giftVoucher') : title}
                    </ListCell>
                    <ListCell>x{quantity}</ListCell>
                  </ListRow>
                </React.Fragment>
              ),
            )}
          </List>
          <div align="center">
            {(!isEmpty || hasExpiredItems) && (
              <Button
                onClick={onGoToCartClick}
                label={t('weblink:goToCart')}
                preventDefault
                stopPropagation
                type="primary"
              />
            )}
          </div>
        </div>
      </Popover>
    );
  },
);

CompactCartWidget.defaultProps = {
  reservationsValidUntil: null,
};

CompactCartWidget.propTypes = {
  cartSummaryList: summaryListProp.isRequired,
  reservationsValidUntil: PropTypes.string,
  onGoToCartClick: PropTypes.func.isRequired,
  hasExpiredItems: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
