import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';

export const pathDetails = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  shortDescription: PropTypes.string,
  customFields: MobxPropTypes.observableArray,
  description: PropTypes.string,
});

const courseObjective = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

const eventLocation = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const eventObjective = PropTypes.shape({
  id: PropTypes.string,
  remainingPlaces: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
  timeZoneName: PropTypes.string,
  deliveryMethod: PropTypes.string,
  location: eventLocation,
});

export const learningObjective = PropTypes.shape({
  __typename: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  course: courseObjective,
  event: eventObjective,
});
