import React from 'react';
import { Container } from '@administrate/piston';
import { withTranslation } from 'react-i18next';

export default withTranslation()(({ t }) => (
  <Container>
    <div className="text-center">
      <h1>{t('weblink:pageNotFound')}</h1>
      <p>{t('weblink:pageNotFoundBody')}</p>
    </div>
  </Container>
));
