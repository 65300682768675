import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty as _isEmpty, get as _get } from 'lodash';
import { LabelledInput, InputValue } from '@administrate/piston';
import { Row, Col } from '@administrate/piston-ux';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';

import withCurrency from '../withCurrency';
import STORES from '../../constants/stores';
import inject from '../inject';
import LegacyModal from '../../components/ModalOutlet/LegacyModal';
import {
  PriceLevelPicker,
  PickerModalTotal,
  ProductImage,
  QuantityPicker,
} from '../Modals/ProductPickerModal';
import { MAX_SEATS } from '../../constants';

const { STORE_PATH_PICKER } = STORES;

const PathModal = ({
  formatCurrency,
  t,
  [STORE_PATH_PICKER]: {
    selectedPath,
    isLoading,
    show: showModal,
    close: closeModal,
    action,
    title,
  },
}) => (
  <LegacyModal
    show={showModal}
    onCancel={closeModal}
    title={t(title)}
    submitLabel={t(title)}
    onSubmit={() => action(selectedPath)}
    disabled={
      !selectedPath.pathId ||
      isLoading ||
      !selectedPath.quantity ||
      (!_isEmpty(selectedPath.priceLevels) && !selectedPath.priceLevel)
    }
    isLoading={isLoading}
  >
    <div className="selected-event">
      {selectedPath.priceLevels && selectedPath.priceLevels.length > 1 ? (
        <>
          <Row className="mb-4">
            <Col md={12}>
              <PathDetails selectedPath={selectedPath} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PriceLevelSelect formatCurrency={formatCurrency} />
            </Col>
            <Col md={2}>
              <PriceInput formatCurrency={formatCurrency} />
            </Col>
            <Col md={2}>
              <QuantityInput />
            </Col>
            <Col md={2}>
              <TotalInput formatCurrency={formatCurrency} />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col md={6}>
            <PathDetails selectedPath={selectedPath} />
          </Col>
          <Col md={2}>
            <PriceInput formatCurrency={formatCurrency} />
          </Col>
          <Col md={2}>
            <QuantityInput />
          </Col>
          <Col md={2}>
            <TotalInput formatCurrency={formatCurrency} />
          </Col>
        </Row>
      )}
    </div>
  </LegacyModal>
);

PathModal.propTypes = {
  t: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_PATH_PICKER)(withCurrency(observer(PathModal))),
);

const PathDetails = ({ selectedPath }) => (
  <div className="event">
    <div className="event-details">
      <ProductImage imageUrl={selectedPath.imageUrl} />
      <div className="title hidden-xs hidden-sm">{selectedPath.title}</div>
    </div>
  </div>
);

PathDetails.propTypes = {
  selectedPath: PropTypes.shape({}).isRequired,
};

const Price = ({
  formatCurrency,
  t,
  [STORE_PATH_PICKER]: { selectedPath },
}) => {
  const price = _isEmpty(selectedPath.priceLevels)
    ? selectedPath.price.amount
    : _get(selectedPath, 'priceLevel.amount', null);

  return (
    <div className="total">
      <LabelledInput label={t('weblink:price')} valid>
        {price && <InputValue>{formatCurrency(price)}</InputValue>}
      </LabelledInput>
    </div>
  );
};

Price.propTypes = {
  t: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
};

const PriceInput = withTranslation()(
  inject(STORE_PATH_PICKER)(observer(Price)),
);

const PriceLevel = ({
  formatCurrency,
  [STORE_PATH_PICKER]: { selectedPath, changePriceLevel, isLoading },
}) => (
  <PriceLevelPicker
    formatCurrency={formatCurrency}
    initialPriceLevel={selectedPath.priceLevel}
    priceLevels={selectedPath.priceLevels}
    onPriceLevelChange={changePriceLevel}
    isLoading={isLoading}
  />
);

PriceLevel.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
};

const PriceLevelSelect = inject(STORE_PATH_PICKER)(observer(PriceLevel));

const Quantity = ({
  [STORE_PATH_PICKER]: {
    changeQuantity,
    isLoading,
    selectedPath,
    maxCartQuantity,
  },
}) => (
  <QuantityPicker
    changeQuantity={changeQuantity}
    isLoading={isLoading}
    selectedProduct={selectedPath}
    max={maxCartQuantity !== null ? maxCartQuantity : MAX_SEATS}
  />
);

Quantity.propTypes = {};

const QuantityInput = inject(STORE_PATH_PICKER)(observer(Quantity));

const Total = ({ formatCurrency, [STORE_PATH_PICKER]: { selectedPath } }) => (
  <PickerModalTotal
    formatCurrency={formatCurrency}
    selectedProduct={selectedPath}
    priceLevels={selectedPath.priceLevels}
  />
);

Total.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
};

const TotalInput = inject(STORE_PATH_PICKER)(observer(Total));
