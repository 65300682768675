import { IANAZone } from 'luxon';

const deprecatedTimezoneLookUpTable = {
  'US/Alaska': 'America/Anchorage',
  'US/Aleutian': 'America/Adak',
  'US/Arizona': 'America/Phoenix',
  'US/Central': 'America/Chicago',
  'US/Eastern': 'America/New_York',
  'US/East-Indiana': 'America/Indiana/Indianapolis',
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Indiana-Starke': 'America/Indiana/Knox',
  'US/Michigan': 'America/Detroit',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
  'US/Pacific-New': 'America/Los_Angeles',
  'US/Samoa': 'Pacific/Pago_Pago',
};

export const parseTimezone = timezoneName => {
  if (!timezoneName) return null;

  if (!IANAZone.isValidZone(timezoneName)) {
    if (Object.keys(deprecatedTimezoneLookUpTable).includes(timezoneName)) {
      const linkedTimezone = deprecatedTimezoneLookUpTable[timezoneName];
      // eslint-disable-next-line no-console
      console.warn(
        `The '${timezoneName}' timezone has been deprecated, instead please use '${linkedTimezone}'`,
      );
      return linkedTimezone;
    }
    throw new Error(
      `'${timezoneName}' is not a valid timezone name. Please use a valid IANA timezone name`,
    );
  }
  return timezoneName;
};
