import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';

const priceObject = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
});

const eventObject = PropTypes.shape({
  id: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  duration: PropTypes.number,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string,
  remainingPlaces: PropTypes.number,
  price: priceObject,
});

const courseDetails = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  shortDescription: PropTypes.string,
  customFields: MobxPropTypes.observableArray,
  outcomes: PropTypes.shape({}),
  description: PropTypes.string,
  topics: PropTypes.string,
  prerequisites: PropTypes.string,
  events: MobxPropTypes.observableArrayOf(eventObject),
});

export default courseDetails;
