import React from 'react';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import OverPageNotice from '../components/OverPageNotice';
import alertStore from '../stores/alertStore';

const Alert = ({ t }) => (
  <OverPageNotice
    show={alertStore.show}
    onDismiss={alertStore.closeAlert}
    type={alertStore.alertType}
    body={t(alertStore.alertMessage)}
  />
);

Alert.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(Alert));
