import React, { useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { PrimaryAction, SuppressedAction } from '@administrate/piston';
import { Modal as BSModal } from 'react-bootstrap';

const LegacyModal = ({
  title,
  show,
  size,
  cancelLabel,
  onCancel,
  submitLabel,
  onSubmit,
  isLoading,
  disabled,
  className,
  children,
  t,
}) => {
  const keyFunctions = useCallback(
    ({ keyCode }) => {
      if (keyCode === 27) {
        onCancel();
      }
    },
    [onCancel],
  );
  useEffect(() => {
    if (show) {
      document.body.classList.add('weblink');
    } else {
      document.body.classList.remove('weblink');
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', keyFunctions, false);
    }
    return () => {
      if (show) {
        document.removeEventListener('keydown', keyFunctions, false);
      }
    };
  }, [show, keyFunctions]);

  return (
    <Fragment>
      {show && (
        <BSModal
          show
          onCancel={onCancel}
          className={`weblink ${className}`}
          bsSize={size}
          aria-labelledby="modal-title"
          aria-modal="true"
        >
          <BSModal.Header id="modal-title" role="heading" aria-level="1">
            {title}
          </BSModal.Header>
          <BSModal.Body className="weblink-modal-body">{children}</BSModal.Body>
          <BSModal.Footer>
            <div className="pull-right">
              {onCancel && (
                <SuppressedAction
                  label={cancelLabel || t('weblink:cancel')}
                  onClick={onCancel}
                />
              )}
              {onSubmit && (
                <PrimaryAction
                  label={submitLabel || t('weblink:submit')}
                  onClick={onSubmit}
                  disabled={disabled}
                  isLoading={isLoading}
                />
              )}
            </div>
          </BSModal.Footer>
        </BSModal>
      )}
    </Fragment>
  );
};

LegacyModal.defaultProps = {
  submitLabel: null,
  onSubmit: null,
  cancelLabel: null,
  className: null,
  children: null,
  disabled: false,
  isLoading: false,
  size: undefined,
  onCancel: null,
};

LegacyModal.propTypes = {
  title: PropTypes.node.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.node,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(LegacyModal));
