import React, { useState, useEffect } from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import {
  Container,
  Card,
  SingleChoice,
  SecondaryAction,
} from '@administrate/piston';
import {
  useTypedFormValues,
  OpacityOverlay,
  LoadingScreen,
} from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Check, { CheckPaymentButton } from './Check';
import Invoice, { InvoicePaymentButton } from './Invoice';
import Stripe, { StripePaymentButton } from './Stripe';
import MultiSafePay, { MultiSafePayPaymentButton } from './MultiSafePay';
import Worldpay, { WorldpayPaymentButton } from './Worldpay';
import Converge, { ConvergePaymentButton } from './Converge';
import inject from '../../inject';
import { STORES } from '../../../constants';
import { emailRegex } from '../../../stores/Cart';
import setBrandedPageTitle from '../../../utils/setBrandedPageTitle';
import { paymentRegionPaymentProviders } from '../../../queries/paymentRegions';
import { PageViewEvent } from '../../../analytics';

const STRIPE_API_VERSION = '2023-10-16';

const { STORE_ANALYTICS, STORE_CHECKOUT, STORE_STORE, STORE_CART } = STORES;

const Payment = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_STORE]: { paymentProviders, storeDetails, multiRegion },
  [STORE_CART]: { cart },
  integration,
  t,
}) => {
  useEffect(() => {
    if (!storeDetails) return;

    setBrandedPageTitle(
      ['Checkout', t('weblink:paymentMethod')],
      storeDetails,
      !integration,
    );

    captureEvent(new PageViewEvent());
  }, [captureEvent, storeDetails, integration, t]);

  if (multiRegion) {
    return <PaymentRegionPaymentOptions cart={cart} t={t} />;
  }

  if (!paymentProviders.length) {
    return <h2>{t('weblink:atLeastOnePaymentMethodMessage')}</h2>;
  }

  return <Checkout paymentProviders={paymentProviders} cart={cart} t={t} />;
};

Payment.propTypes = {
  integration: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const PaymentRegionPaymentOptions = ({ cart, t }) => {
  const { loading: loadingPaymentRegions, error, data } = useQuery(
    paymentRegionPaymentProviders,
    {
      variables: {
        regionId: cart.region.id,
        currencyCode: cart.currency.code,
      },
    },
  );

  let paymentProviders = [];
  if (!loadingPaymentRegions && !error && data.paymentRegions[0]) {
    paymentProviders = data.paymentRegions[0].paymentProviders || [];
  }

  if (loadingPaymentRegions) {
    return <LoadingScreen />;
  }

  if (!paymentProviders.length) {
    return <h2>{t('weblink:atLeastOnePaymentMethodMessage')}</h2>;
  }

  return <Checkout paymentProviders={paymentProviders} cart={cart} t={t} />;
};

PaymentRegionPaymentOptions.propTypes = {
  cart: MobxPropTypes.objectOrObservableObject.isRequired,
  t: PropTypes.func.isRequired,
};

const CheckoutPayment = ({
  [STORE_CHECKOUT]: {
    changeStepBackward,
    completeOrderAndChangeStep,
    isLoading,
    itemsRequiringLearnerDetails,
    showOrderRetrievalPath,
    customerBillingAddress,
  },
  cart,
  t,
  paymentProviders,
}) => {
  const [stripe, setStripe] = useState(null);
  const [cardElement, setCardElement] = useState(null);
  const [multiSafePayPaymentMethod, changemultiSafePayPaymentMethod] = useState(
    'CREDITCARD',
  );
  const creditCardMethods = ['MultiSafepay', 'Stripe', 'Converge', 'Worldpay'];

  const values = useTypedFormValues({
    billingEmail: '',
  });

  const convergeProvider = paymentProviders.find(
    provider => provider.__typename === 'Converge',
  );

  const convergeValues = useTypedFormValues({
    cardNumber: '',
    expiration: null,
    cvv2: '',
    address1: customerBillingAddress
      ? customerBillingAddress.streetAddress1
      : '',
    zip: customerBillingAddress ? customerBillingAddress.postcode : '',
  });

  let defaultPaymentMethod;
  const creditCardProvider = paymentProviders.find(provider =>
    creditCardMethods.includes(provider.__typename),
  );
  if (creditCardProvider) {
    defaultPaymentMethod = 'CreditCard';
  }

  const checkProvider = paymentProviders.find(
    provider => provider.__typename === 'Check',
  );
  let checkMailingAddress;
  if (checkProvider) {
    checkMailingAddress = _get(checkProvider, 'mailingAddress', null);
    checkMailingAddress = {
      ...checkMailingAddress,
      countryName: checkMailingAddress.country
        ? checkMailingAddress.country.name
        : null,
    };
    defaultPaymentMethod = defaultPaymentMethod || 'Check';
  }

  const invoiceProvider = paymentProviders.find(
    provider => provider.__typename === 'Invoice',
  );
  let invoiceAttributeDefinitions;
  if (invoiceProvider) {
    invoiceAttributeDefinitions = _get(
      invoiceProvider,
      'paymentAttributeDefinitions',
      [],
    );
    defaultPaymentMethod = defaultPaymentMethod || 'Invoice';
  }
  const validateBillingEmail = value => {
    if (!value) {
      return t('weblink:billingEmailRequired');
    }
    if (!emailRegex.test(value)) {
      return t('weblink:invalidEmail');
    }
    return true;
  };

  const [paymentOption, changePaymentOption] = useState(defaultPaymentMethod);

  const paymentMethod =
    paymentOption === 'CreditCard'
      ? creditCardProvider.__typename
      : paymentOption;

  // TODO: The payment button for MSP needs to be updated to use the
  // payment providers from the portal's payment regions when using the
  // multiRegion portal setting.

  let paymentInfo = null;
  let paymentButton = null;

  const stripeKey =
    paymentMethod === 'Stripe' && creditCardProvider
      ? creditCardProvider.publicKey
      : null;
  useEffect(() => {
    const getStripe = async () => {
      const stripeObject = await loadStripe(stripeKey, {
        apiVersion: STRIPE_API_VERSION,
      });
      setStripe(stripeObject);
    };
    if (!stripe && stripeKey) {
      getStripe();
    }
  }, [stripe, stripeKey]);

  switch (paymentMethod) {
    case 'Invoice':
      paymentInfo = (
        <Invoice
          values={values}
          invoiceAttributeDefinitions={invoiceAttributeDefinitions}
          validateBillingEmail={validateBillingEmail}
        />
      );
      paymentButton = (
        <InvoicePaymentButton
          values={values}
          invoiceAttributeDefinitions={invoiceAttributeDefinitions}
          validateBillingEmail={validateBillingEmail}
        />
      );
      break;
    case 'Check':
      paymentInfo = <Check checkMailingAddress={checkMailingAddress} />;
      paymentButton = <CheckPaymentButton />;
      break;
    case 'MultiSafepay':
      paymentInfo = (
        <MultiSafePay
          paymentMethod={multiSafePayPaymentMethod}
          changePaymentMethod={changemultiSafePayPaymentMethod}
        />
      );
      paymentButton = (
        <MultiSafePayPaymentButton paymentMethod={multiSafePayPaymentMethod} />
      );
      break;
    case 'Worldpay':
      paymentInfo = <Worldpay />;
      paymentButton = <WorldpayPaymentButton />;
      break;
    case 'Stripe':
      paymentInfo = stripe ? (
        <Elements stripe={stripe}>
          <Stripe setCardElement={setCardElement} />
        </Elements>
      ) : null;
      paymentButton = stripe ? (
        <Elements stripe={stripe}>
          <StripePaymentButton cardElement={cardElement} />
        </Elements>
      ) : null;
      break;
    case 'Converge':
      paymentInfo = (
        <Converge isLive={convergeProvider.isLive} values={convergeValues} />
      );
      paymentButton = (
        <ConvergePaymentButton
          isLive={convergeProvider.isLive}
          values={convergeValues}
        />
      );
      break;
    default:
      break;
  }

  const paymentOptions = [];
  if (creditCardProvider) {
    paymentOptions.push(
      <SingleChoice.Option key="CreditCard" optionKey="CreditCard">
        {t('weblink:payByCreditCard')}
      </SingleChoice.Option>,
    );
  }
  if (checkProvider) {
    paymentOptions.push(
      <SingleChoice.Option key="Check" optionKey="Check">
        {t('weblink:payByCheck')}
      </SingleChoice.Option>,
    );
  }
  if (invoiceProvider) {
    paymentOptions.push(
      <SingleChoice.Option key="Invoice" optionKey="Invoice">
        {t('weblink:invoiceMyCompany')}
      </SingleChoice.Option>,
    );
  }

  return cart.isFree ? (
    <Card>
      <h1 className="CardTitle">{t('weblink:completeOrder')}</h1>
      <Card.Footer
        left={
          <OpacityOverlay on={isLoading}>
            <span
              className="btn btn-link"
              role="link"
              tabIndex={0}
              onClick={changeStepBackward}
              onKeyPress={changeStepBackward}
              aria-disabled={isLoading}
            >
              {itemsRequiringLearnerDetails.length
                ? t('weblink:backToLearner')
                : t('weblink:backToCustomerInfo')}
            </span>
          </OpacityOverlay>
        }
        right={
          <SecondaryAction
            label={t('weblink:completeOrder')}
            onClick={() => completeOrderAndChangeStep()}
          />
        }
      />
    </Card>
  ) : (
    <Card>
      <h1 className="CardTitle">{t('weblink:paymentMethod')}</h1>
      <Card.Body>
        <Container fluid>
          {paymentOptions.length > 1 && (
            <Container.Row>
              <Container.Col xs={12}>
                <strong>{t('weblink:paymentOptions')}</strong>
                <SingleChoice
                  value={paymentOption}
                  onChange={value => changePaymentOption(value)}
                >
                  {paymentOptions}
                </SingleChoice>
              </Container.Col>
            </Container.Row>
          )}
          {paymentInfo}
        </Container>
      </Card.Body>
      <Card.Footer
        left={
          !showOrderRetrievalPath && (
            <OpacityOverlay on={isLoading}>
              <span
                className="btn btn-link"
                role="link"
                tabIndex={0}
                onClick={changeStepBackward}
                onKeyPress={changeStepBackward}
                aria-disabled={isLoading}
              >
                {t('weblink:backToLearner')}
              </span>
            </OpacityOverlay>
          )
        }
        right={paymentButton}
      />
    </Card>
  );
};

CheckoutPayment.propTypes = {
  cart: MobxPropTypes.objectOrObservableObject.isRequired,
  t: PropTypes.func.isRequired,
  paymentProviders: PropTypes.shape([]).isRequired,
};

const Checkout = inject(STORE_CHECKOUT)(observer(CheckoutPayment));

export default withTranslation()(
  inject(STORE_ANALYTICS, STORE_STORE, STORE_CART)(observer(Payment)),
);
