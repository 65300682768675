import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { PropTypes as MobxPropTypes } from 'mobx-react';

import GiftVoucherItem from '../Items/GiftVoucherItem';
import EventItem from '../Items/EventItem';
import PathItem from '../Items/PathItem';

const getUnitAmount = row => Number(row.unitAmount);

const CartItemDescription = ({
  row,
  isSoldOut,
  showPlacesRemaining,
  children,
}) => {
  if (row.giftVoucherId) {
    return (
      <GiftVoucherItem
        unitAmount={getUnitAmount(row)}
        imageUrl={row.imageUrl}
      />
    );
  }

  if (row.pathId) {
    return (
      <PathItem
        id={row.pathId}
        unitAmount={getUnitAmount(row)}
        title={row.course}
        imageUrl={row.imageUrl}
        showPlacesRemaining={showPlacesRemaining}
        remainingPlaces={row.remainingPlaces}
      >
        {children}
      </PathItem>
    );
  }

  return (
    <EventItem
      id={row.eventId}
      location={row.location?.name}
      startDateTime={row.startDateTime}
      unitAmount={getUnitAmount(row)}
      course={row.course}
      remainingPlaces={row.remainingPlaces}
      imageUrl={row.imageUrl}
      isSoldOut={isSoldOut}
      showPlacesRemaining={showPlacesRemaining}
      deliveryMethod={row.deliveryMethod}
    />
  );
};

CartItemDescription.defaultProps = {
  isSoldOut: false,
  children: null,
  showPlacesRemaining: true,
};

CartItemDescription.propTypes = {
  row: MobxPropTypes.objectOrObservableObject.isRequired,
  isSoldOut: PropTypes.bool,
  children: PropTypes.node,
  showPlacesRemaining: PropTypes.bool,
};

export default observer(CartItemDescription);
