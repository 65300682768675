import React from 'react';
import PropTypes from 'prop-types';

import LegacyModal from '../ModalOutlet/LegacyModal';

const ExpiredItemsModal = ({
  t,
  name,
  bookedQuantity,
  remainingPlaces,
  onRemove,
  onRebook,
}) => {
  const hasRemainingPlaces = remainingPlaces > 0;

  const buttonProps = hasRemainingPlaces
    ? {
        onSubmit: onRebook,
        submitLabel: t('weblink:bookNumberOfPlaces', {
          numberOfPlaces: remainingPlaces,
        }),
        onCancel: onRemove,
        cancelLabel: t('weblink:removeFromCart'),
      }
    : { onSubmit: onRemove, submitLabel: t('weblink:removeFromCart') };

  return (
    <LegacyModal
      show
      title={
        <span>
          <i
            className="glyphicon glyphicon-exclamation-sign"
            style={{ color: '#EFC440', marginRight: '8px' }}
          />
          {t('weblink:placesUnavailable')}
        </span>
      }
      size="large"
      cancelLabel={buttonProps.cancelLabel}
      onCancel={buttonProps.onCancel}
      submitLabel={buttonProps.submitLabel}
      onSubmit={buttonProps.onSubmit}
    >
      {hasRemainingPlaces ? (
        <div>
          {t('weblink:notEnoughPlacesRemainingToRebook', {
            itemName: name,
            remainingPlaces,
            bookedQuantity,
          })}
          <br />
          <br />
          {t('weblink:wouldYouLikeToBookRemaining', { remainingPlaces })}
        </div>
      ) : (
        <div>
          {t('weblink:noPlacesRemainingForBooking', { itemName: name })}
        </div>
      )}
    </LegacyModal>
  );
};

ExpiredItemsModal.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  bookedQuantity: PropTypes.number.isRequired,
  remainingPlaces: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRebook: PropTypes.func.isRequired,
};

export default ExpiredItemsModal;
