import gql from 'graphql-tag';

const getPointOfSaleOrderFieldValues = gql`
  query getPointOfSaleOrderFieldValues($cartId: ID!) {
    pointOfSaleOrderFieldValues(id: $cartId) {
      pointOfSaleFieldKey
      value
    }
  }
`;

export default getPointOfSaleOrderFieldValues;
