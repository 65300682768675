import { decorate, action, computed, runInAction } from 'mobx';

import BaseStore from './baseStore';
import { portalTokenStore } from './localStorage';
import portalStore from './portalStore';

class UserStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.user = null;
      this.customUserId = null;
    });
  }

  initialize = action('userStore initialize', user => {
    this.user = user;
  });

  get userId() {
    if (this.user) {
      const [_instance, _type, contactId] = this.user.sub.split(':');
      return contactId;
    }

    if (this.customUserId) {
      return this.customUserId;
    }

    return portalTokenStore.getPortalToken(portalStore.portalAuthDomain);
  }

  setCustomUserId = action(user => {
    if (this.customUserId !== user) {
      this.customUserId = user;
    }
  });
}

decorate(UserStore, {
  userId: computed,
});

export default UserStore;
