import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { Auth0Provider } from './lib/react-auth0-wrapper';
import { usePortalConfiguration } from '../hooks/portalConfiguration';
import { history } from '../history';
import { PageLoadingSkeleton } from '../components/PageLoadingSkeleton';
import { WeblinkApiConfigurationPropType } from '../graphql/providers/WeblinkApiProvider';

const navigateToPreviousPage = appState => {
  return history.replace(
    (appState && appState.targetPath) || window.location.pathname,
  );
};

const onRedirectCallback = appState => {
  return navigateToPreviousPage(appState);
};

const clearCartState = portalAuthDomain => {
  window.localStorage.removeItem(`weblink:${portalAuthDomain}:cartId`);
  window.localStorage.removeItem(`weblink:${portalAuthDomain}:buyNowCartId`);
  window.localStorage.removeItem('weblink:cartRecoveryToken');
};

const AuthProvider = ({ children, weblinkApiConfiguration }) => {
  const { idpConfiguration, requireLoginOnCheckout } = usePortalConfiguration();
  const { portalAuthDomain } = weblinkApiConfiguration;
  const preLogout = useCallback(() => {
    clearCartState(portalAuthDomain);
  }, [portalAuthDomain]);

  if (!idpConfiguration) {
    return <PageLoadingSkeleton />;
  }

  return (
    <Auth0Provider
      enabled={requireLoginOnCheckout}
      domain={idpConfiguration.domain}
      clientId={idpConfiguration.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      returnTo={window.location.origin}
      brand={idpConfiguration.brand}
      leeway={300} // 5 minutes
      useRefreshTokens
      cacheLocation="localstorage"
      preLogout={preLogout}
    >
      {children}
    </Auth0Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  weblinkApiConfiguration: WeblinkApiConfigurationPropType.isRequired,
};

export default AuthProvider;
