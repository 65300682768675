import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';

import { SelectListInput } from '@administrate/piston';

import ValidatedLabelledInput from '../ValidatedLabelledInput';
import { useWebLinkQuery } from '../../weblink-hooks';
import { getCountries } from '../../queries/country';

export const isProvinceValueValid = currentValue => {
  if (currentValue) {
    if (currentValue.indexOf('-') === currentValue.length - 1) {
      return false;
    }
  }
  return true;
};

const getCountryCodeFromProvinceValue = provinceValue => {
  if (!provinceValue) return null;
  if (provinceValue.includes('-')) return provinceValue.split('-')[0];
  return provinceValue;
};

const getMatchingOptionFromValue = (options, currentValue) =>
  _.find(options, ['value', currentValue]) || null;

const CountryProvinceSelector = ({
  id,
  definition,
  learner,
  value,
  onChange,
  t,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);

  const globalProvinceOptions = _.groupBy(definition.options, (x) => getCountryCodeFromProvinceValue(x.value))

  const { loading: loadingCountries } = useWebLinkQuery(
    getCountries,
    {},
    {
      fetchPolicy: 'cache-first',
      onCompleted: data => {
        const options = data.countries.map(country => ({
          label: country.name,
          value: country.code,
        }));
        setCountryOptions(options);
        setSelectedCountry(
          getMatchingOptionFromValue(
            options,
            getCountryCodeFromProvinceValue(value),
          ),
        );
      },
    },
  );

  return (
    <>
      <CountrySelector
        id={id}
        definition={definition}
        onChange={onChange}
        value={value}
        countryOptions={countryOptions}
        loadingCountries={loadingCountries}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        globalProvinceOptions={globalProvinceOptions}
        learner={learner}
        t={t}
      />
      <ProvinceSelector
        id={id}
        definition={definition}
        loadingCountries={loadingCountries}
        selectedCountry={selectedCountry}
        globalProvinceOptions={globalProvinceOptions}
        onChange={onChange}
        value={value}
        learner={learner}
        t={t}
      />
    </>
  );
};

CountryProvinceSelector.defaultProps = {
  value: null,
};

CountryProvinceSelector.propTypes = {
  definition: PropTypes.shape({}).isRequired,
  learner: PropTypes.shape({}).isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const CountrySelector = ({
  id,
  definition,
  onChange,
  value,
  countryOptions,
  loadingCountries,
  selectedCountry,
  setSelectedCountry,
  globalProvinceOptions,
  learner,
  t,
}) => {
  const countryValid = () =>
    !(value === null && learner.fieldIsRequired(definition));

  const onCountryChange = newValue => {
    setSelectedCountry(newValue);

    if (newValue && _.has(globalProvinceOptions, newValue.value)) {
      onChange({ ...newValue, value: `${newValue.value}-` });
    } else {
      onChange(newValue);
    }
  };

  return (
    <ValidatedLabelledInput
      id={id}
      key={`${definition.key} - Country`}
      label={t('weblink:customCountryLabel', {
        label: definition.label,
      })}
      value={selectedCountry}
      onChange={onCountryChange}
      isValid={countryValid()}
      required={!countryValid()}
    >
      <SelectListInput
        id={id}
        key={`${definition.key} - Country`}
        value={selectedCountry}
        onChange={onCountryChange}
        options={_.sortBy(countryOptions, 'label')}
        isLoading={loadingCountries}
      />
    </ValidatedLabelledInput>
  );
};

CountrySelector.propTypes = {
  id: PropTypes.string.isRequired,
  definition: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  countryOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingCountries: PropTypes.bool.isRequired,
  selectedCountry: PropTypes.shape({}).isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  learner: PropTypes.shape({}).isRequired,
  globalProvinceOptions: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

const ProvinceSelector = ({
  id,
  definition,
  value,
  onChange,
  loadingCountries,
  selectedCountry,
  globalProvinceOptions,
  learner,
  t,
}) => {
  const getProvinceOptions = country => {
    if (_.isEmpty(country)) return [];
    return globalProvinceOptions[country.value];
  };

  const provinceOptions = getProvinceOptions(selectedCountry);

  const currentProvince = getMatchingOptionFromValue(provinceOptions, value);

  const provinceValid = () => {
    if (value === null && !learner.fieldIsRequired(definition)) return true;
    return isProvinceValueValid(value);
  };

  return (
    <ValidatedLabelledInput
      id={id}
      key={`${definition.key} - Province`}
      label={t('weblink:customProvinceLabel', {
        label: definition.label,
      })}
      value={currentProvince}
      onChange={onChange}
      isValid={provinceValid()}
      required={!provinceValid()}
    >
      <SelectListInput
        id={id}
        key={`${definition.key} - Province`}
        value={currentProvince}
        onChange={onChange}
        options={_.sortBy(provinceOptions, 'label')}
        disabled={_.isEmpty(provinceOptions)}
        isLoading={loadingCountries}
      />
    </ValidatedLabelledInput>
  );
};

ProvinceSelector.propTypes = {
  id: PropTypes.string.isRequired,
  definition: PropTypes.shape({}).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loadingCountries: PropTypes.bool.isRequired,
  selectedCountry: PropTypes.shape({}).isRequired,
  learner: PropTypes.shape({}).isRequired,
  globalProvinceOptions: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(CountryProvinceSelector));
