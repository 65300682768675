import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StickyContainer } from 'react-sticky';

import { Button, Card } from '@administrate/piston-ux';

import inject from '../inject';
import { STORES } from '../../constants';

const { STORE_NAVIGATION } = STORES;

const TrainingRequestCompleteCard = ({
  t,
  interestName,
  successMessage,
  showGoHomeButton,
  [STORE_NAVIGATION]: { toHome },
}) => (
  <StickyContainer style={{ display: 'flex', justifyContent: 'center' }}>
    <Card
      label={{ text: interestName, color: 'green' }}
      title={t('weblink:requestCompleted')}
      extraClass="training-request-complete-card"
    >
      <p>{successMessage}</p>
      { showGoHomeButton && <Button label={t('weblink:returnHome')} onClick={toHome} /> }
    </Card>
  </StickyContainer>
);

TrainingRequestCompleteCard.propTypes = {
  t: PropTypes.func.isRequired,
  interestName: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  showGoHomeButton: PropTypes.bool.isRequired,
};

export default withTranslation()(
  inject(STORE_NAVIGATION)(TrainingRequestCompleteCard),
);
