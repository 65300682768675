import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import { pick as _pick } from 'lodash';
import {
  Card,
  CheckboxInput,
  Container,
  LabelledInput,
  SecondaryAction,
  TextInput,
} from '@administrate/piston';
import {
  Form,
  AddressInput,
  useTypedFormValues,
} from '@administrate/piston-ux';
import { FormConsumer } from '@administrate/piston-ux/lib/Form';
import { hasErrors } from '@administrate/piston-ux/lib/Submit';
import { RawStaticInput } from '@administrate/piston-ux/lib/StaticInput';
import { withTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import inject from '../inject';
import { STORES } from '../../constants';
import FormIncompleteNotice from './FormIncompleteNotice';
import ValidatedLabelledInput from '../../components/ValidatedLabelledInput';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import useQueryParams from '../../hooks/useQueryParams';
import { useAuth0 } from '../../auth/lib/react-auth0-wrapper';
import { labelForContinueButton } from './labels';
import { useWebLinkQuery } from '../../weblink-hooks';
import { getCountries } from '../../queries/country';
import getPointOfSaleOrderFields from '../../queries/pointOfSaleOrderField';
import getPointOfSaleOrderFieldValues from '../../queries/getPointOfSaleOrderFieldValues';
import {
  PointOfSaleCustomFieldInput,
  serialisePoSValuesForMutation,
} from '../../components/PointOfSaleCustomFieldInput';
import { PageViewEvent } from '../../analytics';

const { STORE_ANALYTICS, STORE_CHECKOUT, STORE_CART, STORE_STORE } = STORES;

const allowedTags = [
  'a',
  'b',
  'i',
  'p',
  'q',
  's',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'hr',
  'li',
  'ol',
  'ul',
  'strong',
];

const billingAddressFieldKey = {
  streetAddress1: 'streetAddress1',
  streetAddress2: 'streetAddress2',
  streetAddress3: 'streetAddress3',
  town: 'town',
  postcode: 'postcode',
  countryId: 'countryId',
  provinceCode: 'provinceCode',
};

const extractBillingAddressFromFormValues = objectifiedFormValue =>
  _pick(objectifiedFormValue, Object.keys(billingAddressFieldKey));

const removeUnnecessaryFieldsFromFormValue = formValues => {
  const toDeleteFields = ['set', 'get', 'province', 'country'];
  toDeleteFields.forEach(toDelete => {
    delete formValues[toDelete];
  });
  return formValues;
};

const submitUXForm = (formValues, updateCustomer, pointOfSaleOrderFields) => {
  const objectFormValues = removeUnnecessaryFieldsFromFormValue(
    toJS(formValues),
  );
  if (objectFormValues) {
    if (objectFormValues[billingAddressFieldKey.countryId]) {
      updateCustomer(
        'billingAddress',
        extractBillingAddressFromFormValues(objectFormValues),
      );
    }
    if (pointOfSaleOrderFields.length > 0) {
      updateCustomer(
        'pointOfSaleOrderFields',
        serialisePoSValuesForMutation(objectFormValues, pointOfSaleOrderFields),
      );
    }
  }
};

const CheckoutDetails = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_CHECKOUT]: {
    checkDataCompletion,
    updateCartBuyerAndChangeStep,
    updateCustomer,
    updateCustomerEmail,
    showIncompleteNotice,
    isLoading,
  },
  [STORE_CART]: { cart, containsValidItems },
  [STORE_STORE]: {
    terms,
    confirmEmails,
    storeDetails,
    requireLearnerDetails,
    hideCompanyFieldForBooker,
    requireBookerBillingAddress,
  },
  t,
  integration,
}) => {
  const { buyerDetails } = cart;
  const [termsMap, setTermsMap] = useState(
    terms.reduce((map, term) => ({ ...map, [term.title]: false }), {}),
  );
  const [formHasErrors, setFormHasErrors] = useState(false);

  useEffect(() => {
    if (!storeDetails) return;

    setBrandedPageTitle(
      ['Checkout', t('weblink:checkoutBooker')],
      storeDetails,
      !integration,
    );
    captureEvent(new PageViewEvent());
  }, [storeDetails, integration, captureEvent, t]);

  const [inputsStatic, setInputsStatic] = useState({
    email: false,
    firstName: false,
    lastName: false,
    company: false,
  });
  const { isAuthenticated } = useAuth0();
  const prepopulateCustomerEmail = useCallback(
    value => {
      updateCustomerEmail(value, confirmEmails);
      if (confirmEmails) updateCustomer('confirmedEmail', value);
    },
    [confirmEmails, updateCustomerEmail, updateCustomer],
  );

  const params = useQueryParams();
  const [countryOptions, setCountryOptions] = useState([]);
  const [pointOfSaleOrderFields, setPointOfSaleOrderFields] = useState([]);
  const [pointOfSaleOrderValues, setPointOfSaleOrderValues] = useState([]);

  const billingAddressFormValues = buyerDetails.billingAddress
    ? {
        countryId: buyerDetails.billingAddress.country
          ? buyerDetails.billingAddress.country.id
          : null,
        provinceCode: buyerDetails.billingAddress.province
          ? buyerDetails.billingAddress.province.code
          : null,
        ...buyerDetails.billingAddress,
      }
    : {};

  const formValues = useTypedFormValues({
    ...billingAddressFormValues,
  });

  const { loading: loadingCountries } = useWebLinkQuery(
    getCountries,
    {},
    {
      fetchPolicy: 'cache-first',
      onCompleted: ({ countries: fetchedCountries }) => {
        setCountryOptions(
          fetchedCountries.map(({ name, id, provinces }) => ({
            name,
            code: id,
            provinces,
          })),
        );
      },
    },
  );

  const { loading: loadingPoSOrderFields } = useWebLinkQuery(
    getPointOfSaleOrderFields,
    {},
    {
      fetchPolicy: 'cache-first',
      onCompleted: ({ pointOfSaleOrderFields: fetchedPoSFields }) => {
        setPointOfSaleOrderFields(fetchedPoSFields);
      },
    },
  );

  const { loading: loadingPoSOrderFieldValues } = useWebLinkQuery(
    getPointOfSaleOrderFieldValues,
    {
      cartId: cart.id,
    },
    {
      onCompleted: ({ pointOfSaleOrderFieldValues: fetchedPoSFieldValues }) => {
        setPointOfSaleOrderValues(fetchedPoSFieldValues);
      },
    },
  );

  useEffect(() => {
    const emailParam = params.get('email');
    const firstNameParam = params.get('firstName');
    const lastNameParam = params.get('lastName');
    const companyParam = params.get('company');

    setInputsStatic({
      email: isAuthenticated || !!emailParam,
      firstName: isAuthenticated || !!firstNameParam,
      lastName: isAuthenticated || !!lastNameParam,
      company: isAuthenticated || !!companyParam,
    });

    if (isAuthenticated) {
      updateCustomer('confirmedEmail', buyerDetails.email);
      return;
    }

    if (emailParam) {
      prepopulateCustomerEmail(emailParam);
    }

    if (firstNameParam) updateCustomer('firstName', firstNameParam);
    if (lastNameParam) updateCustomer('lastName', lastNameParam);
    if (companyParam) updateCustomer('company', companyParam);
  }, [
    confirmEmails,
    isAuthenticated,
    buyerDetails,
    params,
    updateCustomer,
    prepopulateCustomerEmail,
  ]);
  const hideCompanyField =
    hideCompanyFieldForBooker || (isAuthenticated && !buyerDetails.company);

  const pageIsLoading =
    isLoading ||
    loadingCountries ||
    loadingPoSOrderFields ||
    loadingPoSOrderFieldValues;

  return (
    <Card>
      {showIncompleteNotice && <FormIncompleteNotice />}
      <h1 className="CardTitle">{t('weblink:checkoutBooker')}</h1>
      <Card.Body>
        <Container fluid>
          <div>
            <Container.Row>
              <Container.Col xs={12}>
                {inputsStatic.email ? (
                  <RawStaticInput
                    label={t('weblink:email')}
                    value={buyerDetails.email}
                    dataId="booker-email"
                  />
                ) : (
                  <ValidatedLabelledInput
                    isValid={buyerDetails.isEmailValid}
                    label={t('weblink:email')}
                    onChange={email =>
                      updateCustomerEmail(email, confirmEmails)
                    }
                    value={buyerDetails.email}
                    disabled={isLoading || inputsStatic.email}
                    required
                    id="email"
                  />
                )}
              </Container.Col>
            </Container.Row>
            {confirmEmails && (
              <Container.Row>
                <Container.Col xs={12}>
                  <ValidatedLabelledInput
                    isValid={buyerDetails.isEmailConfirmed}
                    label={t('weblink:confirmEmail')}
                    onChange={confirmedEmail =>
                      updateCustomer('confirmedEmail', confirmedEmail)
                    }
                    value={buyerDetails.confirmedEmail}
                    disabled={isLoading || inputsStatic.email}
                    errorMessage={t('weblink:emailConfirmationIsRequired')}
                    required
                    id="confirmEmail"
                  />
                </Container.Col>
              </Container.Row>
            )}
            <Container.Row>
              <Container.Col xs={12} md={6}>
                {inputsStatic.firstName ? (
                  <RawStaticInput
                    label={t('weblink:firstName')}
                    value={buyerDetails.firstName}
                    dataId="booker-first-name"
                  />
                ) : (
                  <ValidatedLabelledInput
                    isValid={buyerDetails.isFirstNameValid}
                    label={t('weblink:firstName')}
                    onChange={firstName =>
                      updateCustomer('firstName', firstName)
                    }
                    value={buyerDetails.firstName}
                    disabled={isLoading}
                    required
                    id="firstName"
                  />
                )}
              </Container.Col>
              <Container.Col xs={12} md={6}>
                {inputsStatic.lastName ? (
                  <RawStaticInput
                    label={t('weblink:lastName')}
                    value={buyerDetails.lastName}
                    dataId="booker-last-name"
                  />
                ) : (
                  <ValidatedLabelledInput
                    isValid={buyerDetails.isLastNameValid}
                    label={t('weblink:lastName')}
                    onChange={lastName => updateCustomer('lastName', lastName)}
                    value={buyerDetails.lastName}
                    disabled={isLoading}
                    required
                    id="lastName"
                  />
                )}
              </Container.Col>
            </Container.Row>
            {!hideCompanyField && (
              <Container.Row>
                <Container.Col xs={12}>
                  {inputsStatic.company ? (
                    <RawStaticInput
                      label={t('weblink:company')}
                      value={buyerDetails.company}
                      dataId="booker-company"
                    />
                  ) : (
                    <LabelledInput label={t('weblink:company')}>
                      <TextInput
                        ariaLabel={t('company')}
                        onChange={company => updateCustomer('company', company)}
                        value={buyerDetails.company}
                        disabled={isLoading || inputsStatic.company}
                      />
                    </LabelledInput>
                  )}
                </Container.Col>
              </Container.Row>
            )}

            <Form values={formValues} loading={pageIsLoading}>
              <FormConsumer>
                {({ errors }) => (
                  <Observer>
                    {() => {
                      /*
                        errors/hasErrors is returned true if a field has a wrong input type
                        or if the field is required but not filled yet
                      */
                      setFormHasErrors(hasErrors(errors));
                      return (
                        <div className="additional-information">
                          <PointOfSaleCustomFieldInput
                            pointOfSaleFields={pointOfSaleOrderFields}
                            pointOfSaleValues={pointOfSaleOrderValues}
                            t={t}
                          />
                          {requireBookerBillingAddress && (
                            <AddressInput
                              disabled={isLoading && loadingCountries}
                              legend={t('weblink:billingAddress')}
                              inputs={{
                                line1: {
                                  name: billingAddressFieldKey.streetAddress1,
                                },
                                line2: {
                                  name: billingAddressFieldKey.streetAddress2,
                                },
                                line3: {
                                  name: billingAddressFieldKey.streetAddress3,
                                },
                                city: {
                                  name: billingAddressFieldKey.town,
                                },
                                postalCode: {
                                  name: billingAddressFieldKey.postcode,
                                },
                                country: {
                                  name: billingAddressFieldKey.countryId,
                                },
                                province: {
                                  name: billingAddressFieldKey.provinceCode,
                                },
                              }}
                              countryList={countryOptions}
                              id="billing-address"
                            />
                          )}
                        </div>
                      );
                    }}
                  </Observer>
                )}
              </FormConsumer>
            </Form>

            {terms && <br />}
            {terms &&
              terms.map(({ title, text }) => (
                <Container.Row key={title}>
                  <Container.Col xs={12}>
                    <div className="term">
                      <h2 className="term-title">{title}</h2>
                      <hr />
                      <div
                        className="term-text"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(text, { allowedTags }),
                        }}
                      />
                      <div className="term-checkbox">
                        <CheckboxInput
                          title={t('weblink:termAgreeText', { title })}
                          onChange={value =>
                            setTermsMap({ ...termsMap, [title]: value })
                          }
                          required
                        />
                      </div>
                    </div>
                  </Container.Col>
                </Container.Row>
              ))}
          </div>
        </Container>
      </Card.Body>
      <Card.Footer
        right={
          <SecondaryAction
            label={
              <span>
                {labelForContinueButton(cart, requireLearnerDetails, t)}
                {isLoading && (
                  <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
                )}
              </span>
            }
            disabled={
              !buyerDetails.isValid ||
              pageIsLoading ||
              formHasErrors ||
              Object.values(termsMap).includes(false) ||
              !containsValidItems
            }
            onClick={() => {
              submitUXForm(formValues, updateCustomer, pointOfSaleOrderFields);
              return checkDataCompletion() && updateCartBuyerAndChangeStep();
            }}
          />
        }
      />
    </Card>
  );
};

CheckoutDetails.propTypes = {
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
};

export default withTranslation()(
  inject(
    STORE_ANALYTICS,
    STORE_CHECKOUT,
    STORE_CART,
    STORE_STORE,
  )(observer(CheckoutDetails)),
);
