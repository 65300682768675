import React from 'react';
import { Card } from '@administrate/piston-ux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Truncate from 'react-truncate';

import { ColorOptions } from '@administrate/piston-ux/lib/types';
import withIntegration from '../../containers/withIntegration';
import withCurrency from '../../containers/withCurrency';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../constants';
import { slugify } from '../../utils/slugs';

import { CatalogueLoadingSkeleton } from './CatalogueLoadingSkeleton';
import HTMLContainer from '../HTMLContainer';

const CardCustomFields = ({ definitions, values }) => {
  const customFields = definitions.map(definition => {
    const customField = { ...definition };
    values.forEach(fieldValue => {
      if (fieldValue.definitionKey === definition.key) {
        customField.value = fieldValue.value;
      }
    });
    return customField;
  });
  const customFieldsWithValue = customFields.filter(
    customField => customField.value,
  );
  return (
    <div className="CardCourse--custom_fields">
      {customFieldsWithValue.map((field, index) => (
        <span key={field.key}>
          <strong>{`${field.label}: `}</strong>
          {field.value}
          {index + 1 < customFieldsWithValue.length && (
            <span className="CardCourse--custom_field_separator">, </span>
          )}
        </span>
      ))}
    </div>
  );
};

CardCustomFields.propTypes = {
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      definitionKey: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const getCardClickHandler = (
  integration,
  catalogue,
  overrideLink,
  onSelect,
  setSearchBarText,
) => {
  if (overrideLink) {
    return undefined;
  }
  if (integration) {
    return () => onSelect(catalogue);
  }
  return () => setSearchBarText();
};

const CatalogueView = ({
  catalogueItems,
  onSelect,
  formatCurrency,
  integration,
  setSearchBarText,
  categoryId,
  subcategoryId,
  displayPrices,
  courseCustomFieldDefinitions,
  pathCustomFieldDefinitions,
  showDescription = false,
  showCategory = true,
  showFeaturedLabel = true,
  cardLinkBuilder,
  t,
}) => (
  <div className="CardWrapper" role="list">
    {catalogueItems.map((catalogue, index) => {
      let statusLabel = t('weblink:free');
      const minimumPrice = _get(catalogue, 'priceRange.minimumPrice.amount');
      const normalPrice = _get(catalogue, 'priceRange.normalPrice.amount');
      const type = _get(catalogue, '__typename');
      if (minimumPrice && Number(minimumPrice) !== 0) {
        statusLabel = formatCurrency(minimumPrice);
      } else if (normalPrice && Number(normalPrice) !== 0) {
        statusLabel = formatCurrency(normalPrice);
      }
      let isPath = false;
      if (type === 'LearningPath') {
        isPath = true;
        const pathPrice = _get(catalogue, 'price.amount');
        if (pathPrice && Number(pathPrice) !== 0) {
          statusLabel = formatCurrency(pathPrice);
        }
      }
      const customFieldDefinitions =
        isPath === true
          ? pathCustomFieldDefinitions
          : courseCustomFieldDefinitions;

      let overrideLink;
      let isValidLink = false;
      try {
        if (cardLinkBuilder) {
          overrideLink = cardLinkBuilder(catalogue);
          isValidLink = isValidUrl(overrideLink);
          if (!isValidLink) {
            // eslint-disable-next-line no-console
            console.warn(
              `WebLink: ${overrideLink} is not a valid URL. The Catalogue Card will revert to default linking behavior.`,
            );
          }
        }
      } catch (e) {
        overrideLink = null;
        // eslint-disable-next-line no-console
        console.warn('Error generating link:', e);
      }

      const cardCourse = (
        <Card
          title={
            <Truncate lines={2} ellipsis="...">
              {catalogue.name}
            </Truncate>
          }
          label={
            showCategory
              ? {
                  text: catalogue.category,
                  color: ColorOptions.LightGrey,
                  extraClass: 'CardCourse--highlights',
                }
              : undefined
          }
          imageSource={catalogue.imageUrl || CATALOG_ITEM_IMAGE_PLACEHOLDER}
          tag={
            showFeaturedLabel && catalogue.isFeatured
              ? {
                  text: t('weblink:featured'),
                  color: ColorOptions.Yellow,
                }
              : undefined
          }
          onClick={getCardClickHandler(
            integration,
            catalogue,
            isValidLink ? overrideLink : null,
            onSelect,
            setSearchBarText,
          )}
          stacked={isPath}
          stretchHeight
          extraClass="catalogue-card"
        >
          {customFieldDefinitions && (
            <CardCustomFields
              definitions={customFieldDefinitions}
              values={catalogue.customFieldValues}
            />
          )}
          {showDescription && (
            <CardTeaserDescription
              description={
                isPath ? catalogue.description : catalogue.teaserDescription
              }
            />
          )}
          {displayPrices && (
            <div className="CardCourse--values">
              <span className="Card--values--primary">{statusLabel}</span>
            </div>
          )}
        </Card>
      );

      const linkCategoryPrefix = `${
        categoryId ? `/catalogue/${categoryId}` : ''
      }${subcategoryId ? `/subcategory/${subcategoryId}` : ''}`;
      return (
        <div
          key={catalogue.id}
          className="CardLink"
          role="link"
          tabIndex={index + 1}
        >
          <CardLink
            catalogue={catalogue}
            linkCategoryPrefix={linkCategoryPrefix}
            type={type}
            overrideLink={isValidLink ? overrideLink : null}
            isIntegration={integration}
          >
            {cardCourse}
          </CardLink>
        </div>
      );
    })}
  </div>
);

const CardLink = ({
  catalogue,
  children,
  linkCategoryPrefix,
  type,
  overrideLink,
  isIntegration,
}) => {
  if (overrideLink) {
    return (
      <a className="link-text" href={overrideLink}>
        {children}
      </a>
    );
  }

  if (isIntegration) {
    return children;
  }

  return (
    <Link
      className="link-text"
      to={
        type === 'Course'
          ? `${linkCategoryPrefix}/courses/${catalogue.code}%1E-${slugify(
              catalogue.name,
            )}`
          : `${linkCategoryPrefix}/paths/${catalogue.id}%1E-${slugify(
              catalogue.name,
            )}`
      }
      aria-label={`${catalogue.name} (ID: ${
        type === 'Course' ? catalogue.code : catalogue.id
      })`}
    >
      {children}
    </Link>
  );
};

const CardTeaserDescription = ({ description }) => {
  if (!description) {
    return <div className="CardCourse--teaser" />;
  }

  return (
    <HTMLContainer
      className="CardCourse--teaser"
      content={description}
      clearTags
    />
  );
};

CardTeaserDescription.propTypes = {
  description: PropTypes.string,
};

CardTeaserDescription.defaultProps = {
  description: null,
};

const cataloguePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  customFieldValues: PropTypes.arrayOf(
    PropTypes.shape({
      definitionKey: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
});

CardLink.propTypes = {
  catalogue: cataloguePropType.isRequired,
  linkCategoryPrefix: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  isIntegration: PropTypes.bool.isRequired,
  overrideLink: PropTypes.string,
};

CardLink.defaultProps = {
  overrideLink: null,
};

CatalogueView.defaultProps = {
  catalogueItems: [],
  categoryId: null,
  subcategoryId: null,
  displayPrices: true,
  courseCustomFieldDefinitions: null,
  pathCustomFieldDefinitions: null,
  showDescription: false,
  showCategory: true,
  showFeaturedLabel: true,
  cardLinkBuilder: null,
};

CatalogueView.propTypes = {
  catalogueItems: PropTypes.arrayOf(cataloguePropType),
  onSelect: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
  setSearchBarText: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  subcategoryId: PropTypes.string,
  displayPrices: PropTypes.bool,
  courseCustomFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  pathCustomFieldDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  showDescription: PropTypes.bool,
  showCategory: PropTypes.bool,
  showFeaturedLabel: PropTypes.bool,
  cardLinkBuilder: PropTypes.func,
};

const CatalogueViewWithCurrency = withIntegration(
  withTranslation()(withCurrency(CatalogueView)),
);

CatalogueViewWithCurrency.Loading = CatalogueLoadingSkeleton;

export default CatalogueViewWithCurrency;
