import React from 'react';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-client';
import { ApolloProvider as HooksApolloProvider } from '@apollo/react-hooks';
import { ApolloProvider as ReactApolloProvider } from 'react-apollo';
import { useAuth0 } from '../../auth/lib/react-auth0-wrapper';
import { createAuthenticatedClient } from '../apolloClient';

const ApolloClientProvider = ({ apolloClient, children }) => {
  return (
    <ReactApolloProvider client={apolloClient}>
      <HooksApolloProvider client={apolloClient}>
        {children}
      </HooksApolloProvider>
    </ReactApolloProvider>
  );
};

ApolloClientProvider.propTypes = {
  apolloClient: PropTypes.instanceOf(ApolloClient).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const WeblinkApiProvider = ({
  guestApolloClient,
  weblinkApiConfiguration,
  children,
}) => {
  const { isAuthenticated, getTokenSilently } = useAuth0();
  if (!isAuthenticated) {
    return (
      <ApolloClientProvider apolloClient={guestApolloClient}>
        {children}
      </ApolloClientProvider>
    );
  }

  const { uri, portalAuthDomain } = weblinkApiConfiguration;
  const authenticatedApolloClient = createAuthenticatedClient({
    uri,
    portalAuthDomain,
    getTokenSilently,
  });
  return (
    <ApolloClientProvider apolloClient={authenticatedApolloClient}>
      {children}
    </ApolloClientProvider>
  );
};

export const WeblinkApiConfigurationPropType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  portalAuthDomain: PropTypes.string.isRequired,
});

WeblinkApiProvider.propTypes = {
  guestApolloClient: PropTypes.instanceOf(ApolloClient).isRequired,
  weblinkApiConfiguration: WeblinkApiConfigurationPropType.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
