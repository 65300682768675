import { decorate, action, observable, runInAction } from 'mobx';

class PortalStore {
  constructor() {
    runInAction(() => {
      this.embedSite = null;
      this.portalAuthDomain = null;
      this.postCheckoutRedirectUrl = null;
    });
  }

  setPortalAuthDomain = action(portalAuthDomain => {
    if (this.portalAuthDomain !== portalAuthDomain) {
      this.portalAuthDomain = portalAuthDomain;
    }
  });

  setEmbedSite = action(embedSite => {
    if (this.embedSite !== embedSite) {
      this.embedSite = embedSite;
    }
  });

  setPostCheckoutRedirectUrl = action(url => {
    if (this.postCheckoutRedirectUrl !== url) {
      this.postCheckoutRedirectUrl = url;
    }
  });
}

decorate(PortalStore, {
  embedSite: observable,
  portalAuthDomain: observable,
  postCheckoutRedirectUrl: observable,
});

export default new PortalStore();
