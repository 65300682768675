import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Avatar } from '@administrate/piston-ux';
import { MenuItem, NavDropdown } from 'react-bootstrap';
import { useAuth0 } from '../../auth/lib/react-auth0-wrapper';

const UserMenu = ({ iconOnly, t }) => {
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();
  const login = () =>
    loginWithRedirect({
      appState: {
        targetPath: window.location.pathname + window.location.search,
      },
    });
  return (
    <NavDropdown
      id="menubar_user"
      noCaret={!isAuthenticated}
      onClick={!isAuthenticated ? login : undefined}
      title={
        <>
          <Avatar icon="user" size="xs" />
          {!iconOnly && (
            <>
              &nbsp;&nbsp;
              {isAuthenticated
                ? `${user.given_name} ${user.family_name}`
                : t('weblink:signIn')}
            </>
          )}
        </>
      }
      data-id={iconOnly ? 'mobile-login-menu' : 'login-menu'}
    >
      {iconOnly && isAuthenticated && (
        <MenuItem disabled>
          {user.given_name} {user.family_name}
        </MenuItem>
      )}
      {isAuthenticated && (
        <MenuItem onClick={logout} data-id="login-menu-logout-entry">
          {t('weblink:signOut')}
        </MenuItem>
      )}
    </NavDropdown>
  );
};

UserMenu.defaultProps = {
  iconOnly: false,
};

UserMenu.propTypes = {
  iconOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserMenu);
