import React, { useContext } from 'react';
import PropTypes from 'prop-types';

const IntegrationContext = React.createContext();
export const useIntegration = () => useContext(IntegrationContext);

export const IntegrationProvider = ({ active, children }) => {
  return (
    <IntegrationContext.Provider value={{ active }}>
      {children}
    </IntegrationContext.Provider>
  );
};

IntegrationProvider.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
