export const UNAUTHORIZED_CART_RETRIEVAL_MESSAGE =
  'The cart does not match the authenticated user';

export const INVALID_TOKEN_CART_RETRIEVAL_MESSAGE = `The specified recovery token is invalid`;

export class UnauthorizedCartRetrievalError extends Error {
  constructor() {
    super(UNAUTHORIZED_CART_RETRIEVAL_MESSAGE);
  }
}

export class InvalidRetrievalTokenError extends Error {
  constructor() {
    super(INVALID_TOKEN_CART_RETRIEVAL_MESSAGE);
  }
}
