import gql from 'graphql-tag';

export const paymentRegionPaymentProviders = gql`
  query paymentRegions($regionId: ID!, $currencyCode: String!) {
    paymentRegions(regionId: $regionId, currencyCode: $currencyCode) {
      paymentProviders {
        id
        __typename
        paymentAttributeDefinitions {
          key
          label
          type
          options {
            label
            value
          }
        }
        ... on Stripe {
          publicKey
        }
        ... on Check {
          mailingAddress {
            lines
            postcode
            region
            town
            country {
              name
            }
          }
        }
        ... on Converge {
          isLive
        }
      }
    }
  }
`;
