import React from 'react';
import { isIE } from 'react-device-detect';
import { Alert } from '@administrate/piston-ux';

export const SupportMessage = () => {
  if (!isIE) return null;

  return (
    <Alert
      type="warning"
      className="support-message"
      message={
        <>
          As of 31 March, 2022, We will no longer be supporting the Internet
          Explorer 11 (IE11) browser. Accessing this application with IE11 on or
          after that date may result in degraded or unexpected site behavior. We
          suggest that you upgrade as soon as possible to a more modern browser
          such as{' '}
          <a
            target="_blank"
            href="https://www.google.com/chrome/"
            rel="noopener noreferrer"
          >
            Google Chrome
          </a>
          . Read more on our{' '}
          <a
            target="_blank"
            href="https://support.getadministrate.com/hc/en-us/articles/360012089854"
            rel="noopener noreferrer"
          >
            supported browser list page
          </a>
          .
        </>
      }
    />
  );
};
