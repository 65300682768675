import React from 'react';
import { observer } from 'mobx-react-lite';
import { SecondaryAction } from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import inject from '../../../containers/inject';
import { STORES } from '../../../constants';

const { STORE_CHECKOUT, STORE_CART } = STORES;

const PaymentButton = ({
  [STORE_CHECKOUT]: { isLoading },
  [STORE_CART]: { isApplyPromotionCodeLoading },
  t,
  label = t('weblink:continue'),
  disabled = isApplyPromotionCodeLoading || isLoading,
  onClick,
}) => (
  <SecondaryAction
    label={
      <span>
        {label}
        {isLoading && (
          <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
        )}
      </span>
    }
    disabled={disabled}
    onClick={onClick}
  />
);

PaymentButton.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PaymentButton.defaultProps = {
  label: undefined,
  disabled: undefined,
};

export const CheckoutPaymentButton = withTranslation()(
  inject(STORE_CHECKOUT, STORE_CART)(observer(PaymentButton)),
);
