import { LoadingScreen } from '@administrate/piston-ux';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { STORES } from '../../../../constants';
import inject from '../../../inject';
import WeblinkNavigation from '../../../WeblinkNavigation/WeblinkNavigation';
import { removePaymentProviderDetailsFromQueryString } from './RedirectPaymentCancelCallback';

const { STORE_CART, STORE_CHECKOUT } = STORES;

const ConfirmingPayment = ({ t }) => {
  return (
    <>
      <WeblinkNavigation type="basic" disabled />
      <LoadingScreen message={t('weblink:paymentLoading')} />
    </>
  );
};

ConfirmingPayment.propTypes = {
  t: PropTypes.func.isRequired,
};

const RedirectPaymentCompleteCallback = ({
  [STORE_CART]: {
    cart: { state: cartState },
    isLoading,
  },
  [STORE_CHECKOUT]: { waitForRedirectCheckoutComplete },
  t,
}) => {
  const isPending = cartState === 'pending';

  useEffect(() => {
    if (!isLoading && isPending) {
      waitForRedirectCheckoutComplete();
    }
  }, [waitForRedirectCheckoutComplete, isPending, isLoading]);

  if (isLoading || isPending) {
    return <ConfirmingPayment t={t} />;
  }

  const queryString = removePaymentProviderDetailsFromQueryString(
    window.location.href,
  );

  return <Redirect to={{ pathname: '/checkout', search: `?${queryString}` }} />;
};

RedirectPaymentCompleteCallback.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  inject(STORE_CART, STORE_CHECKOUT)(observer(RedirectPaymentCompleteCallback)),
);
