import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ExtraErrorData } from '@sentry/integrations';
import { history } from './history';

import createApolloClient from './apollo';
import createStores from './stores';
import Weblink from './App';
import config from './config';
import queries from './queries';

if (config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    environment: config.sentryEnvironment,
    release: config.sentryRelease,
    integrations: [new ExtraErrorData({ depth: 3 })],
    normalizeDepth: 4,
    // Don't capture transaction data
    tracesSampleRate: 0.0,
    ignoreErrors: [
      // Email link Microsoft Outlook crawler compatibility error
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    ],
  });
}

if (config.isDev) {
  // eslint-disable-next-line
  import('./index.css');
}

const { graphQLURI: SFAPIURL, portalAuthURI } = config;
const portalAuthDomain = window.location.hostname;

const apolloClient = createApolloClient({
  SFAPIURL,
  portalAuthURL: portalAuthURI,
  portalAuthDomain,
});
// prime the cache with store details
apolloClient.query({ query: queries.store.getStoreDetails });

const stores = createStores(false, { history, apolloClient });

ReactDOM.render(
  <Weblink
    stores={stores}
    history={history}
    apolloClient={apolloClient}
    weblinkApiConfiguration={{
      uri: SFAPIURL,
      portalAuthDomain,
    }}
  />,
  document.getElementById('weblink-root'),
);
