import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';

import CustomInput from '../CustomInput';
import { CartItem } from '../../stores/Cart';

const translationTypeMap = {
  Contact: 'weblink:contactInformation',
  Event: 'weblink:eventInformation',
  Path: 'weblink:pathInformation',
};

const LearnerAttributeSection = ({ item, type, learner, definitions, t }) => {
  if (definitions.length === 0) {
    return null;
  }

  // TODO: Use SubSection from piston-ux below instead of h5 and hr
  // Unfortunately SubSection uses h3 at the moment, which doesn't match
  // the design in piston-playground
  const translationType = translationTypeMap[type];
  return (
    <div className="checkout_learner_attribute-wrapper">
      <h4>{t(translationType)}</h4>
      <hr className="checkout_learner_attribute-separator" />
      <div className="checkout_learner_attribute-grid">
        {definitions.map(definition => (
          <CustomInput
            key={definition.key}
            definition={definition}
            learner={learner}
            id={`${item.id}_${learner.id}_${type}_${definition.key}`}
          />
        ))}
      </div>
    </div>
  );
};

LearnerAttributeSection.propTypes = {
  item: PropTypes.instanceOf(CartItem).isRequired,
  type: PropTypes.oneOf(['Contact', 'Event', 'Path']).isRequired,
  learner: PropTypes.shape({}).isRequired,
  definitions: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(observer(LearnerAttributeSection));
