import React from 'react';
import { Card } from '@administrate/piston';

export const CatalogueLoadingSkeleton = () => (
  <div className="CardWrapper loading">
    <div className="CardLink">
      <Card>
        <Card.Course />
      </Card>
    </div>
    <div className="CardLink">
      <Card>
        <Card.Course />
      </Card>
    </div>
    <div className="CardLink">
      <Card>
        <Card.Course />
      </Card>
    </div>
  </div>
);
