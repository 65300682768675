import { get as _get } from 'lodash';
import { DateTime, Interval } from 'luxon';

import { formatDate, formatTime } from '../utils/formatDateTime';
import { DELIVERY_METHODS } from '../constants';

export default class Event {
  constructor({
    id,
    eventFromAPI,
    location = 'Undecided',
    venue = 'Undecided',
    price: { amount = 0, currency },
    range,
    classroomRange,
    remainingPlaces,
    maxPlaces,
    eventName,
    deliveryMethod,
    accessDuration,
    lmsEventStartDate,
    lmsEventEndDate,
    courseCode,
    courseId,
    priceLevels,
    locale,
    sessionCount,
    registrationOpen,
    registrationOpensAt,
  }) {
    this.id = id;
    this.eventFromAPI = eventFromAPI;
    this.courseCode = courseCode;
    this.courseId = courseId;
    this.location = location;
    this.venue = venue;
    this.price = {
      amount,
      currency,
    };
    this.lmsEventStartDate = lmsEventStartDate;
    this.lmsEventEndDate = lmsEventEndDate;
    this.range =
      deliveryMethod === DELIVERY_METHODS.BLENDED && classroomRange
        ? classroomRange
        : range;
    this.classroomRange = classroomRange;
    this.remainingPlaces = remainingPlaces;
    this.maxPlaces = maxPlaces;
    this.eventName = eventName;
    this.deliveryMethod = deliveryMethod;
    this.accessDuration = accessDuration;
    this.priceLevels = priceLevels;
    this.locale = locale;
    this.sessionCount = sessionCount;
    this.registrationOpen = registrationOpen;
    this.registrationOpensAt = registrationOpensAt;
  }

  get timeZone() {
    return this.range.start.toFormat('ZZZZ');
  }

  get startDate() {
    return formatDate(this.range.start);
  }

  get startTime() {
    return formatTime(this.range.start);
  }

  get endTime() {
    return formatTime(this.range.end);
  }

  get endDateValid() {
    return this.range.end.isValid;
  }

  get endDate() {
    return formatDate(this.range.end);
  }

  get duration() {
    return this.deliveryMethod === DELIVERY_METHODS.LMS
      ? this.accessDuration
      : Math.ceil(this.range.toDuration('days').days);
  }

  get classroomTimeZone() {
    return this.classroomRange && this.classroomRange.start.toFormat('ZZZZ');
  }

  get classroomStartDate() {
    return this.classroomRange && formatDate(this.classroomRange.start);
  }

  get classroomStartTime() {
    return this.classroomRange && formatTime(this.classroomRange.start);
  }

  get classroomEndTime() {
    return this.classroomRange && formatTime(this.classroomRange.end);
  }

  get classroomEndDateValid() {
    return this.classroomRange && this.classroomRange.end.isValid;
  }

  get classroomEndDate() {
    return this.classroomRange && formatDate(this.classroomRange.end);
  }

  get classroomDuration() {
    return (
      this.classroomRange &&
      (this.deliveryMethod === 'lms'
        ? this.accessDuration
        : Math.ceil(this.classroomRange.toDuration('days').days))
    );
  }

  get lmsTimeZone() {
    return this.lmsEventStartDate && this.lmsEventStartDate.toFormat('ZZZZ');
  }

  get lmsStartDate() {
    return (
      this.lmsEventStartDate && formatDate(this.lmsEventStartDate.toFormat)
    );
  }

  get lmsStartTime() {
    return this.lmsEventStartDate && formatTime(this.lmsEventStartDate);
  }

  get lmsEndTime() {
    return this.lmsEventEndDate && formatTime(this.lmsEventEndDate);
  }

  get lmsEndDateValid() {
    return this.lmsEventEndDate && this.lmsEventEndDate.isValid;
  }

  get lmsEndDate() {
    return this.lmsEventEndDate && formatDate(this.lmsEventEndDate);
  }

  get lmsDuration() {
    return (
      this.lmsEventStartDate &&
      this.lmsEventEndDate &&
      Math.ceil(
        this.lmsEventEndDate.diff(this.lmsEventStartDate, ['days']).toObject()
          .days,
      )
    );
  }

  static fromAPI(eventFromAPI, currencyCode) {
    const zone = _get(eventFromAPI, 'node.timeZoneName');
    const startDate = DateTime.fromISO(_get(eventFromAPI, 'node.start'), {
      zone,
    });
    const endDate = DateTime.fromISO(_get(eventFromAPI, 'node.end'), { zone });
    const classroomStart = _get(eventFromAPI, 'node.classroomStart');
    const classroomEnd = _get(eventFromAPI, 'node.classroomEnd');
    const lmsStart = _get(eventFromAPI, 'node.lmsStart');
    const lmsEnd = _get(eventFromAPI, 'node.lmsEnd');

    return new Event({
      id: _get(eventFromAPI, 'node.id'),
      eventFromAPI: eventFromAPI.node,
      range: Interval.fromDateTimes(startDate, endDate),
      classroomRange:
        classroomStart && classroomEnd
          ? Interval.fromDateTimes(
              DateTime.fromISO(classroomStart),
              DateTime.fromISO(classroomEnd),
            )
          : null,
      location: _get(eventFromAPI, 'node.location.name'),
      venue: _get(eventFromAPI, 'node.venue.name'),
      lmsEventStartDate: lmsStart ? DateTime.fromISO(lmsStart) : null,
      lmsEventEndDate: lmsEnd ? DateTime.fromISO(lmsEnd) : null,
      price: {
        amount: _get(eventFromAPI, 'node.price.amount'),
        currency: currencyCode,
      },
      remainingPlaces: _get(eventFromAPI, 'node.remainingPlaces'),
      maxPlaces: _get(eventFromAPI, 'node.maxPlaces'),
      eventName: _get(eventFromAPI, 'node.name'),
      deliveryMethod: _get(eventFromAPI, 'node.deliveryMethod'),
      accessDuration: _get(eventFromAPI, 'node.accessDuration'),
      courseCode: _get(eventFromAPI, 'node.course.code'),
      courseId: _get(eventFromAPI, 'node.course.id'),
      priceLevels: _get(eventFromAPI, 'node.priceLevels'),
      locale: _get(eventFromAPI, 'node.locale.name'),
      sessionCount: _get(eventFromAPI, 'node.sessionCount'),
      registrationOpen: _get(eventFromAPI, 'node.registrationOpen'),
      registrationOpensAt: DateTime.fromISO(
        _get(eventFromAPI, 'node.registrationOpensAt'),
      ),
    });
  }
}
