import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { PageLoadingSkeleton } from '../../../../components/PageLoadingSkeleton';
import { STORES } from '../../../../constants';
import inject from '../../../inject';

const { STORE_CART } = STORES;

export const removePaymentProviderDetailsFromQueryString = currentUrl => {
  const url = new URL(currentUrl);
  const searchParams = new URLSearchParams(url.search);

  const paymentProviderQueryParameters = [
    'transactionid',
    'orderKey',
    'paymentStatus',
    'paymentAmount',
    'paymentCurrency',
    'mac2',
  ];

  paymentProviderQueryParameters.forEach(queryParameter =>
    searchParams.delete(queryParameter),
  );

  return searchParams.toString();
};

const RedirectPaymentCancelCallback = ({
  [STORE_CART]: {
    cart: { id, state: cartState },
    isLoading,
    moveCartToEntry,
  },
}) => {
  const isPending = cartState === 'pending';

  useEffect(() => {
    if (isPending) {
      moveCartToEntry(id);
    }
  }, [moveCartToEntry, id, isPending]);

  if (isLoading || isPending) {
    return <PageLoadingSkeleton />;
  }

  const queryString = removePaymentProviderDetailsFromQueryString(
    window.location.href,
  );

  return <Redirect to={{ pathname: '/checkout', search: `?${queryString}` }} />;
};

export default inject(STORE_CART)(observer(RedirectPaymentCancelCallback));
