import React, { useContext } from 'react';
import { STORE } from '../stores/contexts/store';

const inject = (...selector) => WrappedComponent => props => {
  const stores = useContext(STORE);
  const storeProps = selector.reduce(
    (selected, key) => ({
      [key]: stores[key],
      ...selected,
    }),
    {},
  );
  return <WrappedComponent {...storeProps} {...props} />;
};

export default inject;
