import { observable, decorate, runInAction, action } from 'mobx';
import bundleConfig from '../bundleConfig';
import { INTEGRATON_NAVIGATION_PAGES } from '../constants';

const {
  CATALOGUE,
  COURSES,
  PATHS,
  CART,
  EVENTLIST,
  TRAININGREQUEST,
  PAGE,
} = INTEGRATON_NAVIGATION_PAGES;

class IntegrationNavigationStore {
  constructor() {
    runInAction(() => {
      this.page = CATALOGUE;
      this.courseCode = null;
      this.pathId = null;
      this.interestId = null;
      this.categoryId = null;
      this.subcategoryId = null;
      this.courseNameSearchText = null;
      this.widgetCataloguePage = null;
      if (bundleConfig.hashRouting) {
        this.parseHash();
        window.addEventListener('hashchange', this.parseHash);
      }
    });
  }

  goToCourse = action(
    ({ categoryId = null, subcategoryId = null, courseCode = null }) => {
      this.categoryId = categoryId;
      this.subcategoryId = subcategoryId;
      this.courseCode = courseCode;
      this.page = COURSES;
      this.createHash({
        page: COURSES,
        categoryId,
        subcategoryId,
        courseCode,
      });
    },
  );

  goToPath = action(
    ({ categoryId = null, subcategoryId = null, pathId = null }) => {
      this.categoryId = categoryId;
      this.subcategoryId = subcategoryId;
      this.pathId = pathId;
      this.page = PATHS;
      this.createHash({
        page: PATHS,
        categoryId,
        subcategoryId,
        pathId,
      });
    },
  );

  goToCatalogue = action(
    (
      {
        categoryId = null,
        subcategoryId = null,
        courseNameSearchText = null,
      } = { categoryId: null, subcategoryId: null, courseNameSearchText: null },
    ) => {
      this.categoryId = categoryId;
      this.subcategoryId = subcategoryId;
      this.courseNameSearchText = courseNameSearchText;
      this.page = CATALOGUE;
      this.courseCode = null;
      this.pathId = null;
      this.createHash({
        page: CATALOGUE,
        categoryId,
        subcategoryId,
        courseNameSearchText,
      });
    },
  );

  goToPage = action(
    ({ pageNumber = null, categoryId = null, subcategoryId = null }) => {
      this.createHash({
        page: PAGE,
        pageNumber,
        categoryId,
        subcategoryId,
      });
    },
  );

  goToCart = action(() => {
    this.page = CART;
    this.createHash({ page: CART });
  });

  goToEventList = action(() => {
    this.page = EVENTLIST;
    this.courseCode = null;
    this.pathId = null;
    this.categoryId = null;
    this.courseNameSearchText = null;
    this.createHash({ page: EVENTLIST });
  });

  goToRequestTraining = action((interestId = null) => {
    this.interestId = interestId;
    this.page = TRAININGREQUEST;
    this.createHash({
      page: TRAININGREQUEST,
      interestId,
    });
  });

  createHash = ({
    page = null,
    categoryId = null,
    subcategoryId = null,
    courseCode = null,
    pathId = null,
    interestId = null,
    courseNameSearchText = null,
    pageNumber = null,
  }) => {
    if (bundleConfig.hashRouting) {
      let hash = '';
      // Catalogue
      if (page === CATALOGUE) {
        hash = 'catalogue';
      }
      // Catalogue, Course, Path
      if (categoryId) {
        hash = `catalogue/${categoryId}/`;

        if (subcategoryId) {
          hash += `subcategory/${subcategoryId}/`;
        }
      }
      if (courseCode) {
        hash += `courses/${courseCode}/`;
      }
      if (pathId) {
        hash += `paths/${pathId}/`;
      }
      if (courseNameSearchText) {
        hash = '';
        if (categoryId) {
          hash += `catalogue/${categoryId}/`;

          if (subcategoryId) {
            hash += `subcategory/${subcategoryId}/`;
          }
        }
        hash += `nameSearch/${courseNameSearchText}/`;
      }
      if (pageNumber) {
        hash += `page/${pageNumber}/`;
      }
      // Cart
      if (page === CART) {
        hash = 'cart';
      }
      if (page === TRAININGREQUEST) {
        hash = `trainingRequest/${interestId}/`;
      }
      window.location.hash = hash;
    }
  };

  parseHash = action(() => {
    const hashArray = window.location.hash
      .substr(1)
      .split('/')
      .filter(element => element !== '');
    const hashObject = {};
    for (let i = 0; i < hashArray.length; i += 2) {
      hashObject[hashArray[i]] = hashArray[i + 1];
    }
    this.courseCode = null;
    this.pathId = null;
    this.categoryId = null;
    this.subcategoryId = null;
    this.courseNameSearchText = null;
    this.PAGE = null;
    this.widgetCataloguePage = null;
    if ('courses' in hashObject) {
      this.courseCode = hashObject.courses;
      this.categoryId = hashObject.catalogue;
      this.subcategoryId = hashObject.subcategory;
      this.page = COURSES;
    } else if ('paths' in hashObject) {
      this.pathId = hashObject.paths;
      this.categoryId = hashObject.catalogue;
      this.subcategoryId = hashObject.subcategory;
      this.page = PATHS;
    } else if ('cart' in hashObject) {
      this.page = CART;
    } else if ('catalogue' in hashObject || 'nameSearch' in hashObject) {
      this.categoryId = hashObject.catalogue || null;
      this.subcategoryId = hashObject.subcategory || null;
      this.courseNameSearchText = hashObject.nameSearch || null;
      this.page = CATALOGUE;
    } else if ('eventList' in hashObject) {
      this.page = EVENTLIST;
    } else if ('trainingRequest' in hashObject) {
      this.interestId = hashObject.trainingRequest;
      this.page = TRAININGREQUEST;
    } else if ('page' in hashObject) {
      this.categoryId = hashObject.catalogue;
      this.subcategoryId = hashObject.subcategory;
      this.page = PAGE;
      this.widgetCataloguePage = hashObject.page;
    }
  });
}

decorate(IntegrationNavigationStore, {
  page: observable,
  courseCode: observable,
  pathId: observable,
  categoryId: observable,
  subcategoryId: observable,
  courseNameSearchText: observable,
  widgetCataloguePage: observable,
});

export default IntegrationNavigationStore;
