import React from 'react';
import PropTypes from 'prop-types';
import { Card, StatusLabel } from '@administrate/piston';
import {
  Col,
  Container,
  OpacityOverlay,
  Placeholder,
  Row,
} from '@administrate/piston-ux';
import SecondaryAction from '@administrate/piston/dist/atoms/SecondaryAction/SecondaryAction';

const style = {
  flexConatiner: {
    alignItems: 'center',
    height: '30px',
  },
  horizontalRule: {
    top: '50%',
  },
  container: {
    height: '30px',
  },
  label: {
    marginRight: '10px',
  },
};

export const LearnersSkeleton = ({ t }) => (
  <OpacityOverlay on>
    <Card>
      <h1 className="CardTitle">{t('weblink:learnerDetails')}</h1>
      <Card.Body>
        <Container fluid>
          <Row className="mb-3">
            <Col xs={12}>
              <Placeholder />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <div
                className="checkout_setlearners_courses_learner-title learner-title"
                style={style.container}
              >
                <div
                  className="checkout_setlearners_courses_learner-title_hr"
                  style={style.horizontalRule}
                />
                <div
                  className="checkout_setlearners_courses_learner-title_group"
                  style={style.flexConatiner}
                >
                  <div
                    className="checkout_setlearners_courses_learner-title_label"
                    style={style.label}
                  >
                    <StatusLabel color="lightgrey">
                      {`${t('weblink:learner')} 1`}
                    </StatusLabel>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>
              <Placeholder />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Placeholder />
            </Col>
            <Col xs={12} md={6}>
              <Placeholder />
            </Col>
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer
        left={
          <span className="d-none btn btn-link d-sm-inline-block">
            {t('weblink:backToCustomerInfo')}
          </span>
        }
        right={<SecondaryAction label={t('weblink:continue')} disabled />}
      />
    </Card>
  </OpacityOverlay>
);

LearnersSkeleton.propTypes = {
  t: PropTypes.func.isRequired,
};
