/*

Payment error code sources

Stripe: https://stripe.com/docs/declines/codes
*/

const stripe = {
  cardDeclined: [
    'call_issuer',
    'card_velocity_exceeded',
    'do_not_honor',
    'do_not_try_again',
    'duplicate_transaction',
    'invalid_account',
    'generic_decline',
    'lost_card',
    'new_account_information_available',
    'no_action_taken',
    'not_permitted',
    'pickup_card',
    'restricted_card',
    'revocation_of_all_authorizations',
    'revocation_of_authorization',
    'security_violation',
    'service_not_allowed',
    'stolen_card',
    'stop_payment_order',
    'transaction_not_allowed',
    'card_declined',
  ],
  cardDetailsIncorrect: [
    'incorrect_number',
    'incorrect_cvc',
    'incorrect_zip',
    'invalid_amount',
    'invalid_cvc',
    'invalid_expiry_month',
    'invalid_expiry_year',
    'invalid_number',
  ],
  cardExpired: ['expired_card'],
  cardNotAccepted: ['card_not_supported', 'currency_not_supported'],
  alternativeCardRequired: [
    'withdrawal_count_limit_exceeded',
    'card_decline_rate_limit_exceeded',
  ],
  insufficientFunds: ['insufficient_funds'],
  tryCardAgain: [
    'issuer_not_available',
    'processing_error',
    'reenter_transaction',
    'try_again_later',
  ],
  notTestMode: ['testmode_decline', 'live_mode_test_card'],
  authenticationFailure: ['payment_intent_authentication_failure'],
};

const paymentProviders = [stripe];

export const WEBLINK_CARD_DECLINED_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.cardDeclined,
);
export const WEBLINK_CARD_DETAILS_INCORRECT_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.cardDetailsIncorrect,
);
export const WEBLINK_CARD_EXPIRED_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.cardExpired,
);
export const WEBLINK_CARD_TYPE_NOT_ACCEPTED_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.cardNotAccepted,
);
export const WEBLINK_ALTERNATIVE_CARD_REQUIRED_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.alternativeCardRequired,
);
export const WEBLINK_INSUFFICIENT_FUNDS_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.insufficientFunds,
);
export const WEBLINK_TRY_CARD_AGAIN_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.tryCardAgain,
);
export const WEBLINK_NOT_TEST_MODE_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.notTestMode,
);
export const WEBLINK_AUTHENTICATION_FAILURE_ERROR_CODES = paymentProviders.flatMap(
  provider => provider.authenticationFailure,
);
