import { decorate, action, observable, runInAction } from 'mobx';

class ModalOutletStore {
  constructor() {
    runInAction(() => {
      this.outletId = null;
    });
  }

  setOutletId = action(outletId => {
    this.outletId = outletId;
  });
}

decorate(ModalOutletStore, {
  outletId: observable,
});

export default ModalOutletStore;
