import gql from 'graphql-tag';
import fragments from './fragments';

const applyPromotionCode = gql`
  mutation applyPromotionCode($promotionCode: String!, $cartId: ID!) {
    cart {
      applyPromotionCode(
        input: { promotionCode: $promotionCode, cartId: $cartId }
      ) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const removePromotionCode = gql`
  mutation removePromotionCode($promotionCode: String!, $cartId: ID!) {
    cart {
      removePromotionCode(
        input: { promotionCode: $promotionCode, cartId: $cartId }
      ) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const applyGiftVoucher = gql`
  mutation applyGiftVoucher($code: String!, $cartId: ID!) {
    cart {
      applyGiftVoucher(input: { code: $code, cartId: $cartId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const unapplyGiftVoucher = gql`
  mutation unapplyGiftVoucher($code: String!, $cartId: ID!) {
    cart {
      unapplyGiftVoucher(input: { code: $code, cartId: $cartId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const createCart = gql`
  mutation createCart($firstName: String, $lastName: String, $email: String) {
    cart {
      createCart(
        input: { firstName: $firstName, lastName: $lastName, email: $email }
      ) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const updateEventLineItem = gql`
  mutation updateEventLineItem($input: UpdateEventLineItemInput!) {
    cart {
      updateEventLineItem(input: $input) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const removeCartLineItem = gql`
  mutation removeCartLineItem($cartId: ID!, $itemId: ID!) {
    cart {
      removeCartLineItem(input: { cartId: $cartId, itemId: $itemId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const placeOrder = gql`
  mutation placeOrder($input: PlaceOrderInput!) {
    cart {
      placeOrder(input: $input) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const updateCart = gql`
  mutation updateCart(
    $cartId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $company: String
    $billingAddress: PostalAddressInput
    $pointOfSaleFields: [PointOfSaleFieldValueInput!]
    $locale: String
  ) {
    cart {
      updateCart(
        input: {
          id: $cartId
          firstName: $firstName
          lastName: $lastName
          email: $email
          company: $company
          billingAddress: $billingAddress
          pointOfSaleFields: $pointOfSaleFields
          locale: $locale
        }
      ) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const getCartById = gql`
  query getCartById($id: ID!) {
    cart(id: $id) {
      ...Cart
    }
  }
  ${fragments.cart}
`;

const claimCartWithRecoveryToken = gql`
  mutation claimCartWithRecoveryToken($token: String!) {
    cart {
      claimCartWithRecoveryToken(input: { token: $token }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const addLineItem = gql`
  mutation addLineItem(
    $cartId: ID!
    $productId: ID!
    $quantity: Int!
    $learnerDetails: [NewLearnerDetailsInput!]!
    $objectives: [LearningPathObjectivesInput]
    $priceLevelId: ID
  ) {
    cart {
      addLineItem(
        input: {
          cartId: $cartId
          cartLineItem: {
            productOptionId: $productId
            quantity: $quantity
            learners: { newLearners: $learnerDetails }
            objectives: $objectives
            priceLevelId: $priceLevelId
          }
        }
      ) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const updatePathLineItem = gql`
  mutation updatePathLineItem($input: UpdatePathLineItemInput!) {
    cart {
      updatePathLineItem(input: $input) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const startMultisafepayCheckout = gql`
  mutation startCheckout($input: StartMultisafepayCheckoutInput!) {
    multisafepay {
      startCheckout(input: $input) {
        url
        errors {
          ...Error
        }
      }
    }
  }
  ${fragments.error}
`;

const startWorldpayCheckout = gql`
  mutation startCheckout($input: StartWorldpayCheckoutInput!) {
    worldpay {
      startCheckout(input: $input) {
        url
        errors {
          ...Error
        }
      }
    }
  }
  ${fragments.error}
`;

const moveCartPending = gql`
  mutation moveCartPending($cartId: ID!) {
    cart {
      moveCartPending(input: { cartId: $cartId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const moveCartToEntryStep = gql`
  mutation moveCartToEntryStep($cartId: ID!) {
    cart {
      moveCartToEntryStep(input: { cartId: $cartId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const prepareCartForCheckout = gql`
  mutation prepareCartForCheckout($cartId: ID!) {
    cart {
      prepareForCheckout(input: { cartId: $cartId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const createPaymentSession = gql`
  mutation createPaymentSession($input: PaymentSessionCreateInput!) {
    createPaymentSession(input: $input) {
      token
      validUntil
    }
  }
`;

const addGiftVoucherLineItem = gql`
  mutation addGiftVoucherLineItem(
    $cartId: ID!
    $productOptionId: ID!
    $amount: Decimal!
  ) {
    cart {
      addGiftVoucherLineItem(
        input: {
          cartId: $cartId
          productOptionId: $productOptionId
          amount: $amount
        }
      ) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const updateGiftVoucherLineItem = gql`
  mutation updateGiftVoucherLineItem($input: UpdateGiftVoucherLineItemInput!) {
    cart {
      updateGiftVoucherLineItem(input: $input) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const addChildLineItem = gql`
  mutation addChildLineItem($input: AddCartChildLineItemInput!) {
    cart {
      addChildLineItem(input: $input) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const prepareItemsForCheckout = gql`
  mutation prepareItemsForCheckout($cartId: ID!) {
    cart {
      prepareItemsForCheckout(input: { cartId: $cartId }) {
        errors {
          ...Error
        }
        cart {
          ...Cart
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

const claimCart = gql`
  mutation claimCart($cartId: ID!, $guestToken: String!) {
    cart {
      claimCart(input: { cartId: $cartId, guestToken: $guestToken }) {
        cart {
          ...Cart
        }
        errors {
          ...Error
        }
      }
    }
  }
  ${fragments.cart}
  ${fragments.error}
`;

export default {
  createCart,
  getCartById,
  claimCartWithRecoveryToken,
  addGiftVoucherLineItem,
  addChildLineItem,
  updateEventLineItem,
  updateGiftVoucherLineItem,
  removeCartLineItem,
  placeOrder,
  updateCart,
  addLineItem,
  updatePathLineItem,
  applyPromotionCode,
  removePromotionCode,
  applyGiftVoucher,
  unapplyGiftVoucher,
  startMultisafepayCheckout,
  startWorldpayCheckout,
  moveCartPending,
  moveCartToEntryStep,
  prepareCartForCheckout,
  createPaymentSession,
  prepareItemsForCheckout,
  claimCart,
};
