import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@administrate/piston';
import {
  Col,
  Container,
  OpacityOverlay,
  Placeholder,
  Row,
} from '@administrate/piston-ux';
import SecondaryAction from '@administrate/piston/dist/atoms/SecondaryAction/SecondaryAction';

export const DetailsSkeleton = ({ t }) => (
  <OpacityOverlay on>
    <Card>
      <h1 className="CardTitle">{t('weblink:checkoutBooker')}</h1>
      <Card.Body>
        <Container fluid>
          <Row className="mb-3">
            <Col xs={12}>
              <Placeholder />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Placeholder />
            </Col>
            <Col xs={12} md={6}>
              <Placeholder />
            </Col>
          </Row>
        </Container>
      </Card.Body>
      <Card.Footer
        right={
          <SecondaryAction
            label={t('weblink:continue')}
            disabled
          />
        }
      />
    </Card>
  </OpacityOverlay>
);

DetailsSkeleton.propTypes = {
  t: PropTypes.func.isRequired,
};
