import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DetailsItem from './DetailsItem';
import BillingAddressItem from './BillingAddressItem';

const BuyerDetails = ({ customer, t }) =>
  customer &&
  customer.firstName &&
  customer.lastName && (
    <div className="row mb-4">
      <DetailsItem
        label={t('weblink:name')}
        value={`${customer.firstName} ${customer.lastName}`}
      />
      <DetailsItem label={t('weblink:emailAddress')} value={customer.email} />
      {customer.billingAddress && (
        <BillingAddressItem
          label={t('weblink:billingAddress')}
          billingAddress={customer.billingAddress}
        />
      )}
    </div>
  );

BuyerDetails.propTypes = {
  t: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    company: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    billingAddress: PropTypes.object,
  }).isRequired,
};

export default withTranslation()(BuyerDetails);
