import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { AlertModal } from '@administrate/piston-ux';
import { useTranslation } from 'react-i18next';
import createAuth0Client from './auth0-spa-js.development';
import { PageLoadingSkeleton } from '../../components/PageLoadingSkeleton';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  enabled,
  domain,
  clientId,
  redirectUri,
  onRedirectCallback,
  returnTo,
  brand,
  leeway,
  useRefreshTokens,
  cacheLocation,
  children,
  preLogout,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        domain,
        client_id: clientId,
        redirect_uri: redirectUri,
        returnTo,
        brand,
        leeway,
        useRefreshTokens,
        cacheLocation,
      });
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (error) {
          if (error.message === 'Invalid state') {
            await auth0FromHook.loginWithRedirect();
          } else {
            setAuthError(true);
          }
        }
      }

      const _isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(_isAuthenticated);

      if (_isAuthenticated) {
        setUser(await auth0FromHook.getUser());
      }

      setLoading(false);
    };
    if (!enabled) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    }
    initAuth0();
  }, [
    enabled,
    domain,
    clientId,
    redirectUri,
    onRedirectCallback,
    returnTo,
    brand,
    leeway,
    useRefreshTokens,
    cacheLocation,
  ]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    setUser(await auth0Client.getUser());
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const _user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(_user);
  };

  const handleClickTryAgain = async () => {
    window.location.href = '/';
  };

  const { t } = useTranslation();
  if (authError) {
    return (
      <div className="login-page">
        <AlertModal
          title={t('authenticateError')}
          show
          onDone={() => handleClickTryAgain()}
          type="warningWithoutCancel"
        />
      </div>
    );
  }

  if (loading) return <PageLoadingSkeleton />;

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: paramObj => {
          if (preLogout) {
            preLogout();
          }
          return auth0Client.logout({
            redirect_uri: auth0Client.options.redirect_uri,
            ...auth0Client.customOptions,
            ...paramObj,
          });
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.defaultProps = {
  enabled: false,
  onRedirectCallback: () =>
    window.history.replaceState({}, document.title, window.location.pathname),
  leeway: 60,
  returnTo: window.location.origin,
  useRefreshTokens: false,
  cacheLocation: 'memory',
  preLogout: undefined,
};

Auth0Provider.propTypes = {
  enabled: PropTypes.bool,
  domain: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  onRedirectCallback: PropTypes.func,
  returnTo: PropTypes.string,
  brand: PropTypes.string.isRequired,
  leeway: PropTypes.number,
  useRefreshTokens: PropTypes.bool,
  cacheLocation: PropTypes.string,
  preLogout: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
