import PropTypes from 'prop-types';

export const summaryListProp = PropTypes.arrayOf(
  PropTypes.shape({
    itemId: PropTypes.string,
    title: PropTypes.string,
    quantity: PropTypes.number,
    imageUrl: PropTypes.string,
    isGiftVoucher: PropTypes.bool,
  }),
);
