import React from 'react';
import { observer } from 'mobx-react-lite';
import * as PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

const HamburgerMenu = ({
  menuOpen,
  menuItems,
  removedMenu,
  cartSize,
  cartlessCheckout,
  storeDetailsIsLoading,
  t,
}) => (
  <div
    className={`hamburger-menu ${menuOpen ? 'hamburger-menu-appear' : ''} ${
      removedMenu ? 'hamburger-menu-remove' : ''
    }`}
  >
    {menuItems.map(item => {
      if ((storeDetailsIsLoading || cartlessCheckout) && item.key === 'cart')
        return false;
      return (
        <button
          type="button"
          key={item.key}
          className={`menu-item ${item.back ? 'menu-item-border-bottom' : ''}`}
          onClick={item.onclick}
        >
          {item.back && <span className="arrow-left" />}
          {t(`weblink:${item.key}`)} {item.key === 'cart' && `(${cartSize})`}
          {item.subSections && <span className="arrow-right" />}
        </button>
      );
    })}
  </div>
);

HamburgerMenu.propTypes = {
  t: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  removedMenu: PropTypes.bool.isRequired,
  cartSize: PropTypes.number.isRequired,
  cartlessCheckout: PropTypes.bool.isRequired,
  storeDetailsIsLoading: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      back: PropTypes.bool,
      key: PropTypes.string,
      name: PropTypes.func,
      onclick: PropTypes.func,
      subSections: PropTypes.bool,
    }),
  ).isRequired,
};

export default withTranslation()(observer(HamburgerMenu));
