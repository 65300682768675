import { CustomFieldInput, Fieldset } from '@administrate/piston-ux';
import { useFormContext } from '@administrate/piston-ux/lib/Form';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { pick as _pick } from 'lodash';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { parseRawValueIntoBoolean } from '../utils/customFields';

const CUSTOM_FIELD_CHOICE_JOINER = '|';
const CUSTOM_FIELD_MULTI_CHOICES = ['multichoice'];
const CUSTOM_FIELD_BOOLEAN_CHOICES = ['boolean', 'checkbox'];

const parseValueFromPoSQuery = (value, pointOfSaleField) => {
  if (!value || !pointOfSaleField) {
    return value;
  }
  const { type } = pointOfSaleField;
  if (CUSTOM_FIELD_MULTI_CHOICES.includes(type)) {
    return value.split(CUSTOM_FIELD_CHOICE_JOINER);
  }
  if (CUSTOM_FIELD_BOOLEAN_CHOICES.includes(type)) {
    return parseRawValueIntoBoolean(value);
  }
  return value;
};

const serialisePosValueForMutation = value => {
  if (Array.isArray(value)) {
    return value.join(CUSTOM_FIELD_CHOICE_JOINER);
  }
  if (typeof value === 'boolean') {
    return value.toString();
  }
  // ?? operator was not enabled and i don't want to change falsey values to null (e.g. 0, "", etc.)
  if (value === undefined) {
    return null;
  }
  return value;
};

export const serialisePoSValuesForMutation = (
  objectifiedFormValue,
  pointOfSaleFields,
) => {
  const pointOfSaleFieldKeys = pointOfSaleFields.map(({ key }) => key);
  const pointOfSaleFormValues = _pick(
    objectifiedFormValue,
    pointOfSaleFieldKeys,
  );
  return Object.entries(pointOfSaleFormValues).map(
    ([pointOfSaleFieldKey, value]) => ({
      pointOfSaleFieldKey,
      value: serialisePosValueForMutation(value),
    }),
  );
};

const PointOfSaleCustomField = ({
  t,
  pointOfSaleFields,
  pointOfSaleValues,
}) => {
  const { values } = useFormContext();

  useEffect(() => {
    if (pointOfSaleFields && pointOfSaleValues && pointOfSaleValues.length) {
      const pointOfSalesByKey = pointOfSaleFields.reduce(
        (obj, item) => ({ ...obj, [item.key]: item }),
        {},
      );
      pointOfSaleValues.forEach(({ pointOfSaleFieldKey, value }) => {
        values.set(
          pointOfSaleFieldKey,
          parseValueFromPoSQuery(value, pointOfSalesByKey[pointOfSaleFieldKey]),
        );
      });
    }
  }, [pointOfSaleValues, pointOfSaleFields, values]);

  return (
    <>
      {pointOfSaleFields.length > 0 && (
        <Fieldset legend={t('weblink:additionalInformation')}>
          {pointOfSaleFields.map(
            ({ key, label, options, isRequired, type }) => (
              <CustomFieldInput
                key={key}
                uniqueId={key}
                name={key}
                label={label}
                required={isRequired}
                valid={v => (isRequired ? !!v || t('weblink:required') : true)}
                type={type}
                options={options || null}
              />
            ),
          )}
        </Fieldset>
      )}
    </>
  );
};

PointOfSaleCustomField.propTypes = {
  pointOfSaleFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pointOfSaleValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
};

export const PointOfSaleCustomFieldInput = withTranslation()(
  observer(PointOfSaleCustomField),
);
