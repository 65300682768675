import { supplementEventWithCommonData } from '../analytics';
import configStore from './configStore';
import BaseStore from './baseStore';

class AnalyticsStore extends BaseStore {
  constructor(rootStore, analyticsHandler) {
    super(rootStore);
    this.handler = analyticsHandler;
  }

  initialize = () => {
    this._setHandlerToGoogleTagManagerIfEnabled();
  };

  captureEvent = event => {
    if (!event) {
      return;
    }

    // TODO: @gbannerman - See if we can remove this. We currently need it because the script hasn't finished loading
    this._setHandlerToGoogleTagManagerIfEnabled();
    if (!this.handler) {
      console.debug('No analytics handler set');
      console.debug(event.payload);
      return;
    }

    const { userId } = this.rootStore.userStore;
    const { locale } = configStore;
    this.handler(
      supplementEventWithCommonData(event.payload, {
        userId,
        locale,
      }),
    );
  };

  _setHandlerToGoogleTagManagerIfEnabled = () => {
    const googleTagManagerEnabled = !!window.dataLayer;

    if (googleTagManagerEnabled && !this.handler) {
      this.handler = window.dataLayer.push;
    }
  };
}

export default AnalyticsStore;
