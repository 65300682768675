import { get as _get } from 'lodash';
import config from '../config';
import Loader from '../utils/Loader';

class StoreNotInitializedError extends Error {
  constructor() {
    super('Stores not initialized, cannot use apolloClient');
  }
}

class BaseStore {
  constructor(rootStore) {
    this.loader = new Loader({
      // Only enable error logging during development
      isLoggingEnabled: config.isDev,
    });
    this.rootStore = rootStore;
  }

  get apolloClient() {
    const { apolloClient } = this.rootStore;
    if (!apolloClient) {
      throw new StoreNotInitializedError();
    }
    return apolloClient;
  }

  async mutate({ mutation, variables, checkForMutationErrors }) {
    const response = await this.apolloClient.mutate({
      mutation,
      variables,
    });

    if (checkForMutationErrors) {
      const errors = _get(response.data, checkForMutationErrors, []);
      if (errors.length) {
        throw new Error(errors[0].message);
      }
    }
    return response;
  }
}

export default BaseStore;
