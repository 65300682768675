import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import bundleConfig from './bundleConfig';

const locales = import.meta.glob('./locales/**/*.json', {
  eager: true,
});

const WEBLINK_NAMESPACE = 'weblink';
export const PISTONUX_NAMESPACE = 'pistonUx';

export function configure(instance) {
  const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'locale',
  };

  const initParams = {
    fallbackLng: 'en-US',
    detection: options,
  };
  if (bundleConfig.locale) {
    initParams.lng = bundleConfig.locale;
  }

  if (!instance) {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(initParams);

    addResourceBundles(i18n);
    addBundleTranslations(i18n);
    return i18n;
  }

  addResourceBundles(instance);
  addBundleTranslations(instance);
  return instance;
}

function addBundleTranslations(instance) {
  const extendExisting = false;
  const overwriteExisting = true;
  if (bundleConfig.localeStrings) {
    Object.keys(bundleConfig.localeStrings).forEach(element => {
      instance.addResourceBundle(
        element,
        WEBLINK_NAMESPACE,
        bundleConfig.localeStrings[element],
        extendExisting,
        overwriteExisting,
      );
    });
  }
}

function addResourceBundles(instance) {
  for (const path in locales) {
    const { locale, namespace } = getLocaleAndNamespaceFromFilePath(path);
    instance.addResourceBundle(locale, namespace, locales[path]);
  }
}

function getLocaleAndNamespaceFromFilePath(path) {
  const [_, locale, filename] = path.match(/\/locales\/([^\/]+)\/(\S+).json/);

  return {
    locale,
    namespace: getNamespaceFromFilename(filename),
  };
}

function getNamespaceFromFilename(filename) {
  switch (filename) {
    case 'translation':
      return WEBLINK_NAMESPACE;
    case 'piston-ux':
      return PISTONUX_NAMESPACE;
  }
}
