import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Modal as PistonUxModal } from '@administrate/piston-ux';

const Modal = ({ children, show, ...props }) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('weblink');
    } else {
      document.body.classList.remove('weblink');
    }

    return () => document.body.classList.remove('weblink');
  }, []);

  return (
    <PistonUxModal show={show} {...props}>
      {children}
    </PistonUxModal>
  );
};

Modal.defaultProps = {
  id: null,
  show: null,
  children: null,
};

Modal.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default observer(Modal);
