import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { summaryListProp } from './summaryListProp';
import { CompactCartWidget } from './CompactCartWidget';
import { CartWidgetIconLabel } from './CartWidgetIconLabel';

export const CartWidget = ({
  isLoading,
  isDisabled,
  toCart,
  cartSummaryList,
  hasExpiredItems,
  shoppingCartSeatCount,
  showCompactCartOnClick,
  t,
  reservationsValidUntil,
}) => {
  const onClickHandler = showCompactCartOnClick ? undefined : toCart;
  const cartButton = (
    <span
      id="compactCartSpan"
      onClick={onClickHandler}
      onKeyPress={onClickHandler}
      role="link"
      tabIndex={isDisabled ? -1 : 0}
      style={
        (isDisabled && { cursor: 'disabled' },
        { paddingTop: 10, paddingBottom: 10 })
      }
    >
      <span className="sr-only">{t('weblink:shoppingTrolleyContents')}</span>
      <CartWidgetIconLabel
        isLoading={isLoading}
        shoppingCartSeatCount={shoppingCartSeatCount}
      />
    </span>
  );
  return (
    <li
      className={`btn btn-link ${isDisabled ? 'disabled' : ''}`}
      style={{ zIndex: 1, paddingTop: 15, paddingBottom: 15 }}
    >
      {showCompactCartOnClick ? (
        <OverlayTrigger
          key="compactCartWidget"
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <CompactCartWidget
              cartSummaryList={cartSummaryList}
              hasExpiredItems={hasExpiredItems}
              t={t}
              onGoToCartClick={toCart}
              reservationsValidUntil={reservationsValidUntil}
            />
          }
        >
          {cartButton}
        </OverlayTrigger>
      ) : (
        cartButton
      )}
    </li>
  );
};

CartWidget.defaultProps = {
  isLoading: false,
  isDisabled: false,
  shoppingCartSeatCount: 0,
  showCompactCartOnClick: false,
  reservationsValidUntil: null,
};

CartWidget.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  toCart: PropTypes.func.isRequired,
  cartSummaryList: summaryListProp.isRequired,
  hasExpiredItems: PropTypes.bool.isRequired,
  shoppingCartSeatCount: PropTypes.number,
  showCompactCartOnClick: PropTypes.bool,
  t: PropTypes.func.isRequired,
  reservationsValidUntil: PropTypes.string,
};
