import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../constants';

const DetailsCardImages = ({
  imageUrls,
}) => {
  const images = imageUrls.map(url => ({
      original: url || CATALOG_ITEM_IMAGE_PLACEHOLDER,
      thumbnail: url || CATALOG_ITEM_IMAGE_PLACEHOLDER,
  }
  ));

  return (
    <ImageGallery
      showPlayButton={false}
      showFullscreenButton={false}
      showNav={false}
      showThumbnails={images.length > 1}
      items={images}
    />
  );
}

DetailsCardImages.defaultProps = {
  imageUrls: [CATALOG_ITEM_IMAGE_PLACEHOLDER],
};

DetailsCardImages.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string),
};

export default DetailsCardImages;
