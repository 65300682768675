export default (amount, symbol, code, hideZeroDecimals = true) => {
  const toConvert = Number(amount);
  if (Number.isNaN(toConvert)) {
    return '';
  }

  const minimumFractionDigits = hideZeroDecimals
    ? Math.ceil(toConvert % 1) * 2
    : 2;
  const converted = toConvert.toLocaleString(
    {},
    { minimumFractionDigits, maximumFractionDigits: 2 },
  );
  if (symbol) {
    return `${symbol}${converted}`;
  }
  if (code) {
    return `${code} ${converted}`;
  }
  return converted;
};
