import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import DetailsCardImages from './DetailsCardImages';
import DetailsCardActions from '../../containers/Details/DetailsCardActions';
import withCurrency from '../../containers/withCurrency';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../constants';

const DetailsCard = ({
  normalPrice,
  formatCurrency,
  imageUrls,
  isPath,
  displayPrices,
  hideTrainingRequestLink,
}) => (
  <Card>
    <Card.Body type="course-detail">
      <DetailsCardImages imageUrls={imageUrls} />
      {isPath ? (
        <div className="CardCourse_feature-label">
          <p>Learning Path</p>
        </div>
      ) : null}
      <div className="card-content course-price">
        {displayPrices && (
          <div className="CardCourse--values">
            <span className="Card--values--primary">
              {formatCurrency(normalPrice)}
            </span>
          </div>
        )}
      </div>
      <div className="card-content call-to-actions">
        <DetailsCardActions
          isPath={isPath}
          hideTrainingRequestLink={hideTrainingRequestLink}
        />
      </div>
    </Card.Body>
  </Card>
);

DetailsCard.defaultProps = {
  normalPrice: null,
  imageUrls: [CATALOG_ITEM_IMAGE_PLACEHOLDER],
  isPath: false,
  displayPrices: true,
  hideTrainingRequestLink: false,
};

DetailsCard.propTypes = {
  normalPrice: PropTypes.number,
  formatCurrency: PropTypes.func.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  isPath: PropTypes.bool,
  displayPrices: PropTypes.bool,
  hideTrainingRequestLink: PropTypes.bool,
};

export default withTranslation()(withCurrency(DetailsCard));
