import {
  observable,
  decorate,
  action,
  runInAction,
  reaction,
  computed,
} from 'mobx';
import { get as _get, isEqual as _isEqual } from 'lodash';
import moment from 'moment';

import queries from '../queries';
import { PAGING } from '../constants';
import BaseStore from './baseStore';
import { SearchEvent } from '../analytics';

const VALID_PLACES_REMAINING_OPERATIONS = ['eq', 'ne', 'gt', 'lt', 'ge', 'le'];

class CatalogueStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);

    runInAction(() => {
      this.cataloguePage = 0;
      this.catalogueType = null;
      this.categoriesResponse = null;
      this.categoriesResponseIsLoading = false;
      this.allCategoriesResponse = null;
      this.allCategoriesIsLoading = false;
      this.getCoursesIsLoading = false;
      this.getCatalogueIsLoading = true;
      this.getCoursesResponse = null;
      this.getCatalogueResponse = null;
      this.products = [];
      this.navbarOpen = false;
      this.searchBarText = null;
      this.searchBarLoading = false;
      this.searchBarOptions = [];
      this.courseNameSearchText = '';
      this.courseCustomFieldDefinitions = [];
      this.pathCustomFieldDefinitions = [];
      this.getCourseCustomFieldDefinitionsResponse = null;
      this.getPathCustomFieldDefinitionsResponse = null;
      this.cataloguePagerType = PAGING.TYPE; // loadMore Or full
      this.isSetPager = false;
      this.pageSize = PAGING.COURSE_LISTING;
      this.locations = [];
      this.placesRemaining = [];
      this.fromDate = null;
      this.toDate = null;
      this.categories = [];
      this.learningTags = [];
      this.customFieldFilters = [];
    });
  }

  initialize = async () => {
    await this.fetchAllCategories();

    reaction(
      () => ({
        catalogueType: this.catalogueType,
        cataloguePage: this.cataloguePage,
        courseNameSearchText: this.courseNameSearchText,
        categories: this.categories,
      }),
      () => {
        const {
          fetchCatalogue,
          fetchCourseCustomFieldDefinitions,
          fetchPathCustomFieldDefinitions,
        } = this;
        fetchCatalogue();
        fetchCourseCustomFieldDefinitions();
        fetchPathCustomFieldDefinitions();
      },
      { delay: 500 },
    );

    reaction(
      () => this.searchBarText,
      () => {
        if (this.searchBarText) {
          this.fetchOptionsForSearchBar();
        }
      },
      {
        delay: 1000,
      },
    );

    reaction(
      () => this.getCatalogueResponse || {},
      getCatalogueResponse => {
        if (getCatalogueResponse) {
          runInAction(() => {
            this.products = this.products.concat(
              _get(getCatalogueResponse, 'catalogue.edges', []).map(
                ({ node }) => node,
              ),
            );
          });
        }
      },
    );
  };

  changeNavbarOpen = action(value => {
    const { removedMenu, menuOpen, closeMenu } = this.rootStore.navStore;
    if (value && menuOpen && !removedMenu) {
      closeMenu();
    }
    this.navbarOpen = value;
  });

  get hasMoreProducts() {
    const totalProducts = _get(
      this.getCatalogueResponse,
      'catalogue.pageInfo.totalRecords',
    );
    return (this.cataloguePage + 1) * this.pageSize < totalProducts;
  }

  get numberOfPages() {
    const totalProducts = _get(
      this.getCatalogueResponse,
      'catalogue.pageInfo.totalRecords',
    );
    return Math.ceil(totalProducts / this.pageSize);
  }

  buildCatalogueFilters = action(() => {
    const filters = [];

    if (this.locations.length > 0) {
      filters.push({
        field: 'locationId',
        operation: 'in',
        values: this.locations,
      });
    }

    if (this.placesRemaining.length) {
      const validFilters = this.placesRemaining.filter(
        filter =>
          filter.operation &&
          VALID_PLACES_REMAINING_OPERATIONS.includes(filter.operation) &&
          Number.isInteger(filter.value) &&
          filter.value >= 0,
      );

      const graphqlFilters = validFilters.map(filter => ({
        field: 'remainingPlaces',
        operation: filter.operation,
        value: filter.value.toString(),
      }));

      filters.push(...graphqlFilters);

      if (validFilters.length !== this.placesRemaining.length) {
        // eslint-disable-next-line no-console
        console.warn(
          `WebLink: Filtering by places remaining requires a positive integer value and the operation to be one of ${VALID_PLACES_REMAINING_OPERATIONS}.`,
        );
      }
    }

    if (this.fromDate) {
      const utcFromDate = moment(this.fromDate)
        .utc(true)
        .startOf('day');

      if (utcFromDate.isValid()) {
        filters.push({
          field: 'start',
          operation: 'ge',
          value: utcFromDate,
        });
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          "WebLink: fromDate filter is invalid. Please specify the From Date in 'YYYY-MM-DD' format.",
        );
      }
    }

    if (this.toDate) {
      const utcToDate = moment(this.toDate)
        .utc(true)
        .endOf('date');

      if (utcToDate.isValid()) {
        filters.push({
          field: 'start',
          operation: 'le',
          value: utcToDate,
        });
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          "WebLink: toDate filter is invalid. Please specify the To Date in 'YYYY-MM-DD' format.",
        );
      }
    }

    if (this.categories.length > 0) {
      filters.push({
        field: 'categoryId',
        operation: 'in',
        values: this.categories,
      });
    }

    if (this.learningTags.length > 0) {
      filters.push({
        field: 'learningTagId',
        operation: 'in',
        values: this.learningTags,
      });
    }

    return filters;
  });

  fetchCatalogue = action(async () => {
    this.getCatalogueIsLoading = true;
    const filters = this.buildCatalogueFilters();
    const { data: getCatalogueResponse } = await this.apolloClient.query({
      query: queries.course.getCatalogueForListingPage,
      variables: {
        pageSize: this.pageSize,
        offset: this.cataloguePage * this.pageSize,
        search: this.courseNameSearchText,
        filters,
        customFieldFilters: this.customFieldFilters,
        ...(this.catalogueType
          ? {
              type: this.catalogueType,
            }
          : {}),
      },
    });
    if (this.courseNameSearchText || filters.length) {
      this.rootStore.analyticsStore.captureEvent(
        SearchEvent.fromCatalogueSearch({
          searchTerm: this.courseNameSearchText,
          filters,
          response: getCatalogueResponse,
        }),
      );
    }
    runInAction(() => {
      this.getCatalogueResponse = getCatalogueResponse;
      this.getCatalogueIsLoading = false;
    });
  });

  fetchCourseCustomFieldDefinitions = action(async () => {
    this.getCourseCustomFieldDefinitionsIsLoading = true;
    const {
      data: getCourseCustomFieldDefinitionsResponse,
    } = await this.apolloClient.query({
      query: queries.store.getCustomFieldDefinitions,
      variables: {
        type: 'CourseCard',
      },
    });
    runInAction(() => {
      this.courseCustomFieldDefinitions = [
        ..._get(
          getCourseCustomFieldDefinitionsResponse,
          'customFieldDefinitions',
          [],
        ),
      ];
      this.getCourseCustomFieldDefinitionsIsLoading = false;
    });
  });

  fetchPathCustomFieldDefinitions = action(async () => {
    this.getPathCustomFieldDefinitionsIsLoading = true;
    const {
      data: getPathCustomFieldDefinitionsResponse,
    } = await this.apolloClient.query({
      query: queries.store.getCustomFieldDefinitions,
      variables: {
        type: 'LearningPathCard',
      },
    });
    runInAction(() => {
      this.pathCustomFieldDefinitions = [
        ..._get(
          getPathCustomFieldDefinitionsResponse,
          'customFieldDefinitions',
          [],
        ),
      ];
      this.getPathCustomFieldDefinitionsIsLoading = false;
    });
  });

  fetchCourses = action(async () => {
    this.getCoursesIsLoading = true;
    const { data: getCoursesResponse } = await this.apolloClient.query({
      query: queries.course.getCourseForListingPage,
      variables: {
        offset: this.cataloguePage * PAGING.COURSE_LISTING,
        filters: [
          ...(this.categories.length > 0
            ? [
                {
                  field: 'categoryId',
                  operation: 'in',
                  value: this.categories,
                },
              ]
            : []),
          ...(this.courseNameSearchText
            ? [
                {
                  field: 'name',
                  operation: 'like',
                  value: `%${this.courseNameSearchText}%`,
                },
              ]
            : []),
        ],
      },
    });
    runInAction(() => {
      this.getCoursesResponse = getCoursesResponse;
      this.getCoursesIsLoading = false;
    });
  });

  showMore = action(() => {
    this.cataloguePage += 1;
  });

  setPage = action(page => {
    this.resetPaging();
    this.cataloguePage = page;
    this.isSetPager = true;
  });

  fetchAllCategories = action(async () => {
    this.allCategoriesIsLoading = true;
    const { data: allCategoriesResponse } = await this.apolloClient.query({
      query: queries.category.getCategories,
      variables: {
        categoryFieldOrder: {
          field: 'name',
          direction: 'asc',
        },
      },
    });

    runInAction(() => {
      this.allCategoriesResponse = allCategoriesResponse;
      this.allCategoriesIsLoading = false;
    });
  });

  get allCategories() {
    return _get(this.allCategoriesResponse, 'categories.edges', []);
  }

  setCourseNameSearchText = action(({ courseNameSearchText }) => {
    if (courseNameSearchText !== this.courseNameSearchText) {
      this.resetPaging();
      this.courseNameSearchText = courseNameSearchText;
      this.searchBarText = courseNameSearchText;
    }
  });

  setSearchBarText = action(searchBarText => {
    if (!_isEqual(searchBarText, this.searchBarText)) {
      this.searchBarText = searchBarText;
    }
  });

  setCatalogueType = action(({ catalogueType }) => {
    if (!_isEqual(catalogueType, this.catalogueType)) {
      this.resetPaging();
      this.catalogueType = catalogueType;
    }
  });

  setPageSize = action(pageSize => {
    if (!_isEqual(pageSize, this.pageSize)) {
      this.pageSize = pageSize;
    }
  });

  setLocations = action(locations => {
    if (!_isEqual(locations, this.locations)) {
      this.resetPaging();
      this.locations = locations;
    }
  });

  setPlacesRemaining = action(placesRemaining => {
    if (!_isEqual(placesRemaining, this.placesRemaining)) {
      this.resetPaging();
      this.placesRemaining = placesRemaining;
    }
  });

  setFromDate = action(fromDate => {
    if (!_isEqual(fromDate, this.fromDate)) {
      this.resetPaging();
      this.fromDate = fromDate;
    }
  });

  setToDate = action(toDate => {
    if (!_isEqual(toDate, this.toDate)) {
      this.resetPaging();
      this.toDate = toDate;
    }
  });

  setCategories = action(categories => {
    if (!_isEqual(categories, this.categories)) {
      this.resetPaging();
      this.categories = categories;
    }
  });

  setLearningTags = action(learningTags => {
    if (!_isEqual(learningTags, this.learningTags)) {
      this.resetPaging();
      this.learningTags = learningTags;
    }
  });

  setCustomFieldFilters = action(customFieldFilters => {
    if (!_isEqual(customFieldFilters, this.customFieldFilters)) {
      this.resetPaging();
      this.customFieldFilters = customFieldFilters;
    }
  });

  fetchOptionsForSearchBar = action(async () => {
    this.searchBarLoading = true;

    const {
      data: {
        courses: { edges: courseEdges },
      },
    } = await this.apolloClient.query({
      query: queries.course.getCourseForSearchBar,
      variables: { name: `%${this.searchBarText}%` },
    });

    const {
      data: {
        learningPaths: { edges: learningPathEdges },
      },
    } = await this.apolloClient.query({
      query: queries.learningPath.getPathForSearchBar,
      variables: { name: `%${this.searchBarText}%` },
    });

    const sortedResults = courseEdges
      .concat(learningPathEdges)
      .sort((a, b) => (a?.node?.name ?? '').localeCompare(b?.node?.name));

    this.rootStore.analyticsStore.captureEvent(
      SearchEvent.fromSearchBarInput({
        searchTerm: this.searchBarText,
        resultCount: sortedResults.length,
      }),
    );

    runInAction(() => {
      this.searchBarOptions = sortedResults;
      this.searchBarLoading = false;
    });
  });

  resetPaging = action(() => {
    this.getCatalogueIsLoading = true;
    this.cataloguePage = 0;
    this.products = [];
  });
}

decorate(CatalogueStore, {
  allCategoriesIsLoading: observable,
  allCategoriesResponse: observable,
  allCategories: computed,
  categoriesResponse: observable,
  categoriesResponseIsLoading: observable,
  getCoursesIsLoading: observable,
  getCatalogueIsLoading: observable,
  getCoursesResponse: observable,
  getCatalogueResponse: observable,
  getCourseCustomFieldDefinitionsIsLoading: observable,
  getCourseCustomFieldDefinitionsResponse: observable,
  getPathCustomFieldDefinitionsResponse: observable,
  getPathCustomFieldDefinitionsIsLoading: observable,
  cataloguePage: observable,
  catalogueType: observable,
  products: observable,
  navbarOpen: observable,
  courseNameSearchText: observable,
  searchBarText: observable,
  searchBarLoading: observable,
  searchBarOptions: observable,
  hasMoreProducts: computed,
  numberOfPages: computed,
  isSetPager: observable,
  categories: observable,
});

export default CatalogueStore;
