import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Alert, Container } from '@administrate/piston-ux';
import inject from '../inject';
import withIntegration from '../withIntegration';
import { STORES } from '../../constants';
import Checkout from './Checkout';
import { UnauthorizedCartRetrievalError } from '../../stores/errors/cart';
import { useAuth0 } from '../../auth/lib/react-auth0-wrapper';
import ReturnToCatalogue from '../ReturnToCatalogue';

const { STORE_NAVIGATION, STORE_CART } = STORES;

const RetrieveOrder = ({
  [STORE_CART]: { cart, updateCartRecoveryToken, error },
  [STORE_NAVIGATION]: { toCatalogue },
  cartRecoveryToken,
  t,
}) => {
  const { logout } = useAuth0();

  useEffect(() => {
    updateCartRecoveryToken(cartRecoveryToken);
  }, [cartRecoveryToken, updateCartRecoveryToken]);

  if (error) {
    if (error instanceof UnauthorizedCartRetrievalError) {
      logout({ redirect_uri: window.location.href });
      return <></>;
    }
    return (
      <Container>
        <Alert type="warning" message={t('weblink:invalidRetrievalToken')} />
      </Container>
    );
  }

  // Remove the "my" prefix from the cart translations
  const customMessage = t('weblink:notFoundHeader', {
    item: t('weblink:cart').split(' ')[1],
  });

  return ['won', 'loading', 'orderRetrieval'].includes(
    (cart && cart.state) || 'loading',
  ) ? (
    <Checkout />
  ) : (
    <ReturnToCatalogue
      toCatalogue={toCatalogue}
      customMessage={customMessage}
    />
  );
};

RetrieveOrder.propTypes = {
  cartRecoveryToken: PropTypes.string.isRequired,
};

export default withTranslation()(
  inject(
    STORE_NAVIGATION,
    STORE_CART,
  )(withIntegration(observer(RetrieveOrder))),
);
