const setDocumentTitle = (storeDetails, title) => {
  document.title = `${storeDetails ? `${storeDetails.name}` : ''}${
    storeDetails && title ? ' - ' : ''
  } ${title}`;
};

const setMetaTag = title => {
  const existingMetaTag = document.querySelector("[name='weblink-title']");
  if (existingMetaTag) {
    existingMetaTag.parentElement.removeChild(existingMetaTag);
  }
  const meta = document.createElement('meta');
  meta.name = 'weblink-title';
  meta.content = title;
  document.getElementsByTagName('head')[0].appendChild(meta);
};

const setBrandedPageTitle = (pageTitle, storeDetails, hosted) => {
  let title;
  if (pageTitle) {
    const titleFragment = pageTitle.join(' - ');
    title = titleFragment;
    setMetaTag(title);
  }

  if (hosted) {
    setDocumentTitle(storeDetails, title);
  }
};

export default setBrandedPageTitle;
