import { Card } from '@administrate/piston';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Button } from '@administrate/piston-ux';
import ResponsiveDataGrid from '../ResponsiveDataGrid/ResponsiveDataGrid';
import inject from '../../containers/inject';
import { STORES } from '../../constants';
import CartItemDescription from './CartItemsList/CartItemDescription';
import CartItemPrice from './CartItemsList/CartItemPrice';
import CartItemQuantity from './CartItemsList/CartItemQuantity';

const { STORE_CART, STORE_NAVIGATION, STORE_COURSE } = STORES;

const CartExpiredItemsList = ({
  items,
  showPlacesRemaining,
  [STORE_CART]: { isLoading, rebookCartItem, isItemRebookable },
  [STORE_NAVIGATION]: { toCourseDetail, toPathDetail },
  [STORE_COURSE]: { getCourseDataById },
  t,
}) => {
  return (
    <div className="mt-4">
      <Card type="expired-items-list">
        <h2 className="sr-only">Cart Contents</h2>
        <Card.Body>
          <ResponsiveDataGrid
            hideKey
            columns={[
              {
                label: t('weblink:item'),
                columntype: 'cart-row-item',
                content: row => (
                  <CartItemDescription
                    isSoldOut={row.remainingPlaces === 0}
                    row={row}
                    showPlacesRemaining={showPlacesRemaining}
                  />
                ),
              },
              {
                label: t('weblink:price'),
                columntype: 'col-small cart-row-price',
                content: row => <CartItemPrice amount={row.unitAmount} />,
              },
              {
                label: t('weblink:quantity'),
                columntype: 'col-small cart-row-quantity',
                content: row => <CartItemQuantity row={row} disabled />,
              },
              {
                label: t('weblink:total'),
                columntype: 'col-small cart-row-total',
                content: row => <CartItemPrice amount={row.subTotalAmount} />,
              },
              {
                label: '',
                columntype: 'col-small expired-item-action',
                content: row => {
                  if (isLoading) {
                    return <></>;
                  }
                  return isItemRebookable(row) ? (
                    <Button
                      type="primary"
                      label={t('weblink:bookNow')}
                      onClick={() => {
                        rebookCartItem(row.id);
                      }}
                    />
                  ) : (
                    <Button
                      type="suppressed"
                      label={t('weblink:cartExpiredListView')}
                      onClick={async () => {
                        if (row.isPath) {
                          toPathDetail({ pathId: row.pathId });
                        } else {
                          const { code, name } = await getCourseDataById(
                            row.courseId,
                          );
                          toCourseDetail({
                            courseCode: code,
                            courseName: name,
                          });
                        }
                      }}
                    />
                  );
                },
              },
            ]}
            data={items}
            type="simple-table cart-table"
          />
        </Card.Body>
      </Card>
    </div>
  );
};

CartExpiredItemsList.propTypes = {
  items: MobxPropTypes.arrayOrObservableArray.isRequired,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CartExpiredItemsList.defaultProps = {
  showPlacesRemaining: true,
};

export default withTranslation()(
  inject(
    STORE_CART,
    STORE_NAVIGATION,
    STORE_COURSE,
  )(observer(CartExpiredItemsList)),
);
