export const mapValuesArrayToObject = valuesArray =>
  Object.fromEntries(
    valuesArray.map(({ definitionKey, value }) => [definitionKey, value]),
  );

export const mapValuesObjectToArray = (valuesObject, definitions) => {
  if (!definitions) {
    return Object.entries(valuesObject).map(([definitionKey, value]) => ({
      definitionKey,
      value,
    }));
  }
  return definitions
    .filter(({ key }) => valuesObject[key] !== undefined)
    .map(({ key }) => ({
      definitionKey: key,
      value: valuesObject[key],
    }));
};

export const parseRawValueIntoBoolean = rawValue => {
  if (rawValue === null) {
    return null;
  }
  return typeof rawValue === 'string'
    ? rawValue.trim().toLowerCase() === 'true'
    : !!rawValue;
};
