import STORES from './stores';
import DATETIME_FORMATTING from './datetimeFormatting';
import COURSE_PLACEHOLDER_IMAGE_PNG_BASE64 from './coursePlaceholderImage';

export { STORES, DATETIME_FORMATTING };

export const PAGING = {
  COURSE_LISTING: 6,
  TYPE: 'loadMore', // loadMore || full
};

export const MAX_PENDING_REFRESH_COUNT = 4;
export const PENDING_REFRESH_INTERVAL = 3000;

export const DELIVERY_METHODS = {
  LMS: 'lms',
  BLENDED: 'blended',
  CLASSROOM: 'classroom',
};

export const INTEGRATON_CATEGORY_PAGES = {
  CATEGORIES: 'categories',
  SUBCATEGORIES: 'subcategories',
  CATALOGUE: 'catalogue',
};

export const INTEGRATON_NAVIGATION_PAGES = {
  CATALOGUE: 'catalogue',
  COURSES: 'courses',
  PATHS: 'paths',
  CART: 'cart',
  EVENTLIST: 'eventList',
  TRAININGREQUEST: 'trainingRequest',
  PAGE: 'page',
};

export const MAX_SEATS = 500;

export const CATALOG_ITEM_IMAGE_PLACEHOLDER = `
data:image/png;charset=utf-8;base64,${COURSE_PLACEHOLDER_IMAGE_PNG_BASE64.replace(
  /\n/g,
  '',
)}
`;
