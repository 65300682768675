import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '../../auth/lib/react-auth0-wrapper';
import { usePortalConfiguration } from '../../hooks/portalConfiguration';
import { initializeStores } from '../index';
import { useWebLinkClient } from '../../weblink-hooks';
import wrapPromise from '../../utils/wrapPromise';

export const STORE = createContext();

/** Need to use .read in a separate component. This will await store initialization */
const StoreInnerProvider = ({ storeResource, children }) => {
  const stores = storeResource.read();

  return <STORE.Provider value={stores}>{children}</STORE.Provider>;
};

StoreInnerProvider.propTypes = {
  storeResource: PropTypes.shape({ read: PropTypes.func }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const StoreProvider = ({ stores, children }) => {
  const portalConfiguration = usePortalConfiguration();
  const apolloClient = useWebLinkClient();
  const { user, isAuthenticated } = useAuth0();

  const initStores = initializeStores(
    portalConfiguration,
    apolloClient,
    isAuthenticated ? user : null,
  ).then(() => stores);
  const storeResource = wrapPromise(initStores);

  return (
    <StoreInnerProvider storeResource={storeResource}>
      {children}
    </StoreInnerProvider>
  );
};

StoreProvider.propTypes = {
  stores: PropTypes.shape(undefined).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
