import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { Container } from '@administrate/piston';
import { Row } from 'react-bootstrap';
import inject from '../inject';
import { FiltersView } from '../../components/Filters/FiltersView';
import CatalogueView from '../../components/Catalogue/Catalogue';
import { STORES } from '../../constants/index';

const { STORE_CATALOGUE_FILTERS, STORE_CATALOGUE, STORE_STORE } = STORES;

const Filters = ({
  [STORE_CATALOGUE_FILTERS]: {
    allLocationsIsLoading,
    setFilteredLocations,
    setFilters,
    allLocations,
    setCourseCode,
    filteredResultsIsLoading,
    filteredResultsResponse,
    filteredLocations,
  },
  [STORE_CATALOGUE]: {
    setSearchBarText,
    courseCustomFieldDefinitions,
    pathCustomFieldDefinitions,
  },
  [STORE_STORE]: { hidePrices },
  code,
  showLocationsFilter,
}) => {
  const isEventFilters = false;

  const selectCatalogueItem = () => {};

  return (
    <Container>
      <div className="course-events-list mb-4">
        <Row>
          <FiltersView
            showLocationsFilter={showLocationsFilter}
            setFilters={setFilters}
            allLocations={allLocations}
            allLocationsIsLoading={allLocationsIsLoading}
            setFilteredLocations={setFilteredLocations}
            filteredLocations={filteredLocations}
            courseCode={code}
            setCourseCode={setCourseCode}
            isEventFilters={isEventFilters}
          />
        </Row>
      </div>

      <CatalogueView
        onSelect={selectCatalogueItem}
        catalogueItems={
          filteredResultsIsLoading ? [] : toJS(filteredResultsResponse)
        }
        setSearchBarText={setSearchBarText}
        displayPrices={!hidePrices}
        courseCustomFieldDefinitions={courseCustomFieldDefinitions}
        pathCustomFieldDefinitions={pathCustomFieldDefinitions}
      />
    </Container>
  );
};

Filters.defaultProps = {
  showLocationsFilter: true,
};

Filters.propTypes = {
  showLocationsFilter: PropTypes.bool,
};

export default withTranslation()(
  inject(
    STORE_CATALOGUE_FILTERS,
    STORE_CATALOGUE,
    STORE_STORE,
  )(observer(Filters)),
);
