import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxGroup,
  Form,
  useTypedFormValues,
} from '@administrate/piston-ux';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { Popover } from 'react-bootstrap';
import formatSelectList from '../../utils/formatSelectList';

export const LocationsFilter = ({
  allLocations,
  allLocationsIsLoading,
  setFilteredLocations,
  filteredLocations,
}) => {
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    setFilteredLocations(selectedLocations);
  }, [selectedLocations, setFilteredLocations]);

  const formattedLocations = allLocationsIsLoading
    ? []
    : formatSelectList({
        list: allLocations,
        label: 'name',
        value: 'id',
      });

  const mapSelectedLocationsToString = () => {
    return filteredLocations
      .map(
        filteredLocation =>
          formattedLocations.find(l => l.value === filteredLocation).label,
      )
      .toString();
  };

  const selectedLocationsString =
    filteredLocations === [] ? '' : mapSelectedLocationsToString();

  const values = useTypedFormValues({
    filteredLocations,
  });

  return (
    <OverlayTrigger
      key="locationsFilter"
      trigger="click"
      placement="bottom"
      rootClose
      overlay={
        <Popover id="locationsFilter" bsClass="weblink popover">
          <Form values={values}>
            <CheckboxGroup
              name="filteredLocations"
              options={formattedLocations}
              onChange={selectedValues =>
                setSelectedLocations(
                  selectedValues.filter(value => value !== undefined),
                )
              }
            />
          </Form>
        </Popover>
      }
    >
      <button type="button" className="btn filter-btn">
        <span>
          <i className="glyphicon glyphicon-map-marker" />
          Locations
          <i className="caret" />
        </span>
        <div className="chosen-options-wrapper">
          <span className="chosen-options">{selectedLocationsString}</span>
          {selectedLocations.length !== 0 && (
            <span className="selected-number">{selectedLocations.length}</span>
          )}
        </div>
      </button>
    </OverlayTrigger>
  );
};

LocationsFilter.defaultProps = {
  allLocations: {},
  allLocationsIsLoading: false,
};

LocationsFilter.propTypes = {
  allLocations: PropTypes.shape({}),
  allLocationsIsLoading: PropTypes.bool,
  setFilteredLocations: PropTypes.func.isRequired,
  filteredLocations: PropTypes.shape({}).isRequired,
};
