/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import {
  Card,
  SecondaryAction,
  Container,
  TextInput,
} from '@administrate/piston';
import { withTranslation } from 'react-i18next';

const translateValidationMessage = (t, label) => {
  switch (label) {
    case 'expiredGiftVoucher':
      return t('weblink:giftVoucherExpired');

    case 'insufficientBalanceGiftVoucher':
      return t('weblink:giftVoucherInsufficientBalance');

    case 'opportunityContainsGiftVoucher':
      return t('weblink:cartContainsGiftVoucher');

    default:
      return t('weblink:giftVoucherApplicationFail');
  }
};

const GiftVoucherInput = ({ isLoading, applyGiftVoucher, t }) => {
  const cardRef = useRef(null);
  const [applicationResult, setApplicationResult] = useState(null);
  const [giftVoucher, setGiftVoucher] = useState('');

  const onSubmit = useCallback(async () => {
    const result = await applyGiftVoucher(giftVoucher); //
    setApplicationResult(result);
    setGiftVoucher('');
  }, [giftVoucher, setGiftVoucher, setApplicationResult, applyGiftVoucher]);

  const listener = useCallback(
    async e => {
      if (e.keyCode === 13 && e.metaKey) {
        onSubmit();
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    /* eslint-disable-next-line */
    const domElement = findDOMNode(cardRef.current);
    domElement.addEventListener('keydown', listener);
    return () => {
      domElement.removeEventListener('keydown', listener);
    };
  }, [applyGiftVoucher, listener]);

  useEffect(
    () => () => {
      setGiftVoucher('');
      setApplicationResult(null);
    },
    [],
  );

  return (
    <Card ref={cardRef}>
      <h2 className="CardTitle">{t('weblink:haveAGiftVoucher')}</h2>
      <Card.Body>
        <Container fluid className="py-4">
          <Container.Row>
            <Container.Col xs={12}>
              <div className="d-flex">
                <div style={{ flex: '1', paddingRight: '8px' }}>
                  <label
                    id="promo-code-label"
                    htmlFor="TextInput_gift-voucher"
                    className="sr-only"
                  >
                    {t('weblink:addYourGiftVoucher')}
                  </label>
                  <TextInput
                    id="gift-voucher"
                    value={giftVoucher}
                    onChange={setGiftVoucher}
                  />
                </div>
                <SecondaryAction
                  disabled={isLoading || !giftVoucher.length}
                  onClick={onSubmit}
                  label={t('weblink:addVoucher')}
                />
              </div>
            </Container.Col>
          </Container.Row>
          <Container.Row>
            <Container.Col xs={12}>
              <div className="pt-4">
                {applicationResult &&
                  (applicationResult.success ? (
                    <>
                      <span className="glyphicon glyphicon-ok" />{' '}
                      {t('weblink:giftVoucherApplicationSuccess')}
                    </>
                  ) : (
                    <>
                      <span className="glyphicon glyphicon-exclamation-sign" />{' '}
                      {translateValidationMessage(t, applicationResult.label)}
                    </>
                  ))}
              </div>
            </Container.Col>
          </Container.Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

GiftVoucherInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  applyGiftVoucher: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(GiftVoucherInput);
