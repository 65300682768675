/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
import React, { forwardRef } from 'react';
import { noop as _noop } from 'lodash';
import { withTranslation } from 'react-i18next';
import { SecondaryAction } from '@administrate/piston';

import withIntegration from '../containers/withIntegration';

const NavDropdown = (
  {
    open = false,
    children,
    onClick = _noop,
    label,
    integration,
    categoryDropdownRef,
  },
  dropdownWrapper,
) =>
  React.createElement(
    integration ? 'div' : 'li',
    {
      className: `dropdown  ${open ? 'open' : ''}`,
      ref: dropdownWrapper,
    },
    <>
      {integration ? (
        <SecondaryAction label={label} onClick={onClick} />
      ) : (
        <a
          id="categories-dropdown-desktop"
          role="button"
          className="dropdown-toggle"
          aria-haspopup="true"
          tabIndex={0}
          aria-expanded={open.toString()}
          onClick={onClick}
        >
          {label}
        </a>
      )}
      <ul
        role="menu"
        className="dropdown-menu"
        aria-labelledby="categories-dropdown-desktop"
        ref={categoryDropdownRef}
      >
        {children}
      </ul>
    </>,
  );

export default withIntegration(
  withTranslation('translation', { withRef: true })(forwardRef(NavDropdown)),
);
