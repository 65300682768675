import React from 'react';
import { observer } from 'mobx-react-lite';

import inject from './inject';
import { STORES } from '../constants';

const { STORE_STORE } = STORES;

const withCurrency = WrappedComponent =>
  inject(STORE_STORE)(
    observer(props => {
      const { [STORE_STORE]: storeStore } = props;
      return (
        <WrappedComponent
          currency={storeStore.currency}
          formatCurrency={storeStore.formatCurrency}
          formatCurrencyForCalculation={storeStore.formatCurrencyForCalculation}
          {...props}
        />
      );
    }),
  );

export default withCurrency;
