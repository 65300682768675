import React from 'react';
import { observer } from 'mobx-react-lite';
import * as PropTypes from 'prop-types';
import { STORES } from '../../constants';
import SearchBar from '../../containers/WeblinkNavigation/SearchBar';
import inject from '../../containers/inject';
import UserMenu from '../Navigation/UserMenu';
import HamburgerMenu from './HamburgerMenu';

const { STORE_NAV, STORE_CART, STORE_STORE } = STORES;

const MobileNavigation = ({
  className = '',
  showSignInButton = false,
  [STORE_NAV]: {
    searchOpen,
    menuOpen,
    toggleSearch,
    toggleMenu,
    currentMenu,
    removedMenu,
  },
  [STORE_CART]: { cartSize },
  [STORE_STORE]: { cartlessCheckout, storeDetailsIsLoading },
}) => (
  <div
    className={`MobileNavigation ${className} ${menuOpen ? 'menu-open' : ''}`}
  >
    <div className="mobile-nav-row-container">
      <div className="mobile-nav-left">
        <button
          type="button"
          className="hamburger"
          onClick={toggleMenu}
          aria-label="Menu toggle"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <div className="company-logo company-logo-mobile" />
      </div>
      <div className="mobile-nav-right">
        <button
          type="button"
          aria-label="Search toggle"
          className="search-toggle"
          onClick={toggleSearch}
        >
          <span className="glyphicon glyphicon-search" />
        </button>
        {showSignInButton && <UserMenu iconOnly />}
      </div>
    </div>
    <div
      className={`search-bar ${searchOpen ? 'search-bar-appear' : ''}`}
      aria-label="Search container"
    >
      <SearchBar id="mobile-searchBar" />
    </div>
    <HamburgerMenu
      menuOpen={menuOpen}
      menuItems={currentMenu.toJS()}
      removedMenu={removedMenu}
      cartSize={cartSize}
      cartlessCheckout={cartlessCheckout}
      storeDetailsIsLoading={storeDetailsIsLoading}
    />
  </div>
);

MobileNavigation.defaultProps = {
  className: null,
  showSignInButton: false,
};

MobileNavigation.propTypes = {
  className: PropTypes.string,
  showSignInButton: PropTypes.bool,
};

export default inject(
  STORE_NAV,
  STORE_CART,
  STORE_STORE,
)(observer(MobileNavigation));
