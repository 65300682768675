import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const ignoreSlug = pathname => {
  const encodedURI = encodeURI(pathname);
  const endOfPath = encodedURI.split('/').pop();
  if (endOfPath.indexOf('%1E') !== -1) {
    history.location.pathname = encodedURI.replace(
      `%1E${endOfPath
        .split('%1E')
        .slice(1)
        .join('%1E')}`,
      '',
    );
  }
};

ignoreSlug(history.location.pathname); // Direct link (first app load)
history.listen(location => {
  ignoreSlug(location.pathname); // Navigation
});
