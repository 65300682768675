import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { observer } from 'mobx-react-lite';

import Catalogue from './containers/Catalogue/Catalogue';
import Checkout from './containers/Checkout/Checkout';
import Cart from './containers/Cart/Cart';
import PageNotFound from './components/404';
import CourseDetails from './containers/Details/CourseDetails';
import TrainingRequest from './containers/TrainingRequest/TrainingRequest';
import PathDetails from './containers/Details/PathDetails';
import RetrieveOrder from './containers/Checkout/RetrieveOrder';
import GiftVouchers from './containers/GiftVouchers/GiftVouchers';
import Filters from './containers/Filters/Filters';
import WeblinkNavigation from './containers/WeblinkNavigation/WeblinkNavigation';
import inject from './containers/inject';
import { STORES } from './constants';
import { useAuth0 } from './auth/lib/react-auth0-wrapper';
import { usePortalConfiguration } from './hooks/portalConfiguration';
import useQueryParams from './hooks/useQueryParams';
import { SupportMessage } from './components/SupportMessage';
import { AnalyticsProvider } from './containers/analyticsProvider';
import { withAuthenticationRequired } from './containers/withAuthenticationRequired';
import RedirectPaymentCancelCallback from './containers/Checkout/Payment/Redirect/RedirectPaymentCancelCallback';
import RedirectPaymentCompleteCallback from './containers/Checkout/Payment/Redirect/RedirectPaymentCompleteCallback';

const SIGN_IN_ROUTE = '/signin';
const CHECKOUT_ROUTE = '/checkout';

const { STORE_STORE, STORE_CART } = STORES;

// eslint-disable-next-line react/prop-types
const mapMatchParamsToProps = ComponentToWrap => ({ match: { params } }) => (
  <ComponentToWrap {...params} />
);

const ExternalRedirect = ({ path, to, exact }) => (
  <Route exact={exact} path={path} render={() => window.location.replace(to)} />
);

const SignInRoute = () => {
  const { requireLoginOnCheckout } = usePortalConfiguration();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const params = useQueryParams();
  const targetPath = params.get('next');
  // eslint-disable-next-line no-console
  console.info('** Redirecting to Sign In page and then to: ', targetPath);
  if (!requireLoginOnCheckout || isAuthenticated) {
    return <Redirect to={targetPath || '/'} />;
  }
  loginWithRedirect({ appState: { targetPath } });
  return <></>;
};

const PreventNavigationRoute = props => {
  useEffect(() => {
    const onUnload = e => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  return <Route {...props} />;
};

const PaymentRoutes = () => (
  <>
    <Route
      exact
      path="/payments/redirect/cancel"
      component={RedirectPaymentCancelCallback}
    />
    <PreventNavigationRoute
      exact
      path="/payments/redirect/complete"
      component={RedirectPaymentCompleteCallback}
    />
  </>
);

const RequireCartInEntryStepComponent = ({
  children,
  [STORE_CART]: {
    cart: { id, state: cartState },
    moveCartToEntry,
  },
}) => {
  const isPending = cartState === 'pending';

  useEffect(() => {
    if (isPending) {
      moveCartToEntry(id);
    }
  }, [moveCartToEntry, id, isPending]);

  return children;
};

RequireCartInEntryStepComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const RequireCartInEntryStep = inject(STORE_CART)(
  observer(RequireCartInEntryStepComponent),
);

const Router = ({
  integration,
  embedded,
  [STORE_STORE]: { catalogueOverrideUrl, cartOverrideUrl },
}) => (
  <div className="weblink">
    {!embedded && <SupportMessage />}
    <AnalyticsProvider>
      <Switch>
        <Route path="/payments" component={PaymentRoutes} />
        <Route
          render={() => (
            <RequireCartInEntryStep>
              <Switch>
                <Route exact path={SIGN_IN_ROUTE} component={SignInRoute} />
                <Route
                  exact
                  path="/retrieve_order/:cartRecoveryToken"
                  component={withAuthenticationRequired(
                    mapMatchParamsToProps(RetrieveOrder),
                  )}
                />
                <Route
                  exact
                  path={CHECKOUT_ROUTE}
                  component={withAuthenticationRequired(Checkout)}
                />
                {cartOverrideUrl ? (
                  <ExternalRedirect exact path="/cart" to={cartOverrideUrl} />
                ) : (
                  <Route exact path="/cart" component={Cart} />
                )}
                {catalogueOverrideUrl && (
                  <ExternalRedirect to={catalogueOverrideUrl} />
                )}
                {!integration ? (
                  <WrappedRoutes embedded={embedded}>
                    <Routes integration={integration} />
                  </WrappedRoutes>
                ) : (
                  <Routes integration={integration} />
                )}
              </Switch>
            </RequireCartInEntryStep>
          )}
        />
      </Switch>
    </AnalyticsProvider>
  </div>
);

const WrappedRoutes = ({ embedded, children }) => (
  <Fragment>
    {!embedded && <WeblinkNavigation />}
    <main>{children}</main>
  </Fragment>
);

const Routes = ({ integration }) => (
  <Switch>
    {!integration && (
      <Route exact path="/" render={() => <Redirect to="/catalogue" />} />
    )}
    <Route exact path="/gift-vouchers" component={GiftVouchers} />
    <Route
      exact
      path="/test-advanced-filters"
      component={mapMatchParamsToProps(Filters)}
    />
    <Route
      exact
      path="/trainingRequest/:interestId"
      component={mapMatchParamsToProps(TrainingRequest)}
    />
    {[
      '/catalogue/:categoryId/subcategory/:subcategoryId',
      '/catalogue/:categoryId?/page/:pageNumber?',
      '/catalogue/:categoryId?',
    ].map(path => (
      <Route
        exact
        key={path}
        path={path}
        component={({ match: { params } }) => {
          const { name: courseNameSearchText } = qs.parse(
            window.location && window.location.search.substring(1),
          );
          return (
            <Catalogue
              {...params}
              courseNameSearchText={courseNameSearchText}
              integration={integration}
            />
          );
        }}
      />
    ))}
    {[
      '/catalogue/:categoryId/subcategory/:subcategoryId/courses/:code',
      '/catalogue/:categoryId/courses/:code',
      '/courses/:code',
    ].map(path => (
      <Route
        exact
        key={path}
        path={path}
        component={({ match: { params } }) => (
          <CourseDetails {...params} integration={integration} />
        )}
      />
    ))}
    {[
      '/catalogue/:categoryId/subcategory/:subcategoryId/paths/:id',
      '/catalogue/:categoryId/paths/:id',
      '/paths/:id',
    ].map(path => (
      <Route
        exact
        key={path}
        path={path}
        component={({ match: { params } }) => (
          <PathDetails {...params} integration={integration} />
        )}
      />
    ))}
    {!integration && <Route component={PageNotFound} />}
  </Switch>
);

ExternalRedirect.defaultProps = {
  path: undefined,
  exact: undefined,
};

ExternalRedirect.propTypes = {
  path: PropTypes.string,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

Router.defaultProps = {
  integration: false,
  embedded: false,
};

Router.propTypes = {
  integration: PropTypes.bool,
  embedded: PropTypes.bool,
};

WrappedRoutes.defaultProps = {
  embedded: false,
  children: null,
};

WrappedRoutes.propTypes = {
  embedded: PropTypes.bool,
  children: PropTypes.node,
};

Routes.defaultProps = {
  integration: false,
};

Routes.propTypes = {
  integration: PropTypes.bool,
};

export default inject(STORE_STORE)(observer(Router));
