const extractObjectiveType = objective =>
  objective.__typename ? objective.__typename.replace('Objective', '') : '';

export const mapObjective = objective => {
  switch (objective.__typename) {
    case 'CourseObjective':
      return {
        id: objective.id,
        type: 'Course',
        name: objective.course.name,
        courseCode: objective.course.code,
        event: {
          eventId: '',
          location: null,
          remainingPlaces: null,
          start: null,
          end: null,
          timeZoneName: null,
          deliveryMethod: null,
        },
      };
    case 'EventObjective':
      return {
        id: objective.id,
        type: 'Event',
        name: objective.event.name,
        courseCode: '',
        event: {
          eventId: objective.event.id,
          location: objective.event.location
            ? objective.event.location.name
            : '-',
          remainingPlaces: objective.event.remainingPlaces,
          start: objective.event.start,
          end: objective.event.end,
          timeZoneName: objective.event.timeZoneName,
          deliveryMethod: objective.event.deliveryMethod,
        },
      };
    case 'ExternalObjective':
      return {
        name: objective.name,
        type: 'External',
      };
    case 'LearningPathObjective':
      return {
        name: objective.learningPath.name,
        type: 'LearningPath',
        learningPath: {
          id: objective.learningPath.id,
        },
      };
    default:
      return {
        type: extractObjectiveType(objective),
        name: '',
        courseCode: '',
      };
  }
};

export const getLearningObjectiveOptions = (objectives, filters) => {
  if (!objectives) return [];
  const learningObjectives = objectives.map(
    objectiveEdge => objectiveEdge.node,
  );
  return learningObjectives.map(learningObjective => {
    return {
      id: learningObjective.id,
      objective: mapObjective(learningObjective),
      ...filters,
    };
  });
};
