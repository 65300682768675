const DATE_SHORT = { year: 'numeric', month: 'numeric', day: 'numeric' };
const DATE_LONG = { day: 'numeric', month: 'long', year: 'numeric' };
const TIME = { hour: '2-digit', minute: '2-digit' };
const TIME_ZONE = { timeZoneName: 'short' };

const DATE_AND_TIME = {
  ...DATE_SHORT,
  month: 'short',
  ...TIME,
};

export default {
  DEFAULT_DATE: DATE_SHORT,
  DEFAULT_TIME: TIME,
  DEFAULT_TIME_WITH_TZ: {
    ...TIME,
    ...TIME_ZONE,
  },
  DEFAULT_DATE_WITH_TIME: {
    ...DATE_AND_TIME,
  },
  DEFAULT_DATE_WITH_TIME_AND_TZ: {
    ...DATE_AND_TIME,
    ...TIME_ZONE,
  },
  DEFAULT_DATE_LONG: DATE_LONG,
  MOMENT_LOCAL_DATETIME: 'YYYY-MM-DDTHH:mm:ss',
};
