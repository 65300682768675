import { runInAction, action, decorate, observable, computed } from 'mobx';
import { get } from 'lodash';
import BaseStore from './baseStore';
import queries from '../queries';

const {
  course: { getCatalogueForListingPage },
} = queries;

class CatalogueFiltersStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    runInAction(() => {
      this.allLocationsResponse = [];
      this.allLocationsIsLoading = false;
      this.filteredLocations = [];
      this.courseCode = null;
      this.order = { field: 'locationName', direction: 'asc' };
      this.filteredResultsResponse = [];
      this.filteredResultsIsLoading = false;
    });
  }

  initialize = async () => {
    await this.fetchAllLocations();
  };

  fetchAllLocations = action(async () => {
    this.allLocationsIsLoading = true;
    const {
      data: { locations },
    } = await this.apolloClient.query({
      query: queries.location.getLocations,
    });
    runInAction(() => {
      this.allLocationsResponse = locations.edges.map(
        ({ node: { name, id } }) => ({ name, id }),
      );
      this.allLocationsIsLoading = false;
    });
  });

  setFilteredLocations = action(values => {
    this.filteredLocations = values;
  });

  setCourseCode = action(value => {
    this.courseCode = value ? value.code : null;
  });

  setFilters = action(() => {
    const filters = [];
    if (this.courseCode) {
      filters.push({
        field: 'courseCode',
        operation: 'eq',
        value: this.courseCode,
      });
    }
    if (this.filteredLocations && this.filteredLocations.length) {
      filters.push({
        field: 'locationId',
        operation: 'in',
        values: this.filteredLocations,
      });
    }
    this.fetchWithFilters(filters);
  });

  fetchWithFilters = action(async filters => {
    this.filteredResultsIsLoading = true;
    const { data: filteredResultsResponse } = await this.apolloClient.query({
      query: getCatalogueForListingPage,
      variables: {
        filters,
        pageSize: this.rootStore.catalogueStore.pageSize,
        offset: 0, // to be fixed to adjust to page number
        order: this.order,
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });

    runInAction(() => {
      this.filteredResultsResponse = get(
        filteredResultsResponse,
        'catalogue.edges',
        [],
      ).map(({ node }) => node);
      this.filteredResultsIsLoading = false;
    });
  });

  get allLocations() {
    return this.allLocationsResponse;
  }

  get allFilteredEvents() {
    return this.filteredResultsResponse;
  }
}

decorate(CatalogueFiltersStore, {
  allLocationsIsLoading: observable,
  allLocationsResponse: observable,
  filteredEvents: observable,
  filteredResultsIsLoading: observable,
  filteredResultsResponse: observable,
  filteredLocations: observable,
  allLocations: computed,
  allFilteredEvents: computed,
});

export default CatalogueFiltersStore;
