import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { Button, Col, Prompt, Row } from '@administrate/piston-ux';
import CartExpiredItemsList from '../../components/Cart/CartExpiredItemsList';

const ExpiredItemsContainer = ({ items, showPlacesRemaining, t }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  if (!items.length) {
    return null;
  }

  return (
    <Panel expanded={isExpanded} onToggle={() => {}}>
      <Panel.Heading>
        <Panel.Title>
          <Row>
            <Col xs={10}>
              <div className="expired-items-prompt-col">
                <Prompt
                  type="warning"
                  message={t('weblink:cartExpiredListPanelTitle')}
                />
              </div>
            </Col>
            <Col xs={2} className="text-right">
              <Button
                label={t('weblink:cartExpiredListView')}
                type="suppressed"
                onClick={toggleExpanded}
              />
            </Col>
          </Row>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          <CartExpiredItemsList
            items={items}
            showPlacesRemaining={showPlacesRemaining}
          />
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

ExpiredItemsContainer.propTypes = {
  showPlacesRemaining: PropTypes.bool,
  items: MobxPropTypes.arrayOrObservableArray.isRequired,
  t: PropTypes.func.isRequired,
};

ExpiredItemsContainer.defaultProps = {
  showPlacesRemaining: true,
};

export default withTranslation()(observer(ExpiredItemsContainer));
