import { get as _get } from 'lodash';
import { observable, decorate, runInAction, action, computed } from 'mobx';

import BaseStore from './baseStore';
import formatCurrency from '../utils/formatCurrency';

class StoreStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);

    runInAction(() => {
      this.storeDetails = null;
      this.storeDetailsIsLoading = true;
    });
  }

  formatCurrency = amount =>
    formatCurrency(amount, this.currency.symbol, this.currency.code);

  formatCurrencyForCalculation = amount =>
    formatCurrency(amount, this.currency.symbol, this.currency.code, false);

  get paymentProviders() {
    return _get(this.storeDetails, 'paymentMethods', []);
  }

  get currency() {
    return _get(this.storeDetails, 'currency') || { symbol: null, code: null };
  }

  get region() {
    return _get(this.storeDetails, 'region', { id: null, code: null });
  }

  get defaultErrorMessage() {
    return _get(this.storeDetails, 'defaultErrorMessage', null);
  }

  get terms() {
    return _get(this.storeDetails, 'terms', []);
  }

  get taxInclusive() {
    return _get(this.storeDetails, 'taxInclusive', false);
  }

  get isTaxInclusiveOnly() {
    return _get(this.storeDetails, 'isTaxInclusiveOnly', false);
  }

  get confirmEmails() {
    return _get(this.storeDetails, 'confirmEmails', false);
  }

  get hidePrices() {
    return _get(this.storeDetails, 'hidePrices', false);
  }

  get cartlessCheckout() {
    return _get(this.storeDetails, 'cartlessCheckout', false);
  }

  get requireLearnerDetails() {
    return _get(this.storeDetails, 'requireLearnerDetails', true);
  }

  get hideCompanyFieldForBooker() {
    return _get(this.storeDetails, 'hideCompanyFieldForBooker', false);
  }

  get requireBookerBillingAddress() {
    return _get(this.storeDetails, 'requireBookerBillingAddress', false);
  }

  get giftVouchersEnabled() {
    const giftVoucherConfiguration = _get(
      this.storeDetails,
      'giftVoucherConfiguration',
      null,
    );
    return giftVoucherConfiguration !== null;
  }

  get giftVoucherConfigurationId() {
    return _get(this.storeDetails, 'giftVoucherConfiguration.id', null);
  }

  get giftVoucherConfigDescription() {
    return _get(
      this.storeDetails,
      'giftVoucherConfiguration.productDescription',
      null,
    );
  }

  get priceLevels() {
    return _get(this.storeDetails, 'priceLevels', []);
  }

  get giftVoucherEmailTemplates() {
    const giftVoucherEmailTemplates = _get(
      this.storeDetails,
      'giftVoucherEmailTemplates.edges',
      [],
    );
    return giftVoucherEmailTemplates.map(edge => edge.node);
  }

  get allowTrainingRequests() {
    return _get(this.storeDetails, 'trainingRequestWorkflow.enabled', false);
  }

  get catalogueOverrideUrl() {
    return _get(this.storeDetails, 'overrideUrls.catalogue', null);
  }

  get cartOverrideUrl() {
    return _get(this.storeDetails, 'overrideUrls.cart', null);
  }

  get postalCountries() {
    return _get(this.storeDetails, 'postalCountries', []);
  }

  get multiRegion() {
    return _get(this.storeDetails, 'multiRegion', false);
  }

  updateStoreDetails = action(store => {
    this.storeDetails = store;
    this.storeDetailsIsLoading = false;
  });
}

decorate(StoreStore, {
  storeDetails: observable,
  storeDetailsIsLoading: observable,
  paymentProviders: computed,
  taxInclusive: computed,
  isTaxInclusiveOnly: computed,
  currency: computed,
  region: computed,
  defaultErrorMessage: computed,
  terms: computed,
  confirmEmails: computed,
  hidePrices: computed,
  cartlessCheckout: computed,
  requireLearnerDetails: computed,
  giftVouchersEnabled: computed,
  giftVoucherConfigurationId: computed,
  giftVoucherConfigDescription: computed,
  giftVoucherEmailTemplates: computed,
  postalCountries: computed,
  priceLevels: computed,
  allowTrainingRequests: computed,
});

export default StoreStore;
