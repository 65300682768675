import gql from 'graphql-tag';

export const getSessionsForEvent = gql`
  query getSessionsForEvent(
    $eventId: ID!
    $filters: [SessionFieldFilter!]
    $order: SessionFieldOrder
    $offset: Int
    $first: Int
  ) {
    sessions(
      eventId: $eventId
      filters: $filters
      order: $order
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalRecords
      }
      edges {
        node {
          id
          name
          start
          end
          location {
            id
            name
          }
        }
      }
    }
  }
`;
