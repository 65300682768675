import React from 'react';
import { StickyContainer, Sticky } from 'react-sticky';
import PropTypes from 'prop-types';
import { Container, PageTitle, LoadingBar, Notice } from '@administrate/piston';

import { isObservableArray } from 'mobx';
import portalStore from '../../stores/portalStore';
import { CartExpiryCountdown } from './CartExpiryCountdown';
import CartItemsList from './CartItemsList/CartItemsList';
import ExpiredItemsModal from './ExpiredItemsModal';
import { CANNOT_EDIT_CART_MESSAGE } from '../../stores/cartStore';
import WeblinkNavigation from '../../containers/WeblinkNavigation/WeblinkNavigation';
import ReturnToCatalogue from '../../containers/ReturnToCatalogue';
import PromotionCodeInput from '../../containers/Cart/PromotionCodeInput';
import CartSummaryCard from '../../containers/Cart/CartSummaryCard';
import ExpiredItemsContainer from '../../containers/Cart/ExpiredItemsContainer';

const getTranslatableStringFromErrorMessage = (message, t) => {
  if (message && message.includes(CANNOT_EDIT_CART_MESSAGE)) {
    return t('weblink:sessionExpired');
  }
  switch (message) {
    case 'You can not purchase a Gift Voucher when a Gift Voucher is being redeemed':
      return t('weblink:cartHasGiftVoucherApplied');
    default:
      return '';
  }
};

const mapErrorToItemisedList = (errorOrErrors, defaultMessage, t) => {
  let errorList = [];
  if (Array.isArray(errorOrErrors) || isObservableArray(errorOrErrors)) {
    errorList = errorOrErrors;
  } else {
    errorList.push(errorOrErrors);
  }
  return (
    <ul className="errors-list">
      {errorList.map(error => {
        const message = error ? error.message : defaultMessage;
        const translatableString = getTranslatableStringFromErrorMessage(
          message,
          t,
        );
        return <li key={`${message}`}>{translatableString || message}</li>;
      })}
    </ul>
  );
};

const CartNavigation = ({ t, embedSite }) => {
  if (embedSite) {
    return (
      <WeblinkNavigation
        type="basic"
        back={{
          label: t('weblink:backToCatalogue'),
          onClick: () => {
            window.location = embedSite;
          },
        }}
      />
    );
  }

  return <WeblinkNavigation type="general" />;
};

CartNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  embedSite: PropTypes.string,
};

CartNavigation.defaultProps = {
  embedSite: null,
};

const Cart = ({
  t,
  integration,
  hideEditItemButton,
  showPlacesRemaining,
  defaultErrorMessage,
  cart,
  reloadCart,
  isLoading,
  isApplyPromotionCodeLoading,
  applyPromotionCode,
  isError,
  error,
  reservationsValidUntil,
  handleCartExpiry,
  containsValidItems,
  rebookCartItem,
  cartItemToRebook,
  removeCartItemToRebookFromCart,
  toCatalogue,
}) => (
  <>
    {/* TODO: Move this further up the component tree so that the Cart does not need to be responsible for rendering it
      https://administrate.atlassian.net/browse/PLAT-11827 */}
    {!integration && <CartNavigation t={t} embedSite={portalStore.embedSite} />}
    {!!cartItemToRebook && (
      <ExpiredItemsModal
        t={t}
        name={cartItemToRebook.name}
        bookedQuantity={cartItemToRebook.bookedQuantity}
        remainingPlaces={cartItemToRebook.remainingPlaces}
        onRemove={removeCartItemToRebookFromCart}
        onRebook={() =>
          rebookCartItem(cartItemToRebook.id, cartItemToRebook.remainingPlaces)
        }
      />
    )}
    <StickyContainer>
      <div className="cart_loading-bar">
        <Sticky>
          {({ style }) => (
            <div style={{ ...style, zIndex: 1 }}>
              <LoadingBar
                isLoading={isLoading || isApplyPromotionCodeLoading}
              />
            </div>
          )}
        </Sticky>
      </div>
      <main>
        <PageTitle title={t('weblink:myCart')} />
        {isError && (
          <Container>
            <Container.Row>
              <Container.Col xs={12}>
                <div className="mt-5">
                  <Notice
                    body={mapErrorToItemisedList(error, defaultErrorMessage, t)}
                    type="danger"
                  />
                </div>
              </Container.Col>
            </Container.Row>
          </Container>
        )}
        {!isLoading && !cart.items.length && (
          <ReturnToCatalogue toCatalogue={toCatalogue} />
        )}
        {(isLoading || !!cart.items.length) && (
          <Container className={isLoading ? 'skeletonStyle' : ''}>
            {reservationsValidUntil && (
              <Container.Row>
                <Container.Col xs={12}>
                  <div className="reservations-timer-container">
                    <CartExpiryCountdown
                      t={t}
                      reservationsValidUntil={reservationsValidUntil}
                      onComplete={reloadCart}
                      handleCartExpiry={handleCartExpiry}
                      includeModal={false}
                    />
                  </div>
                </Container.Col>
              </Container.Row>
            )}
            <Container.Row>
              <Container.Col xs={12}>
                {!containsValidItems ? (
                  <br />
                ) : (
                  <CartItemsList
                    items={cart.currentItems}
                    hideEditItemButton={hideEditItemButton}
                    showPlacesRemaining={showPlacesRemaining}
                  />
                )}
              </Container.Col>
            </Container.Row>
            <Container.Row>
              <Container.Col sm={6}>
                <PromotionCodeInput
                  isLoading={isLoading || isApplyPromotionCodeLoading}
                  applyPromotionCode={applyPromotionCode}
                />
              </Container.Col>
              <Container.Col sm={6}>
                <CartSummaryCard canCheckout toCatalogue={toCatalogue} />
              </Container.Col>
            </Container.Row>
            <Container.Row>
              <Container.Col xs={12}>
                <ExpiredItemsContainer
                  items={cart.expiredItems}
                  showPlacesRemaining={showPlacesRemaining}
                />
              </Container.Col>
            </Container.Row>
          </Container>
        )}
      </main>
    </StickyContainer>
  </>
);

Cart.propTypes = {
  hideEditItemButton: PropTypes.bool,
  showPlacesRemaining: PropTypes.bool,
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
  defaultErrorMessage: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cart: PropTypes.any.isRequired,
  reloadCart: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isApplyPromotionCodeLoading: PropTypes.bool.isRequired,
  applyPromotionCode: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  reservationsValidUntil: PropTypes.string,
  handleCartExpiry: PropTypes.func.isRequired,
  containsValidItems: PropTypes.bool.isRequired,
  rebookCartItem: PropTypes.func.isRequired,
  cartItemToRebook: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    bookedQuantity: PropTypes.number.isRequired,
    remainingPlaces: PropTypes.number.isRequired,
  }),
  removeCartItemToRebookFromCart: PropTypes.func.isRequired,
  toCatalogue: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  hideEditItemButton: false,
  showPlacesRemaining: true,
  error: null,
  reservationsValidUntil: null,
  cartItemToRebook: null,
};

export default Cart;
