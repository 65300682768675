import { merge as _merge } from 'lodash';

const envConfig = {
  nodeEnv: import.meta.env.MODE,
  get isDev() {
    return this.nodeEnv === 'development';
  },
};

const source = envConfig.isDev ? import.meta.env : window;

const config = {
  portalAuthURI: source.VITE_PORTAL_AUTH_URL,
  graphQLURI: source.VITE_GRAPHQL_API_URL,
  sentryDSN: source.VITE_SENTRY_DSN,
  sentryEnvironment: source.VITE_SENTRY_ENVIRONMENT,
  sentryRelease: source.VITE_SENTRY_RELEASE,
};

export default _merge(config, envConfig);
