import { decorate, action, observable, computed } from 'mobx';

import BaseStore from './baseStore';

class ReviewOrderStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.initialize();
  }

  initialize = action('Review Order Store initialize', () => {
    this.price = {
      subTotal: 0,
      grandTotal: 0,
      discountTotal: 0,
      taxes: [],
    };
    this.cartContent = [];
    this.customer = null;
    // TODO: handle payment details data here
    this.payment = null;
    this.promotionalCode = null;
    this.giftVoucherApplications = null;
    this.requiresReviewBeforePurchase = false;
  });

  get isEmpty() {
    return this.cartContent.length === 0;
  }

  get isFree() {
    return Number(this.price.grandTotal) === 0;
  }

  setGiftVoucherApplications = action(
    'setGiftVoucherApplications',
    giftVoucherApplications => {
      this.giftVoucherApplications = giftVoucherApplications;
    },
  );

  setPromotionalCode = action('setPromotionalCode', promotionalCode => {
    this.promotionalCode = promotionalCode;
  });

  setPrice = action('setPrice', price => {
    this.price = price;
  });

  setCartContent = action('setCartContent', cartContent => {
    this.cartContent = cartContent;
  });

  setCustomer = action('setCustomer', customer => {
    this.customer = customer;
  });

  setRequiresReviewBeforePurchase = action(
    'setRequiresReviewBeforePurchase',
    requiresReviewBeforePurchase => {
      this.requiresReviewBeforePurchase = requiresReviewBeforePurchase;
    },
  );
}

decorate(ReviewOrderStore, {
  price: observable,
  cartContent: observable,
  customer: observable,
  giftVoucherApplications: observable,
  payment: observable,
  promotionalCode: observable,
  requiresReviewBeforePurchase: observable,
  isFree: computed,
  isEmpty: computed,
});

export default ReviewOrderStore;
