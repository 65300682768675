import React, { useEffect } from 'react';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Select,
  useTypedFormValues,
  Form,
  Input,
  StaticInput,
} from '@administrate/piston-ux';

import { withTranslation } from 'react-i18next';
import { CATALOG_ITEM_IMAGE_PLACEHOLDER } from '../../constants';

export const ProductImage = ({ imageUrl }) => (
  <div className="image hidden-xs hidden-sm">
    <img
      className="CourseImage"
      src={imageUrl || CATALOG_ITEM_IMAGE_PLACEHOLDER}
      alt=""
    />
  </div>
);

ProductImage.propTypes = {
  imageUrl: PropTypes.string,
};

ProductImage.defaultProps = {
  imageUrl: null,
};

const PriceLevelPickerComponent = ({
  t,
  formatCurrency,
  initialPriceLevel,
  priceLevels,
  onPriceLevelChange,
  isLoading,
}) => {
  const options = priceLevels.map(({ id, name, amount }) => ({
    value: id,
    label: `${name} (${formatCurrency(amount)})`,
  }));

  const formValues = useTypedFormValues({
    priceLevel: initialPriceLevel ? initialPriceLevel.id : null,
  });

  return (
    <Form values={formValues}>
      <Select
        name="priceLevel"
        label={t('weblink:priceLevel')}
        options={options}
        disabled={isLoading}
        onChange={value => {
          const priceLevel = priceLevels.find(x => x.id === value);
          onPriceLevelChange(priceLevel);
        }}
        isClearable={false}
        valid={value => !!value}
      />
    </Form>
  );
};

PriceLevelPickerComponent.propTypes = {
  t: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  initialPriceLevel: PropTypes.shape({}).isRequired,
  priceLevels: PropTypes.oneOfType([
    MobXPropTypes.observableArrayOf(PropTypes.shape({})),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  onPriceLevelChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const PriceLevelPicker = withTranslation()(PriceLevelPickerComponent);

export const PickerModalTotalComponent = ({
  t,
  formatCurrency,
  selectedProduct,
  priceLevels,
}) => {
  const price =
    priceLevels.length > 0 ? selectedProduct.priceLevel : selectedProduct.price;

  const total =
    price && selectedProduct.quantity
      ? formatCurrency(Number(price.amount) * Number(selectedProduct.quantity))
      : '';

  const formValues = useTypedFormValues({
    total,
  });

  useEffect(() => {
    formValues.total = total;
  }, [total]);

  return (
    <div className="total">
      <Form values={formValues}>
        <StaticInput label={t('weblink:total')} name="total" />
      </Form>
    </div>
  );
};

PickerModalTotalComponent.propTypes = {
  t: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  selectedProduct: PropTypes.shape({}).isRequired,
  priceLevels: PropTypes.oneOfType([
    MobXPropTypes.observableArrayOf(PropTypes.shape({})),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
};

export const PickerModalTotal = withTranslation()(PickerModalTotalComponent);

export const QuantityPickerComponent = ({
  t,
  changeQuantity,
  isLoading,
  selectedProduct,
  max,
}) => {
  const formValues = useTypedFormValues({
    quantity: selectedProduct.quantity,
  });

  return (
    <Form values={formValues}>
      <div className="seats">
        <span className="sr-only">Number of seats to reserve</span>
        <Input
          uniqueId="seats"
          label={t('weblink:quantity')}
          type="number"
          name="quantity"
          min={1}
          max={max}
          step="1"
          disabled={isLoading || !selectedProduct}
          onChange={value => changeQuantity(value)}
          valid={value => value !== undefined || value !== null}
        />
      </div>
    </Form>
  );
};

QuantityPickerComponent.propTypes = {
  t: PropTypes.func.isRequired,
  changeQuantity: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedProduct: PropTypes.shape({}).isRequired,
  max: PropTypes.number.isRequired,
};

export const QuantityPicker = withTranslation()(QuantityPickerComponent);
