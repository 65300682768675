import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const LearnerDetailsItem = ({ label, value }) =>
  value ? (
    <p className="col-sm-6">
      <span className="buyer-detail-label">{label}</span>
      <span className="buyer-detail-value">{value}</span>
    </p>
  ) : null;

LearnerDetailsItem.defaultProps = {
  value: null,
};

LearnerDetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const LearnerDetails = ({ learner, t }) =>
  learner &&
  learner.firstName &&
  learner.lastName && (
    <div className="row mb-4">
      <LearnerDetailsItem
        label={t('weblink:name')}
        value={`${learner.firstName} ${learner.lastName}`}
      />
      <LearnerDetailsItem
        label={t('weblink:emailAddress')}
        value={learner.email}
      />
    </div>
  );

LearnerDetails.propTypes = {
  t: PropTypes.func.isRequired,
  learner: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default withTranslation()(LearnerDetails);
