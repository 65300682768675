import React from 'react';
import { observer } from 'mobx-react-lite';

import inject from '../../inject';
import { STORES } from '../../../constants';
import {
  RedirectPaymentButton,
  RedirectPaymentInfo,
} from './Redirect/RedirectPaymentMethod';

const { STORE_CHECKOUT } = STORES;

const PaymentButton = ({ [STORE_CHECKOUT]: { checkoutWithWorldpay } }) => (
  <RedirectPaymentButton onClick={checkoutWithWorldpay} />
);

PaymentButton.propTypes = {};

export const WorldpayPaymentButton = inject(STORE_CHECKOUT)(
  observer(PaymentButton),
);

const PaymentInfo = () => (
  <RedirectPaymentInfo paymentProviderName="Worldpay" />
);

PaymentInfo.propTypes = {};

export default observer(PaymentInfo);
