import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Container, SecondaryAction } from '@administrate/piston';
import { OpacityOverlay } from '@administrate/piston-ux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import inject from '../inject';
import { STORES } from '../../constants';
import OrderItem from '../../components/Checkout/OrderItem';
import FormIncompleteNotice from './FormIncompleteNotice';
import NonDiverseEmailsNotice from './NonDiverseEmailsNotice';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import { PageViewEvent } from '../../analytics';

const { STORE_ANALYTICS, STORE_CHECKOUT, STORE_CART, STORE_STORE } = STORES;

const CheckoutLearners = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_CHECKOUT]: {
    currentItemIndex,
    itemsRequiringLearnerDetails,
    updateCurrentItemIndex,
    checkDataCompletion,
    addLearnersAndChangeStep,
    isLoading: isCheckoutLoading,
    showFreeCheckoutPath,
    changeStepBackward,
    showOrderReviewPath,
    hasDuplicateEmails,
  },
  [STORE_CART]: {
    updateItemLearnerProperty,
    updateItemLearnerEmail,
    updateItemRecipientProperty,
    updateItemRecipientEmail,
    showIncompleteNotice,
    clearNotice,
    isLoading: isCartLoading,
    toggleUseBookerDetails,
    setBookerAsRecipient,
    buyerIsRecipient,
    clearRecipientDetails,
    isCartExpired,
    addPostage,
    removePostage,
    addGiftVoucherRecipient,
  },
  [STORE_STORE]: { confirmEmails, storeDetails, requireLearnerDetails },
  t,
  integration,
}) => {
  const [pointOfSaleLearnerFields, setPointOfSaleLearnerFields] = useState(
    null,
  );

  useEffect(() => {
    setPointOfSaleLearnerFields(
      itemsRequiringLearnerDetails[currentItemIndex].pointOfSaleDefinitions,
    );
  }, [itemsRequiringLearnerDetails, currentItemIndex]);

  const isLoading = isCheckoutLoading || isCartLoading;

  useEffect(() => {
    if (!storeDetails) return;

    setBrandedPageTitle(
      ['Checkout', t('weblink:learnerDetails')],
      storeDetails,
      !integration,
    );
    captureEvent(new PageViewEvent());
  }, [storeDetails, integration, captureEvent, t]);

  const currentItemTypeName = () => {
    if (itemsRequiringLearnerDetails[currentItemIndex].isPath) {
      return t('weblink:learningPath');
    }
    if (itemsRequiringLearnerDetails[currentItemIndex].isGiftVoucher) {
      return t('weblink:giftVoucher');
    }
    return t('weblink:course');
  };

  const currentItemDetailsName = () =>
    itemsRequiringLearnerDetails[currentItemIndex].isGiftVoucher
      ? t('weblink:recipientDetails')
      : t('weblink:learnerDetailsForCourseOf', {
          type: currentItemTypeName(),
          itemIndex: currentItemIndex + 1,
          itemsRequiringLearnerDetailsCount:
            itemsRequiringLearnerDetails.length,
        });

  const handleVouchers = item => {
    if (
      !item ||
      !item.isGiftVoucher ||
      !item.recipientDetails ||
      !item.childItems
    ) {
      return;
    }

    addGiftVoucherRecipient(item);
    if (
      item.recipientDetails.giftVoucherHasLinkedItem &&
      !item.childItems.length
    ) {
      addPostage(item);
    } else if (
      !item.recipientDetails.giftVoucherHasLinkedItem &&
      item.childItems.length
    ) {
      removePostage(item);
    }
  };

  const backButton =
    currentItemIndex - 1 < 0 ? (
      <OpacityOverlay on={isLoading}>
        <span
          className="d-none btn btn-link d-sm-inline-block"
          onClick={() => {
            clearNotice();
            changeStepBackward();
          }}
          onKeyPress={() => {
            clearNotice();
            changeStepBackward();
          }}
          role="link"
          tabIndex={0}
          aria-disabled={isLoading}
        >
          {t('weblink:backToCustomerInfo')}
        </span>
      </OpacityOverlay>
    ) : (
      <OpacityOverlay on={isLoading}>
        <span
          className="d-none btn btn-link d-sm-inline-block"
          onClick={() => {
            clearNotice();
            updateCurrentItemIndex(currentItemIndex - 1);
          }}
          onKeyPress={() => {
            clearNotice();
            updateCurrentItemIndex(currentItemIndex - 1);
          }}
          style={{ marginLeft: -12 }}
          role="link"
          tabIndex={0}
          aria-disabled={isLoading}
        >
          {`${t('weblink:backToCourse')} ${currentItemIndex}`}
        </span>
      </OpacityOverlay>
    );

  const isLastItem =
    currentItemIndex + 1 === itemsRequiringLearnerDetails.length;

  const nextButtonIsDisabled = item =>
    isLoading ||
    !item.isComplete(pointOfSaleLearnerFields, requireLearnerDetails) ||
    (hasDuplicateEmails && isLastItem) ||
    (!item.isGiftVoucher && isCartExpired);

  const nextButton = isLastItem ? (
    <SecondaryAction
      label={
        <span>
          {showFreeCheckoutPath
            ? t('weblink:completeOrder')
            : showOrderReviewPath
            ? t('weblink:submitRequest')
            : t('weblink:continueToPaymentMethod')}
          {isLoading && (
            <span className="glyphicon glyphicon-refresh glyphicon-spinner" />
          )}
        </span>
      }
      disabled={nextButtonIsDisabled(
        itemsRequiringLearnerDetails[currentItemIndex],
      )}
      onClick={() => {
        handleVouchers(itemsRequiringLearnerDetails[currentItemIndex]);
        checkDataCompletion();
        addLearnersAndChangeStep();
      }}
    />
  ) : (
    <SecondaryAction
      label={t('weblink:learnerDetailsForCourse', {
        type: currentItemTypeName(),
        itemIndex: currentItemIndex + 2,
      })}
      disabled={nextButtonIsDisabled(
        itemsRequiringLearnerDetails[currentItemIndex],
      )}
      onClick={() => {
        handleVouchers(itemsRequiringLearnerDetails[currentItemIndex]);
        updateCurrentItemIndex(currentItemIndex + 1);
      }}
    />
  );

  return (
    <Card>
      {showIncompleteNotice && <FormIncompleteNotice />}
      {hasDuplicateEmails && <NonDiverseEmailsNotice />}
      <h1 className="CardTitle">{currentItemDetailsName()}</h1>
      <Card.Body>
        <Container fluid>
          <Container.Row>
            <Container.Col xs={12}>
              <OrderItem
                item={itemsRequiringLearnerDetails[currentItemIndex]}
                updateItemLearnerProperty={updateItemLearnerProperty}
                updateItemLearnerEmail={updateItemLearnerEmail}
                updateItemRecipientProperty={updateItemRecipientProperty}
                updateItemRecipientEmail={updateItemRecipientEmail}
                toggleUseBookerDetails={toggleUseBookerDetails}
                setBookerAsRecipient={setBookerAsRecipient}
                isLoading={isLoading}
                confirmEmails={confirmEmails}
                attributeDefinitions={pointOfSaleLearnerFields || []}
                requireLearnerDetails={requireLearnerDetails}
                buyerIsRecipient={buyerIsRecipient}
                clearRecipientDetails={clearRecipientDetails}
              />
            </Container.Col>
          </Container.Row>
        </Container>
      </Card.Body>
      <Card.Footer left={backButton} right={nextButton} />
    </Card>
  );
};

CheckoutLearners.propTypes = {
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
};

export default withTranslation()(
  inject(
    STORE_ANALYTICS,
    STORE_CHECKOUT,
    STORE_CART,
    STORE_STORE,
  )(observer(CheckoutLearners)),
);
