import React from 'react';
import { get as _get } from 'lodash';
import { Container } from '@administrate/piston';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';
import { withTranslation } from 'react-i18next';

import withIntegration from '../../containers/withIntegration';
import EventList from '../../containers/EventList/EventList';
import courseDetailsProptype from '../../proptypes/courseDetails';
import HTMLContainer from '../HTMLContainer';
import Icon from '../Icon/Icon';
import LegacyModal from '../ModalOutlet/LegacyModal';

const Details = ({
  code, // Course Code
  courseDetails,
  isLoading,
  isError,
  redirect,
  // Event List Filters
  eventListOrder,
  showDateFilter,
  showLocationFilter,
  // Event List Columns
  showTitleColumn,
  showLocationColumn,
  showVenueColumn,
  showStartDateColumn,
  showEndDateColumn,
  showDateAndTimeColumn,
  showDurationColumn,
  showTimeColumn,
  showPlacesRemainingColumn,
  showPriceColumn,
  showAddToCartColumn,
  showClassroomStartDateColumn,
  showClassroomDurationColumn,
  showClassroomTimeColumn,
  showLmsStartDateColumn,
  showLmsDurationColumn,
  showLmsTimeColumn,
  showSessionsColumn,
  alwaysHideSessionsColumn,
  // HOC
  integration,
  t,
}) => (
  <div
    className={
      isLoading
        ? 'withpanel detail defined-background loading'
        : 'withpanel detail defined-background'
    }
  >
    <StickyContainer>
      {!integration && (
        <Sticky topOffset={0}>
          {({ style }) => (
            <div className="container-fluid Sticky sticky-title" style={style}>
              <div className="container">
                <span className="title">
                  {!isLoading && courseDetails.name}
                </span>
              </div>
            </div>
          )}
        </Sticky>
      )}
      <LegacyModal
        title={t('weblink:weAreSorry')}
        show={isError}
        cancelLabel={t('weblink:backToCatalogue')}
        onCancel={redirect}
      >
        <h3>
          {' '}
          {t('weblink:notFoundHeader', {
            item: t('weblink:course'),
          })}
        </h3>
        <p>{t('weblink:noCourseBody')}</p>
      </LegacyModal>
      <div className="detail-body">
        <Container>
          <Col sm={12} md={8} className="CourseDetail">
            <Row>
              {!isLoading && !!_get(courseDetails, 'outcomes.length', 0) && (
                <div className="content-row course-outcomes panel panel-default">
                  <div className="panel-body">
                    <h2>{t('weblink:whatYouLearn')}</h2>
                    <ul className="list-of-items with-icons">
                      {!isLoading &&
                        courseDetails.outcomes.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li className="half-item" key={index}>
                            <Icon size="medium" glyph="check" pro />
                            <HTMLContainer
                              className="course-outcome"
                              content={item}
                              clearTags
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
              {(isLoading || courseDetails.description) && (
                <div className="content-row course-description">
                  <h2>{t('weblink:description')}</h2>
                  {!isLoading ? (
                    <HTMLContainer content={courseDetails.description} />
                  ) : (
                    <p />
                  )}
                </div>
              )}
              {!isLoading && courseDetails.topicsCovered && (
                <div className="content-row course-topic">
                  <h3>{t('weblink:topicsCovered')}</h3>
                  <HTMLContainer content={courseDetails.topicsCovered} />
                </div>
              )}
              {!isLoading && courseDetails.prerequisites && (
                <div className="content-row course-prerequisites">
                  <h3>{t('weblink:prerequisites')}</h3>
                  <HTMLContainer content={courseDetails.prerequisites} />
                </div>
              )}
              {!isLoading && courseDetails.custom && (
                <div className="content-row course-fields">
                  <h3>{t('weblink:customFields')}</h3>
                  <p>{courseDetails.custom}</p>
                </div>
              )}
            </Row>

            <Row>
              <EventList
                code={code}
                eventListOrder={eventListOrder}
                showDateFilter={showDateFilter}
                showLocationFilter={showLocationFilter}
                showCourseFilter={false}
                showCategoryFilter={false}
                showSubcategoryFilter={false}
                showTitleColumn={showTitleColumn}
                showLocationColumn={showLocationColumn}
                showVenueColumn={showVenueColumn}
                showStartDateColumn={showStartDateColumn}
                showEndDateColumn={showEndDateColumn}
                showDateAndTimeColumn={showDateAndTimeColumn}
                showDurationColumn={showDurationColumn}
                showTimeColumn={showTimeColumn}
                showPlacesRemainingColumn={showPlacesRemainingColumn}
                showClassroomStartDateColumn={showClassroomStartDateColumn}
                showClassroomDurationColumn={showClassroomDurationColumn}
                showClassroomTimeColumn={showClassroomTimeColumn}
                showLmsStartDateColumn={showLmsStartDateColumn}
                showLmsDurationColumn={showLmsDurationColumn}
                showLmsTimeColumn={showLmsTimeColumn}
                showPriceColumn={showPriceColumn}
                showAddToCartColumn={showAddToCartColumn}
                showSessionsColumn={showSessionsColumn}
                alwaysHideSessionsColumn={alwaysHideSessionsColumn}
              />
            </Row>
          </Col>
        </Container>
      </div>
    </StickyContainer>
  </div>
);

Details.defaultProps = {
  showTitleColumn: false,
  showLocationColumn: true,
  showVenueColumn: false,
  showStartDateColumn: true,
  showEndDateColumn: false,
  showDateAndTimeColumn: false,
  showDurationColumn: true,
  showTimeColumn: true,
  showPlacesRemainingColumn: true,
  showPriceColumn: true,
  showAddToCartColumn: true,
  showClassroomStartDateColumn: false,
  showClassroomDurationColumn: false,
  showClassroomTimeColumn: false,
  showLmsStartDateColumn: false,
  showLmsDurationColumn: false,
  showLmsTimeColumn: false,
  showSessionsColumn: true,
  alwaysHideSessionsColumn: false,
  courseDetails: {},
  code: null,
  eventListOrder: {
    field: 'locationName',
    direction: 'asc',
  },
};

Details.propTypes = {
  code: PropTypes.string,
  t: PropTypes.func.isRequired,
  courseDetails: courseDetailsProptype,
  eventListOrder: PropTypes.shape({
    field: PropTypes.oneOf([
      'title',
      'locationName',
      'start',
      'classroomStart',
      'lmsStart',
    ]),
    direction: PropTypes.oneOf(['asc', 'desc']),
  }),
  integration: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  showDateFilter: PropTypes.bool.isRequired,
  showLocationFilter: PropTypes.bool.isRequired,
  showTitleColumn: PropTypes.bool,
  showLocationColumn: PropTypes.bool,
  showVenueColumn: PropTypes.bool,
  showStartDateColumn: PropTypes.bool,
  showEndDateColumn: PropTypes.bool,
  showDateAndTimeColumn: PropTypes.bool,
  showDurationColumn: PropTypes.bool,
  showTimeColumn: PropTypes.bool,
  showPlacesRemainingColumn: PropTypes.bool,
  showPriceColumn: PropTypes.bool,
  showAddToCartColumn: PropTypes.bool,
  showClassroomStartDateColumn: PropTypes.bool,
  showClassroomDurationColumn: PropTypes.bool,
  showClassroomTimeColumn: PropTypes.bool,
  showLmsStartDateColumn: PropTypes.bool,
  showLmsDurationColumn: PropTypes.bool,
  showLmsTimeColumn: PropTypes.bool,
  showSessionsColumn: PropTypes.bool,
  alwaysHideSessionsColumn: PropTypes.bool,
};

export default withIntegration(withTranslation()(Details));
