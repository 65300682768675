import gql from 'graphql-tag';

const getCategories = gql`
  query getCategories(
    $categoryFieldFilter: [CategoryFieldFilter]
    $categoryFieldOrder: CategoryFieldOrder
  ) {
    categories(
      filters: $categoryFieldFilter
      order: $categoryFieldOrder
      first: 1000
    ) {
      edges {
        node {
          id
          name
          shortDescription
          parent {
            id
            name
          }
          categories {
            edges {
              node {
                name
                id
                shortDescription
              }
            }
          }
        }
      }
    }
  }
`;

const getCategoryById = gql`
  query getCategoryWithCourses($categoryId: String!, $subcategoryId: String) {
    category: categories(
      filters: [{ field: id, value: $categoryId, operation: eq }]
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
    subcategory: categories(
      filters: [{ field: id, value: $subcategoryId, operation: eq }]
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const getCategoryWithCourses = gql`
  query getCategoryWithCourses($categoryId: String!) {
    categories(filters: [{ field: id, value: $categoryId, operation: eq }]) {
      edges {
        node {
          id
          name
          shortDescription
          products {
            edges {
              node {
                id
                ... on Course {
                  code
                }
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default { getCategories, getCategoryWithCourses, getCategoryById };
