import React from 'react';
import { observer } from 'mobx-react-lite';

import { Container } from '@administrate/piston';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';
import { withTranslation } from 'react-i18next';

import withIntegration from '../../containers/withIntegration';
import { pathDetails as pathDetailsProptype } from '../../proptypes/pathDetails';
import HTMLContainer from '../HTMLContainer';
import inject from '../../containers/inject';
import STORES from '../../constants/stores';
import configStore from '../../stores/configStore';
import LegacyModal from '../ModalOutlet/LegacyModal';
import PathObjectives from './PathObjectives/PathObjectives';

const { STORE_ANALYTICS } = STORES;

const Details = ({
  [STORE_ANALYTICS]: { captureEvent },
  pathDetails,
  isLoading,
  isError,
  redirect,
  setPathObjective,
  requireObjectiveFulfillment,
  integration,
  t,
}) => {
  const { locale } = configStore;

  return (
    <div
      className={
        isLoading
          ? 'withpanel detail defined-background loading'
          : 'withpanel detail defined-background'
      }
    >
      <StickyContainer>
        {!integration && (
          <Sticky topOffset={0}>
            {({ style }) => (
              <div
                className="container-fluid Sticky sticky-title"
                style={style}
              >
                <div className="container">
                  <span className="title">{pathDetails.name}</span>
                </div>
              </div>
            )}
          </Sticky>
        )}
        <LegacyModal
          title={t('weblink:weAreSorry')}
          show={isError}
          cancelLabel={t('weblink:backToCatalogue')}
          onCancel={redirect}
        >
          <h3>
            {t('weblink:notFoundHeader', {
              item: t('weblink:learningPath'),
            })}
          </h3>
          <p>{t('weblink:noCourseBody')}</p>
        </LegacyModal>
        {!isError && (
          <div className="detail-body">
            <Container>
              <Col sm={12} md={8} className="CourseDetail">
                <Row>
                  {(isLoading || pathDetails.description) && (
                    <div className="content-row course-description">
                      <h2>{t('weblink:description')}</h2>
                      {!isLoading ? (
                        <HTMLContainer content={pathDetails.description} />
                      ) : (
                        <p />
                      )}
                    </div>
                  )}
                </Row>
                <Row>
                  {!isLoading && pathDetails.id ? (
                    <PathObjectives
                      learningPathId={pathDetails.id}
                      learningObjectives={pathDetails.objectives}
                      requireObjectiveFulfillment={requireObjectiveFulfillment}
                      onObjectiveSelection={(objectiveId, event) => {
                        setPathObjective(objectiveId, event);
                      }}
                      locale={locale}
                      onEventSearchCompleted={captureEvent}
                    />
                  ) : (
                    <p />
                  )}
                </Row>
              </Col>
            </Container>
          </div>
        )}
      </StickyContainer>
    </div>
  );
};

Details.defaultProps = {
  pathDetails: {},
  requireObjectiveFulfillment: false,
};

Details.propTypes = {
  t: PropTypes.func.isRequired,
  pathDetails: pathDetailsProptype,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
  setPathObjective: PropTypes.func.isRequired,
  requireObjectiveFulfillment: PropTypes.bool,
};

export default withIntegration(
  withTranslation()(inject(STORE_ANALYTICS)(observer(Details))),
);
