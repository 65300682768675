import gql from 'graphql-tag';
import fragments from './fragments';

const getEventByFilter = gql`
  query getEventByFilter(
    $filters: [EventFieldFilter!]!
    $orderBy: [EventFieldOrder!]!
    $offset: Int!
    $first: Int!
  ) {
    events(
      filters: $filters
      offset: $offset
      first: $first
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalRecords
      }
      edges {
        node {
          ...Event
        }
      }
    }
  }
  ${fragments.event}
`;

const getEventAvailablePlaces = gql`
  query getEventAvailablePlaces($eventId: String!) {
    events(filters: [{ field: id, operation: eq, value: $eventId }]) {
      edges {
        node {
          id
          remainingPlaces
        }
      }
    }
  }
`;

export default {
  getEventByFilter,
  getEventAvailablePlaces,
};
