import React from 'react';
import PropTypes from 'prop-types';
import { PageTitle as PageTitleBar } from '@administrate/piston';

import HTMLContainer from './HTMLContainer';

const PageTitle = ({ children, pageTitle, shortDescription, className }) => (
  <PageTitleBar className={className}>
    {children}
    {pageTitle && <h1>{pageTitle}</h1>}
    {shortDescription && (
      <HTMLContainer className="short-description" content={shortDescription} />
    )}
  </PageTitleBar>
);

PageTitle.defaultProps = {
  children: null,
  pageTitle: null,
  shortDescription: null,
  className: null,
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  pageTitle: PropTypes.string,
  className: PropTypes.string,
  shortDescription: PropTypes.string,
};

export default PageTitle;
