import React, { cloneElement } from 'react';
import { LabelledInput, TextInput } from '@administrate/piston';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Icon from './Icon/Icon';

const ValidatedLabelledInput = ({
  label,
  onChange,
  value,
  isValid,
  disabled,
  t,
  errorMessage,
  children,
  required,
  id,
}) => {
  const displayError = (required && !value) || !isValid;
  return (
    <div className={displayError ? 'ValidatedLabelledInput_invalid' : ''}>
      <LabelledInput label={label}>
        {children ? (
          cloneElement(children, {
            ariaLabel: label,
            onChange,
            value,
            disabled,
            required,
            ...children.props,
          })
        ) : (
          <TextInput
            id={id}
            ariaLabel={label}
            required={required}
            onChange={onChange}
            value={value || ''}
            disabled={disabled}
          />
        )}
      </LabelledInput>
      {displayError && (
        <div
          style={{
            margin: '-10px 0 10px 0',
          }}
        >
          <Icon glyph="exclamation-sign" />
          <span
            style={{
              marginLeft: 3,
            }}
          >
            {errorMessage || t('weblink:required')}
          </span>
        </div>
      )}
    </div>
  );
};

ValidatedLabelledInput.defaultProps = {
  disabled: false,
  value: null,
  isValid: false,
  errorMessage: null,
  children: null,
  required: false,
};

ValidatedLabelledInput.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  /* eslint-disable-next-line */
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
};

export default withTranslation()(ValidatedLabelledInput);
