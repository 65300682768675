import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import inject from '../../containers/inject';
import { STORES } from '../../constants';

const { STORE_NAVIGATION, STORE_STORE } = STORES;

const TrainingRequestLink = ({
  t,
  interestId,
  interestName,
  [STORE_NAVIGATION]: { toRequestTraining },
  [STORE_STORE]: { allowTrainingRequests },
}) =>
  allowTrainingRequests && (
    <Button
      bsClass="training-request-link btn"
      bsStyle="link"
      onClick={() => toRequestTraining(interestId, interestName)}
    >
      {t('weblink:requestTraining')}
    </Button>
  );

TrainingRequestLink.propTypes = {
  t: PropTypes.func.isRequired,
  interestId: PropTypes.string.isRequired,
  interestName: PropTypes.string.isRequired,
};

export default withTranslation()(
  inject(STORE_NAVIGATION, STORE_STORE)(observer(TrainingRequestLink)),
);
