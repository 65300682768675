import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { StickyContainer, Sticky } from 'react-sticky';
import { Col, Row } from 'react-bootstrap';
import { LoadingBar } from '@administrate/piston';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import inject from '../inject';
import Breadcrumb from '../Breadcrumb';
import PageTitle from '../../components/PageTitle';
import { STORES } from '../../constants';
import Details from '../../components/Details/Details';
import DetailsCard from '../../components/Details/DetailsCard';
import HTMLContainer from '../../components/HTMLContainer';
import setBrandedPageTitle from '../../utils/setBrandedPageTitle';
import getImageUrls from './getImageUrls';
import DetailsCardSkeleton from '../../components/Details/DetailsCardSkeleton';
import { PageViewEvent, ProductEvent } from '../../analytics';

const { STORE_ANALYTICS, STORE_DETAILS, STORE_STORE } = STORES;

const CourseDetails = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_DETAILS]: {
    courseDetails,
    isLoading,
    isError,
    eventGridData,
    navigateBackToCatalogue,
    queryCourseDetails,
    isRegisterLoading,
  },
  [STORE_STORE]: { formatCurrency, hidePrices, storeDetails, currency },
  integration,
  code,
  categoryId,
  eventListOrder,
  subcategoryId,
  showBreadcrumb,
  showDateFilter,
  showLocationFilter,
  // Event List Columns
  showTitleColumn,
  showLocationColumn,
  showVenueColumn,
  showStartDateColumn,
  showEndDateColumn,
  showDateAndTimeColumn,
  showDurationColumn,
  showTimeColumn,
  showPlacesRemainingColumn,
  showPriceColumn,
  showAddToCartColumn,
  showClassroomStartDateColumn,
  showClassroomDurationColumn,
  showClassroomTimeColumn,
  showLmsStartDateColumn,
  showLmsDurationColumn,
  showLmsTimeColumn,
  showSessionsColumn,
  alwaysHideSessionsColumn,
  embedded,
}) => {
  useEffect(() => {
    if (courseDetails?.code !== code) {
      queryCourseDetails({ code });
    }
  }, [code, queryCourseDetails, courseDetails?.code]);

  useEffect(() => {
    if (storeDetails && !isLoading && courseDetails?.name) {
      setBrandedPageTitle(
        [courseDetails.name],
        storeDetails,
        !(integration || embedded),
      );
      captureEvent(new PageViewEvent());
    }
  }, [
    storeDetails,
    isLoading,
    courseDetails?.name,
    integration,
    embedded,
    captureEvent,
  ]);

  useEffect(() => {
    if (!isLoading && !isError && courseDetails.id) {
      captureEvent(
        ProductEvent.fromCourseView({
          course: courseDetails,
          currencyCode: currency.code,
        }),
      );
    }
  }, [isLoading, !isError, courseDetails.id, captureEvent, currency.code]);

  return (
    <>
      <Helmet
        meta={[
          {
            name: 'Description',
            content: courseDetails.teaserDescription,
          },
        ]}
      />
      <StickyContainer
        className={
          isLoading
            ? 'details_course withpanel loading'
            : 'details_course withpanel'
        }
      >
        <div className="details_loading-bar">
          <Sticky>
            {({ style }) => (
              <div style={{ ...style, zIndex: 2 }}>
                <LoadingBar isLoading={isLoading || isRegisterLoading} />
              </div>
            )}
          </Sticky>
        </div>
        <PageTitle className="detail">
          <Row>
            <Col sm={7} md={8}>
              <div className={`course-overview course-${courseDetails.id}`}>
                {!isLoading && showBreadcrumb && (
                  <Breadcrumb
                    courseName={courseDetails.name}
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                  />
                )}
                <h1>{!isLoading && <span>{courseDetails.name}</span>}</h1>
                <div className="short-description">
                  {!isLoading && (
                    <HTMLContainer content={courseDetails.teaserDescription} />
                  )}
                </div>
              </div>
            </Col>
            <Col sm={5} md={4}>
              {window.innerWidth < 992 && (
                <div className="course-information-panel show-mobile">
                  {isLoading ? (
                    <DetailsCardSkeleton />
                  ) : (
                    <DetailsCard
                      normalPrice={courseDetails.normalPrice}
                      imageUrls={getImageUrls(courseDetails)}
                      formatCurrency={formatCurrency}
                      displayPrices={!hidePrices}
                    />
                  )}
                </div>
              )}
              {window.innerWidth >= 992 && (
                <div className="course-information-panel show-desktop">
                  <Sticky topOffset={26}>
                    {({ style, isSticky }) => (
                      <div
                        style={{
                          ...style,
                          marginTop: isSticky ? '50px' : null,
                        }}
                      >
                        {isLoading ? (
                          <DetailsCardSkeleton />
                        ) : (
                          <DetailsCard
                            normalPrice={courseDetails.normalPrice}
                            imageUrls={getImageUrls(courseDetails)}
                            formatCurrency={formatCurrency}
                            displayPrices={!hidePrices}
                          />
                        )}
                      </div>
                    )}
                  </Sticky>
                </div>
              )}
            </Col>
          </Row>
        </PageTitle>
        <Details
          code={code}
          courseDetails={courseDetails}
          isLoading={isLoading}
          eventGridData={eventGridData}
          isError={isError}
          redirect={navigateBackToCatalogue}
          eventListOrder={eventListOrder}
          showDateFilter={showDateFilter}
          showLocationFilter={showLocationFilter}
          showTitleColumn={showTitleColumn}
          showLocationColumn={showLocationColumn}
          showVenueColumn={showVenueColumn}
          showStartDateColumn={showStartDateColumn}
          showEndDateColumn={showEndDateColumn}
          showDateAndTimeColumn={showDateAndTimeColumn}
          showDurationColumn={showDurationColumn}
          showTimeColumn={showTimeColumn}
          showPlacesRemainingColumn={showPlacesRemainingColumn}
          showClassroomStartDateColumn={showClassroomStartDateColumn}
          showClassroomDurationColumn={showClassroomDurationColumn}
          showClassroomTimeColumn={showClassroomTimeColumn}
          showLmsStartDateColumn={showLmsStartDateColumn}
          showLmsDurationColumn={showLmsDurationColumn}
          showLmsTimeColumn={showLmsTimeColumn}
          showSessionsColumn={showSessionsColumn}
          alwaysHideSessionsColumn={alwaysHideSessionsColumn}
          showPriceColumn={!hidePrices && showPriceColumn}
          showAddToCartColumn={showAddToCartColumn}
        />
      </StickyContainer>
    </>
  );
};

CourseDetails.propTypes = {
  code: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  eventListOrder: PropTypes.shape({
    field: PropTypes.oneOf([
      'title',
      'locationName',
      'start',
      'classroomStart',
      'lmsStart',
    ]),
    direction: PropTypes.oneOf(['asc', 'desc']),
  }),
  subcategoryId: PropTypes.string,
  showBreadcrumb: PropTypes.bool,
  showDateFilter: PropTypes.bool,
  showLocationFilter: PropTypes.bool,
  showTitleColumn: PropTypes.bool,
  showLocationColumn: PropTypes.bool,
  showVenueColumn: PropTypes.bool,
  showStartDateColumn: PropTypes.bool,
  showEndDateColumn: PropTypes.bool,
  showDateAndTimeColumn: PropTypes.bool,
  showDurationColumn: PropTypes.bool,
  showTimeColumn: PropTypes.bool,
  showPlacesRemainingColumn: PropTypes.bool,
  showClassroomStartDateColumn: PropTypes.bool,
  showClassroomDurationColumn: PropTypes.bool,
  showClassroomTimeColumn: PropTypes.bool,
  showLmsStartDateColumn: PropTypes.bool,
  showLmsDurationColumn: PropTypes.bool,
  showLmsTimeColumn: PropTypes.bool,
  showPriceColumn: PropTypes.bool,
  showAddToCartColumn: PropTypes.bool,
  showSessionsColumn: PropTypes.bool,
  alwaysHideSessionsColumn: PropTypes.bool,
  embedded: PropTypes.bool,
};

CourseDetails.defaultProps = {
  categoryId: null,
  subcategoryId: null,
  eventListOrder: {
    field: 'locationName',
    direction: 'asc',
  },
  showBreadcrumb: true,
  showDateFilter: true,
  showLocationFilter: true,
  showTitleColumn: false,
  showLocationColumn: true,
  showVenueColumn: false,
  showStartDateColumn: true,
  showEndDateColumn: false,
  showDateAndTimeColumn: false,
  showDurationColumn: true,
  showTimeColumn: true,
  showPlacesRemainingColumn: true,
  showClassroomStartDateColumn: false,
  showClassroomDurationColumn: false,
  showClassroomTimeColumn: false,
  showLmsStartDateColumn: false,
  showLmsDurationColumn: false,
  showLmsTimeColumn: false,
  showPriceColumn: true,
  showAddToCartColumn: true,
  showSessionsColumn: true,
  alwaysHideSessionsColumn: false,
  embedded: false,
};

export default withTranslation()(
  inject(STORE_ANALYTICS, STORE_DETAILS, STORE_STORE)(observer(CourseDetails)),
);
