import { DATETIME_FORMATTING } from '../constants/index';

export const formatDate = luxonDate => {
  if (!luxonDate || !luxonDate.zoneName) {
    return null;
  }

  return luxonDate.toLocaleString(DATETIME_FORMATTING.DEFAULT_DATE);
};

export const formatDateWithTime = luxonDate =>
  luxonDate.toLocaleString(DATETIME_FORMATTING.DEFAULT_DATE_WITH_TIME);

export const formatDateWithTimeAndTZ = luxonDate =>
  luxonDate.toLocaleString(DATETIME_FORMATTING.DEFAULT_DATE_WITH_TIME_AND_TZ);

export const formatTime = luxonDate =>
  luxonDate.toLocaleString(DATETIME_FORMATTING.DEFAULT_TIME);

export const formatTimeWithTZ = luxonDate =>
  luxonDate.toLocaleString(DATETIME_FORMATTING.DEFAULT_TIME_WITH_TZ);

export const formatDateLong = luxonDate =>
  luxonDate.toLocaleString(DATETIME_FORMATTING.DEFAULT_DATE_LONG);

export const momentToLocalIsoWithoutOffset = momentValue =>
  momentValue.format(DATETIME_FORMATTING.MOMENT_LOCAL_DATETIME);
