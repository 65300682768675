import React, { useState } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Checkbox,
  CustomFieldInput,
  Form,
  FormStateStatus,
  LoadingScreen,
  Submit,
  Typeahead,
  useDetailFormState,
  useTypedFormValues,
  ValidationErrors,
} from '@administrate/piston-ux';

import { get } from 'lodash';
import {
  useWebLinkClient,
  useWebLinkMutation,
  useWebLinkQuery,
} from '../../weblink-hooks';

import TrainingRequestCompleteCard from './TrainingRequestCompleteCard';

const storeQuery = gql`
  query trainingRequestWorkflow {
    store {
      trainingRequestWorkflow {
        enabled
        id
        successMessage
        workflowFields {
          id
          label
          position
          required
          type
          options {
            value
            label
          }
        }
      }
      region {
        id
      }
    }
  }
`;

const catalogQuery = gql`
  query catalog($filters: [CatalogueFieldFilter!]) {
    catalogue(filters: $filters) {
      edges {
        node {
          __typename
          ... on Course {
            id
            name
          }
          ... on LearningPath {
            id
            name
          }
        }
      }
    }
  }
`;

const requestTrainingMutation = gql`
  mutation requestTraining($input: TrainingRequestInput!) {
    requestTraining(input: $input) {
      trainingRequest {
        id
      }
      errors {
        label
        value
        message
      }
    }
  }
`;

const trainingRequestWorkflowFieldPrefix = 'field_';
const defaultSuccessMessage = 'Thank you for submitting your request.';

const extractCatalogNodes = result =>
  get(result, 'data.catalogue.edges', []).map(edge => edge.node);

const getRequestFieldsFromFormValues = values =>
  Object.keys(values)
    .filter(key => key.startsWith(trainingRequestWorkflowFieldPrefix))
    .map(name => [name.slice(trainingRequestWorkflowFieldPrefix.length), name])
    .map(([fieldId, formKey]) => ({
      id: fieldId,
      value: values[formKey],
    }));

const TrainingRequest = ({ t, interestId, showGoHomeButton, showHeader }) => {
  const [requestComplete, setRequestComplete] = useState(false);
  const client = useWebLinkClient();

  const { loading, data } = useWebLinkQuery(storeQuery);

  const values = useTypedFormValues({
    isPrivate: false,
    interest: null,
  });

  const [requestTraining, { error: requestTrainingError }] = useWebLinkMutation(
    requestTrainingMutation,
  );

  const {
    messages,
    saveState,
    setMessages,
    setSaveState,
  } = useDetailFormState();

  const loadSingleCatalogOption = () =>
    client
      .query({
        query: catalogQuery,
        variables: {
          filters: [
            {
              field: 'id',
              operation: 'eq',
              value: interestId,
            },
          ],
        },
      })
      .then(result => {
        const nodes = extractCatalogNodes(result);

        const [{ id, name }] = nodes;
        values.interest = { id, name };

        return nodes;
      });

  const loadCatalogOptions = inputValue =>
    client
      .query({
        query: catalogQuery,
        variables: {
          filters: [
            {
              field: 'name',
              operation: 'like',
              value: `%${inputValue}%`,
            },
            {
              field: 'name',
              operation: 'ne',
              value: '',
            },
          ],
        },
      })
      .then(extractCatalogNodes);

  if (loading) return <LoadingScreen />;

  const onSubmit = async formValues => {
    setSaveState('saving');

    const response = await requestTraining({
      variables: {
        input: {
          interestId: formValues.interest.id,
          regionId: get(data, 'store.region.id', null),
          isPrivate: formValues.isPrivate,
          requestFields: getRequestFieldsFromFormValues(formValues),
        },
      },
    });

    const validationErrors = response.data.requestTraining.errors;

    if (requestTrainingError) {
      setSaveState('failed');
      setMessages({ failed: requestTrainingError.message });
      return;
    }

    if (validationErrors && validationErrors.length > 0) {
      setSaveState('errors');
      setMessages({
        errors: (
          <ValidationErrors
            title="Encountered an error while Requesting Training"
            mutationValidationErrors={validationErrors}
          />
        ),
      });
      return;
    }

    setMessages({
      success: get(
        data,
        'store.trainingRequestWorkflow.successMessage',
        defaultSuccessMessage,
      ),
    });
    setSaveState('saved');
    setRequestComplete(true);
  };

  const requiredFieldValidator = label => v =>
    !v
      ? t('weblink:requiredValidationMessage', {
          label,
          interpolation: { escapeValue: false },
        })
      : true;

  return (
    <>
      {requestComplete ? (
        <TrainingRequestCompleteCard
          interestName={values.interest.name}
          successMessage={get(
            data,
            'store.trainingRequestWorkflow.successMessage',
            defaultSuccessMessage,
          )}
          showGoHomeButton={showGoHomeButton}
        />
      ) : (
        <div className="training-request-form-container">
          {showHeader && (
            <>
              <br />
              <h1>Training Request</h1>
              <br />
            </>
          )}
          <FormStateStatus saveState={saveState} messages={messages} />
          <Form
            onSubmit={onSubmit}
            values={values}
            disabled={saveState === 'saving'}
          >
            <Checkbox
              name="isPrivate"
              description={t('weblink:isPrivateTrainingRequest')}
            />
            <Typeahead
              label="Training Interest"
              name="interest"
              loadOptions={
                interestId ? loadSingleCatalogOption : loadCatalogOptions
              }
              disabled={!!interestId}
              formatter={catalogOption => catalogOption.name}
              valid={requiredFieldValidator('Training Interest')}
              placeholder={interestId ? 'Loading...' : 'Select...'}
            />
            {get(data, 'store.trainingRequestWorkflow.workflowFields', []).map(
              field => (
                <CustomFieldInput
                  key={field.id}
                  name={`${trainingRequestWorkflowFieldPrefix}${field.id}`}
                  label={field.label}
                  type={field.type}
                  options={field.options}
                  valid={v =>
                    field.required
                      ? requiredFieldValidator(field.label)(v)
                      : true
                  }
                  uniqueId={field.id}
                />
              ),
            )}
            <Submit inline />
          </Form>
        </div>
      )}
    </>
  );
};

TrainingRequest.propTypes = {
  t: PropTypes.func.isRequired,
  interestId: PropTypes.string,
  showGoHomeButton: PropTypes.bool,
  showHeader: PropTypes.bool,
};

TrainingRequest.defaultProps = {
  interestId: '',
  showGoHomeButton: true,
  showHeader: true,
};

export default withTranslation()(TrainingRequest);
