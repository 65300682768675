/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import { LoadingBar } from '@administrate/piston';
import PropTypes from 'prop-types';

import inject from '../../containers/inject';
import TrainingRequestLink from '../TrainingRequest/TrainingRequestLink';
import { STORES } from '../../constants';

const { STORE_DETAILS } = STORES;

const EventTable = ({
  t,
  className,
  columns,
  showTaxInclusivePriceInfo,
  showTaxExclusivePriceInfo,
  isLoading,
  data,
  [STORE_DETAILS]: { courseDetails },
}) => {
  const renderHead = useCallback(
    (
      {
        label,
        noshow,
        className: columnClassName,
        sortable,
        onSort,
        active,
        direction,
        disabled,
      },
      key,
    ) => {
      if (noshow) {
        return null;
      }
      return sortable ? (
        <th
          scope="col"
          className={`event-table-header ${
            sortable ? 'event-table-header__sortable' : ''
          } ${active ? 'event-table-header__active' : ''} ${columnClassName ||
            ''}`}
          key={key}
          onClick={onSort}
          disabled={disabled}
        >
          {label}
          {active &&
            (direction === 'asc' ? (
              <Glyphicon glyph="arrow-down" />
            ) : (
              <Glyphicon glyph="arrow-up" />
            ))}
        </th>
      ) : (
        <th
          scope="col"
          className={`event-table-header ${
            sortable ? 'event-table-header__sortable' : ''
          } ${active ? 'event-table-header__active' : ''} ${columnClassName ||
            ''}`}
          key={key}
        >
          {label}
        </th>
      );
    },
    [],
  );

  const head = useMemo(
    () => columns.map((column, key) => renderHead(column, key)),
    [columns, renderHead],
  );

  const renderRow = useCallback((column, key, rowData) => {
    const { className: columnClassName, label, noshow } = column;
    return !noshow ? (
      <td
        className={`event-table-body ${columnClassName || ''}`}
        key={key}
        data-label={label}
      >
        {column.content(rowData)}
      </td>
    ) : null;
  }, []);

  const body = useMemo(
    () =>
      data.map((rowData, key) => (
        <tr key={key}>
          {columns.map((column, columnKey) =>
            renderRow(column, columnKey, rowData),
          )}
        </tr>
      )),
    [data, columns, renderRow],
  );

  return (
    <div className={className}>
      <table>
        <thead>
          <tr>{head}</tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
      {!data.length && !isLoading && (
        <div className="empty-event-grid">
          {t('weblink:noEvents')}
          {courseDetails && courseDetails.id && courseDetails.name && (
            <TrainingRequestLink
              interestId={courseDetails.id}
              interestName={courseDetails.name}
            />
          )}
        </div>
      )}
      <LoadingBar isLoading={isLoading} />
      {showTaxInclusivePriceInfo && (
        <div className="priceInfo">{`*${t(
          'weblink:eventListTaxInclusivePriceInfo',
        )}`}</div>
      )}
      {showTaxExclusivePriceInfo && (
        <div className="priceInfo">{`*${t(
          'weblink:eventListTaxExclusivePriceInfo',
        )}`}</div>
      )}
    </div>
  );
};

EventTable.defaultProps = {
  className: '',
};

EventTable.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showTaxInclusivePriceInfo: PropTypes.bool.isRequired,
  showTaxExclusivePriceInfo: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export default withTranslation()(inject(STORE_DETAILS)(observer(EventTable)));
