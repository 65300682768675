/* eslint-disable */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// TODO: rework this component for cart table to use hooks
class ResponsiveDataGrid extends Component {
  checkColumnType = ({ columntype }) =>
    `grid-detail ${columntype ? columntype : ''}`;

  renderHead({ label, columntype }, key) {
    return (
      <div
        className={this.checkColumnType({ columntype })}
        key={key}
        role="columnheader"
      >
        {label}
      </div>
    );
  }

  renderRow(column, key, data) {
    const { columntype, label } = column;
    return (
      <div
        className={this.checkColumnType({ columntype })}
        key={key}
        role="cell"
      >
        {!this.props.hideKey && (
          <div className="detail-key">{label && `${label}:`}</div>
        )}
        <div
          className={
            this.props.hideKey
              ? 'detail-value detail-value__no-key '
              : 'detail-value'
          }
        >
          {column.content(data)}
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    const head = this.props.columns.map((column, key) =>
      this.renderHead(column, key),
    );

    if (this.props.data) {
      var body = this.props.data.map((data, key) => (
        <div
          className="grid-row"
          key={key}
          onClick={this.props.onRowClick}
          role="row"
        >
          {this.props.columns.map((column, key) =>
            this.renderRow(column, key, data),
          )}
        </div>
      ));
    } else {
      return <>{t('weblink:noResults')}</>;
    }

    const { type } = this.props;

    return (
      <div
        className={type ? 'ResponsiveDataGrid ' + type : 'ResponsiveDataGrid'}
        role="table"
      >
        <div className="grid-head" role="rowgroup">
          <div className="grid-row" role="row">
            {head}
          </div>
        </div>
        <div className="grid-body" role="rowgroup">
          {body}
        </div>
      </div>
    );
  }
}

export default withTranslation()(observer(ResponsiveDataGrid));
