import { noop as _noop } from 'lodash/noop';

class CallbackStore {
  constructor(rootStore, callbacks = {}) {
    this.rootStore = rootStore;
    this.navigateToCheckoutCallback =
      callbacks.navigateToCheckoutCallback || _noop; // is called when to user would like to navigate to the checkout process
    this.navigateToCatalogueCallback =
      callbacks.navigateToCatalogueCallback || _noop; // is called when the user would like to navigate to course catalogue
    this.navigateToCourseDetailCallback =
      callbacks.navigateToCourseDetailCallback || _noop; // is called when the user would like to navigate to a specific course, the *code* of the course will be provided as a parameter
    this.navigateToGiftVouchersCallback =
      callbacks.navigateToGiftVouchersCallback || _noop; // is called when the user would like to navigate to the gift vouchers product page
    this.navigateToHomeCallback = callbacks.navigateToHomeCallback || _noop; // is called when to user would like to navigate to the home
    this.navigateToCartCallback = callbacks.navigateToCartCallback || _noop; // is called when to user would like to navigate to the cart
    this.navigateToAuthErrorCallback =
      callbacks.navigateToAuthErrorCallback || _noop; // is called when server respond with 'Portal not found' message
    this.navigateToPathDetailCallback =
      callbacks.navigateToPathDetailCallback || _noop; // is called when the user would like to navigate to a specific path, the *pathId* of the path will be provided as a parameter
    this.navigateToCategoryCallback =
      callbacks.navigateToCategoryCallback || _noop; // is called when the user would like to navigate to a specific category, the *categoryId* of the category will be provided as a parameter
    this.navigateToRequestTrainingCallback =
      callbacks.navigateToRequestTrainingCallback || _noop; // is called when the user would like to navigate to the training request
  }
}

export default CallbackStore;
