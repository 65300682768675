/* eslint-disable max-classes-per-file */

import { toJS } from 'mobx';

const AnalyticsEvents = {
  PAGE_VIEW: 'page_view',
  SEARCH: 'search',
  CART_ADD: 'add_to_cart',
  CART_REMOVE: 'remove_from_cart',
  CART_VIEW: 'view_cart',
  CHECKOUT_BEGIN: 'begin_checkout',
  CHECKOUT_BOOKER_DETAILS_SUBMITTED: 'add_shipping_info',
  CHECKOUT_LEARNER_DETAILS_SUBMITTED: 'add_learner_info',
  CHECKOUT_PAYMENT_DETAILS_SUBMITTED: 'add_payment_info',
  CHECKOUT_COMPLETE: 'purchase',
  PRODUCT_VIEW: 'view_item',
};

const PAYMENT_PROVIDER_TYPE_MAP = {
  stripe: 'card',
  converge: 'card',
};

export class SearchEvent {
  static SearchType = {
    CATALOGUE: 'catalogue',
    NAME_SEARCH: 'name_search',
    EVENTS: 'events',
    LEARNING_PATH_OBJECTIVES: 'learning_path_objectives',
  };

  constructor({ searchType, searchTerm, filters, resultCount }) {
    this.payload = {
      event: AnalyticsEvents.SEARCH,
      search_type: searchType,
      search_term: searchTerm ?? null,
      filters: toPlainArrayOfPlainObjects(filters ?? []),
      result_count: resultCount ?? 0,
    };
  }

  static fromSearchBarInput({ searchTerm, resultCount }) {
    return new SearchEvent({
      searchType: this.SearchType.NAME_SEARCH,
      searchTerm,
      resultCount,
    });
  }

  static fromCatalogueSearch({ searchTerm, filters, response }) {
    const resultCount = response?.catalogue?.pageInfo?.totalRecords;
    return new SearchEvent({
      searchType: this.SearchType.CATALOGUE,
      searchTerm,
      filters,
      resultCount,
    });
  }

  static fromEventSearch({ filters, resultCount }) {
    return new SearchEvent({
      searchType: this.SearchType.EVENTS,
      filters,
      resultCount,
    });
  }

  static fromLearningPathObjectivesSearch({ filters, resultCount }) {
    return new SearchEvent({
      searchType: this.SearchType.LEARNING_PATH_OBJECTIVES,
      filters,
      resultCount,
    });
  }
}

export class CartEvent {
  constructor({ event, cart, cartItems, quantity, paymentDetails = {} }) {
    const giftVoucherApplication = cart.giftVoucherApplications?.[0];
    this.payload = {
      event,
      ecommerce: {
        transaction_id: cart.id,
        currency: cart?.currency?.code,
        subtotal: Number(cart.price.subTotal || 0),
        discount: Number(cart.price.discountTotal || 0),
        value: Number(cart.price.grandTotal || 0),
        tax: Number(cart.price.taxTotal || 0),
        coupon: cart.promotionalCode,
        gift_voucher: giftVoucherApplication?.giftVoucher?.code ?? null,
        gift_voucher_value: Number(giftVoucherApplication?.amount ?? 0),
        ...paymentDetails,
        items: (cartItems ?? cart.items).map(item => ({
          item_id: this.mapItemId(item),
          item_name: item?.course,
          ...mapCategories(item?.categories),
          item_variant: this.mapVariant(item),
          price_level: item?.priceLevel?.name,
          price: Number(item?.unitAmount ?? 0),
          tax: Number(item?.unitTaxAmount ?? 0),
          quantity: Number(quantity ?? item?.quantity ?? 1),
          location_id: item?.location?.id,
          location_name: item?.location?.name,
          code: item?.code,
          learning_tags: mapLearningTags(item?.learningTags),
          is_featured: item?.isFeatured ?? false,
          start_datetime: item?.startDateTime?.toISO(),
          account_associations: mapAccountAssociations(
            item?.accountAssociations,
          ),
        })),
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  mapItemId = cartItem =>
    cartItem?.courseId ?? cartItem?.pathId ?? cartItem?.giftVoucherId;

  // eslint-disable-next-line class-methods-use-this
  mapVariant = item =>
    item?.isPath
      ? 'learningPath'
      : item?.isGiftVoucher
      ? 'giftVoucher'
      : 'course';

  static fromCartAdd({ cart, cartItem }) {
    return new CartEvent({
      event: AnalyticsEvents.CART_ADD,
      cart,
      cartItems: [cartItem],
    });
  }

  static fromCartChange({ cart, updatedItem, previousItemProps }) {
    const quantityDiff =
      updatedItem.quantity -
      (previousItemProps?.quantity ?? updatedItem.quantity);
    return new CartEvent({
      event:
        quantityDiff > 0
          ? AnalyticsEvents.CART_ADD
          : AnalyticsEvents.CART_REMOVE,
      cart,
      cartItems: [updatedItem],
      quantity: Math.abs(quantityDiff),
    });
  }

  static fromCartRemove({ cart, cartItem }) {
    return new CartEvent({
      event: AnalyticsEvents.CART_REMOVE,
      cart,
      cartItems: [cartItem],
    });
  }

  static fromCartView({ cart }) {
    return new CartEvent({
      event: AnalyticsEvents.CART_VIEW,
      cart,
    });
  }

  static fromCheckoutBegin({ cart }) {
    return new CartEvent({
      event: AnalyticsEvents.CHECKOUT_BEGIN,
      cart,
    });
  }

  static fromBookerDetailsSubmitted({ cart }) {
    return new CartEvent({
      event: AnalyticsEvents.CHECKOUT_BOOKER_DETAILS_SUBMITTED,
      cart,
    });
  }

  static fromLearnerDetailsSubmitted({ cart }) {
    return new CartEvent({
      event: AnalyticsEvents.CHECKOUT_LEARNER_DETAILS_SUBMITTED,
      cart,
    });
  }

  static fromPaymentDetailsSubmitted({ cart, paymentType }) {
    if (!paymentType) {
      return null;
    }
    return new CartEvent({
      event: AnalyticsEvents.CHECKOUT_PAYMENT_DETAILS_SUBMITTED,
      cart,
      paymentDetails: this.mapPaymentDetails(paymentType),
    });
  }

  static fromCheckoutComplete({ cart, paymentType }) {
    return new CartEvent({
      event: AnalyticsEvents.CHECKOUT_COMPLETE,
      cart,
      paymentDetails: this.mapPaymentDetails(paymentType),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  static mapPaymentDetails(paymentType) {
    return {
      payment_type:
        PAYMENT_PROVIDER_TYPE_MAP[paymentType] || paymentType || null,
    };
  }
}

export class PageViewEvent {
  constructor() {
    this.payload = {
      event: AnalyticsEvents.PAGE_VIEW,
    };
  }
}

export class ProductEvent {
  constructor({ product, itemVariant, currency }) {
    this.payload = {
      event: AnalyticsEvents.PRODUCT_VIEW,
      ecommerce: getProductPayload({
        product,
        itemVariant,
        currency,
      }),
    };
  }

  static fromCourseView({ course, currencyCode }) {
    return new ProductEvent({
      product: course,
      itemVariant: 'course',
      currency: currencyCode,
    });
  }

  static fromLearningPathView({ path, currencyCode }) {
    return new ProductEvent({
      product: path,
      itemVariant: 'learningPath',
      currency: currencyCode,
    });
  }
}

function getProductPayload({ product, itemVariant, currency }) {
  const commonItemPayload = {
    item_id: product.id,
    item_name: product.name,
    item_variant: itemVariant,
    code: product.code,
    is_featured: product.isFeatured,
    ...mapCategories(product.categories),
    learning_tags: mapLearningTags(product.learningTags),
    account_associations: mapAccountAssociations(product.accountAssociations),
  };

  switch (itemVariant) {
    case 'course': {
      const price = Number(product.normalPrice);
      return {
        currency,
        value: price,
        items: [
          {
            ...commonItemPayload,
            price,
          },
        ],
      };
    }

    case 'learningPath': {
      const price = Number(product.price.amount);
      return {
        currency,
        value: price,
        items: [
          {
            ...commonItemPayload,
            price,
          },
        ],
      };
    }

    default:
      return {};
  }
}

function mapAccountAssociations(accountAssociations) {
  return (
    accountAssociations?.map(({ account, associationType }) => ({
      name: account.name,
      type: associationType.name,
    })) ?? []
  );
}

function mapLearningTags(learningTags) {
  return learningTags?.map(({ name }) => name) ?? [];
}

function mapCategories(categories) {
  // MobX warns if we don't check the length of the array and try to access
  // an index that doesn't exist. This is to avoid that warning.
  return {
    item_category:
      categories && categories.length >= 1 ? categories?.[0]?.name : null,
    item_category_2:
      categories && categories.length >= 2 ? categories?.[1]?.name : null,
    item_category_3:
      categories && categories.length >= 3 ? categories?.[2]?.name : null,
    item_category_4:
      categories && categories.length >= 4 ? categories?.[3]?.name : null,
    item_category_5:
      categories && categories.length >= 5 ? categories?.[4]?.name : null,
  };
}

export function supplementEventWithCommonData(event, { userId, locale }) {
  return {
    ...event,
    user_id: userId,
    page_location: window.location.href,
    page_title: window.document.title,
    language: locale,
  };
}
/**
 *
 * Gets rid of any MobX ObservableArray or ObservableObject
 */
function toPlainArrayOfPlainObjects(filters) {
  return filters.map(({ field, operation, value, values }) => ({
    field,
    operation,
    value,
    values: toJS(values),
  }));
}
