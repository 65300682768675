import React from 'react';
import PropTypes from 'prop-types';
import { Notice } from '@administrate/piston';
import { withTranslation } from 'react-i18next';

const FormIncompleteNotice = ({ t }) => (
  <Notice
    title={`${t('weblink:formIncomplete')}`}
    body={`${t('weblink:formIncompleteBody')}`}
    type="danger"
  />
);

FormIncompleteNotice.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FormIncompleteNotice);
