import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@administrate/piston-ux';
import { LocationsFilter } from './LocationsFilter';

export const FiltersView = ({
  setFilters,
  showLocationsFilter,
  allLocations,
  allLocationsIsLoading,
  setFilteredLocations,
  filteredLocations,
  courseCode,
  setCourseCode,
  isEventFilters,
}) => {
  const applyFilters = () => {
    setCourseCode(isEventFilters ? courseCode : null);
    setFilters();
  };

  return (
    <div className="filters-list">
      {showLocationsFilter && (
        <LocationsFilter
          allLocations={allLocations}
          allLocationsIsLoading={allLocationsIsLoading}
          setFilteredLocations={setFilteredLocations}
          filteredLocations={filteredLocations}
        />
      )}

      <Button
        className="btn btn-primary"
        onClick={applyFilters}
        label="Apply filters"
      />
    </div>
  );
};

FiltersView.defaultProps = {
  showLocationsFilter: true,
  allLocations: {},
  allLocationsIsLoading: false,
  isEventFilters: false,
  courseCode: null,
};

FiltersView.propTypes = {
  setFilters: PropTypes.func.isRequired,
  showLocationsFilter: PropTypes.bool,
  allLocations: PropTypes.shape({}),
  allLocationsIsLoading: PropTypes.bool,
  setFilteredLocations: PropTypes.func.isRequired,
  isEventFilters: PropTypes.bool,
  filteredLocations: PropTypes.shape({}).isRequired,
  courseCode: PropTypes.string,
  setCourseCode: PropTypes.func.isRequired,
};
